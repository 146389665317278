export default {
    administrator: {
        current_password: "Mật khẩu cũ",
        new_password: "Mật khẩu mới",
        new_password_confirmation: "Xác nhận mật khẩu mới",
        changePassword: "Đổi mật khẩu",
        validateErrorPassword: "Mật khẩu mới phải có ít nhất 6 ký tự",
        errorPassword: "Mật khẩu xác nhận không khớp",
        confirmResetPasswordTitle: "Khôi phục mật khẩu?",
        confirmResetPassword: "Mật khẩu sẽ được đặt lại về mặc định",
        adminResetPassword: "Khôi phục mật khẩu mặc định",
        member: "Thành viên",
        signIn: "Đăng nhập",
        invalidFieldMessage: "Trường này không hợp lệ.",
        login: "Đăng nhập",
        username: "Tên đăng nhập",
        forgotPassword: "Quên mật khẩu?",
        placeholderUsername: "Tên đăng nhập",
        placeholderPassword: "Mật khẩu",
        resetPassword: "Đặt lại mật khẩu",
        forgotPasswordInstructions: "Nhập địa chỉ email của bạn và chúng tôi sẽ gửi cho bạn một email với hướng dẫn đặt lại mật khẩu của bạn.",
        forgotPasswordEmailSent:"Chúng tôi đã gửi cho bạn một email có chứa liên kết để đặt lại mật khẩu của bạn",
        email: "Email",
        backTo: "Quay lại",
        myAccount: "Tài khoản của tôi",
        logout: "Đăng xuất",
        welcome: "Chào mừng",
        loginError: "Tên đăng nhập hoặc mật khẩu không chính xác",
        itemRequired: "Bạn bắt buộc phải nhập sản phẩm",
        newStaff: "Nhân viên mới",
        fullName: "Họ tên đầy đủ",
        role: "Vị trí",
        lastLogin: "Đăng nhập lần cuối vào",
        active: "Đang hoạt động",
        view: "Xem",
        delete: "Xóa",
        profile: "Thông tin cá nhân",
        permissions: "Quyền hạn",
        extraPermissions: "Quyền ngoài vai trò",
        factorAuth: "Bật xác thực 2 yếu tố",
        factorAuthTooltip:
            "Xác thực hai yếu tố được cung cấp qua email, trước khi bật xác thực hai yếu tố, hãy đảm bảo rằng cài đặt SMTP của bạn được định cấu hình đúng và hệ thống có thể gửi email. Khóa xác thực duy nhất sẽ được gửi đến thư khi đăng nhập.",
        notStaff: "Không phải nhân viên",
        profileImage: "Ảnh đại diện",
        firstName: "Tên",
        lastName: "Họ",
        hourlyRate: "Tiền công theo giờ",
        phone: "Điện thoại",
        defaultLang: "Ngôn ngữ mặc định",
        emailSign: "Chữ ký email",
        deleteCustomerGroup: "Xoá nhóm khách hàng",
        deleteService: "Xóa dịch vụ",
        deleteSpamFilter: "Xóa bộ lọc spam",
        deletePredefinedReply: "Xoá câu trả lời mặc định",
        deletePriority: "Xoá vé ưu đãi",
        deleteDepartment: "Xoá phòng ban",
        direction: "Định hướng",
        memberDepartment: "Các phòng ban của thành viên",
        sendWelcome: "Gửi thư chào mừng",
        admin: "Người quản lý",
        password: "Mật khẩu",
        fieldRequired: "Hãy nhập.",
        validEmail: "Hãy nhập email.",
        feature: "Vai trò",
        capabilities: "Khả năng",
        bulkPDF: "Xuất tập tin PDF lớn",
        contracts: "Hợp đồng",
        creditNotes: "Ghi chú tín dụng",
        customers: "Khách hàng",
        emailTemplate: "Email mẫu",
        estimate: "Báo giá",
        expenses: "Chi phí",
        invoices: "Hóa đơn",
        items: "Sản phẩm",
        knowledgeBase: "Kiến thức chuyên môn",
        payments: "Thanh toán",
        projects: "Các dự án",
        proposals: "Đề xuất kế hoạch",
        reports: "Báo cáo",
        staffRoles: "Vị trí nhân viên",
        settings: "Cài đặt",
        staff: "Nhân viên",
        subscriptions: "Đăng ký",
        tasks: "Phân công",
        taskCheckTemplate: "Mẫu danh sách công việc ",
        leads: "Khách tiềm năng",
        viewGlobal: "Xem (Chung)",
        viewOwn: "Xem (Riêng)",
        create: "Tạo",
        edit: "Sửa",
        
        name: "Tên",
        options: "Cài đặt",
        
        save: "Lưu lại",
        close: "Đóng",
        totalLoggedTime: "Tổng thời gian đăng nhập",
        lastMonthLoggedTime: "Thời gian đăng nhập tháng trước",
        thisMonthLoggedTime: "Thời gian đăng nhập tháng này",
        lastWeekLoggedTime: "Thời gian đăng nhập tuần trước",
        thisWeekLoggedTime: "Thời gian đăng nhập tuần này",
        noteTitle: "Ghi chú",
        newNote: "Ghi chú mới",
        addFrom: "Được thêm từ",
        dateAdd: "Ngày thêm",
        timesheetTitle: "Biểu đồ thời gian & Báo cáo",
        apply: "Áp dụng",
        period: "Giai đoạn",
        task: "Phân công",
        startTime: "Thời gian bắt đầu",
        endTime: "Thời gian kết thúc",
        related: "Có liên quan",
        time: "Giờ",
        decimal: "Hiển thị thập phân",
        totalByHourlyRate: "Tổng số theo giờ chấm công",
        projectName: "Tên dự án",
        startDate: "Ngày bắt đầu",
        deadline: "Hạn chót",
        status: "Trạng thái",
        noteDescription: "Mô tả ghi chú",
        newDepartment: "Phòng ban mới",
        departmentEmail: "Email phòng ban",
        googleCalendar: "Google Calendar ID",
        departmentName: "Tên của phòng ban",
        hideClient: "Ẩn với khách?",
        imapUsername: "Tên người dùng IMAP",
        imapHost: "IMAP Host",
        encryption: "Mã hóa",
        noEncryption: "Không có mã hóa",
        deleteMailAfterImport: "Xóa thư sau khi nhập vào?",
        testIMAP: "Kiểm tra đường tuyển IMAP",
        editDepartment: "Chỉnh sửa phòng ban",
        radioYes: "Có",
        radioNo: "Không",
        require: "Trường này là bắt buộc .",
        customergroup: {
            addNewTitleDialog: "Thêm mới Nhóm khách hàng",
            editTitleDialog: "Chỉnh sửa Nhóm khách hàng",
            addNew: "Thêm mới",
        },
        taxRate: {
            newTax: "Thuế mới",
            id: "ID",
            taxName: "Tên loại thuế",
            ratePercent: "Tỉ giá thuế (phần trăm)",
            taxRate: "Tỉ giá thuế (phần trăm)",
            editTax: "Sửa thuế",
            addNewTax: "Thêm thuế mới",
        },
        currencyCode: {
            newCurrency: "Loại tiền tệ mới",
            symbol: "Symbol",
            currencyCode: "Currency Code",
            decimalSeparator: "Dấu phân cách hàng thập phân",
            thousandSeparator: "Dấu phân cách hàng nghìn",
            currencyPlacement: "ách đặt đơn vị tiền",
            beforeAmount: "Trước số tiền",
            afterAmount: "Sau số tiền",
            alertValidCurrency: "Hãy chắc chắn bạn nhập mã tiền tệ chính xác .",
            isoCode: "ISO Code",
            addNewCurrency: "Thêm loại tiền tệ mới",
            editCurrency: "Chỉnh sửa loại tiền tệ",
        },
        paymentMode: {
            newPaymentMode: "Hình thức thanh toán mới",
            addNewPaymentMode: "Thêm hình thức thanh toán mới",
            editPaymentMode: "Sửa hình thức thanh toán",
            notePaymentMode:
                "Lưu ý: Các phương thức thanh toán dưới đây dành cho ngoại tuyến. Thanh toán trực tuyến có thể tùy chỉnh trong Thiết lập -> Cài đặt -> Cổng thanh toán",
            paymentModeName: "Tên gọi hình thức thanh toán",
            bankAcc_descriptions: "ài khoản ngân hàng / Mô tả",
            showBankAcc_descriptionPDF:
                "Hiển thị Tài khoản ngân hàng / Mô tả trên bản PDF của hóa đơn",
            selectedByInvoice: "Được chọn mặc định trên hóa đơn",
            invoicesOnly: "Invoices Only",
            expensesOnly: "Expenses Only",
        },
        ///////////////////////////
        category: {
            newCategory: "Chi phí mới",
            editExpenseCategory: "Chỉnh sửa chi phí mở rộng",
            categoryName: "Tên chi phí",
            categoryDescription: "Mô tả chi phí",
            descriptions: "Mô tả",
        },
        lead: {
            source: {
                table: {
                    name: "Tên nguồn",
                    options: "Cài đặt",
                    total: "Tổng số mục tiêu",
                },
                add: "Nguồn khách mới",
                edit: "Sửa nguồn",
            },
            status: {
                table: {
                    name: "Status Name",
                    options: "Options",
                    total: "Total Leads",
                },
                add: "New Lead Status",
                edit: "Edit Status",
                name: "Status Name",
                color: "Color",
                order: "Order",
            },
            email: {
                emailTitle: "Tích hơp email",
                spamFilters: "Lọc yêu cầu rác",

                addSpamFilter: "Thêm bộ lọc thư rác",
                editSpamFilter: "Sửa bộ lọc thu rác",
                type: "Loại",
                content: "Nội dung",

                isActive: "Kích hoạt",
                IMAP: "IMAP Server",
                defaultStatus: "Trạng thái mặc định",
                defaultSource: " Nguồn mặc định",
                email: "Địa chỉ Email( đăng nhập)",
                password: "Mật khẩu",
                responsible: "Chịu trách nhiệm cho mục tiêu mới",
                encryption: "Mã hóa",
                TLS: "TLS",
                SSL: "SSL",
                noEncryption: "Không mã hóa",
                notification: "Thiết lập thông báo",
                notify1: "Thông báo khi có mục tiêu được nhập",
                notify2: "Thông báo nếu mục tiêu gửi thưu nhiều lần",
                folder: "Thư mục",
                every: "Kiểm tra mỗi (minutes)",
                radio1: "Nhân viên nắm vai trò ",
                radio2: "Thành viên cụ thể",
                redio3: "Người chịu trách nhiêm",
                checkbox1: " Chỉ kiểm tra thư chưa được mở",
                checkbox2:
                    "  Create task if email sender is already customer and assign to responsible staff member.",
                checkbox3: "Xóa thư sau khi nhập vào ?",
                checkbox4: "Tự động đánh dấu công khai",

                arrowText1:
                    "The script will auto set the email to opened after check. This is used to prevent checking all the emails again and again. Its not recommended to uncheck this option if you have a lot emails and you have setup a lot forwarding to the email you setup for leads",
                arrowText2:
                    "Used for further review on the submission and take the necessary action",

                staffMember1: "Nhân viên nắm vai trò",
                staffMember2: "Thành viên cụ thể",
                responsibleMember: "Người chịu trách nhiêm",
                staffNotify: "Nhân viên cần thông báo",
                imapConnection: "TEST IMAP CONNECTION",
            },
            web: {
                table: {
                    newForm: "Mẫu đơn mới",
                    formName: "Tên mẫu đơn",
                    totalSubmission: "Tổng số lần đã gửi",
                    create: "Đã tạo",
                },
                title: "Tạo mẫu đơn trước tiên để có thể sử dụng mục xây dựng.",
                source: "Nguồn",
                language: "? Ngôn ngữ",
                status: "Trạng thái",
                submitButtonText: "Nội dung chữ trên nút gửi đi",
                responsible: "Người chịu trách nhiệm (người đảm nhiệm)",
                message: "Tin nhắn hiển thị sau khi gửi mẫu đi thành công",
                notification: "Thiết lập thông báo",
                notify1: "Thông báo khi có mục tiêu được nhập",
                autoMark: "Tự động đánh dấu công khai",
                allowDuplicate:
                    "Cho phép sao đôi Khách tìm năng được chèn vào cơ sở dữ liệu?",
                staffMember1: "Nhân viên nắm vai trò",
                staffMember2: "Thành viên cụ thể",
                responsibleMember: "Người chịu trách nhiêm",
                staffNotify: "Nhân viên cần thông báo",
                arrowLang: "Sử dụng cho tin nhắn xác nhận",

                newCategory: "Chi phí mới",
                editExpenseCategory: "Chỉnh sửa chi phí mở rộng",
                categoryName: "Tên chi phí",
                categoryDescription: "Mô tả chi phí",
                descriptions: "Descriptions",
                prevent_duplicate: "Tránh việc sao chép các mục",
                add_field:
                    "+ mục (để trống nếu muốn theo dõi các bản sao thông qua duy nhất 1 mục)",
                create_lead:
                    "Tạo bản sao dữ liệu Khách tìm năng thành phân công và chỉ định cho nhân viên đảm nhiệm",
                support: {
                    predefinedReplies: {
                        newPredefinedReply: "Mẫu phản hồi mới",
                        predefinedReplyName: "Tên gọi mẫu phản hồi",
                        options: "Cài đặt",
                        addNewPredefined: "Thêm mẫu phản hồi mới",
                        editPredefined: "Chỉnh sửa mẫu phản hồi",
                    },
                    ticketPriority: {
                        newPriority: "Quyền ưu tiên mới",
                        addPriority: "Thêm mức độ ưu tiên",
                        priorityName: "Tên gọi quyền ưu tiên",
                        editPriority: "Sửa mức độ ưu tiên",
                        low: "Thấp",
                        medium: "Trung bình",
                        high: "Cao",
                    },
                    ticketStatus: {
                        newTicketStatus: "Trạng thái liên hệ hỗ trợ",
                        editTicketStatus: "Sửa trạng thái yêu cầu",
                        ticketStatusName: "Tên trạng thái",
                        pickColor: "Chọn màu",
                        statusOrder: "Thứ tự trạng thái",
                    },
                    services: {
                        newService: "Dịch vụ mới",
                        serviceName: "Tên dịch vụ",
                        editTicketService: "Sửa dịch vụ yêu cầu",
                    },
                },
                editForm: {
                    formBuilder: "Mục xây dựng mẫu đơn",
                    formInfo: "Thông tin và thiết lập mẫu đơn",
                    intergrationCode: "Mã tích hợp",
                    titleEditForm:
                        "Sao chép và dán đoạn mã vào bất kì nơi nào trên trang của bạn để chèn mẫu đơn, ngoài ra bạn có thể tùy chỉnh ngang dọc theo đơn vị px để vừa với trang.",
                },
            },
        },
        setting: {
            general: {
                general: "Tổng quan",
                companyLogoDark: "Logo công ty",
                favicon: "Favicon",
                companyName: "Tên công ty",
                companyMainDomain: "Tên miền chính công ty",
                RTLAdmin: "RTL Khu vực quản trị admin (Right to Left)",
                RTLCustomer: "RTL Khu vực quản trị khách hàng (Right to Left)",
                allowedFileTypes: "Loại tệp được phép",
            },
            company: {
                company: "Thông tin công ty",
                textDescription:
                    "Các thông tin này sẽ được hiển thị trên hóa đơn/báo giá/thanh toán và các văn bản PDF khác có yêu cầu thông tin công ty",
                companyName: "Tên công ty",
                address: "Địa chỉ",
                city: "Thành phố",
                state: "Bang",
                countryCode: "Mã quốc gia",
                zipCode: "Mã bưu chính",
                phone: "Điện thoại",
                vatNumber: "Mã số thuế",
                PDFandHTML: "Định dạng thông tin công ty (PDF and HTML)",
                customFields: "Các mục tự tạo",
                label: {
                    companyName: "{company_name}",
                    address: "{address}",
                    city: "{city}",
                    state: "{state}",
                    zipCode: "{zip_code}",
                    countryCode: "{country_code}",
                    phone: "{phone}",
                    vatNumber: "{vat_number}",
                    vatNumberWithLabel: "{vat_number_with_label}",
                },
            },
            localization: {
                localization: "Ngôn ngữ và thời gian",
                dateFormat: "Định dạng ngày",
                timeFormat: "Định dạng thời gian",
                defaultTimezone: "Múi giờ mặc định",
                defaultLanguage: "Ngôn ngữ mặc định",
                disableLanguages: "Vô hiệu hóa ngôn ngữ",
                outputClient:
                    "Xuất văn bản PDF khách hàng từ khu vực admin sang ngôn ngữ khách hàng",
                textTooltip:
                    "Nếu chọn Có cho chức năng này và ví dụ ngôn ngữ mặc định của hệ thống là tiếng Anh còn khách hàng đặt tiếng Pháp, thì văn bản PDF sẽ được xuất theo ngôn ngữ khách hàng đã chọn .",
            },
            email: {
                email: "Email",
                smtpSetting: "Cài đặt SMTP",
                emailQueue: "Hàng đợi Email",
                setupMail: "Thiết lập email chính",
                mailEngine: "Công cụ mail",
                phpMailer: "PHPMailer",
                codeIgniter: "codeIgniter",
                emailProtocol: "Giao thức email",
                smtp: "SMTP",
                sendMail: "Sendmail",
                mail: "Mail",
                emailEncryption: "Mã hóa email",
                smtpHost: "Máy chủ SMTP",
                smtpPort: "Cổng SMTP",
                textTooltip:
                    "Chỉ nhập khi email khách hàng của bạn sử dụng tên người dùng để truy cập SMTP.",
                smtpUsername: "Tên người dùng SMTP",
                smtpPassword: "Mật khẩu SMTP",
                emailCharset: "Bộ chữ email",
                bcc: "BCC All Emails To",
                emailSignature: "Chữ kí email",
                predefinedHeader: "Predefined Header",
                predefinedFooter: "Predefined Footer",
                titleSendTestEmail: "Gửi thử email",
                sendTestEmail:
                    "Gửi thử email để chắc chắn SMTP của bạn được cài đặt chính xác.",
                textHeaderQueue:
                    "Tính năng này yêu cầu một công việc cron job được cấu hình đúng .Trước khi kích hoạt tính năng này, hãy đảm bảo rằng",
                textCronJob: "cron job",
                textFooterQueue: "được cáu hình như giải thích trong tài liệu.",
                toolTipEmailQueue:
                    "Để tăng tốc quá trình emailling, hệ thống sẽ thêm các email vào hàng đợi và sẽ gửi chúng qua cron job, hãy đảm bảo rằng cron job được định cấu hình đúng để sử dụng tính năng này.",
                enableEmailQueue: "Bật hàng đợi email",
                toolTipDoNotAdd:
                    "Nhiều khả năng bạn sẽ gặp sự cố với hàng đợi email nếu hệ thống cần thêm các tệp lớn vào hàng đợi. Nếu bạn định sử dụng tùy chọn này, hãy tham khảo ý kiến ​​của quản trị viên máy chủ / nhà cung cấp dịch vụ lưu trữ để tăng tùy chọn max_allowed_packet và wait_timeout trong cấu hình máy chủ của bạn, nếu không khi tùy chọn này được đặt thành có, hệ thống sẽ không thêm email có tệp đính kèm vào hàng đợi và sẽ gửi ngay lập tức.",
                doNotAddEmail:
                    "Không thêm email có tệp đính kèm trong hàng đợi?",
            },
            finance: {
                finance: "Tài chính",
                general: "Tổng quan",
                generalSetting: "Cài đặt chung",
                decimalSeparator: "Dấu phân cách hàng thập phân",
                thousandSeparator: "Dấu phân cách hàng nghìn",
                tooltipNumber: "Hóa đơn , Báo giá , Đề xuất kế hoạch",
                numberFormats: "Định dạng số kí tự hiển thị",
                noteNumberFormats:
                    "Ví dụ: nếu giá trị mục này là 3 thì số đánh dấu sẽ được định dạng thành 005 hay 025",
                showTAX: "Hiển thị thuế trên sản phẩm",
                tooltipRemove:
                    "Ví dụ: mục THUẾ 15% sẽ được hiển thị là 15% không kèm theo tên thuế ( không áp dụng nếu nhiều loại thuế mang trùng tên và trong mục có chứa phần trăm thuế )",
                removeTax: "Loại bỏ tên thuế trong các dòng danh sách sản phẩm",
                defaultTax: "Thuế mặc định",
                textRemoveDecimals:
                    "Loại bỏ phần thập phân nếu số/giá tiền là số nguyên (2.00 thành 2 nhưng 2.25 vẫn là 2.25)",
                amountToWords: "Viết thành chữ",
                noteAmountToWords:
                    "Xuất tổng giá tiền thành chữ trong hóa đơn/bản báo giá/đề xuất kế hoạch",
                enable: "Bật",
                numberWordsIntoLowercase: "Xuất thành chữ thường",
                fieldRequired: "Hãy nhập.",
                validCurrency: "Hãy nhập từ 3 kí tự trở xuống.",
                invoice: {
                    invoiceNumberPrefix: "Tiền tố thứ tự của hóa đơn",
                    tooltipNextInvoiceNumber:
                        "Nhập 1 vào mục này nếu bạn muốn tiền hành từ đầu",
                    nextInvoiceNumber: "Số hóa đơn kế tiếp",
                    tooltipInvoiceDueAfter:
                        "Đặt giá trị không để tránh cộng vào",
                    invoiceDueAfter: "Hóa đơn chốt hạn sau (ngày)",
                    allowStaff:
                        "Cho phép nhân viên xem hóa đơn nơi họ được chỉ định",
                    requireClient:
                        "Yêu cầu khách hàng đăng nhập để xem hóa đơn",
                    deleteInvoice:
                        "Chỉ áp dụng chức năng xóa cho hóa đơn trước",
                    tooltipDecrement:
                        "Bạn có muốn hạ số thứ tự cho hóa đơn khi có hóa đơn trước nó bị xóa? Ví dụ: Nếu chọn Có thì số hóa đơn kế tiếp là 15 sẽ tự đọng lùi xuống 14. Nếu chọn Không thì vẫn sẽ là 15. Nếu bạn chỉ đặt Không cho hóa đơn trước thì nên đặt Không cho cả hóa đơn này để số hóa đơn kế tiếp không bị lùi xuống.",
                    decrementInvoice:
                        "Tự động giảm số thứ tự khi có hóa đơn bị xóa",
                    excludeInvoices:
                        "Loại trừ hóa đơn mang trạng thái nháp khỏi khu vực khách hàng",
                    showSale: "Hiển thị người bán trên hóa đơn",
                    showProject: "Hiển thị tên dự án trên hóa đơn",
                    showTotal: "Hiển thị Tổng số Thanh toán Trên Hóa đơn",
                    showCredits:
                        "Hiển thị các khoản tín dụng được áp dụng trên hóa đơn",
                    showAmount: "Hiển thị số tiền đến hạn trên hóa đơn",
                    invoiceNumberFormat: "Định dạng số hóa đơn",
                    numberBased: "Hệ số ",
                    yearBased: "Hệ năm",
                    predefinedClientNote: "Ghi chú khách hàng đã tạo trước",
                    predefinedTerms: "Điều khoản & luật lệ đặt trước",
                },
                creditNotes: {
                    creditNoteNumberPrefix: "Tiền tố số giấy báo có",
                    tooltipNextCreditNote:
                        "Nhập 1 vào mục này nếu bạn muốn tiến hành từ đầu",
                    nextCreditNoteNumber: "Số giấy báo có tiếp theo",
                    creditNoteNumberFormat: "Định dạng số giấy báo có",
                    tooltipDecrementCreditNote:
                        "Số lượng sẽ chỉ giảm nếu giấy báo có lần cuối được tạo.",
                    decrementCreditNoteNumber:
                        "Số ghi chú tín dụng giảm dần khi xóa.",
                    showProjectName: "Hiển thị tên dự án trên giấy ghi có",
                    predefinedClientNote: "Ghi chú khách hàng đã tạo trước",
                    predefinedTerms: "Điều khoản & luật lệ đặt trước",
                },
                estimates: {
                    estimateNumberPrefix: "Số báo giá",
                    tooltipNextEstimate:
                        "Nhập 1 vào mục này nếu bạn muốn tiến hành từ đầu",
                    nextEstimateNumber: "Số báo giá tiếp theo",
                    tooltipEstimateDue: "Đặt giá trị không để tránh cộng vào",
                    estimateDueAfter: "Chốt hạn báo giá sau (ngày)",
                    deleteEstimate:
                        "Chỉ cho phép xóa báo giá trên hóa đơn trước",
                    tooltipDecrement:
                        "Bạn có muốn hạ số thứ tự cho hóa đơn khi có hóa đơn trước nó bị xóa? Ví dụ: Nếu chọn Có thì số hóa đơn kế tiếp là 15 sẽ tự đọng lùi xuống 14. Nếu chọn Không thì vẫn sẽ là 15. Nếu bạn chỉ đặt Không cho hóa đơn trước thì nên đặt Không cho cả hóa đơn này để số hóa đơn kế tiếp không bị lùi xuống.",
                    decrementEstimate:
                        "Tự động giảm số thứ tự khi có hóa đơn bị xóa",
                    allowStaff:
                        "Cho phép nhân viên xem các ước tính mà họ được chỉ định",
                    requireClient:
                        "Yêu cầu khách hàng đăng nhập để xem báo giá",
                    showSale: "Hiển thị người bán trên bản báo giá",
                    showProject: "Hiển thị tên dự án trên ước tính",
                    autoConvert:
                        "Tự động chuyển báo giá thành hóa đơn sau khi khách hàng đồng ý",
                    excludeEstimates:
                        "Loại bỏ báo giá có trạng thái Nháp khỏi khu vực khách hàng",
                    estimateNumberFormat: "Định dạng số hóa đơn",
                    pipelineLimit: "Giới hạn cột mỗi trạng thái",
                    defaultPipelineSort: "Sắp xếp cột mặc định",
                    ascending: "Từ dưới lên ",
                    descending: "Từ trên xuống",
                    predefinedClientNote: "Ghi chú khách hàng đã tạo trước",
                    predefinedTerms: "Điều khoản & luật lệ đặt trước",
                },
                proposals: {
                    proposalNumberPrefix: "Số đánh dấu bản đề xuất",
                    tooltipProposalDue: "Đặt giá trị không để tránh cộng vào",
                    proposalDueAfter:
                        "Đề xuất kế hoạch chốt hẹn trong (ngày) nữa",
                    pipelineLimit: "Giới hạn cột mỗi trạng thái",
                    defaultPipelineSort: "Sắp xếp cột mặc định",
                    ascending: "Từ dưới lên ",
                    descending: "Từ trên xuống",
                    excludeProposals:
                        "Loại trừ các đề xuất có trạng thái nháp khỏi khu vực khách hàng",
                    allowStaff:
                        "Cho phép nhân viên xem các đề xuất mà họ được chỉ định",
                    proposalInfoFormat:
                        "Định dạng thông tin đề xuất (PDF and HTML)",
                    proposalTo: "{proposal_to}",
                    address: "{address}",
                    city: "{city}",
                    state: "{state}",
                    zipCode: "{zip_code}",
                    countryCode: "{country_code}",
                    countryName: "{country_name}",
                    phone: "{phone}",
                    email: "{email}",
                },
            },
            subscriptions: {
                subscriptions: "Đăng ký",
                tooltipSubscriptions:
                    "Tùy chọn này chỉ hợp lệ cho người liên hệ chính của khách hàng.",
                textShowSubscriptions:
                    "Hiển thị đăng ký trong khu vực khách hàng?",
                textAfterSucceeded: "Sau khi thanh toán đăng ký thành công",
                invoiceAndPayment: "Gửi hóa đơn và biên lai thanh toán",
                invoice: "Gửi hóa đơn",
                payment: "Gửi biên lai thanh toán",
                doNothing: "Không làm gì cả",
                emailTemplate: "Mẫu email: ",
                contentEmailTemplate: "Đăng ký thanh toán thành công",
            },
            paymentGateways: {
                paymentGateways: "Cổng thanh toán",
                general: "General",
                paypalSmart: "Paypal Smart CheckOut",
                paypal: "Paypal",
                payU: "PayU Money",
                stripeCheck: "Stripe CheckOut",
                stripeIdeal: "Stripe iDEAL",
                _2check: "2Check Out",
                aim: "Authorize AIM",
                sim: "Authorize SIM",
                instamojo: "Instamojo",
                mollie: "Mollie",
                brainTree: "Brain Tree",
                generalTab: {
                    receiveNotify:
                        "Nhận thông báo khi khách hàng thanh toán hóa đơn (cài đặt sẵn)",
                    allowModify:
                        "Cho phép khách hàng tùy chỉnh số tiền trả (áp dụng cho thanh toán trực tuyến)",
                },
                active: "Kích hoạt",
                label: "Nhãn",
                clientId: "Mã khách hàng",
                secret: "Secret",
                dashbord: "Gateway Dashbord Payment Description",
                currencies: "Đơn vị tiền (phân cách bằng dấu phẩy)",
                currency: "Currency",
                enableTest: "Kích hoạt kiểm tra",
                selectedInvoice: "Được chọn mặc định trên hóa đơn",
                devMode: "Developer Mode",
                enablePaypal: "Enable PayPal Payments",
                allowContact:
                    "Cho phép người liên hệ chính cập nhật mã thông báo thẻ tín dụng được lưu trữ?",
                paypalUser: "Tên người dùng PayPal API",
                paypalPass: "Mật khẩu PayPal API",
                sign: "Chữ ký API",
                payUKey: "PayU Money Key",
                payUSalt: "PayU Money Salt",
                stripeSecretKey: "Mã Stripe API riêng tư",
                stripePublishKey: "Mã Stripe công khai",
                stateDesc:
                    "Bộ mô tả sao kê (được hiển thị trong bảng sao kê ngân hàng của khách hàng)",
                stateDescInfo:
                    "Các bộ mô tả câu lệnh được giới hạn trong 22 ký tự, không được sử dụng các ký tự đặc biệt, ',' hoặc * và không được chỉ chứa các số.",
                accNumber: "Số tài khoản (ID người bán)",
                privateKey: "Private Key",
                publishKey: "Publish Key",
                secretKey: "Mã bí mật",
                _2checkTitle:
                    "Yêu cầu SSL nếu bạn đang sử dụng thanh toán API của 2Checkout. Khuyến khích thực hiện cuộc gọi an toàn cho bên cấp quyền và token. Hệ thống vẫn sẽ hoạt động nếu không có SSL, tuy nhiên, điều này sẽ đi ngược lại mong muốn của bạn, làm tổn hại đến quyền hạn vô hiệu hóa API của bạn.",
                aimTitle:
                    "Yêu cầu SSL nếu bạn đang sử dụng thanh toán API của Authorize.net; Authorize.net chỉ hỗ trợ 1 đơn vị tiền cho mỗi tài khoản. Hãy chắc chắn bạn chỉ thêm đúng 1 đơn vị tương ứng với tài khoản Authorize của bạn trong mục này.",
                supportCurr: "Các đơn vị tiền đang được hỗ trợ:  ",
                loginId: "ID đăng nhập API",
                transId: "ID giao dịch API",
                ApiKey: "Private API Key",
                AuthToken: "Private Auth Token",
                apiKey: "Mã API",
                merchantId: "ID doanh nghiệp",
            },
            customers: {
                customers: "Khách hàng",
                defaultCustomers: "Nền tảng mặc định của khách hàng",
                defaultCountry: "Quốc gia mặc định",
                visibleTabs: "Visible Tabs(Thông tin cá nhân)",
                companyField: "Yêu cầu mục công ty?",
                companyVatNumber: "Công ty yêu cầu sử dụng mục Mã số thuế",
                customersRegister: "Cho phép khách hàng đăng kí",
                registrationConfirmation:
                    "Yêu cầu xác nhận đăng ký từ quản trị viên sau khi khách hàng đăng ký",
                primaryContact:
                    "Cho phép người liên hệ chính xem / chỉnh sửa chi tiết thanh toán và giao hàng",
                tooltipContact:
                    "Nếu bạn chia sẻ theo phương thức thủ công từ tiểu sử khách hàng sang các liên hệ khác thì họ sẽ nhìn thấy tập tin.",
                contactUploaded:
                    "Các liên hệ chỉ thấy được các tập tin họ tự tải lên trong khu vực khách hàng (các tập tin được tải lên trong tiểu sử khách hàng)",
                contactDelete:
                    "Cho phép liên hệ xóa các tập tin họ đã tải lên trong khu vực khách hàng",
                tooltipKnowledge:
                    "Nếu bạn kích hoạt cài đặt này, KTCM cũng sẽ hiển thị với bên khách hàng",
                knowledgeBase: "Sử dụng Kiến thức chuyên môn",
                knowledgeViewed: "Cho phép xem bài viết KTCM không cần đăng ký",
                contactPermissions: "Quyền hạn liên hệ mặc định",
                tooltipInfoFormat: "Hóa đơn, Báo giá, Thanh toán, Statement",
                infoFormat: "Định dạng thông tin khách hàng (PDF và HTML)",
            },
            tasks: {
                tasks: "Phân công",
                limitTasks: "Giới hạn cột phân công Kan Ban cho mỗi trạng thái",
                allowAllStaff:
                    "Cho phép tất cả nhân viên xem các phân công liên quan đến dự án (bao gồm cả người ngoài công việc)",
                allowCustomerStaff:
                    "Chỉ cho phép khách hàng/nhân viên thêm/chỉnh sửa bình luận bảng phân công trong 1 giờ đầu tiên (không áp dụng cho người quản lý)",
                autoAssignTasks:
                    "Tự động chỉ định người tạo phân công khi phân công được tạo",
                autoAddTask:
                    "Tự động thêm người tạo nhiệm vụ làm người theo dõi nhiệm vụ khi nhiệm vụ mới được tạo",
                stopAll:
                    "Dừng tất cả những mục tính thời gian đang chạy khi bắt đầu mục tính thời gian mới",
                changeTaskStatus:
                    "Thay đổi trạng thái công việc thành Đang tiến hành khi bộ hẹn giờ bắt đầu (chỉ áp dụng nếu trạng thái công việc là Chưa bắt đầu)",
                billableOption:
                    "Tùy chọn có thể lập hóa đơn được chọn theo mặc định khi tác vụ mới được tạo?",
                defaultStatus: "Trạng thái mặc định khi tác vụ mới được tạo",
                defaultPriority: "Mức độ ưu tiên mặc định",
                modalWidthClass:
                    "Lớp chiều rộng phương thức (modal-lg, modal-xl, modal-xxl)",
            },
            support: {
                support: "Hỗ trợ",
                useServices: "Sử dụng dịch vụ",
                allowStaffDepartments:
                    "Chỉ cho phép nhân viên truy cập vào những yêu cầu thuộc về phòng ban đó",
                tooltipReceiveNewTicket:
                    "Tất cả nhân viên thuộc phòng ban yêu cầu sẽ được nhận thông báo khi có yêu cầu hỗ trợ mới được mở",
                receiveNotificationNewTicket:
                    "Nhận thông báo khi có yêu cầu mới chưa mở",
                tooltipReceiveCustomerReply:
                    "Tất cả nhân viên phòng vé sẽ nhận được thông báo khi khách hàng trả lời vé",
                receiveNotificationCustomerReply:
                    "Nhận thông báo khi khách hàng trả lời vé",
                tooltipAllowStaff:
                    "Nếu nhân viên không có thẩm quyền theo dõi khách hàng thì họ chỉ có thể tạo yêu cầu từ khu vực quản trị đến các liên hệ khách hàng mà họ được chỉ định làm quản trị khách hàng.",
                allowStaffMembers:
                    "Cho phép nhân viên mở yêu cầu đến tất cả liên hệ?",
                allowAccess:
                    "Cho phép người dùng không phải nhân viên truy cập vào yêu cầu",
                allowNonAdmin:
                    "Cho phép nhân viên không phải quản trị viên xóa tệp đính kèm vé",
                allowCustomer:
                    "Cho phép khách hàng thay đổi trạng thái yêu cầu ở khi vực khách hàng",
                inCustomers:
                    "Trong khu vực khách hàng chỉ hiển thị yêu cầu liên quan đến liên hệ đã đăng nhập (không áp dụng cho liên hệ cơ bản)",
                tooltipTicket:
                    "Thông báo vé ban đầu sẽ luôn được hiển thị như đầu tiên",
                ticketRepliesOrder: "Yêu cầu trả lời vé",
                ascending: "Từ dưới lên",
                descending: "Từ trên xuống",
                defaultStatus:
                    "Trạng thái mặc định được chọn khi trả lời phiếu",
                maximumTicket: "Số yêu cầu tối đa có thể đính kèm",
                allowedAttachments:
                    "Các định dạng tập tin đính kèm được cho phép",
                emailPiping: {
                    pipeOnly: "Chỉ liên kết với người dùng đã đăng kí",
                    onlyReplies: "Chỉ những phản hồi được email cho phép",
                    tryToImport:
                        "Cố gắng chỉ nhập câu trả lời vé thực tế (không có thông báo được trích dẫn / chuyển tiếp)",
                    defaultPriority:
                        "Mức độ ưu tiên mặc định cho yêu cầu liên kết",
                },
            },
            leads: {
                leads: "Khách tìm năng",
                limitLeads:
                    "Giới hạn hiển thị số dòng Kan Ban của Khách tìm năng cho mỗi trạng thái",
                defaultStatus: "Trạng thái mặc định",
                defaultSource: "Nguồn mặc định",
                performValidation:
                    "Thực hiện xác thực cho khách hàng tiềm năng trùng lặp trên các trường sau:",
                tooltipAutoAssign:
                    "Nếu bạn chọn CÓ cho mục này thì nhân viên đã chuyển mục tiêu sang khách hàng sẽ được chỉ định làm quản trị khách hàng. LƯU Ý: chức năng này chỉ áp dụng cho nhân viên không có thẩm quyền theo dõi khách hàng",
                autoAssign:
                    "Tự động chỉ định khách hàng làm quản trị sau khi chuyển đổi",
                allowNonAdmin:
                    "Cho phép nhân viên không phải quản trị viên nhập khách hàng tiềm năng",
                defaultLeads: "Phân loại Kan Ban mục tiêu mặc định",
                ascending: "Từ dưới lên",
                descending: "Từ trên xuống",
                doNotAllow:
                    "Không cho phép sửa mục tiêu khi đã chuyển đổi sang khách hàng (không áp dụng cho người quản lý)",
                modalWidthClass:
                    "Modal Width Class (modal-lg, modal-xl, modal-xxl)",
            },
            sms: {
                sms: "SMS",
                only: "Chỉ cho phép 1 cổng SMS đang hoạt động",
                clickatell: {
                    clickatell: "Clickatell",
                    titleClickatell:
                        "Tích hợp SMS Clickatell là một cách nhắn tin, có nghĩa là khách hàng của bạn sẽ không thể trả lời SMS.",
                    apiKey: "API Key",
                    active: "Hoạt động",
                },
                msg91: {
                    msg91: "MSG91",
                    titleMsg:
                        "Tích hợp MSG91 SMS là một cách nhắn tin, có nghĩa là khách hàng của bạn sẽ không thể trả lời SMS.",
                    senderID: "Sender ID",
                    authKey: "Auth Key",
                    active: "Hoạt động",
                },
                twilio: {
                    twilio: "Twilio",
                    title: "Tích hợp Twilio SMS là một cách nhắn tin, có nghĩa là khách hàng của bạn sẽ không thể trả lời SMS. Số điện thoại phải ở định dạng E.164. Nhấp vào đây để đọc thêm cách định dạng số điện thoại.",
                    accountSID: "Tài khoản SID",
                    authToken: "Mã xác thực",
                    twilioPhone: "Số điện thoại Twilio ",
                    active: "Hoạt động",
                },
                triggers: "Triggers",
                titleTrigger: "Để trống nội dung để tắt trình kích hoạt cụ thể",
                invoiceOverdueNotice: "Thông báo quá hạn của hóa đơn",
                textInvoiceOverdueNotice:
                    "Kích hoạt khi thông báo quá hạn hóa đơn được gửi đến địa chỉ liên hệ của khách hàng.",
                availableMergeFields: "Những trường đại diện sẵn có",
                invoicePaymentRecorded: "Thanh toán hóa đơn được ghi lại",
                textInvoicePaymentRecorded:
                    "Kích hoạt khi thanh toán hóa đơn được ghi lại.",
                estimate: "Lời nhắc ước tính hết hạn",
                textEstimate:
                    "Kích hoạt khi lời nhắc hết hạn sẽ được gửi đến địa chỉ liên hệ của khách hàng.",
                proposalExpiration: "Lời nhắc Hết hạn Đề xuất",
                textProposalExpiration:
                    "Kích hoạt khi lời nhắc hết hạn sẽ được gửi đến đề xuất.",
                newCommentOnProposalCustomer:
                    "Nhận xét mới về Đề xuất (cho khách hàng)",
                textNewCommentOnProposalCustomer:
                    "Kích hoạt khi nhân viên nhận xét về đề xuất, SMS sẽ được gửi đến số đề xuất (khách hàng / khách hàng tiềm năng).",
                newCommentOnProposalStaff:
                    "Nhận xét mới về Đề xuất (cho nhân viên)",
                textNewCommentOnProposalStaff:
                    "Kích hoạt khi khách hàng / khách hàng tiềm năng nhận xét về đề xuất, SMS sẽ được gửi đến người tạo đề xuất và nhân viên được chỉ định.",
                newCommentOnContractCustomer:
                    "Nhận xét mới về hợp đồng (cho khách hàng)",
                textNewCommentOnContractCustomer:
                    "Kích hoạt khi nhân viên thêm comment vào hợp đồng, SMS sẽ được gửi đến danh bạ khách hàng.",
                newCommentOnContractStaff:
                    "Nhận xét mới về hợp đồng (cho nhân viên)",
                textNewCommentOnContractStaff:
                    "Kích hoạt khi khách hàng thêm nhận xét vào hợp đồng, SMS sẽ được gửi đến người tạo hợp đồng.",
                contractExpirationReminder: "Nhắc nhở hết hạn hợp đồng",
                textContractExpirationReminder:
                    "Kích hoạt khi lời nhắc hết hạn sẽ được gửi qua Cron Job đến các địa chỉ liên hệ của khách hàng.",
                staffReminder: "Nhân viên nhắc nhở",
                textStaffReminder:
                    "Kích hoạt khi nhân viên được thông báo về một lời nhắc tùy chỉnh cụ thể.",
            },
            // /////////////////////////////////////////////
            calendar: {
                calendar: "Calendar",
                general: "General",
                stylings: "Stylings",
                generalTab: {
                    events: "Calendar Events Limits (Months and Week View)",
                    view: "Default View",
                    firstDay: "First Day",
                    showCalendar: "Show On Calendar",
                    hideNotify: "Hide notified reminders from calendar",
                    leadReminder: "Lead Reminders",
                    customerReminder: "Customer Reminders",
                    estimateReminder: "Estimates Reminders",
                    invoiceReminder: "Invoice Reminders",
                    proposalReminder: "Proposal Reminders",
                    expenseReminder: "Expense Reminders",
                    creditNote: "Credit Note Reminders",
                    ticketReminder: "Ticket Reminders",
                    invoices: "Invoices",
                    estimates: "Estimates",
                    proposals: "Proposals",
                    contract: "Contracts",
                    tasks: "Tasks",
                    showOnlyTask:
                        "Show only tasks assigned to the logged in staff member",
                    projects: "Projects",
                },
                stylingTab: {
                    invoice: "Invoice Color",
                    estimates: "Estimates Color",
                    proposal: "Proposal Color",
                    reminder: "Reminder Color",
                    contract: "Contract Color",
                    project: "Project Color",
                },
            },
            pdf: {
                pdf: "PDF",
                general: "General",
                signature: "Signature",
                documentFormats: "Document Formats",
                generalTab: {
                    font: "PDF Font",
                    swapCompany:
                        "Swap Company/Customer Details (company details to right side, customer details to left side)",
                    fontSize: "Default Font size",
                    headingColor: "Items table heading color",
                    headingTextColor: "Items table heading text color",
                    logoUrl: "Custom PDF Company Logo URL",
                    logoWidth: "Logo Width (PX)",
                    showInvoice:
                        "Show Invoice/Estimate/Credit Note status on PDF documents",
                    showPayInvoice:
                        "Show Pay Invoice link to PDF (Not applied if invoice status is Cancelled)",
                    showEstimates:
                        "Show Invoice/Estimate/Credit Note status on PDF documents",
                    showPageNumber: "Show page number on PDF",
                },
                signatureTab: {
                    invoice: "Show PDF Signature on Invoice",
                    estimates: "Show PDF Signature on Estimates",
                    creditNote: "Show PDF Signature on Credit Note",
                    contract: "Show PDF Signature on Contract",
                    image: "Signature Image",
                },
                documentTab: {
                    invoice: "Invoice",
                    estimate: "Estimate",
                    proposal: "Proposal",
                    payment: "Payment",
                    creditNote: "Credit Note",
                    contract: "Contract",
                    statement: "Statement",
                },
            },
            eSign: {
                eSign: "E-Sign",
                proposal: "Proposal",
                requireDigital:
                    "Require digital signature and identity confirmation on accept",
                estimates: "Estimates",
                legal: "Legal Bound Text",
            },
            cronJob: {
                cronJob: "Cron Job",
                invoice: "Invoices",
                estimate: "Estimates",
                proposal: "Proposals",
                expense: "Expenses",
                contract: "Contracts",
                tasks: "Tasks",
                ticket: "Tickets",
                invoiceTab: {
                    hour: "Hour of day to perform automatic operations",
                    hourTooltip:
                        "Used for recurring invoices, overdue notices etc..",
                    sendDay: "Auto send reminder after (days)",
                    reSendDay: "Auto re-send reminder after (days)",
                    recuring: "Recurring Invoices",
                    recuringItem1:
                        "Generate and autosend the renewed invoice to the customer",
                    recuringItem2: "Generate a Unpaid Invoice",
                    recuringItem3: "Generate a Draft Invoice",
                    createInvoice:
                        " Create new invoice from recurring invoice only if the invoice is with status paid?",
                    createInvoiceTooltip:
                        "If this field is set to YES and the recurring invoices is not with status PAID, the new invoice will NOT be created. arrow",
                },
                estimateTab: {
                    sendExp: "Send expiration reminder before (DAYS)",
                    hourTooltip2:
                        "24 hours format eq. 9 for 9am or 15 for 3pm.",
                },
                taskTab: {
                    deadline: "Task deadline reminder before (Days)",
                    deadlineTooltip:
                        "Notify task assignees about deadline before X days.The notification/email is sent only to the assignees. If the difference between task start date and task due date is smaller then the reminders day no notification will be sent.",
                },
                ticketTab: {
                    hours: "Auto close ticket after (Hours)",
                    hourTooltip: "Set 0 to disable",
                },
            },
            tags: {
                tags: "Tags",
            },
            pusher: {
                pusher: "Pusher.com",
                id: "APP ID",
                key: "APP KEY",
                secret: "APP SECRET",
                cluster: "Cluster",
                realTime: "Enable Real Time Notifications",
                realTimeItem1:
                    "Generate and autosend the renewed invoice to the customer",
                realTimeItem2: "Generate a Unpaid Invoice",
                desktop: "Enable Desktop Notifications ",
                desktopItem1:
                    "Generate and autosend the renewed invoice to the customer",
                desktopItem2: "Generate a Unpaid Invoice",
                dismiss:
                    "Auto Dismiss Desktop Notifications After X Seconds (0 to disable)",
            },
            google: {
                google: "Google",
                key: "Google API Key",
                id: "Google API Client ID",
                siteKey: "Site key",
                secretKey: "Secret key",
                enableCaptcha:
                    "Enable reCAPTCHA on customers area (Login/Register)",
                calendar: "Calendar",
                calendarId: "Google Calendar ID",
                picker: "Google Picker",
                enablePicker: "Enable Google Picker",
            },
            misc: {
                misc: "Misc",
                table: "Table",
                inlineCreate: "Inline Create",
                miscTab: {
                    requireLogged:
                        "Require client to be logged in to view contract",
                    dropKey: "Dropbox APP Key",
                    fileSize: "Max file size upload in Media (MB)",
                    filePost: "Maximum files upload on post",
                    limitSearch: "Limit Top Search Bar Results to",
                    staffRole: "Default Staff Role",
                    systemActivity:
                        "Delete system activity log older then X months",
                    showSetup:
                        "Show setup menu item only when hover with mouse on main sidebar area",
                    showHelp: "Show help menu item on setup menu",
                    useFile:
                        "Use minified files version for css and js (only system files)",
                },
                tableTab: {
                    activeScroll: "Activate Scroll Responsive Tables",
                    saveOrder: "Save last order for tables",
                    showTable: "Show table export button",
                    showTableItem1: "To all staff members",
                    showTableItem2: "Only to administrators",
                    showTableItem3: "Hide export button for all staff members",
                    tablePag: "Tables Pagination Limit",
                },
                inlineCreateTab: {
                    allowLabel1:
                        "Allow non-admin staff members to create Lead Status in Lead create/edit area?",
                    allowLabel2:
                        "Allow non-admin staff members to create Lead Source in Lead create/edit area?",
                    allowLabel3:
                        "Allow non-admin staff members to create Expense Category in Expense create/edit area?",
                    allowLabel4:
                        "Allow non-admin staff members to create Customer Group in Customer create/edit area?",
                    allowLabel5:
                        "Allow non-admin staff members to create Service in Ticket create/edit area?",
                    allowLabel6:
                        "Allow non-admin staff members to save predefined replies from ticket message",
                    allowLabel7:
                        "Allow non-admin staff members to create Contract type in Contract create/edit area?",
                },
            },
            systemUpdate: {
                system: "Cập nhật hệ thống",
                purchaseKey: "Mua mã kích hoạt",
                version: "Phiên bản của bạn",
                latestVer: "Phiên bản mới nhất",
                notify: "Bạn đang sử dụng phiên bản mới nhất",
            },
            systemServer: {
                systemInfo: "Thông tin hệ thống/ Máy chủ",
                variable: "Tên thuộc tính",
                value: "Gía trị",
            },
        },
        contract: {
            newContractType: "Loại hợp đồng mới",
            editContractType: "Sửa loại hợp đồng",
        },
        customFields: {
            btnNewCustomfield: "Mục tự tạo mới",
            name: "tên",
            belongs: "Thuộc về",
            type: "Loại",
            slug: "Slug",
            active: "Kích hoạt",
            titleAdd: "Thêm mục tự tạo mới",
            titleEdit: "Chỉnh sửa trường tùy chỉnh",
            fieldBelongs: "Mục này thuộc về",
            fieldName: "Tên gọi",
            options: "Chức năng",
            toolTipOptions:
                "Chỉ dùng cho kiểu CHọn hoặc Đánh dấu. Phân cách các lựa chọn bằng dấu phẩy. Ví dụ:táo,cam,chuối",
            order: "Thứ tự",
            sizeFields: "Grid (ví dụ Bootstrap Column là 12) - Tối đa là 12",
            disabled: "Vô hiệu hóa",
            restrict: "Giới hạn hiển thị cho duy nhất người quản trị",
            required: "Bắt buộc",
            visibility: "Mức độ hiển thị",
            showOnTable: "Hiển thị trên danh sách",
            company: "Công ty",
            nothingSelect: "Không có mục nào được chọn",
            leads: "Khách tìm năng",
            customers: "Khách hàng",
            contacts: "Liên hệ",
            staff: "Nhân viên",
            Contracts: "Hợp đồng",
            tasks: "Phân công",
            expenses: "Thu chi",
            invoice: "Hóa đơn",
            items: "Sản phẩm",
            creditNote: "Ghi chú tín dụng",
            estimate: "Báo giá",
            proposal: "Đề xuất kế hoạch",
            projects: "Các dự án",
            tickets: "Yêu cầu",
        },
        roles: {
            newRole: "Vị trí mới",
            addNewRole: "Thêm vị trí mới",
            editRole: "Chỉnh sửa vị trí",
            roleName: "Tên vị trí",
            staffUsingRole: "Nhân viên đang sử dụng vai trò này",
            totalUsers: "Tất cả thành viên:",
            textChangeRole:
                "Thay đổi quyền hạn vị trí không ảnh hưởng đến quyền hạn thành viên hiện tại của vị trí này.",
            textUpdate:
                "Cập nhật tất cả quyền hạn của nhân viên đang sử dụng vị trí này",
            staffMemberRole: "Nhân viên đang sử dụng vai trò này",
        },
    },
};
