import ActionTypes from "./types";

const INIT_STATE = {
    isLoading: false,
    listCustomFieldProposal: [],
    listCustomer: [],
    listStaff: [],
    isSuccess: false,
    proposalById: {},
    dataEditProposal: {},
};

const ProposalReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_PROPOSAL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.GET_PROPOSAL_SUCCESS:
            return {
                ...state,
                listCustomFieldProposal: action.payload,
                isLoading: false,
            };
        case ActionTypes.GET_PROPOSAL_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.CREATE_PROPOSAL_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        case ActionTypes.CREATE_PROPOSAL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
            };
        case ActionTypes.CREATE_PROPOSAL_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.GET_LIST_CUSTOMER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.GET_LIST_CUSTOMER_SUCCESS:
            return {
                ...state,
                listCustomer: action.payload,
                isLoading: false,
            };
        case ActionTypes.GET_LIST_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.FETCH_LIST_STAFF:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_STAFF_SUCCESS:
            return {
                ...state,
                listStaff: action.payload,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_STAFF_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.GET_PROPOSAL_BY_ID:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.GET_PROPOSAL_BY_ID_SUCCESS:
            return {
                ...state,
                proposalById: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_PROPOSAL_BY_ID_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.DELETE_PROPOSAL:
            return {
                ...state,
                deleteProposalSuccess: false,
            };

        case ActionTypes.DELETE_PROPOSAL_SUCCESS:
            return {
                ...state,
                deleteProposalSuccess: true,
            };

        case ActionTypes.DELETE_PROPOSAL_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.EDIT_PROPOSAL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.EDIT_PROPOSAL_SUCCESS:
            return {
                ...state,
                dataEditProposal: action.payload,
                isSuccess: true,
                isLoading: false,
            };

        case ActionTypes.EDIT_PROPOSAL_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default ProposalReducer;
