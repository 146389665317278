const ActionTypes = {
    GET_LIST_STAFF_REQUEST: "FETCH_LIST_STAFF",
    GET_LIST_STAFF_FAILED: "GET_LIST_STAFF_FAILED",
    GET_LIST_STAFF_SUCCESS: "GET_LIST_STAFF_SUCCESS",
    CREATE_STAFF_REQUEST: "CREATE_STAFF_REQUEST",
    CREATE_STAFF_FAILED: "CREATE_STAFF_FAILED",
    CREATE_STAFF_SUCCESS: "CREATE_STAFF_SUCCESS",
    EDIT_STAFF_REQUEST: "EDIT_STAFF_REQUEST",
    EDIT_STAFF_FAILED: "EDIT_STAFF_FAILED",
    EDIT_STAFF_SUCCESS: "EDIT_STAFF_SUCCESS",
    DELETE_STAFF_REQUEST: "DELETE_STAFF_REQUEST",
    DELETE_STAFF_FAILED: "DELETE_STAFF_FAILED",
    DELETE_STAFF_SUCCESS: "DELETE_STAFF_SUCCESS",
    STAFF_STATUS: "STAFF_STATUS",
    EDIT_STAFF_ITEM: "EDIT_STAFF_ITEM",
    FILTER_STAFF_REQUEST: "FILTER_STAFF_REQUEST",
    FILTER_STAFF_FAILED: "FILTER_STAFF_FAILED",
    FILTER_STAFF_SUCCESS: "FILTER_STAFF_SUCCESS",
    GET_DEPARTMENTS_REQUEST: "GET_DEPARTMENTS_REQUEST",
    GET_DEPARTMENTS_FAILED: "GET_DEPARTMENTS_FAILED",
    GET_DEPARTMENTS_SUCCESS: "GET_DEPARTMENTS_SUCCESS",
    GET_STAFF_EDIT_REQUEST: "GET_STAFF_EDIT_REQUEST",
    GET_STAFF_EDIT_FAILED: "GET_STAFF_EDIT_FAILED",
    GET_STAFF_EDIT_SUCCESS: "GET_STAFF_EDIT_SUCCESS",
    GET_STAFF_NOTE_PENDING: "GET_STAFF_NOTE_PENDING",
    GET_STAFF_NOTE_SUCCESS: "GET_STAFF_NOTE_SUCCESS",
    GET_STAFF_NOTE_FAILURE: "GET_STAFF_NOTE_FAILURE",
    ADD_STAFF_NOTE_PENDING: "ADD_STAFF_NOTE_PENDING",
    ADD_STAFF_NOTE_SUCCESS: "ADD_STAFF_NOTE_SUCCESS",
    ADD_STAFF_NOTE_FAILURE: "ADD_STAFF_NOTE_FAILURE",
    GET_STAFF_TIMESHEET_PENDING: "GET_STAFF_TIMESHEET_PENDING",
    GET_STAFF_TIMESHEET_SUCCESS: "GET_STAFF_TIMESHEET_SUCCESS",
    GET_STAFF_TIMESHEET_FAILURE: "GET_STAFF_TIMESHEET_FAILURE",
    SET_STAFF_STATUS_PENDING: "SET_STAFF_STATUS_PENDING",
    SET_STAFF_STATUS_SUCCESS: "SET_STAFF_STATUS_SUCCESS",
    SET_STAFF_STATUS_FAILURE: "SET_STAFF_STATUS_FAILURE",
    GET_LIST_ROLES: "GET_LIST_ROLES",
    GET_LIST_ROLES_SUCCESS: "GET_LIST_ROLES_SUCCESS",
    GET_LIST_ROLES_FAILED: "GET_ROLES_FAILURE",
    GET_LIST_PERMISSIONS: "GET_LIST_PERMISSIONS",
    GET_LIST_PERMISSIONS_SUCCESS: "GET_LIST_PERMISSIONS_SUCCESS",
    GET_LIST_PERMISSIONS_FAILED: "GET_LIST_PERMISSIONS_FAILED",
    RESET_PASSWORD_PENDING: "RESET_PASSWORD_PENDING",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
    CHANGE_PASSWORD_PENDING: "CHANGE_PASSWORD_PENDING",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
    STAFF_CHANGE_AVATAR_REQUEST: "STAFF_CHANGE_AVATAR_REQUEST",
    STAFF_CHANGE_AVATAR_FAILED: "STAFF_CHANGE_AVATAR_FAILED",
    STAFF_CHANGE_AVATAR_SUCCESS: "STAFF_CHANGE_AVATAR_SUCCESS",
};
export default ActionTypes;