const ActionTypes = {
    FETCH_LIST_EXPENSES: "FETCH_LIST_EXPENSES",
    FETCH_LIST_EXPENSES_FAILED: "FETCH_LIST_EXPENSES_FAILED",
    FETCH_LIST_EXPENSES_SUCCESS: "FETCH_LIST_EXPENSES_SUCCESS",
    GET_EXPENSE_ITEM: "GET_EXPENSE_ITEM",
    SET_ISSUCCESS: "SET_ISSUCCESS",
    FETCH_EXPENSE_BY_ID: "FETCH_EXPENSE_BY_ID",
    FETCH_EXPENSE_BY_ID_FAILED: "FETCH_EXPENSE_BY_ID_FAILED",
    FETCH_EXPENSE_BY_ID_SUCCESS: "FETCH_EXPENSE_BY_ID_SUCCESS",
    FETCH_DELETE_EXPENSE: "FETCH_DELETE_EXPENSE",
    FETCH_DELETE_EXPENSE_FAILED: "FETCH_ DELETE_EXPENSE_FAILED",
    FETCH_DELETE_EXPENSE_SUCCESS: "FETCH_DELETE_EXPENSE_SUCCESS",
    FETCH_PAYMENT_MODE: "FETCH_PAYMENT_MODE",
    FETCH_PAYMENT_MODE_FAILED: "FETCH_PAYMENT_MODE_FAILED",
    FETCH_PAYMENT_MODE_SUCCESS: "FETCH_PAYMENT_MODE_SUCCESS",
    FETCH_LIST_CUSTOMER: "FETCH_LIST_CUSTOMER",
    FETCH_LIST_CUSTOMER_FAILED: "FETCH_LIST_CUSTOMER_FAILED",
    FETCH_LIST_CUSTOMER_SUCCESS: "FETCH_LIST_CUSTOMER_SUCCESS",
    FETCH_LIST_EXPENSES_CATEGORY: "FETCH_LIST_EXPENSES_CATEGORY",
    FETCH_LIST_EXPENSES_CATEGORY_FAILED: "FETCH_LIST_EXPENSES_CATEGORY_FAILED",
    FETCH_LIST_EXPENSES_CATEGORY_SUCCESS: "FETCH_LIST_EXPENSES_CATEGORY_SUCCESS",
    FETCH_CREATE_EXPENSES: "FETCH_CREATE_EXPENSES",
    FETCH_CREATE_EXPENSES_FAILED: "FETCH_CREATE_EXPENSES_FAILED",
    FETCH_CREATE_EXPENSES_SUCCESS: "FETCH_CREATE_EXPENSES_SUCCESS",
    FETCH_EDIT_EXPENSES: "FETCH_EDIT_EXPENSES",
    FETCH_EDIT_EXPENSES_FAILED: "FETCH_EDIT_EXPENSES_FAILED",
    FETCH_EDIT_EXPENSES_SUCCESS: "FETCH_EDIT_EXPENSES_SUCCESS",
    FETCH_LIST_PROJECT: "FETCH_LIST_PROJECT",
    FETCH_LIST_PROJECT_FAILED: "FETCH_LIST_PROJECT_FAILED",
    FETCH_LIST_PROJECT_SUCCESS: "FETCH_LIST_PROJECT_SUCCESS",
    FETCH_LIST_SUMMARY: "FETCH_LIST_SUMMARY",
    FETCH_LIST_SUMMARY_FAILED: "FETCH_LIST_SUMMARY_FAILED",
    FETCH_LIST_SUMMARY_SUCCESS: "FETCH_LIST_SUMMARY_SUCCESS",
    
};
export default ActionTypes;