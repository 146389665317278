const ActionTypes = {
    GET_CONTRACT_TYPE_PENDING: "GET_CONTRACT_TYPE_PENDING",
    GET_CONTRACT_TYPE_SUCCESS: "GET_CONTRACT_TYPE_SUCCESS",
    GET_CONTRACT_TYPE_FAILED: "GET_CONTRACT_TYPE_FAILED",
    ADD_CONTRACT_TYPE_PENDING: "ADD_CONTRACT_TYPE_PENDING",
    ADD_CONTRACT_TYPE_SUCCESS: "ADD_CONTRACT_TYPE_SUCCESS",
    ADD_CONTRACT_TYPE_FAILED: "ADD_CONTRACT_TYPE_FAILED",
    PUT_CONTRACT_TYPE_PENDING: "PUT_CONTRACT_TYPE_PENDING",
    PUT_CONTRACT_TYPE_SUCCESS: "PUT_CONTRACT_TYPE_SUCCESS",
    PUT_CONTRACT_TYPE_FAILED: "PUT_CONTRACT_TYPE_FAILED",
    DELETE_CONTRACT_TYPE_PENDING: "DELETE_CONTRACT_TYPE_PENDING",
    DELETE_CONTRACT_TYPE_SUCCESS: "DELETE_CONTRACT_TYPE_SUCCESS",
    DELETE_CONTRACT_TYPE_FAILED: "DELETE_CONTRACT_TYPE_FAILED",    
};
export default ActionTypes;