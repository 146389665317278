import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import  i18n  from 'i18next';
import {
    getListProposalSuccess,
    getListProposalFailed,
    createProposalSuccess,
    createProposalFailed,
    getListCustomerSuccess,
    getListCustomerFailed,
    fetchListStaffSuccess,
    fetchListStaffFailed,
    getProposalByIdSuccess,
    getProposalByIdFailed,
    editProposalSuccess,
    editProposalFailed,
    deleteProposalSuccess,
    deleteProposalFailed
} from "./action";
import { enqueueSnackbar } from "../../../redux/CommonReducer";
import { getProposalRequest, createProposalResquest, getListCustomerRequest, getListStaff, getProposalByIdRequest, editProposal, deleteProposalRequest } from "./api";
import { responeCode, typeMessage } from "../../../constants/Const";
import ActionTypes from "./types";
import {
    throwErrorMessage,
    parseDataNotifications,
} from "../../../helpers/HandleError";

function* listProposal(data) {
    try {
        const response = yield call(getProposalRequest, data);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListProposalSuccess(response.data.result));
        } else {
            yield put(getListProposalFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListProposalFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* createProposalSaga(params) {
    const { data } = params;
    try {
        const response = yield call(createProposalResquest, data);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(createProposalSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('customer.proposal.action.createProposalSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(createProposalFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('customer.proposal.action.createProposalFailure'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(createProposalFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* getListCustomerSaga(data) {
    try {
        const response = yield call(getListCustomerRequest, data);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListCustomerSuccess(response.data.result));
        } else {
            yield put(getListCustomerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* getListStaffSaga(data) {
    try {
        const response = yield call(getListStaff, data.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListStaffSuccess(response.data.result));
        } else {
            yield put(fetchListStaffFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListStaffFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchProposalById(data) {
    const { payload } = data;
    try {
        const response = yield call(getProposalByIdRequest, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getProposalByIdSuccess(response.data.result));
        } else {
            yield put(getProposalByIdFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getProposalByIdFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
  }
  
  function* editProposalSaga(action) {
    try {
        const response = yield call(editProposal, action.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(editProposalSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('customer.proposal.action.editProposalSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(editProposalFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('customer.proposal.action.editProposalFailure'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(editProposalFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

  
function* deleteProposalSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(deleteProposalRequest, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteProposalSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('customer.proposal.action.deleteProposalSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteProposalFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('customer.proposal.action.deleteProposalFailure'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteProposalFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}


export function* proposalSaga() {
    yield takeEvery(ActionTypes.GET_PROPOSAL_REQUEST, listProposal);
    yield takeEvery(ActionTypes.CREATE_PROPOSAL_REQUEST, createProposalSaga)
    yield takeEvery(ActionTypes.GET_LIST_CUSTOMER_REQUEST, getListCustomerSaga)
    yield takeEvery(ActionTypes.FETCH_LIST_STAFF, getListStaffSaga);
    yield takeEvery(ActionTypes.GET_PROPOSAL_BY_ID, fetchProposalById);
    yield takeEvery(ActionTypes.EDIT_PROPOSAL_REQUEST, editProposalSaga);
    yield takeEvery(ActionTypes.DELETE_PROPOSAL, deleteProposalSaga);

}

function* ProposalSaga() {
    yield all([fork(proposalSaga)]);
}

export default ProposalSaga;
