import apiBase from "../../../common/baseAPI";
const PROPOSAL = "proposal";
const CUSTOMER = "customer";
const STAFF_URL = "staff"
export const getProposalRequest = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(PROPOSAL, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
export const createProposalResquest = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(PROPOSAL, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
export const getListCustomerRequest = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(CUSTOMER)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
export const getListStaff = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(STAFF_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
export const getProposalByIdRequest = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${PROPOSAL}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
  };
  
  export const deleteProposalRequest = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${PROPOSAL}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
  };
  
  export const editProposal = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${PROPOSAL}/${data.id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
  };