const ActionTypes = {
    GET_SALE_INVOICE_REQUEST: "GET_SALE_INVOICE_REQUEST",
    GET_SALE_INVOICE_SUCCESS: "GET_SALE_INVOICE_SUCCESS",
    GET_SALE_INVOICE_FAILED: "GET_SALE_INVOICE_FAILED",
    
    ADD_INVOICE_REQUEST: "ADD_INVOICE_REQUEST",
    ADD_INVOICE_SUCCESS: "ADD_INVOICE_SUCCESS",
    ADD_INVOICE_FAILED: "ADD_INVOICE_FAILED",
    
    GET_INVOICE_BY_ID: "GET_INVOICE_BY_ID",
    GET_INVOICE_BY_ID_SUCCESS: "GET_INVOICE_BY_ID_SUCCESS",
    GET_INVOICE_BY_ID_FAILED: "GET_INVOICE_BY_ID_FAILED",
    
    DELETE_INVOICE: "DELETE_INVOICE",
    DELETE_INVOICE_SUCCESS: "DELETE_INVOICE_SUCCESS",
    DELETE_INVOICE_FAILED: "DELETE_INVOICE_FAILED",
    
    EDIT_INVOICE_REQUEST: "EDIT_INVOICE_REQUEST",
    EDIT_INVOICE_SUCCESS: "EDIT_INVOICE_SUCCESS",
    EDIT_INVOICE_FAILED: "EDIT_INVOICE_FAILED",
    
};
export default ActionTypes;