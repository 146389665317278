const ActionTypes = {
  FETCH_WAREHOUSE_REQUEST: "FETCH_WAREHOUSE_REQUEST",
  FETCH_WAREHOUSE_FAILED: "FETCH_WAREHOUSE_FAILED",
  FETCH_WAREHOUSE_SUCCESS: "FETCH_WAREHOUSE_SUCCESS",

  NEW_WAREHOUSE_REQUEST: "NEW_WAREHOUSE_REQUEST",
  NEW_WAREHOUSE_FAILED: "NEW_WAREHOUSE_FAILED",
  NEW_WAREHOUSE_SUCCESS: "NEW_WAREHOUSE_SUCCESS",

  EDIT_WAREHOUSE_REQUEST: "EDIT_WAREHOUSE_REQUEST",
  EDIT_WAREHOUSE_FAILED: "EDIT_WAREHOUSE_FAILED",
  EDIT_WAREHOUSE_SUCCESS: "EDIT_WAREHOUSE_SUCCESS",

  DELETE_WAREHOUSE_REQUEST: "DELETE_WAREHOUSE_REQUEST",
  DELETE_WAREHOUSE_FAILED: "DELETE_WAREHOUSE_FAILED",
  DELETE_WAREHOUSE_SUCCESS: "DELETE_WAREHOUSE_SUCCESS",
};

export default ActionTypes;
