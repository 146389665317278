import apiBase from "./../../../common/baseAPI";

const ROLES_URL = "roles";
const PERMISSIONS_URL = "permissions";
const PATH_URL = "staff";
const PATH_STAFF_NOTE = "note/staff";
const PATH_STAFF_TIMESHEET = "task-timer/staff";
const PATH_STAFF_SET_STATUS = "staff/active";

// const PATH_ADD_STAFF_NOTE = ''
const getListPermissions = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(PERMISSIONS_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListRoles = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(ROLES_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListStaff = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(PATH_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createStaff = (data) => {
    let newFormData = new FormData();
    newFormData.append("profile_image", data.profile_image || "");
    newFormData.append("first_name", data.first_name);
    newFormData.append("last_name", data.last_name);
    newFormData.append(
        "two_factor_auth_enabled",
        data.two_factor_auth_enabled || ""
    );
    newFormData.append("email", data.email || "");
    newFormData.append("facebook", data.facebook || "");
    newFormData.append("linkedin", data.linkedin || "");
    newFormData.append("phone_number", data.phone_number || "");
    newFormData.append("skype", data.skype || "");
    // newFormData.append("password", data.password || "");
    newFormData.append("admin", data.admin || "");
    newFormData.append("default_language", data.default_language || "");
    newFormData.append("direction", data.direction || "");
    newFormData.append("hourly_rate", data.hourly_rate || "");
    // newFormData.append("email_signature", data.email_signature || "");
    newFormData.append("department", data.department);
    newFormData.append("roles", data.roles);
    newFormData.append("permissions", data.permissions);

    // const header = { "Content-Type": "multipart/form-data" };
    const header = { "Content-Type": "multipart/form-data" };

    return new Promise((resolve, reject) => {
        return apiBase
            .post(PATH_URL, newFormData, header)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const editStaff = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${PATH_URL}/${data.staff_id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteStaff = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${PATH_URL}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const filterStaff = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(
                `${PATH_URL}?search=${action.search}&&limit=${action.params.limit}&&page=${action.params.page}`
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getDepartments = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get("department")
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getStaffEdit = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${PATH_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const getStaffNote = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${PATH_STAFF_NOTE}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const addStaffNote = (id, params) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${PATH_STAFF_NOTE}/${id}`, params) //description: {description:"abc"}
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const getStaffTimesheet = (id, params) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${PATH_STAFF_TIMESHEET}/${id}`, params)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const setStaffStatus = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${PATH_URL}/${id}/toggle-active`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const resetPassword = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`/admin/reset-password/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const changePassword = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`/change-password` , data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const staffChangeAvatar = (data) => {
    const newFormData = new FormData();
    if (data.profile_image) {
        newFormData.append("profile_image", data.profile_image);
    }
    const header = { "Content-Type": "multipart/form-data" };
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${PATH_URL}/${data.staff_id}/profile-image`, newFormData, header)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export {
    getListStaff,
    createStaff,
    editStaff,
    deleteStaff,
    filterStaff,
    getDepartments,
    getStaffEdit,
    getStaffNote,
    addStaffNote,
    getStaffTimesheet,
    setStaffStatus,
    getListRoles,
    getListPermissions,
    resetPassword,
    changePassword,
    staffChangeAvatar,
};
