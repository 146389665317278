export const customer = {
  index: "/customer",
  profile: "/customer/profile",
  contacts: "/customer/contacts",
  invoice: "/customer/invoice/new",
  proposal: "/customer/proposal/new",
};

export const lead = {
  index: "/lead",
};

export const project = {
  index: "/projects",
  detail: "project",
  edit: "project/edit",
};

export const task = {
  index: "/tasks",
};

export const sales = {
  credit_notes: {
    credit_notes_new: "/credit_notes/new",
  },
};

export const inventory = {
  index: "/inventory",
  view: "inventory/view",
  edit: "inventory/edit",
};

export const proposal = {
  index: "/proposal",
  new: "proposal/new",
  edit: "proposal/edit",  
  view: "proposal/view",
};

export const estimate = {
  index: "/estimate",
  new: "estimate/new",
  edit: "estimate/edit",  
  view: "estimate/view",
};

export const expense = {};
export const contract = {};

export const administrator = {
  staff: {
    index: "/administrator/staff",
    new: "/administrator/staff/new",
    view: "/administrator/staff/view",
  },
  support: {
    predefined_replies: "/administrator/predefined_reply",
    edit_reply: "/support/edit_predefined_reply",
    new_reply: "/support/new_predefined_reply",
  },
  customField: {
    new_custom_field: "/administrator/custom_fields/new",
    edit_custom_field: "/administrator/custom_fields/edit",
  },
  leads: {
    lead_form_edit: "/administrator/web_lead/form/edit",
    web_to_lead: "/administrator/web_lead"
  }
};
