// import { startcase ActionTypes.} from "lodash";
import ActionTypes from "./types";
import _ from "lodash";
import * as dayjs from "dayjs";

const INIT_STATE = {
  isLoading: false,
  listProjects: {},
  projectId: "",
  projectById: {},
  listTimesheetProject: {},
  isDeleteSuccess: false,
  listTaskByProject: [],
  listStaffByTask: [],
  isCreateSuccess: false,
  isCreateDiscussionSuccess: false,
  isEditSuccess: false,
  listDiscussionProject: [],
  isShowModal: true,
  listSubscriptions: [],
  listTickets: [],
  milestone: [],
  isSuccess: false,
  listTag: [],
  listTask: {},
  overViewCount: {},
  summaryProject: {},
  listStaffNewProject: [],
  isCloseDialog: false,
  listFiles: {},
  dataNewTicket: {},
  customField: [],
  deleteTicket: false,
  listSummary: [],
  listStaffsOfProject: [],
  listMember: [],
  isChangeFile: false,
  dataChart: {},
};

const ProjectReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_LIST_PROJECTS:
      return {
        ...state,
        isLoading: true,
        isCloseDialog: false,
        isSuccess: false,
      };

    case ActionTypes.FETCH_LIST_PROJECTS_SUCCESS:
      if (!action.payload.data) {
        state.listProjects = { data: action.payload };
        return {
          ...state,
          listProjects: state.listProjects,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          listProjects: action.payload,
          isLoading: false,
        };
      }

    case ActionTypes.FETCH_LIST_PROJECTS_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.GET_PROJECT_ID:
      return {
        ...state,
        projectId: action.payload,
      };

    case ActionTypes.FETCH_PROJECT_BY_ID:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_PROJECT_BY_ID_SUCCESS:
      let dataProject = action.payload.Project;
      for (let item in dataProject) {
        if (!_.isArray(dataProject[item]) && dataProject[item] === null) {
          dataProject[item] = "";
        }
      }
      let data = action.payload;
      data.Project = dataProject;
      return {
        ...state,
        projectById: data,
        isLoading: false,
      };

    case ActionTypes.FETCH_PROJECT_BY_ID_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.FETCH_TIMESHEET_PROJECT:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_TIMESHEET_PROJECT_SUCCESS:
      let dataTimeSheet =
        action.payload && action.payload.data ? action.payload.data : [];
      let arrMember = dataTimeSheet.map((item) => {
        if (
          item &&
          item.staff &&
          item.staff.id &&
          item.staff.last_name &&
          item.staff.first_name
        ) {
          let temp = {
            id: item.staff.id,
            name: `${item.staff.last_name} ${item.staff.first_name}`,
          };
          return temp;
        }
      });
      let arrAfterFilter = arrMember.filter(
        (item, index) => arrMember.findIndex((i) => i.id === item.id) === index
      );
      return {
        ...state,
        listTimesheetProject: action.payload || {},
        listMember: arrAfterFilter || [],
        isLoading: false,
        isCreateSuccess: false,
        isDeleteSuccess: false,
        isEditSuccess: false,
      };

    case ActionTypes.FETCH_TIMESHEET_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_DELETE_TIMESHEET_PROJECT:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_DELETE_TIMESHEET_PROJECT_SUCCESS:
      return {
        ...state,
        isDeleteSuccess: true,
        isLoading: false,
      };

    case ActionTypes.FETCH_DELETE_TIMESHEET_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.FETCH_TASK_BY_PROJECT_TIMESHEET:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_TASK_BY_PROJECT_TIMESHEET_SUCCESS:
      return {
        ...state,
        listTaskByProject: action.payload,
        isLoading: false,
      };

    case ActionTypes.FETCH_TASK_BY_PROJECT_TIMESHEET_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.FETCH_STAFF_BY_TASK_TIMESHEET:
      return {
        ...state,
      };

    case ActionTypes.FETCH_STAFF_BY_TASK_TIMESHEET_SUCCESS:
      return {
        ...state,
        listStaffByTask: action.payload,
      };

    case ActionTypes.FETCH_STAFF_BY_TASK_TIMESHEET_FAILED:
      return {
        ...state,
      };
    case ActionTypes.FETCH_CREATE_TIMESHEET_PROJECT:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_CREATE_TIMESHEET_PROJECT_SUCCESS:
      return {
        ...state,
        isCreateSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_CREATE_TIMESHEET_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_EDIT_TIMESHEET_PROJECT:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_EDIT_TIMESHEET_PROJECT_SUCCESS:
      return {
        ...state,
        isEditSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_EDIT_TIMESHEET_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_FILTER_BY_MEMBER_TIMESHEET_PROJECT:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_FILTER_BY_MEMBER_TIMESHEET_PROJECT_SUCCESS:
      return {
        ...state,
        listTimesheetProject: action.payload,
        isLoading: false,
      };

    case ActionTypes.FETCH_FILTER_BY_MEMBER_TIMESHEET_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FILTER_DISCUSSION:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FILTER_DISCUSSION_SUCCESS:
      const filterData = action.payload;
      return {
        ...state,
        isLoading: false,
        listDiscussionProject: filterData,
      };
    case ActionTypes.FILTER_DISCUSSION_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_SUBSCRIPTIONS_PROJECT:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_SUBSCRIPTIONS_PROJECT_SUCCESS:
      return {
        ...state,
        listSubscriptions: action.payload,
        isLoading: false,
      };

    case ActionTypes.FETCH_SUBSCRIPTIONS_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_TICKETS_PROJECT:
      return {
        ...state,
        isLoading: true,
        deleteTicket: false,
      };
    case ActionTypes.FETCH_TICKETS_PROJECT_SUCCESS:
      return {
        ...state,
        listTickets: action.payload,
        isLoading: false,
      };

    case ActionTypes.FETCH_TICKETS_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_FILTER_BY_STATUS_TICKET_PROJECT:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_FILTER_BY_STATUS_TICKET_PROJECT_SUCCESS:
      return {
        ...state,
        listTickets: action.payload,
        isLoading: false,
      };

    case ActionTypes.FETCH_FILTER_BY_STATUS_TICKET_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.GET_PROJECT_MILESTONE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_PROJECT_MILESTONE_SUCCESS:
      return {
        ...state,
        milestone: action.payload,
        isLoading: false,
      };
    case ActionTypes.GET_PROJECT_MILESTONE_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.CREATE_PROJECT_MILESTONE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CREATE_PROJECT_MILESTONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCloseDialog: true,
        isSuccess: true,
      };
    case ActionTypes.CREATE_PROJECT_MILESTONE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCloseDialog: true,
        isSuccess: false,
      };

    case ActionTypes.EDIT_PROJECT_MILESTONE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.EDIT_PROJECT_MILESTONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isCloseDialog: true,
      };
    case ActionTypes.EDIT_PROJECT_MILESTONE_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isCloseDialog: true,
      };
    case ActionTypes.DELETE_PROJECT_MILESTONE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.DELETE_PROJECT_MILESTONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCloseDialog: true,
        isSuccess: true,
      };
    case ActionTypes.DELETE_PROJECT_MILESTONE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCloseDialog: true,
        isSuccess: false,
      };
    case ActionTypes.FETCH_PROJECT_MILESTONE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isCloseDialog: false,
        isSuccess: false,
      };
    case ActionTypes.FETCH_PROJECT_MILESTONE_SUCCESS:
      return {
        ...state,
        milestone: action.payload,
        isLoading: false,
      };
    case ActionTypes.FETCH_PROJECT_MILESTONE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.CHANGE_STATUS_SUCCESS_MILESTONE:
      return {
        ...state,
        isSuccess: false,
        isCloseDialog: false,
      };

    //get tag
    case ActionTypes.GET_PROJECT_TAG_SUCCESS:
      return {
        ...state,
        listTag: action.payload,
      };
    case ActionTypes.GET_PROJECT_TAG_FAILED:
      return {
        ...state,
      };
    //get task
    case ActionTypes.GET_PROJECT_OVERVIEW_TASK_SUCCESS:
      return {
        ...state,
        listTask: action.payload,
      };
    case ActionTypes.GET_PROJECT_OVERVIEW_TASK_FAILED:
      return {
        ...state,
      };

    case ActionTypes.GET_PROJECT_OVERVIEW_COUNT_SUCCESS:
      return {
        ...state,
        overViewCount: action.payload,
      };

    case ActionTypes.GET_PROJECT_OVERVIEW_COUNT_FAILED:
      return {
        ...state,
      };

    case ActionTypes.GET_PROJECT_SUMMARY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_PROJECT_SUMMARY_SUCCESS:
      return {
        ...state,
        summaryProject: action.payload,
        isLoading: false,
      };

    case ActionTypes.GET_PROJECT_SUMMARY_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.GET_ALL_NEW_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_ALL_NEW_PROJECT_SUCCESS:
      return {
        ...state,
        listTag: action.listTag,
        listStaffNewProject: action.listStaff,
        isLoading: false,
      };

    case ActionTypes.GET_ALL_NEW_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.DELETE_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isCloseDialog: true,
      };

    case ActionTypes.DELETE_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isCloseDialog: true,
      };

    case ActionTypes.EDIT_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.EDIT_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.COPY_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.COPY_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isCloseDialog: true,
      };

    case ActionTypes.COPY_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isCloseDialog: true,
      };

    case ActionTypes.GET_PROJECT_FILES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isCloseDialog: false,
        isSuccess: false,
      };

    case ActionTypes.GET_PROJECT_FILES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listFiles: action.payload,
      };

    case ActionTypes.GET_PROJECT_FILES_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.CHANGE_FILES_VISIBLE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.CHANGE_FILES_VISIBLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isChangeFile: true,
      };

    case ActionTypes.CHANGE_FILES_VISIBLE_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_PROJECT_FILES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.DELETE_PROJECT_FILES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCloseDialog: true,
        isSuccess: true,
      };

    case ActionTypes.DELETE_PROJECT_FILES_FAILED:
      return {
        ...state,
        isLoading: false,
        isCloseDialog: true,
        isSuccess: false,
      };
    case ActionTypes.CREATE_PROJECT_FILES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CREATE_PROJECT_FILES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isCloseDialog: true,
      };

    case ActionTypes.CREATE_PROJECT_FILES_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.SEARCH_PROJECT_FILES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isCloseDialog: false,
        isSuccess: false,
        isChangeFile: false,
      };

    case ActionTypes.SEARCH_PROJECT_FILES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listFiles: action.payload,
      };

    case ActionTypes.SEARCH_PROJECT_FILES_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.GET_ALL_PROJECT_VIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.GET_ALL_PROJECT_VIEW_SUCCESS:
      if (!action.listProject.data) {
        state.listProjects = { data: action.listProject };
        return {
          ...state,
          isLoading: false,
          listProjects: state.listProjects,
          listTask: action.openTask,
          overViewCount: action.dayLeft,
          projectById: action.projectById,
          projectId: action.projectId,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          listProjects: action.listProject,
          listTask: action.openTask,
          overViewCount: action.dayLeft,
          projectById: action.projectById,
          projectId: action.projectId,
        };
      }

    case ActionTypes.GET_ALL_PROJECT_VIEW_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.CREATE_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        projectId: action.payload.result.id,
        isLoading: false,
      };

    case ActionTypes.CREATE_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.GET_ALL_PROJECT_VIEW_NEW_TICKET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_ALL_PROJECT_VIEW_NEW_TICKET_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        dataNewTicket: action.payload,
        isLoading: false,
      };

    case ActionTypes.GET_ALL_PROJECT_VIEW_NEW_TICKET_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.CREATE_PROJECT_NEW_TICKET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.CREATE_PROJECT_NEW_TICKET_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };

    case ActionTypes.CREATE_PROJECT_NEW_TICKET_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_PROJECT_TICKET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.DELETE_PROJECT_TICKET_SUCCESS:
      return {
        ...state,
        deleteTicket: true,
        isLoading: false,
      };

    case ActionTypes.DELETE_PROJECT_TICKET_FAILED:
      return {
        ...state,
        isLoading: false,
        deleteTicket: true,
      };

    case ActionTypes.GET_PROJECT_TICKET_SUMMARY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_PROJECT_TICKET_SUMMARY_SUCCESS:
      return {
        ...state,
        listSummary: action.payload,
        isLoading: false,
      };

    case ActionTypes.GET_PROJECT_TICKET_SUMMARY_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.ADD_MEMBER_PROJECT_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.ADD_MEMBER_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.ADD_MEMBER_PROJECT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.DELETE_MEMBER_PROJECT_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.DELETE_MEMBER_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.DELETE_MEMBER_PROJECT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_STAFFS_OF_PROJECT_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_STAFFS_OF_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listStaffsOfProject: action.payload, //[]
      };
    case ActionTypes.FETCH_STAFFS_OF_PROJECT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.DOWNLOAD_FILE_OF_PROJECT_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.DOWNLOAD_FILE_OF_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.DOWNLOAD_FILE_OF_PROJECT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.GET_CHART_LOG_TIME_PROJECT:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.GET_CHART_LOG_TIME_PROJECT_SUCCESS:
      const objChart = action.payload.result; // Truy cập đúng vào 'result'
      const label = [];
      const value = [];
      
      if (objChart.daysOfWeek) {
        // Lấy các ngày trong tuần và format chúng
        Object.values(objChart.daysOfWeek).forEach((date) => {
          label.push(dayjs(date).format("DD-MM-YYYY"));
        });
      }
      
      if (objChart.loggedTime) {
        // Lấy thời gian logged tương ứng với các ngày trong tuần
        const loggedKeys = [
          "loggedMonday",
          "loggedTuesday",
          "loggedWednesday",
          "loggedThursday",
          "loggedFriday",
          "loggedSaturday",
          "loggedSunday",
        ];
      
        loggedKeys.forEach((key) => {
          value.push(objChart.loggedTime[key] || 0); // Thêm giá trị logged, mặc định là 0 nếu không có
        });
      }
      return {
        ...state,
        dataChart: {
          label,
          value,
        },
        isLoading: false,
      };
    case ActionTypes.GET_CHART_LOG_TIME_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};

export default ProjectReducer;
