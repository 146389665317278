import apiBase from "../../../common/baseAPI";

const CUSTOMER_WAREHOUSE = "note/customer";
const WAREHOUSE = "warehouses";

export const fetchListWareHouseRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${WAREHOUSE}?search=${action.params.search}&&limit=${action.params.limit}&&page=${action.params.page}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const addWareHouseRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${WAREHOUSE}`, action)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editWareHouseRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .put(`${WAREHOUSE}/${action.id}`, action.value)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteWareHouseRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .delete(`${WAREHOUSE}/${action.id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
