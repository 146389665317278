import React from "react";
import { PrivateRoute } from "./PrivateRoute";
import i18n from "../i18n";
import { staffRoute } from "./administrator/staffRoute.js";
import { settingRoute } from "./administrator/settingRoute.js";

// Administrator/Contracts
const ContractTypes = React.lazy(() => import("../pages/Administrator/Contracts/ContractTypes"));

// Administrator/Customer
const CustomerGroup = React.lazy(() => import("../pages/Administrator/CustomerGroup"));

// Administrator/CustomFields
const CustomFields = React.lazy(() => import("../pages/Administrator/CustomFields"));
const CustomFieldsNew = React.lazy(() => import("../pages/Administrator/CustomFields/NewCustomFields"));
const CustomFieldsEdit = React.lazy(() => import("../pages/Administrator/CustomFields/EditCustomFields"));

// Administrator/Finance
const Currency = React.lazy(() => import("../pages/Administrator/Finance/Currency/Currency"));
const ExpensesCategories = React.lazy(() => import("../pages/Administrator/Finance/ExpensesCategories/ExpensesCategories"));
const PaymentMode = React.lazy(() => import("../pages/Administrator/Finance/PaymentMode/PaymentMode"));
const TaxRate = React.lazy(() => import("../pages/Administrator/Finance/TaxRate/TaxRate"));

// Administrator/Leads
const EmailIntegration = React.lazy(() => import("../pages/Administrator/Leads/EmailIntegration"));
const LeadForm = React.lazy(() => import("../pages/Administrator/Leads/WebToLead/LeadForm"));
const LeadFormEdit = React.lazy(() => import("../pages/Administrator/Leads/WebToLead/LeadFormEdit"));
const Source = React.lazy(() => import("../pages/Administrator/Leads/Source"));
const Status = React.lazy(() => import("../pages/Administrator/Leads/Statuses"));
const SpamFilters = React.lazy(() => import("../pages/Administrator/Leads/EmailIntegration/SpamFilters"));
const WebToLead = React.lazy(() => import("../pages/Administrator/Leads/WebToLead"));

// Administrator/Support
const Department = React.lazy(() => import("../pages/Administrator/Support/Department"));
const PredefinedReplies = React.lazy(() => import("../pages/Administrator/Support/PredefinedReplies/index"));
const EditPredefinedReply = React.lazy(() => import("../pages/Administrator/Support/PredefinedReplies/EditPredefinedReplies/EditPredefinedReplies"));
const NewPredefinedReply = React.lazy(() => import("../pages/Administrator/Support/PredefinedReplies/NewPredefinedReplies/NewPredefinedReplies"));
const Services = React.lazy(() => import("../pages/Administrator/Support/Services"));
const SupportSpamFilters = React.lazy(() => import("../pages/Administrator/Support/SpamFilters"));
const TicketPriority = React.lazy(() => import("../pages/Administrator/Support/TicketPriority"));
const TicketStatus = React.lazy(() => import("../pages/Administrator/Support/TicketStatus"));

export const administratorsRoute = [
    // Administator
    {
        name: `${i18n.t("route.setup")}`,
        route: PrivateRoute,
        roles: ["Director","Admin", "MENU"],
        title: "Setup",
        childs: [
            ...staffRoute,
            {
                path: "/administrator",
                name: `${i18n.t("route.customer")}`,
                route: PrivateRoute,
                roles: ["Director","Admin", ],
                title: `${i18n.t("route.customer")}`,
                //Dropdown lv 3 of customer
                childs: [
                    {
                        path: "/administrator/customer-group",
                        name: `${i18n.t("route.group")}`,
                        component: CustomerGroup,
                        route: PrivateRoute,
                        roles: ["Director","Admin", ],
                        title: `${i18n.t("route.group")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/customer-source",
                        name: `${i18n.t("route.source")}`,
                        component: Source,
                        route: PrivateRoute,
                        roles: ["Director","Admin", ],
                        title: `${i18n.t("route.source")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/customer-status",
                        name: `${i18n.t("route.status")}`,
                        component: Status,
                        route: PrivateRoute,
                        roles: ["Director","Admin", ],
                        title: `${i18n.t("route.status")}`,
                        exact: true,
                    },
                ],
            },
            {
                name: `${i18n.t("route.support")}`,
                route: PrivateRoute,
                roles: ["Director","Admin", ],
                title: `${i18n.t("route.support")}`,
                childs: [
                    {
                        path: "/administrator/department",
                        name: `${i18n.t("route.department")}`,
                        component: Department,
                        route: PrivateRoute,
                        roles: ["Director","Admin", ],
                        title: `${i18n.t("route.department")}`,
                        exact: true,
                    },
                    {
                        path: "/administrator/predefined_reply",
                        name: `${i18n.t("route.predefinedReply")}`,
                        component: PredefinedReplies,
                        route: PrivateRoute,
                        roles: ["Director","Admin", ],
                        title: `${i18n.t("route.predefinedReply")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/ticket_priority",
                        name: `${i18n.t("route.ticketPriority")}`,
                        component: TicketPriority,
                        route: PrivateRoute,
                        roles: ["Director","Admin", ],
                        title: `${i18n.t("route.ticketPriority")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/ticket_status",
                        name: `${i18n.t("route.ticketStatus")}`,
                        component: TicketStatus,
                        route: PrivateRoute,
                        roles: ["Director","Admin", ],
                        title: `${i18n.t("route.ticketStatus")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/service",
                        name: `${i18n.t("route.service")}`,
                        component: Services,
                        route: PrivateRoute,
                        roles: ["Director","Admin", ],
                        title: `${i18n.t("route.service")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/spam_filter",
                        name: `${i18n.t("route.spamFilter")}`,
                        component: SupportSpamFilters,
                        route: PrivateRoute,
                        roles: ["Director","Admin", ],
                        title: `${i18n.t("route.spamFilter")}`,
                        exact: true,
                    },
                ],
            },
            {
                name: `${i18n.t("route.lead")}`,
                route: PrivateRoute,
                roles: ["Director","Admin", ],
                title: `${i18n.t("route.lead")}`,
                //Dropdown lv 3 of lead
                childs: [
                    
                    {
                        path: "/administator/email_integration",
                        name: `${i18n.t("route.emailIntegation")}`,
                        component: EmailIntegration,
                        route: PrivateRoute,
                        roles: ["Director","Admin", ],
                        title: `${i18n.t("route.emailIntegation")}`,
                        exact: true,
                    },

                    {
                        path: "/administrator/web_lead",
                        name: `${i18n.t("route.webToLead")}`,
                        component: WebToLead,
                        route: PrivateRoute,
                        roles: ["Director","Admin", ],
                        title: `${i18n.t("route.webToLead")}`,
                        exact: true,
                    },
                ],
            },
            {
                name: `${i18n.t("route.finance")}`,
                route: PrivateRoute,
                roles: ["Director","Admin", ],
                title: `${i18n.t("route.finance")}`,
                //Dropdown lv 3 of finance
                childs: [
                    {
                        path: "/administator/tax_rate",
                        name: `${i18n.t("route.taxRate")}`,
                        component: TaxRate,
                        route: PrivateRoute,
                        roles: ["Director","Admin", ],
                        title: `${i18n.t("route.taxRate")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/currency",
                        name: `${i18n.t("route.currency")}`,
                        component: Currency,
                        route: PrivateRoute,
                        roles: ["Director","Admin", ],
                        title: `${i18n.t("route.currency")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/payment_mode",
                        name: `${i18n.t("route.paymentMode")}`,
                        component: PaymentMode,
                        route: PrivateRoute,
                        roles: ["Director","Admin", ],
                        title: `${i18n.t("route.paymentMode")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/expense_category",
                        name: `${i18n.t("route.expenseCategory")}`,
                        component: ExpensesCategories,
                        route: PrivateRoute,
                        roles: ["Director","Admin", ],
                        title: `${i18n.t("route.expenseCategory")}`,
                        exact: true,
                    },
                ],
            },
            {
                name: `${i18n.t("route.contract")}`,
                route: PrivateRoute,
                roles: ["Director","Admin", ],
                title: `${i18n.t("route.contract")}`,
                //Dropdown lv 3 of contract
                childs: [
                    {
                        path: "/administator/contract_type",
                        name: `${i18n.t("route.contractType")}`,
                        component: ContractTypes,
                        route: PrivateRoute,
                        roles: ["Director","Admin", ],
                        title: `${i18n.t("route.contractType")}`,
                        exact: true,
                    },
                ],
            },
            {
                path: "/administrator/custom_fields",
                name: `${i18n.t("route.customFields")}`,
                component: CustomFields,
                route: PrivateRoute,
                roles: ["Director","Admin","customFields"],
                title: `${i18n.t("route.customFields")}`,
                exact: true,
            },
        ],
    },
    ...settingRoute,
    {
        path: "/support/new_predefined_reply",
        name: "support_new_predefined_reply",
        component: NewPredefinedReply,
        route: PrivateRoute,
        roles: ["Director","Admin","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/support/edit_predefined_reply/:id",
        name: "support_edit_predefined_reply",
        component: EditPredefinedReply,
        route: PrivateRoute,
        roles: ["Director","Admin","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administator/email_integration/SpamFilters",
        name: "administator_email_integration_SpamFilters",
        component: SpamFilters,
        route: PrivateRoute,
        roles: ["Director","Admin","SpamFilters"],
        title: `${i18n.t("route.emailIntegation")}`,
        exact: true,
    },
    {
        path: "/administator/web_lead/form",
        name: "administator_web_lead_form",
        component: LeadForm,
        route: PrivateRoute,
        roles: ["Director","Admin","form"],
        title: `${i18n.t("route.emailIntegation")}`,
        exact: true,
    },
    {
        path: "/administrator/web_lead/form/edit/:id",
        name: "administator_web_lead_form_edit_:id",
        component: LeadFormEdit,
        route: PrivateRoute,
        roles: ["Director","Admin","formEdit"],
        title: `${i18n.t("route.emailIntegation")}`,
        exact: true,
    },
    {
        path: "/administrator/custom_fields/new",
        name: "administrator_customfields_new",
        component: CustomFieldsNew,
        route: PrivateRoute,
        roles: ["Director","Admin","profile"],
        title: `${i18n.t("route.customFields")}`,
        exact: true,
    },
    {
        path: "/administrator/custom_fields/edit/:id",
        name: "administrator_cutomsfields_edit",
        component: CustomFieldsEdit,
        route: PrivateRoute,
        roles: ["Director","Admin","profile"],
        title: `${i18n.t("route.customFields")}`,
        exact: true,
    },
]