import ActionTypes from "./type";

export const fetchWareHouseRequest = (params) => {
  return {
    type: ActionTypes.FETCH_WAREHOUSE_REQUEST,
    params,
  };
};

export const fetchWareHouseSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_WAREHOUSE_SUCCESS,
    data,
  };
};

export const fetchWareHouseFailed = () => {
  return {
    type: ActionTypes.FETCH_WAREHOUSE_FAILED,
  };
};

export const newWareHouseRequest = (data) => {
  return {
    type: ActionTypes.NEW_WAREHOUSE_REQUEST,
    data,
  };
};

export const newWareHouseSuccess = (data) => {
  return {
    type: ActionTypes.NEW_WAREHOUSE_SUCCESS,
    data,
  };
};

export const newWareHouseFailed = () => {
  return {
    type: ActionTypes.NEW_WAREHOUSE_FAILED,
  };
};

export const editWareHouseRequest = (id, value) => {
  return {
    type: ActionTypes.EDIT_WAREHOUSE_REQUEST,
    id,
    value,
  };
};

export const editWareHouseSuccess = (data) => {
  return {
    type: ActionTypes.EDIT_WAREHOUSE_SUCCESS,
    data,
  };
};

export const editWareHouseFailed = () => {
  return {
    type: ActionTypes.EDIT_WAREHOUSE_FAILED,
  };
};

export const deleteWareHouseRequest = (id) => {
  return {
    type: ActionTypes.DELETE_WAREHOUSE_REQUEST,
    id,
  };
};

export const deleteWareHouseSuccess = () => {
  return {
    type: ActionTypes.DELETE_WAREHOUSE_SUCCESS,
  };
};

export const deleteWareHouseFailed = () => {
  return {
    type: ActionTypes.DELETE_WAREHOUSE_FAILED,
  };
};
