import ActionTypes from "./types";

export const getListProposalRequest = (params) => {
    return {
        type: ActionTypes.GET_PROPOSAL_REQUEST,
        params,
    };
};

export const getListProposalSuccess = (data) => {
    return {
        type: ActionTypes.GET_PROPOSAL_SUCCESS,
        payload: data,
    };
};

export const getListProposalFailed = () => {
    return {
        type: ActionTypes.GET_PROPOSAL_FAILED,
    };
};
export const createProposalResquest = (data) => {
    return {
        type: ActionTypes.CREATE_PROPOSAL_REQUEST,
        data
    };
};

export const createProposalSuccess = (data) => {
    return {
        type: ActionTypes.CREATE_PROPOSAL_SUCCESS,
        payload: data,
    };
};

export const createProposalFailed = () => {
    return {
        type: ActionTypes.CREATE_PROPOSAL_FAILED,
    };
};


export const getListCustomer = () => {
    return {
        type: ActionTypes.GET_LIST_CUSTOMER_REQUEST,
    };
};
export const getListCustomerSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_CUSTOMER_SUCCESS,
        payload: data,
    };
};

export const getListCustomerFailed = () => {
    return {
        type: ActionTypes.GET_LIST_CUSTOMER_FAILED,
    };
};

export const fetchListStaff = () => {
    return {
        type: ActionTypes.FETCH_LIST_STAFF,
    };
};

export const fetchListStaffSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_STAFF_SUCCESS,
        payload: data,
    };
};

export const fetchListStaffFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_STAFF_FAILED,
    };
};

export const getProposalById = (data) => {
    return {
        type: ActionTypes.GET_PROPOSAL_BY_ID,
        payload: data,
    };
};

export const getProposalByIdSuccess = (data) => {
    return {
        type: ActionTypes.GET_PROPOSAL_BY_ID_SUCCESS,
        payload: data,
    };
};

export const getProposalByIdFailed = () => {
    return {
        type: ActionTypes.GET_PROPOSAL_BY_ID_FAILED,
    };
};

export const deleteProposal = (data) => {
    return {
        type: ActionTypes.DELETE_PROPOSAL,
        payload: data,
    };
};

export const deleteProposalSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_PROPOSAL_SUCCESS,
        payload: data,
    };
};

export const deleteProposalFailed = () => {
    return {
        type: ActionTypes.DELETE_PROPOSAL_FAILED,
    };
};

export const editProposalFailed = () => {
    return {
        type: ActionTypes.EDIT_PROPOSAL_FAILED,
    };
};
export const editProposalRequest = (data) => {
    return {
        type: ActionTypes.EDIT_PROPOSAL_REQUEST,
        payload: data,
    };
};

export const editProposalSuccess = () => {
    return {
        type: ActionTypes.EDIT_PROPOSAL_SUCCESS,
    };
};