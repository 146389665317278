import ActionTypes from "./types";

export const fetchListItemsPending = (data) => {
    return {
        type: ActionTypes.FETCH_SALES_ITEM_PENDING,
        payload: data,
    };
};

export const fetchListItemsSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_SALES_ITEM_SUCCESS,
        payload: data,
    };
};

export const fetchListItemsFailure = () => {
    return {
        type: ActionTypes.FETCH_SALES_ITEM_FAILURE,
    };
};

export const fetchListTaxPending = (data) => {
    return {
        type: ActionTypes.FETCH_SALES_TAX_PENDING,
        payload: data,
    };
};

export const fetchListTaxSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_SALES_TAX_SUCCESS,
        payload: data,
    };
};

export const fetchListTaxFailure = () => {
    return {
        type: ActionTypes.FETCH_SALES_TAX_FAILURE,
    };
};
// item-group actions
export const fetchListItemGroupsPending = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_ITEM_GROUPS_PENDING,
        payload: data,
    };
};

export const fetchListItemGroupsSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_ITEM_GROUPS_SUCCESS,
        payload: data,
    };
};

export const fetchListItemGroupsFailure = () => {
    return {
        type: ActionTypes.FETCH_LIST_ITEM_GROUPS_FAILURE,
    };
};
export const addNewItemPending = (data, params) => {
    return {
        type: ActionTypes.ADD_NEW_ITEM_PENDING,
        payload: data,
        params: params,
    };
};

export const addNewItemSuccess = (data) => {
    return {
        type: ActionTypes.ADD_NEW_ITEM_SUCCESS,
        payload: data,
    };
};

export const addNewItemFailure = () => {
    return {
        type: ActionTypes.ADD_NEW_ITEM_FAILURE,
    };
};
