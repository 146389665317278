export const SUBMIT_ITEM_CUSTOM_FIELD = "SUBMIT_ITEM_CUSTOM_FIELD";
export const EDIT_ITEM_CUSTOM_FIELD = "EDIT_ITEM_CUSTOM_FIELD";
export const DELETE_ITEM_CUSTOM_FIELD = "DELETE_ITEM_CUSTOM_FIELD";
export const SET_ARR_ADD_CUSTOM_FIELD = "SET_ARR_ADD_CUSTOM_FIELD";

export const editItemCustomField = (item, value, index, kind) => {
  return {
    type: EDIT_ITEM_CUSTOM_FIELD,
    item,
    value,
    index,
    kind,
  };
};
export const deleteItemCustomField = (index) => {
  return {
    type: DELETE_ITEM_CUSTOM_FIELD,
    index,
  };
};
export const sumbitItemTable = (data) => {
  return {
    type: SUBMIT_ITEM_CUSTOM_FIELD,
    data,
  };
};
export const setArrAddCustomField = (newArrAddCustomField) => {
  return {
    type: SET_ARR_ADD_CUSTOM_FIELD,
    newArrAddCustomField,
  };
};

const initialState = {
  arrAddCustomField: [],
  statusEditItem: [], // focus edit custom field
  indexEdit: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_ITEM_CUSTOM_FIELD:
      if (action.kind === "customFieldsValues") {
        let arrCopyCustomField = [...state.arrAddCustomField];
        const indexChange = arrCopyCustomField[
          action.index
        ].customFieldsValues.findIndex(
          (item) => item.fieldId === action.item.fieldId
        );
        arrCopyCustomField[action.index].customFieldsValues[
          indexChange
        ].value = action.value;
        arrCopyCustomField[action.index].status =
          !arrCopyCustomField[action.index].status;
        return {
          ...state,
          arrAddCustomField: [...arrCopyCustomField],
          statusEditItem: [action.index, action.item.fieldId],
          indexEdit: action.index,
        };
      } else {
        let arrCopyCustomField = [...state.arrAddCustomField];
        arrCopyCustomField[action.index].itemable[action.item] = action.value;
        arrCopyCustomField[action.index].status =
          !arrCopyCustomField[action.index].status;
        return {
          ...state,
          arrAddCustomField: arrCopyCustomField,
          statusEditItem: [action.index, action.item],
          indexEdit: action.index,
        };
      }

    case SUBMIT_ITEM_CUSTOM_FIELD:
      let dataConvert = {};
      const dataAddCustomField = [...state.arrAddCustomField];
      dataConvert.customFieldsValues = [
        ...action.data.customFieldsValues.data,
      ];
      dataConvert.itemable = action.data.itemable;
      dataConvert.status = false;
      dataAddCustomField.push(dataConvert);
      return {
        ...state,
        arrAddCustomField: dataAddCustomField,
        statusEditItem: [],
        indexEdit: "",
      };
    case DELETE_ITEM_CUSTOM_FIELD:
      const dataDeleteCustomField = [...state.arrAddCustomField];
      dataDeleteCustomField.splice(action.index, 1);
      return {
        ...state,
        arrAddCustomField: dataDeleteCustomField,
        statusEditItem: [],
        indexEdit: "",
      };
    case SET_ARR_ADD_CUSTOM_FIELD:
      return {
        ...state,
        arrAddCustomField: action.newArrAddCustomField,
        statusEditItem: [],
        indexEdit: "",
      };
    default:
      return state;
  }
};

export default reducer;
