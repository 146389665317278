// import _ from 'lodash';
import apiBase from "../../../common/baseAPI";
// import { ParseSimpleEndpoint } from '../../../helpers/ParseEndpoint';

const CONTRACT_URL = "contract";
const CUSTOMER_URL = "customer";
const FILE_CONTRACT_URL = "file";
const NOTE_URL = "note";
const COMMENT_URL = "comment";
const RENEWAL_URL = "renewal";
const TASK_URL = "task";
const CONTRACT_TYPE_URL = "contractType";
const CUSTOM_FIELD_URL = "customField/fieldto/contracts";
const CONTRACT_BY_TYPE_URL = "contract/statistic/by/type";
const CONTRACT_VALUE_BY_TYPE_URL = "contract/statistic/value/by/type";
const FILTER_CONTRACT_URL = "contract/filter";
const YEAR_URL = "year";

const getListContracts = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(
                `${CONTRACT_URL}?search=${action.search}&&limit=${action.params.limit}&&page=${action.params.page}`
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getContract = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${CONTRACT_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createContract = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(CONTRACT_URL, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteContract = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${CONTRACT_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListCustomersContract = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(CUSTOMER_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const updateContract = (id, data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${CONTRACT_URL}/${id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const filterContract = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(
                `${FILTER_CONTRACT_URL}?limit=${data.limit}&&page=${data.page}`,
                data.body
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getSummaryContract = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${CONTRACT_URL}/countActive`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const fetchListAttachmentContract = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${FILE_CONTRACT_URL}/contract/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createAttachmentContract = (id, data) => {
    const newFormData = new FormData();
    newFormData.append("file_name", data);
    const header = { "Content-Type": "multipart/form-data" };
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${FILE_CONTRACT_URL}/contract/${id}`, newFormData, header)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteAttachmentContract = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${FILE_CONTRACT_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const fetchNoteContract = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${NOTE_URL}/contract/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createNoteContract = (id, data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${NOTE_URL}/contract/${id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteNoteContract = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${NOTE_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const updateNoteContract = (id, data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${NOTE_URL}/${id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const fetchListCommentContract = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`contract/${COMMENT_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createCommentContract = (id, data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`contract/${COMMENT_URL}/${id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteCommentContract = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`contract/${COMMENT_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const updateCommentContract = (id, data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`contract/${COMMENT_URL}/${id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

// DETAIL RENEWAL
const fetchListRenewalContract = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`contract/${RENEWAL_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createRenewalContract = (id, data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`contract/${RENEWAL_URL}/${id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteRenewalContract = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`contract/${RENEWAL_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const getListContractByCustomer = (id, payload) => {
    const { params, debouncedSearchTerm } = payload.data
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${CONTRACT_URL}/${CUSTOMER_URL}/${id}?search=${debouncedSearchTerm}&&limit=${params.limit}&&page=${params.page}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

// API TASK CONTRACT
const fetchListTaskContract = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${TASK_URL}/contract/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createTaskContract = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${TASK_URL}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteTaskContract = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${TASK_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const updateTaskContract = (id, data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${TASK_URL}/${id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getContractType = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${CONTRACT_TYPE_URL}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createContractType = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${CONTRACT_TYPE_URL}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const fetchListCustomField = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${CUSTOM_FIELD_URL}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const fetchContractByType = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${CONTRACT_BY_TYPE_URL}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const fetchContractValueByType = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${CONTRACT_VALUE_BY_TYPE_URL}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const fetchListYear = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${YEAR_URL}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const addNewContract = ({ params }) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${CONTRACT_URL}`, params)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export {
    getListContracts,
    getContract,
    deleteContract,
    createContract,
    getListCustomersContract,
    updateContract,
    filterContract,
    getSummaryContract,
    fetchListAttachmentContract,
    createAttachmentContract,
    deleteAttachmentContract,
    fetchNoteContract,
    createNoteContract,
    deleteNoteContract,
    updateNoteContract,
    fetchListCommentContract,
    createCommentContract,
    deleteCommentContract,
    updateCommentContract,
    fetchListRenewalContract,
    createRenewalContract,
    deleteRenewalContract,
    fetchListTaskContract,
    createTaskContract,
    deleteTaskContract,
    updateTaskContract,
    getContractType,
    createContractType,
    fetchListCustomField,
    fetchContractByType,
    fetchContractValueByType,
    fetchListYear,
    addNewContract,
    getListContractByCustomer
};
