import ActionTypes from "./types";
const INIT_STATE = {
    isLoading: false,
    listCustomFieldInvoice: [],
    invoiceById: {},
    isSuccess: false,
};

const SaleInvoiceReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_SALE_INVOICE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.GET_SALE_INVOICE_SUCCESS:

            return {
                ...state,
                listCustomFieldInvoice: action.payload,
                isLoading: false,
            };
        case ActionTypes.GET_SALE_INVOICE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.ADD_INVOICE_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };

        case ActionTypes.ADD_INVOICE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
            };

        case ActionTypes.ADD_INVOICE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.GET_INVOICE_BY_ID:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.GET_INVOICE_BY_ID_SUCCESS:
            return {
                ...state,
                invoiceById: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_INVOICE_BY_ID_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.DELETE_INVOICE:
            return {
                ...state,
                isLoading: true,
                deleteInvoiceSuccess: false,
            };

        case ActionTypes.DELETE_INVOICE_SUCCESS:
            return {
                ...state,
                deleteInvoiceSuccess: true,
                isLoading: false,
            };

        case ActionTypes.DELETE_INVOICE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.EDIT_INVOICE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.EDIT_INVOICE_SUCCESS:
            return {
                ...state,
                dataEditInvoice: action.payload,
                isSuccess: true,
                isLoading: false,
            };

        case ActionTypes.EDIT_INVOICE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

export default SaleInvoiceReducer;