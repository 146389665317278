import ActionTypes from "./types";

export const getListItems = (data) => {
    return {
        type: ActionTypes.GET_LIST_ITEMS,
        payload: data,
    };
};

export const getListItemsSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_ITEMS_SUCCESS,
        payload: data,
    };
};

export const getListItemsFailed = () => {
    return {
        type: ActionTypes.GET_LIST_ITEMS_FAILED,
    };
};

export const getListGroupsItem = (data) => {
    return {
        type: ActionTypes.GET_LIST_GROUP_ITEMS,
        payload: data,
    };
};

export const getListGroupItemsSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_GROUP_ITEMS_SUCCESS,
        payload: data,
    };
};

export const getListGroupItemsFailed = () => {
    return {
        type: ActionTypes.GET_LIST_GROUP_ITEMS_FAILED,
    };
};

export const getListCurrency = (data) => {
    return {
        type: ActionTypes.GET_LIST_CURRENCY,
        payload: data,
    };
};

export const getListCurrencySuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_CURRENCY_SUCCESS,
        payload: data,
    };
};

export const getListCurrencyFailed = () => {
    return {
        type: ActionTypes.GET_LIST_CURRENCY_FAILED,
    };
};

export const saveActivity = (data) => {
    return {
        type: ActionTypes.SAVE_ACTIVITY,
        payload: data,
    };
};

export const saveActivitySuccess = (data) => {
    return {
        type: ActionTypes.SAVE_ACTIVITY_SUCCESS,
        payload: data,
    };
};

export const saveActivityFailed = () => {
    return {
        type: ActionTypes.SAVE_ACTIVITY_FAILED,
    };
};

export const createTask = (data) => {
    return {
        type: ActionTypes.CREATE_TASK,
        payload: data,
    };
};

export const createTaskSuccess = (data) => {
    return {
        type: ActionTypes.CREATE_TASK_SUCCESS,
        payload: data,
    };
};

export const createTaskFailed = () => {
    return {
        type: ActionTypes.CREATE_TASK_FAILED,
    };
};

export const switchToCustomer = (data) => {
    return {
        type: ActionTypes.SWITCH_TO_CUSTOMER,
        payload: data,
    };
};

export const switchToCustomerSuccess = (data) => {
    return {
        type: ActionTypes.SWITCH_TO_CUSTOMER_SUCCESS,
        payload: data,
    };
};

export const switchToCustomerFailed = () => {
    return {
        type: ActionTypes.SWITCH_TO_CUSTOMER_FAILED,
    };
};

export const setIsNoContact = (data) => {
    return {
        type: ActionTypes.SET_IS_NO_CONTACT,
        payload: data,
    };
};

export const setIsNoContactSuccess = (data) => {
    return {
        type: ActionTypes.SET_IS_NO_CONTACT_SUCCESS,
        payload: data,
    };
};

export const setIsNoContactFailed = () => {
    return {
        type: ActionTypes.SET_IS_NO_CONTACT_FAILED,
    };
};

export const setIsTrash = (data) => {
    return {
        type: ActionTypes.SET_IS_TRASH,
        payload: data,
    };
};

export const setIsTrashSuccess = (data) => {
    return {
        type: ActionTypes.SET_IS_TRASH_SUCCESS,
        payload: data,
    };
};

export const setIsTrashFailed = () => {
    return {
        type: ActionTypes.SET_IS_TRASH_FAILED,
    };
};

export const getTaskByLead = (leadId, data) => {
    return {
        type: ActionTypes.GET_TASK_BY_LEAD,
        leadId,
        data,
    };
};

export const getTaskByLeadSuccess = (data) => {
    return {
        type: ActionTypes.GET_TASK_BY_LEAD_SUCCESS,
        payload: data,
    };
};

export const getTaskByLeadFailed = () => {
    return {
        type: ActionTypes.GET_TASK_BY_LEAD_FAILED,
    };
};

export const createProposal = (data) => {
    return {
        type: ActionTypes.CREATE_PROPOSAL,
        payload: data,
    };
};

export const createProposalSuccess = (data) => {
    return {
        type: ActionTypes.CREATE_PROPOSAL_SUCCESS,
        payload: data,
    };
};

export const createProposalFailed = () => {
    return {
        type: ActionTypes.CREATE_PROPOSAL_FAILED,
    };
};

// export const getRemindByLead = (data) => {
//     return {
//         type: ActionTypes.GET_REMIND_BY_LEAD,
//         payload: data
//     }
// }

// export const getRemindByLeadSuccess = (data) => {
//     return {
//         type: ActionTypes.GET_REMIND_BY_LEAD_SUCCESS,
//         payload: data
//     }
// }

// export const getRemindByLeadFailed = () => {
//     return {
//         type: ActionTypes.GET_REMIND_BY_LEAD_FAILED
//     }
// }

export const getListProposal = (data) => {
    return {
        type: ActionTypes.GET_LIST_PROPOSAL,
        payload: data,
    };
};

export const getListProposalSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_PROPOSAL_SUCCESS,
        payload: data,
    };
};

export const getListProposalFailed = () => {
    return {
        type: ActionTypes.GET_LIST_PROPOSAL_FAILED,
    };
};

export const getListProposalBylead = (id) => {
    return {
        type: ActionTypes.GET_LIST_PROPOSAL_BY_LEAD,
        payload: id,
    };
};

export const getListProposalByLeadSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_PROPOSAL_BY_LEAD_SUCCESS,
        payload: data,
    };
};

export const getListProposalByLeadFailed = () => {
    return {
        type: ActionTypes.GET_LIST_PROPOSAL_BY_LEAD_FAILED,
    };
};

export const updateIsCreateSuccess = (data) => {
    return {
        type: ActionTypes.UPDATE_IS_CREATE_SUCCESS,
        payload: data,
    };
};

export const fetchListLeads = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_LEADS,
        payload: data,
    };
};

export const fetchListLeadSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_LEADS_SUCCESS,
        payload: data,
    };
};

export const fetchListLeadFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_LEADS_FAILED,
    };
};

export const updateLead = (id, data) => {
    return {
        type: ActionTypes.UPDATE_LEAD,
        payload: data,
        id,
    };
};

export const updateLeadSuccess = (data) => {
    return {
        type: ActionTypes.UPDATE_LEAD_SUCCESS,
        payload: data,
    };
};

export const updateLeadFailed = () => {
    return {
        type: ActionTypes.UPDATE_LEAD_FAILED,
    };
};

export const getListCountry = (data) => {
    return {
        type: ActionTypes.GET_LIST_COUNTRY,
        payload: data,
    };
};

export const getListCountrySuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_COUNTRY_SUCCESS,
        payload: data,
    };
};

export const getListCountryFailed = () => {
    return {
        type: ActionTypes.GET_LIST_COUNTRY_FAILED,
    };
};

export const createNewLead = (data) => {
    return {
        type: ActionTypes.CREATE_NEW_LEAD,
        payload: data,
    };
};

export const createNewLeadSuccess = (data) => {
    return {
        type: ActionTypes.CREATE_NEW_LEAD_SUCCESS,
        payload: data,
    };
};

export const createNewLeadFailed = () => {
    return {
        type: ActionTypes.CREATE_NEW_LEAD_FAILED,
    };
};

export const deleteLead = (id) => {
    return {
        type: ActionTypes.DELETE_LEAD,
        payload: id,
    };
};

export const deleteLeadSuccess = () => {
    return {
        type: ActionTypes.DELETE_LEAD_SUCCESS,
    };
};

export const deleteLeadFailed = () => {
    return {
        type: ActionTypes.DELETE_LEAD_FAILED,
    };
};

export const fetchListStatusLeads = () => {
    return {
        type: ActionTypes.FETCH_LIST_STATUS_LEADS,
    };
};

export const fetchListStatusLeadSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_STATUS_LEADS_SUCCESS,
        payload: data,
    };
};

export const fetchListStatusLeadFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_STATUS_LEADS_FAILED,
    };
};

export const updateStatusLead = (data) => {
    return {
        type: ActionTypes.UPDATE_STATUS_LEAD,
        payload: data,
    };
};

export const updateStatusLeadSuccess = (data) => {
    return {
        type: ActionTypes.UPDATE_STATUS_LEAD_SUCCESS,
        payload: data,
    };
};

export const updateStatusLeadFailed = () => {
    return {
        type: ActionTypes.UPDATE_STATUS_LEAD_FAILED,
    };
};

export const getListCreatedBy = (data) => {
    return {
        type: ActionTypes.GET_LIST_CREATED_BY,
        payload: data,
    };
};

export const getListCreatedBySuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_CREATED_BY_SUCCESS,
        payload: data,
    };
};

export const getListCreatedByFailed = () => {
    return {
        type: ActionTypes.GET_LIST_CREATED_BY_FAILED,
    };
};

export const getListSource = (data) => {
    return {
        type: ActionTypes.GET_LIST_SOURCE,
        payload: data,
    };
};

export const getListSourceSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_SOURCE_SUCCESS,
        payload: data,
    };
};

export const getListSourceFailed = () => {
    return {
        type: ActionTypes.GET_LIST_SOURCE_FAILED,
    };
};
// Fetch list proposal module Lead (LEAD lớn)
export const fetchListProposalLead = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_PROPOSAL_LEAD,
        payload: data,
    };
};

export const fetchListProposalLeadSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_PROPOSAL_LEAD_SUCCESS,
        payload: data,
    };
};

export const fetchListProposalLeadFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_PROPOSAL_LEAD_FAILED,
    };
};

export const fetchListStaff = () => {
    return {
        type: ActionTypes.FETCH_LIST_STAFF,
    };
};

export const fetchListStaffSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_STAFF_SUCCESS,
        payload: data,
    };
};

export const fetchListStaffFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_STAFF_FAILED,
    };
};

export const fetchListReminderLead = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_REMINDER_LEAD,
        payload: data,
    };
};

export const fetchListReminderLeadSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_REMINDER_LEAD_SUCCESS,
        payload: data,
    };
};

export const fetchListReminderLeadFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_REMINDER_LEAD_FAILED,
    };
};

export const createReminderLead = (id, data) => {
    return {
        type: ActionTypes.CREATE_REMINDER_LEAD,
        payload: data,
        id,
    };
};

export const createReminderLeadSuccess = (data) => {
    return {
        type: ActionTypes.CREATE_REMINDER_LEAD_SUCCESS,
        payload: data,
    };
};

export const createReminderLeadFailed = () => {
    return {
        type: ActionTypes.CREATE_REMINDER_LEAD_FAILED,
    };
};

export const deleteReminderLead = (id) => {
    return {
        type: ActionTypes.DELETE_REMINDER_LEAD,
        payload: id,
    };
};

export const deleteReminderLeadSuccess = (id) => {
    return {
        type: ActionTypes.DELETE_REMINDER_LEAD_SUCCESS,
        payload: id,
    };
};

export const deleteReminderLeadFailed = () => {
    return {
        type: ActionTypes.DELETE_REMINDER_LEAD_FAILED,
    };
};

export const updateReminderLead = (id, data, params) => {
    return {
        type: ActionTypes.UPDATE_REMINDER_LEAD,
        payload: data,
        id,
        params,
    };
};

export const updateReminderLeadSuccess = (id) => {
    return {
        type: ActionTypes.UPDATE_REMINDER_LEAD_SUCCESS,
        payload: id,
    };
};

export const updateReminderLeadFailed = () => {
    return {
        type: ActionTypes.UPDATE_REMINDER_LEAD_FAILED,
    };
};

export const fetchNoteLead = (id) => {
    return {
        type: ActionTypes.FETCH_NOTE_LEAD,
        payload: id,
    };
};

export const fetchNoteLeadSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_NOTE_LEAD_SUCCESS,
        payload: data,
    };
};

export const fetchNoteLeadFailed = () => {
    return {
        type: ActionTypes.FETCH_NOTE_LEAD_FAILED,
    };
};

export const createNoteLead = (id, data) => {
    return {
        type: ActionTypes.CREATE_NOTE_LEAD,
        payload: data,
        id,
    };
};

export const createNoteLeadSuccess = () => {
    return {
        type: ActionTypes.CREATE_NOTE_LEAD_SUCCESS,
    };
};

export const createNoteLeadFailed = () => {
    return {
        type: ActionTypes.CREATE_NOTE_LEAD_FAILED,
    };
};

export const deleteNoteLead = (id) => {
    return {
        type: ActionTypes.DELETE_NOTE_LEAD,
        payload: id,
    };
};

export const deleteNoteLeadSuccess = () => {
    return {
        type: ActionTypes.DELETE_NOTE_LEAD_SUCCESS,
    };
};

export const deleteNoteLeadFailed = () => {
    return {
        type: ActionTypes.DELETE_NOTE_LEAD_FAILED,
    };
};

export const updateNoteLead = (id, data) => {
    return {
        type: ActionTypes.UPDATE_NOTE_LEAD,
        payload: data,
        id,
    };
};

export const updateNoteLeadSuccess = () => {
    return {
        type: ActionTypes.UPDATE_NOTE_LEAD_SUCCESS,
    };
};

export const updateNoteLeadFailed = () => {
    return {
        type: ActionTypes.UPDATE_NOTE_LEAD_FAILED,
    };
};

export const fetchListAttachmentLead = (id) => {
    return {
        type: ActionTypes.FETCH_LIST_ATTACHMENT_LEAD,
        payload: id,
    };
};

export const fetchListAttachmentLeadSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_ATTACHMENT_LEAD_SUCCESS,
        payload: data,
    };
};

export const fetchListAttachmentLeadFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_ATTACHMENT_LEAD_FAILED,
    };
};

export const createAttachmentLead = (id, data) => {
    return {
        type: ActionTypes.CREATE_ATTACHMENT_LEAD,
        payload: data,
        id,
    };
};

export const createAttachmentLeadSuccess = () => {
    return {
        type: ActionTypes.CREATE_ATTACHMENT_LEAD_SUCCESS,
    };
};

export const createAttachmentLeadFailed = () => {
    return {
        type: ActionTypes.CREATE_ATTACHMENT_LEAD_FAILED,
    };
};

export const deleteAttachmentLead = (id) => {
    return {
        type: ActionTypes.DELETE_ATTACHMENT_LEAD,
        payload: id,
    };
};

export const deleteAttachmentLeadSuccess = () => {
    return {
        type: ActionTypes.DELETE_ATTACHMENT_LEAD_SUCCESS,
    };
};

export const deleteAttachmentLeadFailed = () => {
    return {
        type: ActionTypes.DELETE_ATTACHMENT_LEAD_FAILED,
    };
};

export const fetchListTaskLead = (id) => {
    return {
        type: ActionTypes.FETCH_LIST_TASK_LEAD,
        payload: id,
    };
};

export const fetchListTaskLeadSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_TASK_LEAD_SUCCESS,
        payload: data,
    };
};

export const fetchListTaskLeadFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_TASK_LEAD_FAILED,
    };
};

export const getConvertToCustomer = () => {
    return {
        type: ActionTypes.GET_CONVERT_TO_CUSTOMER,
    };
};

export const convertToCustomerLeadPending = (leadId, body) => {
    return {
        type: ActionTypes.CONVERT_TO_CUSTOMER_LEAD_PENDING,
        payload: { leadId: leadId, body: body },
    };
};

export const convertToCustomerLeadSuccess = (data) => {
    return {
        type: ActionTypes.CONVERT_TO_CUSTOMER_LEAD_SUCCESS,
    };
};

export const convertToCustomerLeadFailed = () => {
    return {
        type: ActionTypes.CONVERT_TO_CUSTOMER_LEAD_FAILED,
    };
};
