export default {
    task: {
        listTaskCurrentWeek: "Danh Sách Công Việc Trong Tuần",
        totalTask: "Tổng công việc",
        taskCurrentWeek: "Thống Kê Công Việc Trong Tuần",
        nameCreator: "Không rõ",
        createBy: "Người tạo",
        attachFiles: "Tập tin đính kèm",
        summary: "Tóm lược phân công",
        export: "XUẤT IN",
        bulk: "BULK ACTION",
        name: "Tên",
        status: "Trạng thái",
        startDate: "Ngày bắt đầu",
        dueDate: "Ngày chốt",
        assignedTo: "Đã chỉ định cho",
        tags: "Các thẻ",
        priority: "Mức độ ưu tiên",
        delete: "Xóa",
        recurringTask: "Phân công định kỳ",
        markAs: "Đánh dấu là",
        notStarted: "Chưa bắt đầu",
        inProgress: "Đang tiến hành",
        testing: "Đang kiểm tra",
        awaitingFeedback: "Đang chờ phản hồi",
        complete: "Đã hoàn thành",
        allStatus: "Tất cả trạng thái",
        low: "Thấp",
        medium: "Bình thường",
        high: "Cao",
        urgent: "Cấp bách",
        edit: "Chỉnh sửa",
        public: "Công khai",
        billable: "Có thể xuất biên nhận",
        subject: "Chủ đề",
        hourlyRate: "Tiền công theo giờ",
        repeatEvery: "Lặp lại mỗi",
        totalCycles: "Total Cycles",
        infinity: "Liên tục",
        relatedTo: "Liên quan đến",
        contract: "Hợp đồng",
        taskDescription: "Mô tả công việc",
        description: "Mô tả",
        checklistItem: "Đánh dấu tiến trình",
        checklistItemNone: "Không tìm thấy tiến trình nào được đánh dấu",
        comment: "Bình luận",
        addComment: "Thêm bình luận",
        taskInfo: "Thông tin phân công",
        createdAt: "Thời gian tạo",
        billableAmount: "Billable Amount",
        userLoggedTime: "Thời gian đăng nhập của bạn",
        totalLoggedTime: "Tổng thời gian đăng nhập",
        reminder: "Lời nhắc",
        createReminder: "Tạo lời nhắc",
        reminderNone: "Chưa có lời nhắc cho phân công này",
        assignee: "Người được phân công",
        assignTaskTo: "Ấn định cho",
        follower: "Người theo dõi",
        addFollower: "Thêm người theo dõi",
        followersNone: "Không có người theo dõi cho phân công này",
        uploadFile: "Thả tập tin vào đây để tải lên",
        member: "Thành viên",
        timeStart: "Thời gian bắt đầu",
        timeEnd: "Thời gian kết thúc",
        timeSpent: "Thời gian thực hiện",
        timerNone: "Không tìm thấy mục đếm thời gian nào",
        note: "Ghi chú",
        confirm: "XÁC NHẬN",
        close: "ĐÓNG",
        save: "LƯU LẠI",
        deleteTitle: "Xóa công việc",
        deleteConfirm: "Bạn có chắc bạn muốn xóa",
        cancel: "HỦY",
        noRepeat: "Không lặp lại",
        week: "Tuần",
        day: "Ngày",
        month: "Tháng",
        year: "Năm",
        twoWeeks: "2 Tuần",
        oneMonth: "1 Tháng",
        twoMonths: "2 Tháng",
        threeMonths: "3 Tháng",
        sixMonths: "6 Tháng",
        oneYear: "1 Năm",
        custom: "Tùy chỉnh",
        haveRelate: "Có liên quan đến",
        noSelect: "Không lựa chọn",
        project: "Dự án",
        invoice: "Hóa đơn",
        customer: "Khách hàng",
        estimate: "Báo giá",
        ticket: "Yêu cầu",
        expense: "Chi phí",
        lead: "Khách tiềm năng",
        proposal: "Đề xuất kế hoạch",
        createTaskTitle: "Thêm phân công mới",
        taskOverviewTitle: "Tổng quan phân công",
        allMonth: "Tất cả các tháng",
        jan: "Tháng 1",
        feb: "Tháng 2",
        march: "Tháng 3",
        april: "Tháng 4",
        may: "Tháng 5",
        june: "Tháng 6",
        july: "Tháng 7",
        aug: "Tháng 8",
        sept: "Tháng 9",
        oct: "Tháng 10",
        nov: "Tháng 11",
        dec: "Tháng 12",
        backToTask: "QUAY LẠI DANH SÁCH PHÂN CÔNG",
        totalAttachment: "Tổng số tập tin đính kèm đã thêm",
        totalComments: "Tổng số bình luận",
        finishOn: "Đã hoàn thành trước hẹn?",
        markAsComplete: "Hoàn thành",
        markAsInProgress: "Đang tiến hành",
        statistical: "Thông kê",
        timeChart: "Biểu đồ thời gian",
        reload: "Tải lại",
        action: "Hành động",
        dateNotified: "Ngày cần thông báo",
        setReminder: "Chuyển nhắc nhở sang",
        sendMailToReminder: "Gửi email cho cả nhắc nhở này",
        filter: "Lọc",
        all: "Tất cả",
        inDayTask: "Phân công trong ngày",
        overTerm: "Quá hạn",
        upcomingTask: "Phân công sắp tới",
        myTask: "Các phân công được chỉ định cho tôi",
        myFollowingTask: "Các phân công tôi đang theo dõi",
        notAssigned: "Chưa được phân công",
        recurring: "Định kỳ",
        billed: "Đã xuất",
        notBilled: "Không xuất",
        byAssignedMember: "Bởi thành viên được chỉ định",
        copy: "Copy",
        validation: {
            requirement: "Không được bỏ trống.",
        },
        newTask: "Phân công mới",
        assignedMember: "Bởi thành viên được chỉ định",
        userTask: "Các phân công được chỉ định cho tôi",
        followingTask: "Các phân công tôi đang theo dõi",
        files: "Tệp",
        new: "Mới",
        // table: {
        //   name: 'Tên',
        //   status: 'Trạng thái',
        //   startDate: 'Ngày bắt đầu',
        //   dueDate: 'Ngày chốt',
        //   assignedTo: 'Đã chỉ định cho',
        //   tags: 'Các thẻ',
        //   priority: 'Mức độ ưu tiên',
        // },
        // name: {
        //   edit: 'Sửa',
        //   delete: 'Xóa',
        //   recurring: 'Phân công định kỳ'
        // },
        // status: {
        //   markAs: 'Đánh dấu là',
        //   notStarted: 'Chưa bắt đầu',
        //   inProgress: 'Đang tiến hành',
        //   testing: 'Đang kiểm tra',
        //   awaitingFeedback: 'Đang chờ phản hồi',
        //   complete: 'Đã hoàn thành',
        //   all: 'Tất cả trạng thái',
        // },
        // priority: {
        //   low: 'Thấp',
        //   medium: 'Bình thường',
        //   high: 'Cao',
        //   urgent: 'Cấp bách',
        // },
        // edit: {
        //   label: 'Chỉnh sửa',
        //   public: 'Công khai',
        //   billable: 'Có thể xuất biên nhận',
        //   subject: 'Chủ đề',
        //   hourlyRate: 'Tiền công theo giờ',
        //   startDate: 'Ngày bắt đầu',
        //   dueDate: 'Hạn chót',
        //   priority: 'Mức độ ưu tiên',
        //   repeatEvery: 'Lặp lại mỗi',
        //   totalCycles: 'Total Cycles',
        //   infinity: 'Liên tục',
        //   relatedTo: 'Liên quan đến',
        //   contract: 'Hợp đồng',
        //   tags: 'Các thẻ',
        //   taskDescription: 'Mô tả công việc',
        //   description: 'Mô tả',
        //   checklistItem: 'Đánh dấu tiến trình',
        //   checklistItemNone: 'Không tìm thấy tiến trình nào được đánh dấu',
        //   comment: 'Bình luận',
        //   addComment: 'Thêm bình luận',
        //   taskInfo: 'Thông tin phân công',
        //   createdAt: 'Thời gian tạo',
        //   status: 'Trạng thái',
        //   billableAmount: 'Billable Amount',
        //   userLoggedTime: 'Thời gian đăng nhập của bạn',
        //   totalLoggedTime: 'Tổng thời gian đăng nhập',
        //   reminder: 'Lời nhắc',
        //   createReminder: 'Tạo lời nhắc',
        //   reminderNone: 'Chưa có lời nhắc cho phân công này',
        //   assignee: 'Người được phân công',
        //   assignTask: 'Ấn định cho',
        //   followers: 'Người theo dõi',
        //   addFollowers: 'Thêm người theo dõi',
        //   followersNone: 'Không có người theo dõi cho phân công này',
        //   upload: 'Thả tập tin vào đây để tải lên',
        //   member: 'Thành viên',
        //   timeStart: 'Thời gian bắt đầu',
        //   timeEnd: 'Thời gian kết thúc',
        //   timeSpent: 'Thời gian thực hiện',
        //   timeChartNull: 'Không tìm thấy mục đếm thời gian nào',
        //   note: 'Ghi chú',
          confirm: 'XÁC NHẬN',
        //   close: 'ĐÓNG',
        //   save: 'LƯU LẠI',
        // },
        // delete: {
        //   title: 'Xóa công việc',
        //   confirm: 'Bạn có chắc bạn muốn xóa',
        //   cancel: 'HỦY',
        //   yes: 'XÓA',
        // },
        // taskRepeat: {
        //   nothing: 'Không lặp lại',
        //   week: 'Tuần',
        //   day: 'Ngày',
        //   month: 'Tháng',
        //   year: 'Năm',
        //   twoWeeks: '2 Tuần',
        //   oneMonth: '1 Tháng',
        //   twoMonths: '2 Tháng',
        //   threeMonths: '3 Tháng',
        //   sixMonths: '6 Tháng',
        //   oneYear: '1 Năm',
        //   custom: 'Tùy chỉnh',
        // },
        // related: {
        //   title: 'Có liên quan đến',
        //   nothing: 'Không lựa chọn',
        //   project: 'Dự án',
        //   invoice: 'Hóa đơn',
        //   customer: 'Khách hàng',
        //   estimate: 'Báo giá',
        //   contract: 'Hợp đồng',
        //   ticket: 'Yêu cầu',
        //   expense: 'Chi phí',
        //   lead: 'Khách tiềm năng',
        //   proposal: 'Đề xuất kế hoạch',
        // },
        // addNew: {
        //   title: 'Thêm phân công mới'
        // },
        // taskOverview: {
        //   title: 'Tổng quan phân công',
        //   back: 'QUAY LẠI DANH SÁCH PHÂN CÔNG',
        //   allStaff: 'Tất cả nhân viên',
        //   month: {
        //     allMonth: 'Tất cả các tháng',
        //     jan: 'Tháng 1',
        //     feb: 'Tháng 2',
        //     march: 'Tháng 3',
        //     april: 'Tháng 4',
        //     may: 'Tháng 5',
        //     june: 'Tháng 6',
        //     july: 'Tháng 7',
        //     aug: 'Tháng 8',
        //     sept: 'Tháng 9',
        //     oct: 'Tháng 10',
        //     nov: 'Tháng 11',
        //     dec: 'Tháng 12',
        //   },
        //   table: {
        //     name: 'Tên',
        //     startDate: 'Ngày bắt đầu',
        //     dueDate: 'Ngày chốt',
        //     status: 'Trạng thái',
        //     totalAttachment: 'Tổng số tập tin đính kèm đã thêm',
        //     totalComments: 'Tổng số bình luận',
        //     checklistItems: 'Đánh dấu tiến trình',
        //     totalLoggedTime: 'Tổng thời gian đăng nhập',
        //     finishOn: 'Đã hoàn thành trước hẹn?',
        //     assignedTo: 'Đã chỉ định cho',
        //   }
        // },
        // filter: {
        //   title: 'Lọc',
        //   all: 'Tất cả',
          todayTask: "Phân công trong ngày",
        //   dueDate: 'Quá hạn',
        //   upcomingTask: 'Phân công sắp tới',
        //   userTask: 'Các phân công được chỉ định cho tôi',
        //   followingTask: "Các phân công tôi đang theo dõi",
        //   notAssigned: 'Chưa được phân công',
        //   recurring: 'Định kỳ',
        //   billed: 'Đã xuất',
        //   notBilled: 'Không xuất',
        //   assignedMember: 'Bởi thành viên được chỉ định',
        // },
        // tooltip: {
        //   markAsComplete: 'Đánh dấu hoàn thành',
        //   markAsInProgress: 'Đánh dấu đang tiến hành',
        //   statistical: 'Thông kê',
        //   timeChart: 'Biểu đồ thời gian',
        //   edit: 'Chỉnh sửa',
        //   reload: 'Tải lại',
        //   delete: 'Xóa',
        // },
        // action: {
        //   title: 'Hành động',
        //   edit: 'Chỉnh sửa',
        //   copy: 'Copy',
        //   delete: 'Xóa',
        // },
        // reminder: {
        //   dateNotified: 'Ngày cần thông báo',
        //   setReminder: 'Chuyển nhắc nhở sang',
        //   description: 'Mô tả',
        //   sendMail: 'Gửi email cho cả nhắc nhở này',
        //   createReminder: 'TẠO LỜI NHẮC',
        // },
        // validation: {
        //   requirement: 'Không được bỏ trống.',
        // },
        deleteFollower: "Xóa người theo dõi",
        deleteAssignee: "Xóa người được phân công",
        deleteAllTask: "Xóa tất cả",
        errorStartDate: "Ngày bắt đầu không được để trống",
        errorDueDate: "Ngày chốt không được để trống",
        errorStatus: "Trạng thái không được để trống",
        errorPriority: "Mức độ ưu tiên không được để trống",
        errorProject: "Dự án không được để trống",
        errorTag: "Danh sách thẻ không được để trống",
        errorAssigne: "Người được phân công không được để trống",
        errorFollower: "Người theo dõi không được để trống",
        warningDeleteTask: "Lưu ý khi bạn chọn ở đây tất cả những task bạn chọn sẽ bị xóa vĩnh viễn.",
        listTask: "Danh sách công việc :",
        confirmOpenTaskDetail: "Xem công việc sao chép?",
        taskAction: {
          createTaskSuccess:  "Tạo công việc thành công",
          createTaskFailed:  "Tạo công việc thất bại",
          updateTaskSuccess:  "Cập nhật công việc thành công",
          updateTaskFailed:  "Cập nhật công việc thất bại",
          deleteTaskSuccess:  "Xóa công việc thành công",
          deleteTaskFailed:  "Xóa công việc thất bại",
          updateTaskPrioritySuccess:  "Cập nhật mức độ ưu tiên thành công",
          updateTaskPriorityFailed:  "Cập nhật mức độ ưu tiên thất bại",
          updateTaskStatusSuccess:  "Cập nhật trạng thái thành công",
          updateTaskStatusFailed:  "Cập nhật trạng thái thất bại",
          createTimerSuccess:  "Tạo biểu đồ thời gian thành công",
          createTimerFailed:  "Tạo biểu đồ thời gian thất bại",
          deleteTimerSuccess:  "Xóa biểu đồ thời gian thành công",
          deleteTimerFailed:  "Xóa biểu đồ thời gian thất bại",
          createChecklistSuccess: "Đánh dấu tiến trình thành công",
          createChecklistFailed: "Đánh dấu tiến trình thất bại",
          updateChecklistSuccess: "Cập nhật tiến trình thành công",
          updateChecklistFailed: "Cập nhật tiến trình thất bại",
          deleteChecklistSuccess: "Xóa tiến trình thành công",
          deleteChecklistFailed: "Xóa tiến trình thất bại",
          addCommentSuccess: "Tạo bình luận thành công",
          addCommentFailed: "Tạo bình luận thất bại thất bại",
          editCommentSuccess: "Chỉnh sửa bình luận thành công",
          editCommentFailed: "Chỉnh sửa bình luận thất bại",
          deleteCommentSuccess: "Xóa bình luận thành công",
          deleteCommentFailed: "Xóa bình luận thất bại",
          addReminderSuccess: "Thêm nhắc nhở thành công",
          addReminderFailed: "Thêm nhắc nhở thất bại",
          editReminderSuccess: "Chỉnh sửa nhắc nhở thành công",
          editReminderFailed: "Chỉnh sửa nhắc nhở thất bại",
          deleteReminderSuccess: "Xóa nhắc nhở thành công",
          deleteReminderFailed: "Xóa nhắc nhở thất bại",
          addAssignSuccess: "Thêm người được phân công thành công",
          addAssignFailed: "Thêm người phân công thất bại",
          deleteAssignSuccess: "Xóa người được phân công thành công",
          deleteAssignFailed: "Xóa người phân công thất bại",
          deleteFollowerSuccess: "Xóa người theo dõi thành công",
          deleteFollowerFailed: "Xóa người theo dõi thất bại",
          addFollowerSuccess: "Thêm người theo dõi thành công",
          addFollowerFailed: "Thêm người theo dõi thất bại",
          copyTaskSuccessMessage: "Sao chép công việc thành công",
          copyTaskFailureMessage: "Sao chép công việc thất bại",
          deleteTagSuccess: "Xóa thẻ thành công",
          deleteTagFailure: "Xóa thẻ thất bại",
          bulkActionsSuccess: "Thực hiện hành động hàng loạt thành công",
          bulkActionsFailure: "Thực hiện hành động hàng loạt thất bại"
        }
    },
};
