import ActionTypes from "./types";

// FETCH DISCUSSION
export const fetchDiscussion = (data) => {
  return {
    type: ActionTypes.FETCH_DISCUSSION,
    payload: data,
  };
};

export const fetchDiscussionSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_DISCUSSION_SUCCESS,
    payload: data,
  };
};

export const fetchDiscussionFailed = () => {
  return {
    type: ActionTypes.FETCH_DISCUSSION_FAILED,
  };
};

export const createDiscussion = (data, id) => {
  return {
    type: ActionTypes.CREATE_DISCUSSION,
    payload: data,
    id: id,
  };
};

export const createDiscussionSuccess = (data) => {
  return {
    type: ActionTypes.CREATE_DISCUSSION_SUCCESS,
    payload: data
  };
};

export const createDiscussionFailed = () => {
  return {
    type: ActionTypes.CREATE_DISCUSSION_FAILED,
  };
};

export const deleteDiscussion = (data) => {
  return {
    type: ActionTypes.DELETE_DISCUSSION,
    payload: data,
  };
};

export const deleteDiscussionSuccess = (data) => {
  return {
    type: ActionTypes.DELETE_DISCUSSION_SUCCESS,
    payload: data,
  };
};

export const deleteDiscussionFailed = () => {
  return {
    type: ActionTypes.DELETE_DISCUSSION_FAILED,
  };
};

export const updateDiscussion = (data, id) => {
  return {
    type: ActionTypes.UPDATE_DISCUSSION,
    payload: { data, id },
  };
};

export const updateDiscussionSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_DISCUSSION_SUCCESS,
    payload: data,
  };
};

export const updateDiscussionFailed = (data) => {
  return {
    type: ActionTypes.UPDATE_DISCUSSION_FAILED,
    payload: data,
  };
};

export const setEditDiscussion = (data) => {
  return {
    type: ActionTypes.SET_EDIT_DISCUSSION,
    payload: data,
  };
};
