import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import i18n from "../../../../i18n";

import ActionTypes from "./types";
import {
  fetchInvoicesProjectSuccess,
  fetchInvoicesProjectFailed,
  fetchFilterYearInvoicePrjSuccess,
  fetchFilterYearInvoicePrjFailed,
  fetchTotalInvoicePrjSuccess,
  fetchTotalInvoicePrjFailed,
  fetchStaffInvoicePrjSuccess,
  fetchStaffInvoicePrjFailed,
  filterInvoiceSuccess,
  filterInvoiceFailed,
  fetchCurrenciesSuccess,
  fetchCurrenciesFailed,
} from "./actions";

import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import {
  fetchStaffInvoiceProject,
  filterYearInvoicePrj,
  getTotalInvoicePrj,
  filterInvoicePrj,
  getInvoicesProject,
  getCurrencies,
} from "./api";

import { responeCode, typeMessage } from "../../../../constants/Const";
import {
  throwErrorMessage,
  parseDataNotifications,
} from "../../../../helpers/HandleError";

function* fetchInvoicesProjectSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(getInvoicesProject, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result &&
      response.data.result.data
    ) {
      yield put(fetchInvoicesProjectSuccess(response.data.result));
    } else {
      yield put(fetchInvoicesProjectFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchInvoicesProjectFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* filterYearInvoicePrjSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(filterYearInvoicePrj, payload);
        if (response && response.data) {

            yield put(fetchFilterYearInvoicePrjSuccess(response.data));
        } else {
            yield put(fetchFilterYearInvoicePrjFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchFilterYearInvoicePrjFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchTotalInvoicePrjSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(getTotalInvoicePrj, payload);
        if (response && response.data) {
            yield put(fetchTotalInvoicePrjSuccess(response.data));
        } else {
            yield put(fetchTotalInvoicePrjFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchTotalInvoicePrjFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getStaffInvoicePrjSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(fetchStaffInvoiceProject, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(fetchStaffInvoicePrjSuccess(response.data.result));
    } else {
      yield put(fetchStaffInvoicePrjFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchStaffInvoicePrjFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* filterInvoicePrjSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(filterInvoicePrj, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(filterInvoiceSuccess(response.data.result));
    } else {
      yield put(filterInvoiceFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(filterInvoiceFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* fetchCurrenciesSaga(data) {
  const { payload } = data;
  try {
      const response = yield call(getCurrencies, payload);
      if (response && response.data &&
          response.data.error_code === responeCode.success &&
          response.data.result) {
          yield put(fetchCurrenciesSuccess(response.data.result));
      } else {
          yield put(fetchCurrenciesFailed());
          yield put(
              enqueueSnackbar(
                  parseDataNotifications(
                      response.data.error_mess,
                      typeMessage.error
                  )
              )
          );
      }
  } catch (error) {
      yield put(fetchCurrenciesFailed());
      yield put(
          enqueueSnackbar(
              parseDataNotifications(
                  throwErrorMessage(error),
                  typeMessage.warning
              )
          )
      );
  }
}

export function* invoiceProjectSaga() {
  yield takeEvery( ActionTypes.FETCH_INVOICES_PROJECT, fetchInvoicesProjectSaga);
  yield takeEvery( ActionTypes.FETCH_FILTER_YEAR_INVOICE_PRJ, filterYearInvoicePrjSaga);
  yield takeEvery( ActionTypes.FETCH_TOTAL_INVOICE_PRJ, fetchTotalInvoicePrjSaga);
  yield takeEvery( ActionTypes.FETCH_STAFF_INVOICE_PRJ, getStaffInvoicePrjSaga);
  yield takeEvery( ActionTypes.FILTER_INVOICE_PRJ, filterInvoicePrjSaga);
  yield takeEvery( ActionTypes.FETCH_CURRENCIES, fetchCurrenciesSaga);
}

function* InvoiceProjectSaga() {
  yield all([fork(invoiceProjectSaga)]);
}

export default InvoiceProjectSaga;
