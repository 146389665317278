// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import { responeCode, typeMessage } from "../../../../constants/Const";
import {
    throwErrorMessage,
    parseDataNotifications,
} from "../../../../helpers/HandleError";
import ActionTypes from "./type";

import {
    addPaymentCustomerFailed,
    addPaymentCustomerSuccess,
    fetchPaymentCustomer,
    fetchPaymentCustomerFailed,
    fetchPaymentCustomerSuccess,
} from "./actions";
import { addPaymentCustomerRequest, fetchPaymentCustomerRequest } from "./api";

function* fetchPaymentCustomerSaga({ payload }) {
    try {
        const response = yield call(fetchPaymentCustomerRequest, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchPaymentCustomerSuccess(response.data.result));
        } else {
            yield put(fetchPaymentCustomerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchPaymentCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* addPaymentCustomerSaga({ payload }) {
    const { params, id } = payload;

    try {
        const response = yield call(addPaymentCustomerRequest, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addPaymentCustomerSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
            yield put(fetchPaymentCustomer(id, params));
        } else {
            yield put(addPaymentCustomerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(addPaymentCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

export function* paymentCustomerSaga() {
    yield takeEvery(ActionTypes.FETCH_PAYMENT_CUSTOMER_REQUEST, fetchPaymentCustomerSaga);
    yield takeEvery(ActionTypes.ADD_PAYMENT_CUSTOMER_REQUEST, addPaymentCustomerSaga);
}

export default paymentCustomerSaga;
