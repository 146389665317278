
import apiBase from "../../../../common/baseAPI";
const INVOICE_SALE = "invoice";
const CUSTOMER = "customer";
const CUSTOMFIELD_ESTIMATE = "customField/fieldto/items";

export const getInvoiceSaleRequest = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(INVOICE_SALE, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const addInvoiceRequest = (data) => {
  console.log("data ", data)
    return new Promise((resolve, reject) => {
      return apiBase
        .post(`${INVOICE_SALE}`, data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  
  export const getInvoiceByIdRequest = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${INVOICE_SALE}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
  };
  
  export const deleteInvoiceRequest = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${INVOICE_SALE}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
  };
  
  export const editInvoice = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`invoice/${data.id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
  };