import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import  i18n  from 'i18next';
import {
  fetchListEstimateSaleSuccess,
  fetchListEstimateSaleFailed,
  listSelectCustomerSuccess,
  listSelectCustomerFailed,
  getCustomFieldEstimateSuccess,
  getCustomFieldEstimateFailed,
  addEstimateSuccess,
  addEstimateFailed,
  editEstimateSuccess,
  editEstimateFailed,
  deleteEstimateSuccess,
  deleteEstimateFailed,
  getEstimateByIdFailed,
  getEstimateByIdSuccess,
  // addEstimateCustomerFailed,
  // addEstimateCustomerSuccess,
} from "./actions";
import { enqueueSnackbar } from "../../../redux/CommonReducer";
import {
  getEstimateSaleRequest,
  listSelectCustomerRequest,
  listCustomFieldRequest,
  addEstimateRequest,
  deleteEstimateRequest,
  editEstimateRequest,
  getEstimateByIdRequest,
  editEstimate,
  // addEstimateCustomerRequest,
} from "./api";
import { responeCode, typeMessage } from "../../../constants/Const";
import ActionTypes from "./types";
import {
  throwErrorMessage,
  parseDataNotifications,
} from "../../../helpers/HandleError";
// import { addCustomerRequest } from "../../Customer/redux/actions";

function* fetchEstimateSale(action) {
  try {
    const response = yield call(getEstimateSaleRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result &&
      response.data.result.data
    ) {
      yield put(fetchListEstimateSaleSuccess(response.data.result));
    } else {
      yield put(fetchListEstimateSaleFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchListEstimateSaleFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* listSelectCustomer(action) {
  try {
    const response = yield call(listSelectCustomerRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(listSelectCustomerSuccess(response.data.result));
    } else {
      yield put(listSelectCustomerFailed());
      yield put(

        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(listSelectCustomerFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* listCustomField() {
  try {
    const response = yield call(listCustomFieldRequest);

    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(getCustomFieldEstimateSuccess(response.data.result));
    } else {
      yield put(getCustomFieldEstimateFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(getCustomFieldEstimateFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
// function* addEstimateCustomerSaga(action) {
//   try {
//     const { customer_id, data } = action.payload;
//     const response = yield call(addEstimateCustomerRequest, customer_id, data);
//     if (
//       response &&
//       response.data &&
//       response.data.error_code === responeCode.success
//     ) {
//       yield put(addEstimateCustomerSuccess());
//       yield put(
//         enqueueSnackbar(
//           parseDataNotifications(response.data.error_mess, typeMessage.success)
//         )
//       );
//     } else {
//       yield put(addEstimateCustomerFailed());
//       yield put(
//         enqueueSnackbar(
//           parseDataNotifications(response.data.error_mess, typeMessage.error)
//         )
//       );
//     }
//   } catch (error) {
//     yield put(addEstimateCustomerFailed());
//     yield put(
//       enqueueSnackbar(
//         parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
//       )
//     );
//   }
// }

function* addEstimateSaga(action) {
  try {
    const response = yield call(addEstimateRequest, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(addEstimateSuccess());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(
            i18n.t('customer.estimate.action.createEstimateSuccess'),
            typeMessage.success
          )
        )
      );
    } else {
      yield put(addEstimateFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(
            i18n.t('customer.estimate.action.createEstimateFailed'),
            typeMessage.error
          )
        )
      );
    }
  } catch (error) {
    yield put(addEstimateFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(
          throwErrorMessage(error),
          typeMessage.warning
        )
      )
    );
  }
}

function* fetchEstimateById(data) {
  const { payload } = data;
  try {
      const response = yield call(getEstimateByIdRequest, payload);
      if (
          response &&
          response.data &&
          response.data.error_code === responeCode.success
      ) {
          yield put(getEstimateByIdSuccess(response.data.result));
      } else {
          yield put(getEstimateByIdFailed());
          yield put(
              enqueueSnackbar(
                  parseDataNotifications(
                      response.data.error_mess,
                      typeMessage.error
                  )
              )
          );
      }
  } catch (error) {
      yield put(getEstimateByIdFailed());
      yield put(
          enqueueSnackbar(
              parseDataNotifications(
                  throwErrorMessage(error),
                  typeMessage.warning
              )
          )
      );
  }
}

function* editEstimateSaga(action) {
  try {
      const response = yield call(editEstimate, action.payload);
      if (
          response &&
          response.data &&
          response.data.error_code === responeCode.success
      ) {
          yield put(editEstimateSuccess());
          yield put(
              enqueueSnackbar(
                  parseDataNotifications(
                      i18n.t('customer.estimate.action.updateEstimateSuccess'),
                      typeMessage.success
                  )
              )
          );
      } else {
          yield put(editEstimateFailed());
          yield put(
              enqueueSnackbar(
                  parseDataNotifications(
                      i18n.t('customer.estimate.action.updateEstimateFailed'),
                      typeMessage.error
                  )
              )
          );
      }
  } catch (error) {
      yield put(editEstimateFailed());
      yield put(
          enqueueSnackbar(
              parseDataNotifications(
                  throwErrorMessage(error),
                  typeMessage.warning
              )
          )
      );
  }
}

function* deleteEstimateSaga(data) {
  try {
      const response = yield call(deleteEstimateRequest, data.payload);
      if (
          response &&
          response.data &&
          response.data.error_code === responeCode.success
      ) {
          yield put(deleteEstimateSuccess(response.data.result));

          yield put(
              enqueueSnackbar(
                  parseDataNotifications(
                      i18n.t('customer.estimate.action.deleteEstimateSuccess'),
                      typeMessage.success
                  )
              )
          );
      } else {
          yield put(deleteEstimateFailed());
          yield put(
              enqueueSnackbar(
                  parseDataNotifications(
                      i18n.t('customer.estimate.action.deleteEstimateFailed'),
                      typeMessage.error
                  )
              )
          );
      }
  } catch (error) {
      yield put(deleteEstimateFailed());
      yield put(
          enqueueSnackbar(
              parseDataNotifications(
                  throwErrorMessage(error),
                  typeMessage.warning
              )
          )
      );
  }
}


export function* saleEstimateSaga() {
  yield takeEvery(ActionTypes.FETCH_ESTIMATES_SALE_REQUEST, fetchEstimateSale);
  yield takeEvery(ActionTypes.LIST_SELECT_CUSTOMER_REQUEST, listSelectCustomer);
  yield takeEvery(ActionTypes.GET_CUSTOM_FIELD_REQUEST, listCustomField);
  // yield takeEvery(ActionTypes.ADD_ESTIMATE_CUSTOMER_REQUEST, addEstimateCustomerSaga);
  yield takeEvery(ActionTypes.ADD_ESTIMATE_REQUEST, addEstimateSaga);
  yield takeEvery(ActionTypes.GET_ESTIMATE_BY_ID, fetchEstimateById);
  yield takeEvery(ActionTypes.EDIT_ESTIMATE_REQUEST, editEstimateSaga);
  yield takeEvery(ActionTypes.DELETE_ESTIMATE, deleteEstimateSaga);
}

function* SaleEstimateSaga() {
  yield all([fork(saleEstimateSaga)]);
}

export default SaleEstimateSaga;
