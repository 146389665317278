export default {
  route: {
    dashboard: "Dashboard",
    customer: "Khách hàng",
    lead: "Thu thập dữ liệu",
    project: "Kế hoạch",
    task: "Công việc",
    sales: "Bán hàng",
    proposal: "Đề xuất kế hoạch",
    plan: "Đề xuất",
    inventory: "Kho",
    product: "Sản phẩm",
    support: "Hỗ trợ",
    expense: "Chi phí",
    contract: "Hợp đồng",
    utilities: "Tiện ích",
    report: "Báo cáo",
    setup: "Quản trị",
    estimates: "Báo giá",
    invoices: "Hóa đơn",
    payments: "Thanh toán",
    items: "Mặt hàng",
    staff: "Nhân viên",
    group: "Nhóm",
    department: "Phòng ban",
    predefinedReply: "Phản hồi soạn sẵn",
    ticketPriority: "Độ ưu tiên của yêu cầu",
    ticketStatus: "Trạng thái yêu cầu",
    service: "Dịch vụ",
    spamFilter: "Lọc yêu cầu rác",
    source: "Nguồn",
    status: "Trạng thái",
    emailIntegation: "Tich hợp email",
    webToLead: "Đối tượng từ Web",
    finance: "Tài chính",
    taxRate: "Tỉ giá thuế",
    currency: "Đơn vị tiền",
    paymentMode: "Phương thức thanh toán",
    expenseCategory: "Phân loại chi phí",
    contractType: "Các loại hợp đồng",
    customFields: "Trường Tuỳ chỉnh",
    role: "Vị trí",
    setting: "Cài đặt",
    invoice: "Hóa đơn",
    creditNotes: "Ghi chú tín dụng",
  },
};
