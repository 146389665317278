const ActionTypes = {
    FETCH_INVOICES_PROJECT: 'FETCH_INVOICES_PROJECT',
    FETCH_INVOICES_PROJECT_SUCCESS: 'FETCH_INVOICES_PROJECT_SUCCESS',
    FETCH_INVOICES_PROJECT_FAILED: 'FETCH_INVOICES_PROJECT_FAILED',
    
    FETCH_FILTER_YEAR_INVOICE_PRJ: 'FETCH_FILTER_YEAR_INVOICE_PRJ',
    FETCH_FILTER_YEAR_INVOICE_PRJ_SUCCESS: 'FETCH_FILTER_YEAR_INVOICE_PRJ_SUCCESS',
    FETCH_FILTER_YEAR_INVOICE_PRJ_FAILED: 'FETCH_FILTER_YEAR_INVOICE_PRJ_FAILED',
    
    FETCH_TOTAL_INVOICE_PRJ: 'FETCH_TOTAL_INVOICE_PRJ',
    FETCH_TOTAL_INVOICE_PRJ_SUCCESS: 'FETCH_TOTAL_INVOICE_PRJ_SUCCESS',
    FETCH_TOTAL_INVOICE_PRJ_FAILED: 'FETCH_TOTAL_INVOICE_PRJ_FAILED',
    
    FETCH_CURRENCIES: 'FETCH_CURRENCIES',
    FETCH_CURRENCIES_SUCCESS: 'FETCH_CURRENCIES_SUCCESS',
    FETCH_CURRENCIES_FAILED: 'FETCH_CURRENCIES_FAILED',
    
    FETCH_STAFF_INVOICE_PRJ: 'FETCH_STAFF_INVOICE_PRJ',
    FETCH_STAFF_INVOICE_PRJ_SUCCESS: 'FETCH_STAFF_INVOICE_PRJ_SUCCESS',
    FETCH_STAFF_INVOICE_PRJ_FAILED: 'FETCH_STAFF_INVOICE_PRJ_FAILED',
    
    FILTER_INVOICE_PRJ: 'FILTER_INVOICE_PRJ',
    FILTER_INVOICE_PRJ_SUCCESS: 'FILTER_INVOICE_PRJ_SUCCESS',
    FILTER_INVOICE_PRJ_FAILED: 'FILTER_INVOICE_PRJ_FAILED',
    
};
export default ActionTypes;