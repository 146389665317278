import { call, put, takeEvery } from "@redux-saga/core/effects";
import { responeCode, typeMessage } from "../../../../constants/Const";
import {
    parseDataNotifications,
    throwErrorMessage,
} from "../../../../helpers/HandleError";
import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import {
    addContractTypeFailed,
    addContractTypeSuccess,
    deleteContractTypeFailed,
    deleteContractTypeSuccess,
    getContractTypeFailed,
    getContractTypePending,
    getContractTypeSuccess,
    putContractTypeFailed,
    putContractTypeSuccess,
} from "./actions";
import {
    addContractType,
    deleteContractType,
    getContractType,
    putContractType,
} from "./api";
import ActionTypes from "./type";

function* fetchListContractTypeSaga({ payload }) {
    try {
        const response = yield call(getContractType, payload);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getContractTypeSuccess(response.data.result));
        } else {
            yield put(getContractTypeFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getContractTypeFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* addContractTypeSaga({ payload }) {
    const { params } = payload;
    try {
        const response = yield call(addContractType, payload);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addContractTypeSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
            yield put(getContractTypePending(params));
        } else {
            yield put(addContractTypeFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(addContractTypeFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* putContractTypeSaga({ payload }) {
    const { params } = payload;
    try {
        const response = yield call(putContractType, payload);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(putContractTypeSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
            yield put(getContractTypePending(params));
        } else {
            yield put(putContractTypeFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(putContractTypeFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* deleteContractTypeSaga({ payload }) {
    const { params } = payload;
    try {
        const response = yield call(deleteContractType, payload);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteContractTypeSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
            yield put(getContractTypePending(params));
        } else {
            yield put(deleteContractTypeFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteContractTypeFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

export function* admintratorContractTypeSaga() {
    yield takeEvery(ActionTypes.GET_CONTRACT_TYPE_PENDING, fetchListContractTypeSaga);
    yield takeEvery(ActionTypes.ADD_CONTRACT_TYPE_PENDING, addContractTypeSaga);
    yield takeEvery(ActionTypes.PUT_CONTRACT_TYPE_PENDING, putContractTypeSaga);
    yield takeEvery(ActionTypes.DELETE_CONTRACT_TYPE_PENDING, deleteContractTypeSaga);
}
export default admintratorContractTypeSaga;
