import ActionTypes from "./types";

const INIT_STATE = {
  isLoading: false,
  listEstimateSale: [],
  listSelectCustomer: [],
  listCustomFieldEstimate: [],
  estimateById: {},
  dataEditEstimate: {},
  deleteEstimateSuccess: false,
};

const SaleReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // case ActionTypes.ADD_ESTIMATE_CUSTOMER_REQUEST:
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };

    // case ActionTypes.ADD_ESTIMATE_CUSTOMER_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //   };

    // case ActionTypes.ADD_ESTIMATE_CUSTOMER_FAILED:
    //   return {
    //     ...state,
    //     isLoading: false,
    //   };
    case ActionTypes.ADD_ESTIMATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.ADD_ESTIMATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.ADD_ESTIMATE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_ESTIMATES_SALE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_ESTIMATES_SALE_SUCCESS:
      return {
        ...state,
        listEstimateSale: action.payload,
        isLoading: false,
      };
    case ActionTypes.FETCH_ESTIMATES_SALE_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.LIST_SELECT_CUSTOMER_REQUEST:
      return {
        ...state,
      };
    case ActionTypes.LIST_SELECT_CUSTOMER_SUCCESS:
      return {
        ...state,
        listSelectCustomer: action.value,
      };
    case ActionTypes.LIST_SELECT_CUSTOMER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.GET_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.GET_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        listCustomFieldEstimate: action.data,
        isLoading: false,
      };
    case ActionTypes.GET_CUSTOM_FIELD_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.GET_ESTIMATE_BY_ID:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.GET_ESTIMATE_BY_ID_SUCCESS:
      return {
        ...state,
        estimateById: action.payload,
        isLoading: false,
      };

    case ActionTypes.GET_ESTIMATE_BY_ID_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.DELETE_ESTIMATE:
      return {
        ...state,
      };

    case ActionTypes.DELETE_ESTIMATE_SUCCESS:
      return {
        ...state,
        deleteEstimateSuccess: true,
      };

    case ActionTypes.DELETE_ESTIMATE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.EDIT_ESTIMATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.EDIT_ESTIMATE_SUCCESS:
      return {
        ...state,
        dataEditEstimate: action.payload,
        isSuccess: true,
        isLoading: false,
      };

    case ActionTypes.EDIT_ESTIMATE_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return { ...state };
  }
};

export default SaleReducer;
