const ActionTypes = {
    FETCH_ESTIMATES_PROJECT: 'FETCH_ESTIMATES_PROJECT',
    FETCH_ESTIMATES_PROJECT_SUCCESS: 'FETCH_ESTIMATES_PROJECT_SUCCESS',
    FETCH_ESTIMATES_PROJECT_FAILED: 'FETCH_ESTIMATES_PROJECT_FAILED',
    
    FETCH_FILTER_YEAR_ESTIMATE_PRJ: 'FETCH_FILTER_YEAR_ESTIMATE_PRJ',
    FETCH_FILTER_YEAR_ESTIMATE_PRJ_SUCCESS: 'FETCH_FILTER_YEAR_ESTIMATE_PRJ_SUCCESS',
    FETCH_FILTER_YEAR_ESTIMATE_PRJ_FAILED: 'FETCH_FILTER_YEAR_ESTIMATE_PRJ_FAILED',
    
    FETCH_TOTAL_ESTIMATE_PRJ: 'FETCH_TOTAL_ESTIMATE_PRJ',
    FETCH_TOTAL_ESTIMATE_PRJ_SUCCESS: 'FETCH_TOTAL_ESTIMATE_PRJ_SUCCESS',
    FETCH_TOTAL_ESTIMATE_PRJ_FAILED: 'FETCH_TOTAL_ESTIMATE_PRJ_FAILED',
    
    FILTER_ESTIMATE: "FILTER_ESTIMATE",
    FILTER_ESTIMATE_SUCCESS: "FILTER_ESTIMATE_SUCCESS",
    FILTER_ESTIMATE_FAILED: "FILTER_ESTIMATE_FAILED",
    
};
export default ActionTypes;