import ActionTypes from "./types";

const INIT_STATE = {
    isLoading: false,
    isSuccess: false,
    listExpenses: {},
    expenseItem: {},
    expenseById: {},
    listPayment: [],
    listProject: [],
    listExpensesCategory: [],
    listCustomer: [],
    listSummary: []
};

const ExpenseReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_LIST_EXPENSES:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_EXPENSES_SUCCESS:
            return {
                ...state,
                listExpenses: action.payload,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_EXPENSES_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.GET_EXPENSE_ITEM:
            return {
                ...state,
                expenseById: action.payload,
            };

        case ActionTypes.SET_ISSUCCESS:
            return {
                ...state,
                isSuccess: action.payload,
            };

        case ActionTypes.FETCH_EXPENSE_BY_ID:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_EXPENSE_BY_ID_SUCCESS:
            return {
                ...state,
                expenseById: action.payload,
                isLoading: false,
            };

        case ActionTypes.FETCH_EXPENSE_BY_ID_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_DELETE_EXPENSE:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };

        case ActionTypes.FETCH_DELETE_EXPENSE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
            };

        case ActionTypes.FETCH_DELETE_EXPENSE_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_PAYMENT_MODE:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_PAYMENT_MODE_SUCCESS:
            return {
                ...state,
                listPayment: action.payload,
                isLoading: false,
            };

        case ActionTypes.FETCH_PAYMENT_MODE_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_CUSTOMER:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_CUSTOMER_SUCCESS:
            return {
                ...state,
                listCustomer: action.payload,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_EXPENSES_CATEGORY:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_EXPENSES_CATEGORY_SUCCESS:
            return {
                ...state,
                listExpensesCategory: action.payload,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_EXPENSES_CATEGORY_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_CREATE_EXPENSES:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };

        case ActionTypes.FETCH_CREATE_EXPENSES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
            };

        case ActionTypes.FETCH_CREATE_EXPENSES_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_EDIT_EXPENSES:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };

        case ActionTypes.FETCH_EDIT_EXPENSES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
            };

        case ActionTypes.FETCH_EDIT_EXPENSES_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_PROJECT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_PROJECT_SUCCESS:
            return {
                ...state,
                listProject: action.payload,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_PROJECT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        
        case ActionTypes.FETCH_LIST_SUMMARY:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_SUMMARY_SUCCESS:
            return {
                ...state,
                listSummary: action.payload,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_SUMMARY_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        
        default:
            return { ...state };
    }
};

export default ExpenseReducer;
