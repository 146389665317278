import ActionTypes from "./type";

const INIT_STATE = {
    isLoading: false,
    listPayment: [],
};

const PaymentsCustomerReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_PAYMENT_CUSTOMER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.FETCH_PAYMENT_CUSTOMER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listPayment: action.data,
            };
        case ActionTypes.FETCH_PAYMENT_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.ADD_PAYMENT_CUSTOMER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.ADD_PAYMENT_CUSTOMER_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.ADD_PAYMENT_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return { ...state };
    }
};

export default PaymentsCustomerReducer;
