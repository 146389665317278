// import _ from "lodash";

import { add } from "lodash";
import apiBase from "../../../common/baseAPI";
import { ParseSimpleEndpoint } from "../../../helpers/ParseEndpoint";
const STATUS_URL = "lead-status";
const TASK_URL = "task";
const STAFF_URL = "staff";
const TIMER_URL = "task-timer/task";
const DELETE_TIMER_URL = "task/timer";
const CHECKLIST_URL = "checklist";
const COMMENT_URL = "comment";
const REMINDER_URL = "reminder";
const ASSIGN_URL = "assign";
const FOLLOWER_URL = "follower";
const PROJECT_URL = "project";
const TASK_SUMMARY_PROJECT = "task/project/count";
const FILTER_TASK_PROJECT = "task/filter";
const TASK_BULK_ACTION = "task/bulkAction";
const TASK_CURRENT_WEEK = "task/current-week"
// const PRIORITY_URL = 'task'

export const fetchListTaskCurrentWeekStatus = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(TASK_CURRENT_WEEK)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListStatus = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(STATUS_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListTaskBylead = (action) => {
    const { leadId, data } = action;
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${TASK_URL}/lead/${leadId}?${ParseSimpleEndpoint(data)}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListTask = (data) => {
    if (data.status && Array.isArray(data.status)) {
        data.status = `[${data.status.join(',')}]`;
    }
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${TASK_URL}?${ParseSimpleEndpoint(data)}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createTask = (payload) => {
    const newFormData = new FormData();

    // newFormData.append("assigned", payload.assigned || []);
    // newFormData.append("billable", payload.billable);
    // newFormData.append("custom_recurring", payload.custom_recurring);
    // newFormData.append("hourly_rate", payload.hourly_rate);
    // newFormData.append("is_public", payload.is_public);
    // newFormData.append("recurring_type", payload.recurring);

    newFormData.append("rel_type", payload.rel_type);
    newFormData.append("rel_id", payload.rel_id);
    newFormData.append("description", payload.description);
    newFormData.append("due_date", payload.due_date);
    newFormData.append("priority", payload.priority);
    newFormData.append("start_date", payload.start_date);
    newFormData.append("status", payload.status);
    newFormData.append("name", payload.name);
    if (payload.tag) {
        payload.tag.map((item, index) => {
            newFormData.append(`tag[${index}][name]`, item.name);
            newFormData.append(`tag[${index}][tag_order]`, item.tag_order);
            if (item.id) {
                newFormData.append(`tag[${index}][id]`, item.id);
            }
            return item;
        });
    }
    // newFormData.append("tag", JSON.stringify(payload.tag));
    // newFormData.append("assigned", JSON.stringify(payload.assigned));
    // if (payload.assigned) {
    //     payload.assigned.map((item, index) => {
    //         newFormData.append(`assigned[${index}]`, item);
    //         return item;
    //     });
    // }
    if (payload.file_name) {
        payload.file_name.map((item, index) => {
            newFormData.append(`file_name[${index}]`, item.file);
            return item;
        });
    }
    // newFormData.append(`file_name`, JSON.stringify(payload.file_name["file"]));

    const header = { "Content-Type": "multipart/form-data" };
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${TASK_URL}`, newFormData, header)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const changePriorityTask = (data) => {
    const { priority, id } = data;
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${TASK_URL}/priority/${id}`, { priority: priority })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const changeStatusTask = (data) => {
    const { status, id } = data;
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${TASK_URL}/status/${id}`, { status: status })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getCountTask = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${TASK_URL}/count`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const searchRelated = (data) => {
    const { rel_type, rel_id } = data;
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${rel_type}/${rel_id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const searchRelatedByFilter = (data) => {
    const { rel_type, searchBy, search } = data;
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${rel_type}?${searchBy}=${search}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const editTask = (data, id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${TASK_URL}/${id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteTask = (data) => {
    const { id } = data;
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${TASK_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListStaff = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(STAFF_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getTaskById = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${TASK_URL}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const addTimer = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${TIMER_URL}/${data.id}`, data.data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteTimer = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${DELETE_TIMER_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const addChecklist = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${TASK_URL}/${CHECKLIST_URL}/${data.task_id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteChecklist = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${TASK_URL}/${CHECKLIST_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const addComment = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${TASK_URL}/${COMMENT_URL}/${data.taskid}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const editComment = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${TASK_URL}/${COMMENT_URL}/${data.id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteComment = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${TASK_URL}/${COMMENT_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const addReminder = (data) => {
    console.log(data)
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${REMINDER_URL}/${TASK_URL}/${data.rel_id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const editReminder = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${REMINDER_URL}/${TASK_URL}/${data.id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteReminder = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${TASK_URL}/${REMINDER_URL}/${data.id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const addAssign = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${TASK_URL}/${ASSIGN_URL}/${data.task_id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteAssign = (data) => {
    console.log(data)
    const { task_id, staff_id } = data;
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${TASK_URL}/${ASSIGN_URL}/${task_id}/${staff_id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const addFollower = (data) => {
    console.log(data)
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${TASK_URL}/${FOLLOWER_URL}/${data.task_id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteFollower = (data) => {
    const { task_id, staff_id } = data;
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${TASK_URL}/${FOLLOWER_URL}/${task_id}/${staff_id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListTaskByProject = (id, params) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(
                `${TASK_URL}/${PROJECT_URL}/${id}?search=${params.search}&limit=${params.limit}&page=${params.page}&status=[${params.status}]`
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const getTaskSummaryByProject = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${TASK_SUMMARY_PROJECT}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const filterTaskByProject = (id, data) => {
    if (data.status && Array.isArray(data.status)) {
        data.status = `[${data.status.join(',')}]`;
    }
    return new Promise((resolve, reject) => {
        return apiBase
            // .get(`${FILTER_TASK_PROJECT}/${id}`, data)
            .get(`${FILTER_TASK_PROJECT}/project/${id}`, { params: data })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
// const filterTask = (body) => {
//     if (body.status && Array.isArray(body.status)) {
//         body.status = `[${body.status.join(',')}]`;
//     }
//     return new Promise((resolve, reject) => {
//         return apiBase
//             .get(`${FILTER_TASK_PROJECT}`, { params: body })
//             // .get(`${FILTER_TASK_PROJECT}`, body)
//             .then((res) => resolve(res))
//             .catch((err) => reject(err));
//     });
// };
const copyTask = (id, body) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`task/copy/${id}`, body)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const getFilesInTask = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`file/task/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const deleteTagsInTask = ({ taskId, tagId }) => {
    return new Promise((resolve, reject) => {
        //api/task/tag/{taskid}/{tag_id}
        return apiBase
            .delete(`task/tag/${taskId}/${tagId}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const bulkActionsTask = (data) => {
    return new Promise((resolve, reject) => {
      return apiBase
        .post(TASK_BULK_ACTION, data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
export {
    getListTask,
    deleteTagsInTask,
    getFilesInTask,
    getListStatus,
    getListTaskBylead,
    createTask,
    changePriorityTask,
    getCountTask,
    searchRelated,
    editTask,
    deleteTask,
    changeStatusTask,
    searchRelatedByFilter,
    getListStaff,
    getTaskById,
    addTimer,
    deleteTimer,
    addChecklist,
    deleteChecklist,
    addComment,
    editComment,
    deleteComment,
    addReminder,
    addAssign,
    addFollower,
    editReminder,
    deleteReminder,
    deleteAssign,
    deleteFollower,
    getListTaskByProject,
    getTaskSummaryByProject,
    filterTaskByProject,
    // filterTask,
    copyTask,
    bulkActionsTask,
};
