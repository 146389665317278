// @flow
import ActionTypes from "./types";

export const getTask = (data) => {
    return {
        type: ActionTypes.GET_TASK,
        payload: data,
    };
};

export const getTaskSuccess = (data) => {
    return {
        type: ActionTypes.GET_TASK_SUCCESS,
        payload: data,
    };
};

export const getTaskFailed = () => {
    return {
        type: ActionTypes.GET_TASK_FAILED,
    };
};

export const getTaskByLead = (leadId, data) => {
    return {
        type: ActionTypes.GET_TASK_BY_LEAD,
        leadId,
        data,
    };
};

export const getTaskByLeadSuccess = (data) => {
    return {
        type: ActionTypes.GET_TASK_BY_LEAD_SUCCESS,
        payload: data,
    };
};

export const getTaskByLeadFailed = () => {
    return {
        type: ActionTypes.GET_TASK_BY_LEAD_FAILED,
    };
};

export const createTask = (data) => {
    return {
        type: ActionTypes.CREATE_NEW_TASK,
        payload: data,
    };
};

export const createTaskSuccess = (data) => {
    return {
        type: ActionTypes.CREATE_NEW_TASK_SUCCESS,
        payload: data,
    };
};

export const createTaskFailed = () => {
    return {
        type: ActionTypes.CREATE_NEW_TASK_FAILED,
    };
};

export const fetchListStatusLeads = () => {
    return {
        type: ActionTypes.FETCH_STATUS_LEADS,
    };
};

export const fetchListStatusLeadSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_STATUS_LEADS_SUCCESS,
        payload: data,
    };
};

export const fetchListStatusLeadFailed = () => {
    return {
        type: ActionTypes.FETCH_STATUS_LEADS_FAILED,
    };
};

export const changeTaskPriority = (data) => {
    return {
        type: ActionTypes.CHANGE_TASK_PRIORITY,
        payload: data,
    };
};

export const changeTaskPrioritySuccess = (data) => {
    return {
        type: ActionTypes.CHANGE_TASK_PRIORITY_SUCCESS,
        payload: data,
    };
};

export const changeTaskPriorityFailed = () => {
    return {
        type: ActionTypes.CHANGE_TASK_PRIORITY_FAILED,
    };
};

export const changeTaskStatus = (data) => {
    return {
        type: ActionTypes.CHANGE_TASK_STATUS,
        payload: data,
    };
};

export const changeTaskStatusSuccess = (data) => {
    return {
        type: ActionTypes.CHANGE_TASK_STATUS_SUCCESS,
        payload: data,
    };
};

export const changeTaskStatusFailed = () => {
    return {
        type: ActionTypes.CHANGE_TASK_STATUS_FAILED,
    };
};

export const getCountTask = () => {
    return {
        type: ActionTypes.GET_COUNT_TASK,
    };
};

export const getCountTaskSuccess = (data) => {
    return {
        type: ActionTypes.GET_COUNT_TASK_SUCCESS,
        payload: data,
    };
};

export const getCountTaskFailed = () => {
    return {
        type: ActionTypes.GET_COUNT_TASK_FAILED,
    };
};

export const searchRelated = (data) => {
    return {
        type: ActionTypes.SEARCH_RELATED,
        payload: data,
    };
};

export const searchRelatedSuccess = (data) => {
    return {
        type: ActionTypes.SEARCH_RELATED_SUCCESS,
        payload: data,
    };
};

export const searchRelatedFailed = () => {
    return {
        type: ActionTypes.SEARCH_RELATED_FAILED,
    };
};

export const searchRelatedByFilter = (data) => {
    return {
        type: ActionTypes.SEARCH_RELATED_BY_FILTER,
        payload: data,
    };
};

export const searchRelatedByFilterSuccess = (data) => {
    return {
        type: ActionTypes.SEARCH_RELATED_BY_FILTER_SUCCESS,
        payload: data,
    };
};

export const searchRelatedByFilterFailed = () => {
    return {
        type: ActionTypes.SEARCH_RELATED_BY_FILTER_FAILED,
    };
};

export const deleteTask = (data, params) => {
    return {
        type: ActionTypes.DELETE_TASK,
        payload: { data: data, params: params },
    };
};

export const deleteTaskSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_TASK_SUCCESS,
        payload: data,
    };
};

export const deleteTaskFailed = () => {
    return {
        type: ActionTypes.DELETE_TASK_FAILED,
    };
};

export const editTask = (data, id, params) => {
    return {
        type: ActionTypes.EDIT_TASK,
        payload: data,
        id: id,
        params: params,
    };
};

export const editTaskSuccess = (data) => {
    return {
        type: ActionTypes.EDIT_TASK_SUCCESS,
        payload: data,
    };
};

export const editTaskFailed = () => {
    return {
        type: ActionTypes.EDIT_TASK_FAILED,
    };
};

export const getTaskById = (data) => {
    return {
        type: ActionTypes.GET_TASK_BY_ID,
        payload: data,
    };
};

export const getTaskByIdSuccess = (data) => {
    return {
        type: ActionTypes.GET_TASK_BY_ID_SUCCESS,
        payload: data,
    };
};

export const getTaskByIdFailed = () => {
    return {
        type: ActionTypes.GET_TASK_BY_ID_FAILED,
    };
};

export const openTaskDetail = (data) => {
    return {
        type: ActionTypes.OPEN_TASK_DETAIL,
        payload: data,
    };
};

export const closeTaskDetail = () => {
    return {
        type: ActionTypes.CLOSE_TASK_DETAIL,
    };
};

export const redirectTaskEdit = () => {
    return {
        type: ActionTypes.REDIRECT_TO_TASK_EDIT,
    };
};

export const openPopDel = (data) => {
    return {
        type: ActionTypes.OPEN_POPUP_DELETE,
        payload: data,
    };
};

export const closePopDel = () => {
    return {
        type: ActionTypes.CLOSE_POPUP_DELETE,
    };
};

export const addNewTimer = (data, params) => {
    return {
        type: ActionTypes.ADD_NEW_TIMER,
        payload: data,
        params: params,
    };
};

export const addNewTimerSuccess = (data) => {
    return {
        type: ActionTypes.ADD_NEW_TIMER_SUCCESS,
        payload: data,
    };
};

export const addNewTimerFailed = () => {
    return {
        type: ActionTypes.ADD_NEW_TIMER_FAILED,
    };
};

export const deleteNewTimer = (data, params) => {
    return {
        type: ActionTypes.DELETE_TIMER,
        payload: data,
        params: params,
    };
};

export const deleteNewTimerSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_TIMER_SUCCESS,
        payload: data,
    };
};

export const deleteNewTimerFailed = () => {
    return {
        type: ActionTypes.DELETE_TIMER_FAILED,
    };
};

export const addChecklist = (data) => {
    return {
        type: ActionTypes.ADD_NEW_CHECKLIST,
        payload: data,
    };
};

export const addChecklistSuccess = (data, params) => {
    return {
        type: ActionTypes.ADD_NEW_CHECKLIST_SUCCESS,
        payload: data,
        params: params,
    };
};

export const addChecklistFailed = () => {
    return {
        type: ActionTypes.ADD_NEW_CHECKLIST_FAILED,
    };
};

export const deleteChecklist = (data, params) => {
    return {
        type: ActionTypes.DELETE_CHECKLIST,
        payload: data,
        params: params,
    };
};

export const deleteChecklistSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_CHECKLIST_SUCCESS,
        payload: data,
    };
};

export const deleteChecklistFailed = () => {
    return {
        type: ActionTypes.DELETE_CHECKLIST_FAILED,
    };
};

export const addCommentAction = (data, params) => {
    console.log("data", data, "params:", params)
    return {
        type: ActionTypes.ADD_COMMENT,
        payload: { data: data, params: params },
    };
};

export const addCommentActionSuccess = (data) => {
    return {
        type: ActionTypes.ADD_COMMENT_SUCCESS,
        payload: data,
    };
};

export const addCommentActionFailed = () => {
    return {
        type: ActionTypes.ADD_COMMENT_FAILED,
    };
};

export const editCommentAction = (data, params) => {
    return {
        type: ActionTypes.EDIT_COMMENT,
        payload: data,
        params: params,
    };
};

export const editCommentActionSuccess = (data) => {
    return {
        type: ActionTypes.EDIT_COMMENT_SUCCESS,
        payload: data,
    };
};

export const editCommentActionFailed = () => {
    return {
        type: ActionTypes.EDIT_COMMENT_FAILED,
    };
};

export const deleteCommentAction = (data, params) => {
    return {
        type: ActionTypes.DELETE_COMMENT,
        payload: data,
        params: params,
    };
};

export const deleteCommentActionSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_COMMENT_SUCCESS,
        payload: data,
    };
};

export const deleteCommentActionFailed = () => {
    return {
        type: ActionTypes.DELETE_COMMENT_FAILED,
    };
};

export const addReminderAction = (data, params) => {
    return {
        type: ActionTypes.ADD_REMINDER,
        payload: data,
        params: params,
    };
};

export const addReminderActionSuccess = (data) => {
    return {
        type: ActionTypes.ADD_REMINDER_SUCCESS,
        payload: data,
    };
};

export const addReminderActionFailed = () => {
    return {
        type: ActionTypes.ADD_REMINDER_FAILED,
    };
};

export const addAssignAction = (data, params) => {
    return {
        type: ActionTypes.ADD_ASSIGN,
        payload: { data: data, params: params },
    };
};

export const addAssignActionSuccess = (data) => {
    return {
        type: ActionTypes.ADD_ASSIGN_SUCCESS,
        payload: data,
    };
};

export const addAssignActionFailed = () => {
    return {
        type: ActionTypes.ADD_ASSIGN_FAILED,
    };
};

export const deleteAssignAction = (data, params) => {
    return {
        type: ActionTypes.DELETE_ASSIGN,
        payload: data,
        params: params,
    };
};

export const deleteAssignActionSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_ASSIGN_SUCCESS,
        payload: data,
    };
};

export const deleteAssignActionFailed = () => {
    return {
        type: ActionTypes.DELETE_ASSIGN_FAILED,
    };
};

export const addFollowerAction = (data, params) => {
    return {
        type: ActionTypes.ADD_FOLLOWER,
        payload: data,
        params: params,
    };
};

export const addFollowerActionSuccess = (data) => {
    return {
        type: ActionTypes.ADD_FOLLOWER_SUCCESS,
        payload: data,
    };
};

export const addFollowerActionFailed = () => {
    return {
        type: ActionTypes.ADD_FOLLOWER_FAILED,
    };
};

export const editReminderAction = (data, params) => {
    return {
        type: ActionTypes.EDIT_REMINDER,
        payload: data,
        params: params,
    };
};

export const editReminderActionSuccess = (data) => {
    return {
        type: ActionTypes.EDIT_REMINDER_SUCCESS,
        payload: data,
    };
};

export const editReminderActionFailed = () => {
    return {
        type: ActionTypes.EDIT_REMINDER_FAILED,
    };
};

export const deleteReminderAction = (data, params) => {
    return {
        type: ActionTypes.DELETE_REMINDER,
        payload: data,
        params: params,
    };
};

export const deleteReminderActionSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_REMINDER_SUCCESS,
        payload: data,
    };
};

export const deleteReminderActionFailed = () => {
    return {
        type: ActionTypes.DELETE_REMINDER_FAILED,
    };
};

export const deleteFollowerAction = (data, params) => {
    return {
        type: ActionTypes.DELETE_FOLLOWER,
        payload: data,
        params,
    };
};

export const deleteFollowerActionSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_FOLLOWER_SUCCESS,
        payload: data,
    };
};

export const deleteFollowerActionFailed = () => {
    return {
        type: ActionTypes.DELETE_FOLLOWER_FAILED,
    };
};

export const openTaskEdit = (item) => {
    return {
        type: ActionTypes.OPEN_TASK_EDIT,
        payload: item,
    };
};

export const closeTaskEdit = () => {
    return {
        type: ActionTypes.CLOSE_TASK_EDIT,
    };
};

export const openCreateTask = () => {
    return {
        type: ActionTypes.OPEN_TASK_CREATE,
    };
};

export const closeCreateTask = () => {
    return {
        type: ActionTypes.CLOSE_TASK_CREATE,
    };
};

//Task by project
export const getTaskByProject = (id, params) => {
    return {
        type: ActionTypes.GET_TASK_BY_PROJECT,
        id: id,
        params: params,
    };
};

export const getTaskByProjectSuccess = (data) => {
    return {
        type: ActionTypes.GET_TASK_BY_PROJECT_SUCCESS,
        payload: data,
    };
};

export const getTaskByProjectFailed = () => {
    return {
        type: ActionTypes.GET_TASK_BY_PROJECT_FAILED,
    };
};

//Task summary by project
export const getTaskSummaryByProjectRequest = (id) => {
    return {
        type: ActionTypes.GET_TASK_SUMMARY_BY_PROJECT_REQUEST,
        id: id,
    };
};

export const getTaskSummaryByProjectSuccess = (data) => {
    return {
        type: ActionTypes.GET_TASK_SUMMARY_BY_PROJECT_SUCCESS,
        payload: data,
    };
};

export const getTaskSummaryByProjectFailed = () => {
    return {
        type: ActionTypes.GET_TASK_SUMMARY_BY_PROJECT_FAILED,
    };
};

//filter Task by Project
export const filterTaskByProjectRequest = (projectId, data) => {
    return {
        type: ActionTypes.FILTER_TASK_BY_PROJECT_REQUEST,
        projectId,
        data,
    };
};

export const filterTaskByProjectSuccess = (data) => {
    return {
        type: ActionTypes.FILTER_TASK_BY_PROJECT_SUCCESS,
        payload: data,
    };
};
export const filterTaskByProjectFailed = (data) => {
    return {
        type: ActionTypes.FILTER_TASK_BY_PROJECT_FAILED,
    };
};
// * filter task
// export const filterTaskRequest = (params, body) => {
//     return {
//         type: ActionTypes.FILTER_TASK_REQUEST,
//         payload: params,
//     };
// };

// export const filterTaskSuccess = (data) => {
//     return {
//         type: ActionTypes.FILTER_TASK_SUCCESS,
//         payload: data,
//     };
// };
// export const filterTaskFailed = (data) => {
//     return {
//         type: ActionTypes.FILTER_TASK_FAILED,
//     };
// };
// *
export const getListStaffRequest = () => {
    return {
        type: ActionTypes.GET_LIST_STAFF,
    };
};

export const getListStaffSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_STAFF_SUCCESS,
        payload: data,
    };
};

export const getListStaffFailed = () => {
    return {
        type: ActionTypes.GET_LIST_STAFF_FAILED,
    };
};

export const closeTaskByLead = (data) => {
    return {
        type: ActionTypes.CLOSE_TASK_BY_LEADS,
        payload: data,
    };
};

export const copyTaskPending = (id, assign, status) => {
    return {
        type: ActionTypes.COPY_TASK_PENDING,
        payload: {
            id: id,
            body: { assign: assign, status: status },
        },
    };
};
export const copyTaskSuccess = (data) => {
    return {
        type: ActionTypes.COPY_TASK_SUCCESS,
        payload: data,
    };
};
export const copyTaskFailure = () => {
    return {
        type: ActionTypes.COPY_TASK_FAILURE,
    };
};
//get file
export const getListFileRequest = (id) => {
    return {
        type: ActionTypes.GET_FILE_IN_TASK_PENDING,
        payload: { id: id },
    };
};

export const getListFileSuccess = (data) => {
    return {
        type: ActionTypes.GET_FILE_IN_TASK_SUCCESS,
        payload: data,
    };
};

export const getListFileFailed = () => {
    return {
        type: ActionTypes.GET_FILE_IN_TASK_FAILURE,
    };
};
export const deleteTagInTaskPending = (taskId, tagId) => {
    return {
        type: ActionTypes.DELETE_TAG_IN_TASK_PENDING,
        payload: { taskId: taskId, tagId: tagId },
    };
};

export const deleteTagInTaskSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_TAG_IN_TASK_SUCCESS,
    };
};

export const deleteTagInTaskFailed = () => {
    return {
        type: ActionTypes.DELETE_TAG_IN_TASK_FAILURE,
    };
};
export const bulkActionsTask = (data) => {
    return {
        type: ActionTypes.BULK_ACTIONS_TASK_REQUEST,
        payload: data,
    };
};

export const bulkActionsTaskSuccess = (data) => {
    return {
        type: ActionTypes.BULK_ACTIONS_TASK_SUCCESS,
        payload: data,
    };
};

export const bulkActionsTaskFailed = () => {
    return {
        type: ActionTypes.BULK_ACTIONS_TASK_FAILED,
    };
};

//  task status
export const fetchListTaskCurrentWeekStatusRequest = () => {
    return {
        type: ActionTypes.FETCH_LIST_STATUS_TASK_CURRENT_WEEK_PENDING,
    };
};

export const fetchListTaskCurrentWeekStatusSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_STATUS_TASK_CURRENT_WEEK_SUCCESS,
        payload: data,
    };
};

export const fetchListTaskCurrentWeekStatusFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_STATUS_TASK_CURRENT_WEEK_FAILED,
    };
};