const ActionTypes = {
    FETCH_PAYMENT_CUSTOMER_REQUEST: "FETCH_PAYMENT_CUSTOMER_REQUEST",
    FETCH_PAYMENT_CUSTOMER_FAILED: "FETCH_PAYMENT_CUSTOMER_FAILED",
    FETCH_PAYMENT_CUSTOMER_SUCCESS: "FETCH_PAYMENT_CUSTOMER_SUCCESS",
    
    ADD_PAYMENT_CUSTOMER_REQUEST: "ADD_PAYMENT_CUSTOMER_REQUEST",
    ADD_PAYMENT_CUSTOMER_FAILED: "ADD_PAYMENT_CUSTOMER_FAILED",
    ADD_PAYMENT_CUSTOMER_SUCCESS: "ADD_PAYMENT_CUSTOMER_SUCCESS",

};
export default ActionTypes;