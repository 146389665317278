import ActionTypes from "./types";

const INIT_STATE = {
  isLoading: false,
  dataDiscussionProject: {},
  isEdit: false,
  isAdd: false,
  isDelete: false,
  discussionEditing: {},
};

const DiscussionProjectReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_DISCUSSION:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_DISCUSSION_SUCCESS:
      return {
        ...state,
        dataDiscussionProject: action.payload,
        isLoading: false,
        isEdit: false,
        isAdd: false,
        isDelete: false,
      };

    case ActionTypes.FETCH_DISCUSSION_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.CREATE_DISCUSSION:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.CREATE_DISCUSSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAdd: true,
      };

    case ActionTypes.CREATE_DISCUSSION_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_DISCUSSION:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.DELETE_DISCUSSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDelete: true,
      };

    case ActionTypes.DELETE_DISCUSSION_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.UPDATE_DISCUSSION:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.UPDATE_DISCUSSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdit: true,
      };
    case ActionTypes.UPDATE_DISCUSSION_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.SET_EDIT_DISCUSSION:
      return {
        ...state,
        isLoading: false,
        discussionEditing: action.payload,
      };
    default:
      return { ...state };
  }
};

export default DiscussionProjectReducer;
