import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    getCustomFieldPaymentSuccess,
    getCustomFieldPaymentFailed,
    getPaymentModeSuccess,
    getPaymentModeFailed,
} from "./action";
import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import { getPaymentsModeRequest, getPaymentsSaleRequest } from "./api";
import { responeCode, typeMessage } from "../../../../constants/Const";
import ActionTypes from "./types";
import {
    throwErrorMessage,
    parseDataNotifications,
} from "../../../../helpers/HandleError";

function* listCustomField(data) {
    try {
        const response = yield call(getPaymentsSaleRequest, data);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getCustomFieldPaymentSuccess(response.data.result));
        } else {
            yield put(getCustomFieldPaymentFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getCustomFieldPaymentFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* getPayMentMode() {
    try {
        const response = yield call(getPaymentsModeRequest);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getPaymentModeSuccess(response.data.result));
        } else {
            yield put(getPaymentModeFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getPaymentModeFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* paymentsSaga() {
    yield takeEvery(ActionTypes.GET_PAYMENT_REQUEST, listCustomField);
}
function* paymentsModeSaga() {
    yield takeEvery(ActionTypes.GET_PAYMENT_MODE_REQUEST, getPayMentMode);
}

function* SalePaymentSaga() {
    yield all([fork(paymentsSaga), fork(paymentsModeSaga)]);
}

export default SalePaymentSaga;
