import axios from "axios";
// const baseApiUrl = 'http://192.168.100.100:9999/bhome_be/public/api';
const baseApiUrl = process.env.REACT_APP_REST_URL_API;

const baseInstance = axios.create({
  baseURL: baseApiUrl,
});

baseInstance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("authorization");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

baseInstance.interceptors.response.use(
  (response) => {
    try {
      const authToken = response.headers.authorization;
      if (authToken && authToken.startsWith("Bearer ")) {
        localStorage.setItem("authorization", authToken.replace("Bearer ", ""));
      }
    } catch (error) {
      console.error("Lỗi khi lưu token:", error);
    }
    return response;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

export default baseInstance;
