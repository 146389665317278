import ActionTypes from './types';

export const createFinanceTaxRateRequest = (data) => {
	return {
		type: ActionTypes.CREATE_FINANCE_TAX_RATE_REQUEST,
		payload: data,
	}
};

export const createFinanceTaxRateSuccess = (data) => {
	return {
		type: ActionTypes.CREATE_FINANCE_TAX_RATE_SUCCESS,
		payload: data
	}
};

export const createFinanceTaxRateFailed = () => {
	return {
		type: ActionTypes.CREATE_FINANCE_TAX_RATE_FAILED
	}
};

export const editFinanceTaxRateRequest = (data) => {
	return {
		type: ActionTypes.EDIT_FINANCE_TAX_RATE_REQUEST,
		payload: data,
	}
};

export const editFinanceTaxRateSuccess = (data) => {
	return {
		type: ActionTypes.EDIT_FINANCE_TAX_RATE_SUCCESS,
		payload: data
	}
};

export const editFinanceTaxRateFailed = () => {
	return {
		type: ActionTypes.EDIT_FINANCE_TAX_RATE_FAILED
	}
};

export const deleteFinanceTaxRateRequest = (data) => {
	return {
		type: ActionTypes.DELETE_FINANCE_TAX_RATE_REQUEST,
		payload: data,
	}
};

export const deleteFinanceTaxRateSuccess = (data) => {
	return {
		type: ActionTypes.DELETE_FINANCE_TAX_RATE_SUCCESS,
		payload: data
	}
};

export const deleteFinanceTaxRateFailed = () => {
	return {
		type: ActionTypes.DELETE_FINANCE_TAX_RATE_FAILED,
	}
};

export const changeStatusDialog = () => {
	return {
		type: ActionTypes.CHANGE_STATUS_DIALOG_FINANCE,
	}
};

export const fetchFinanceTaxRateRequest = (params) => {
	return {
		type: ActionTypes.FETCH_FINANCE_TAX_RATE_REQUEST,
		payload: params
	}
};

export const fetchFinanceTaxRateSuccess = (data) => {
	return {
		type: ActionTypes.FETCH_FINANCE_TAX_RATE_SUCCESS,
		payload: data
	}
};

export const fetchFinanceTaxRateFailed = () => {
	return {
		type: ActionTypes.FETCH_FINANCE_TAX_RATE_FAILED,
	}
};


//Finance-curency

export const createFinanceCurrencyRequest = (data) => {
	return {
		type: ActionTypes.CREATE_FINANCE_CURRENCY_REQUEST,
		payload: data,
	}
};

export const createFinanceCurrencySuccess = (data) => {
	return {
		type: ActionTypes.CREATE_FINANCE_CURRENCY_SUCCESS,
		payload: data
	}
};

export const createFinanceCurrencyFailed = () => {
	return {
		type: ActionTypes.CREATE_FINANCE_CURRENCY_FAILED
	}
};

export const editFinanceCurrencyRequest = (data) => {
	return {
		type: ActionTypes.EDIT_FINANCE_CURRENCY_REQUEST,
		payload: data,
	}
};

export const editFinanceCurrencySuccess = (data) => {
	return {
		type: ActionTypes.EDIT_FINANCE_CURRENCY_SUCCESS,
		payload: data
	}
};

export const editFinanceCurrencyFailed = () => {
	return {
		type: ActionTypes.EDIT_FINANCE_CURRENCY_FAILED
	}
};

export const deleteFinanceCurrencyRequest = (data) => {
	return {
		type: ActionTypes.DELETE_FINANCE_CURRENCY_REQUEST,
		payload: data,
	}
};

export const deleteFinanceCurrencySuccess = (data) => {
	return {
		type: ActionTypes.DELETE_FINANCE_CURRENCY_SUCCESS,
		payload: data
	}
};

export const deleteFinanceCurrencyFailed = () => {
	return {
		type: ActionTypes.DELETE_FINANCE_CURRENCY_FAILED,
	}
};


export const fetchFinanceCurrencyRequest = (params) => {
	return {
		type: ActionTypes.FETCH_FINANCE_CURRENCY_REQUEST,
		payload: params
	}
};

export const fetchFinanceCurrencySuccess = (data) => {
	return {
		type: ActionTypes.FETCH_FINANCE_CURRENCY_SUCCESS,
		payload: data
	}
};

export const fetchFinanceCurrencyFailed = () => {
	return {
		type: ActionTypes.FETCH_FINANCE_CURRENCY_FAILED,
	}
};

//Finance-PaymentMode

export const createFinancePaymentModeRequest = (data) => {
	return {
		type: ActionTypes.CREATE_FINANCE_PAYMENT_MODE_REQUEST,
		payload: data,
	}
};

export const createFinancePaymentModeSuccess = (data) => {
	return {
		type: ActionTypes.CREATE_FINANCE_PAYMENT_MODE_SUCCESS,
		payload: data
	}
};

export const createFinancePaymentModeFailed = () => {
	return {
		type: ActionTypes.CREATE_FINANCE_PAYMENT_MODE_FAILED
	}
};

export const editFinancePaymentModeRequest = (data) => {
	return {
		type: ActionTypes.EDIT_FINANCE_PAYMENT_MODE_REQUEST,
		payload: data,
	}
};

export const editFinancePaymentModeSuccess = (data) => {
	return {
		type: ActionTypes.EDIT_FINANCE_PAYMENT_MODE_SUCCESS,
		payload: data
	}
};

export const editFinancePaymentModeFailed = () => {
	return {
		type: ActionTypes.EDIT_FINANCE_PAYMENT_MODE_FAILED
	}
};

export const deleteFinancePaymentModeRequest = (data) => {
	return {
		type: ActionTypes.DELETE_FINANCE_PAYMENT_MODE_REQUEST,
		payload: data,
	}
};

export const deleteFinancePaymentModeSuccess = (data) => {
	return {
		type: ActionTypes.DELETE_FINANCE_PAYMENT_MODE_SUCCESS,
		payload: data
	}
};

export const deleteFinancePaymentModeFailed = () => {
	return {
		type: ActionTypes.DELETE_FINANCE_PAYMENT_MODE_FAILED,
	}
};


export const fetchFinancePaymentModeRequest = (params) => {
	return {
		type: ActionTypes.FETCH_FINANCE_PAYMENT_MODE_REQUEST,
		payload: params
	}
};

export const fetchFinancePaymentModeSuccess = (data) => {
	return {
		type: ActionTypes.FETCH_FINANCE_PAYMENT_MODE_SUCCESS,
		payload: data
	}
};

export const fetchFinancePaymentModeFailed = () => {
	return {
		type: ActionTypes.FETCH_FINANCE_PAYMENT_MODE_FAILED,
	}
};

export const changeActiveStatusPaymentModeFailed = () => {
	return {
		type: ActionTypes.CHANGE_ACTIVE_STATUS_PAYMENT_MODE_FAILED,
	}
};

export const changeActiveStatusPaymentModeSuccess = (data) => {
	return {
		type: ActionTypes.CHANGE_ACTIVE_STATUS_PAYMENT_MODE_SUCCESS,
		payload: data
	}
};

export const changeActiveStatusPaymentModeRequest = (id) => {
	return {
		type: ActionTypes.CHANGE_ACTIVE_STATUS_PAYMENT_MODE_REQUEST,
		id,
	}
};



//Finance-Expenses Category

export const createFinanceExpensesRequest = (data) => {
	return {
		type: ActionTypes.CREATE_FINANCE_EXPENSES_REQUEST,
		payload: data,
	}
};

export const createFinanceExpensesSuccess = (data) => {
	return {
		type: ActionTypes.CREATE_FINANCE_EXPENSES_SUCCESS,
		payload: data
	}
};

export const createFinanceExpensesFailed = () => {
	return {
		type: ActionTypes.CREATE_FINANCE_EXPENSES_FAILED
	}
};

export const editFinanceExpensesRequest = (data) => {
	return {
		type: ActionTypes.EDIT_FINANCE_EXPENSES_REQUEST,
		payload: data,
	}
};

export const editFinanceExpensesSuccess = (data) => {
	return {
		type: ActionTypes.EDIT_FINANCE_EXPENSES_SUCCESS,
		payload: data
	}
};

export const editFinanceExpensesFailed = () => {
	return {
		type: ActionTypes.EDIT_FINANCE_EXPENSES_FAILED
	}
};

export const deleteFinanceExpensesRequest = (data) => {
	return {
		type: ActionTypes.DELETE_FINANCE_EXPENSES_REQUEST,
		payload: data,
	}
};

export const deleteFinanceExpensesSuccess = (data) => {
	return {
		type: ActionTypes.DELETE_FINANCE_EXPENSES_SUCCESS,
		payload: data
	}
};

export const deleteFinanceExpensesFailed = () => {
	return {
		type: ActionTypes.DELETE_FINANCE_EXPENSES_FAILED,
	}
};


export const fetchFinanceExpensesRequest = (params) => {
	return {
		type: ActionTypes.FETCH_FINANCE_EXPENSES_REQUEST,
		payload: params
	}
};

export const fetchFinanceExpensesSuccess = (data) => {
	return {
		type: ActionTypes.FETCH_FINANCE_EXPENSES_SUCCESS,
		payload: data
	}
};

export const fetchFinanceExpensesFailed = () => {
	return {
		type: ActionTypes.FETCH_FINANCE_EXPENSES_FAILED,
	}
};