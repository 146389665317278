import React from "react";
import { PrivateRoute } from "./PrivateRoute";
import i18n from "../i18n.js";
import { inventory } from "../constants/Route.js";

const Inventory = React.lazy(() => import("../pages/Inventory/index.js"));
const InvoiceItems = React.lazy(() => import("../pages/Sales/Items/index.js"));

export const inventoryRoute = [
    {
        name: `${i18n.t("route.inventory")}`,
        route: PrivateRoute,
        roles: ["Director"],
        title: `${i18n.t("route.inventory")}`,
        childs: [
            {
                path: inventory.index,
                name: `${i18n.t("route.inventory")}`,
                component: Inventory,
                route: PrivateRoute,
                roles: ["Director"],
                title: `${i18n.t("route.inventory")}`,
                exact: true
            },
            {
                path: "/items",
                name: `${i18n.t("route.items")}`,
                component: InvoiceItems,
                route: PrivateRoute,
                roles: ["Director"],
                title: `${i18n.t("route.items")}`,
                exact: true,
            },
        ]        
    },
]