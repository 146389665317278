import ActionTypes from "./type";

export const getContractTypePending = (params) => {
    return {
        type: ActionTypes.GET_CONTRACT_TYPE_PENDING,
        payload: { params: params },
    };
};

export const getContractTypeSuccess = (data) => {
    return {
        type: ActionTypes.GET_CONTRACT_TYPE_SUCCESS,
        payload: { data: data },
    };
};

export const getContractTypeFailed = () => {
    return {
        type: ActionTypes.GET_CONTRACT_TYPE_FAILED,
    };
};
export const addContractTypePending = (body, params) => {
    return {
        type: ActionTypes.ADD_CONTRACT_TYPE_PENDING,
        payload: { body: body, params: params },
    };
};

export const addContractTypeSuccess = () => {
    return {
        type: ActionTypes.ADD_CONTRACT_TYPE_SUCCESS,
        // payload: { data: data },
    };
};

export const addContractTypeFailed = () => {
    return {
        type: ActionTypes.ADD_CONTRACT_TYPE_FAILED,
    };
};
export const putContractTypePending = (body, id, params) => {
    return {
        type: ActionTypes.PUT_CONTRACT_TYPE_PENDING,
        payload: { body: body, id: id, params: params },
    };
};

export const putContractTypeSuccess = () => {
    return {
        type: ActionTypes.PUT_CONTRACT_TYPE_SUCCESS,
        // payload: { data: data },
    };
};

export const putContractTypeFailed = () => {
    return {
        type: ActionTypes.PUT_CONTRACT_TYPE_FAILED,
    };
};
export const deleteContractTypePending = (id, params) => {
    return {
        type: ActionTypes.DELETE_CONTRACT_TYPE_PENDING,
        payload: { id: id, params: params },
    };
};

export const deleteContractTypeSuccess = () => {
    return {
        type: ActionTypes.DELETE_CONTRACT_TYPE_SUCCESS,
        // payload: { data: data },
    };
};

export const deleteContractTypeFailed = () => {
    return {
        type: ActionTypes.DELETE_CONTRACT_TYPE_FAILED,
    };
};
