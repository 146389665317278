import apiBase from "../../../../common/baseAPI";

const CUSTOMER_STATEMENT = "customer/statement";

export const fetchStatementCustomerRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${CUSTOMER_STATEMENT}/${action.id}`, action.value)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
