import ActionTypes from "./types";

//fetch estimate project
export const fetchEstimatesProject = (data) => {
    return {
        type: ActionTypes.FETCH_ESTIMATES_PROJECT,
        payload: data,
    };
};

export const fetchEstimatesProjectSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_ESTIMATES_PROJECT_SUCCESS,
        payload: data,
    };
};

export const fetchEstimatesProjectFailed = () => {
    return {
        type: ActionTypes.FETCH_ESTIMATES_PROJECT_FAILED,
    };
};

//filter year
export const fetchFilterYearEstimatePrj = (data) => {
    return {
        type: ActionTypes.FETCH_FILTER_YEAR_ESTIMATE_PRJ,
        payload: data,
    };
};

export const fetchFilterYearEstimatePrjSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_FILTER_YEAR_ESTIMATE_PRJ_SUCCESS,
        payload: data,
    };
};

export const fetchFilterYearEstimatePrjFailed = () => {
    return {
        type: ActionTypes.FETCH_FILTER_YEAR_ESTIMATE_PRJ_FAILED,
    };
};

//fetch total estimate
export const fetchTotalEstimatePrj = (data) => {
    return {
        type: ActionTypes.FETCH_TOTAL_ESTIMATE_PRJ,
        payload: data,
    };
};

export const fetchTotalEstimatePrjSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_TOTAL_ESTIMATE_PRJ_SUCCESS,
        payload: data,
    };
};

export const fetchTotalEstimatePrjFailed = () => {
    return {
        type: ActionTypes.FETCH_TOTAL_ESTIMATE_PRJ_FAILED,
    };
};

//fetch total estimate
export const fetchFilterEstimate = (data) => {
    return {
        type: ActionTypes.FILTER_ESTIMATE,
        payload: data,
    };
};

export const fetchFilterEstimateSuccess = (data) => {
    return {
        type: ActionTypes.FILTER_ESTIMATE_SUCCESS,
        payload: data,
    };
};

export const fetchFilterEstimateFailed = () => {
    return {
        type: ActionTypes.FILTER_ESTIMATE_FAILED,
    };
};