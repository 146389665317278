// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import { responeCode, typeMessage } from "../../../../constants/Const";
import {
  throwErrorMessage,
  parseDataNotifications,
} from "../../../../helpers/HandleError";
import ActionTypes from "./type";

import {
  fecthStatementCustomerSuccess,
  fecthStatementCustomerFailed,
} from "./actions";
import { fetchStatementCustomerRequest } from "./api";

function* fetchStatementCustomer(action) {
  try {
    const response = yield call(fetchStatementCustomerRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(fecthStatementCustomerSuccess(response.data.result));
    } else {
      yield put(fecthStatementCustomerFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fecthStatementCustomerFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

export function* statementCustomerSaga() {
  yield takeEvery(
    ActionTypes.FETCH_STATEMENT_CUSTOMER_REQUEST,
    fetchStatementCustomer
  );
}

export default statementCustomerSaga;
