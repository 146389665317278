import React from "react";
import { PrivateRoute } from "../PrivateRoute.js";
import i18n from "../../i18n.js";

// Administrator/Staff
const Staff = React.lazy(() => import("../../pages/Staff/index.js"));
const NewStaff = React.lazy(() => import("../../pages/Staff/NewStaff.js"));
const ViewStaff = React.lazy(() => import("../../pages/Staff/View.js"));
const Roles = React.lazy(() => import("../../pages/Staff/Roles/index.js"));
const NewRole = React.lazy(() => import("../../pages/Staff/Roles/NewRole.js"));
const EditRole = React.lazy(() => import("../../pages/Staff/Roles/EditRole.js"));

export const staffRoute = [
    {
        name: `${i18n.t("route.staff")}`,
        component: Staff,
        route: PrivateRoute,
        roles: ["Director","Admin", ],
        title: `${i18n.t("route.staff")}`,
        childs:[
            {
                path: "/administrator/staff",
                name: `${i18n.t("route.staff")}`,
                component: Staff,
                route: PrivateRoute,
                roles: ["Director","Admin" ],
                title: `${i18n.t("route.staff")}`,
                exact: true,
            },
            {
                path: "/administrator/role",
                name: `${i18n.t("route.role")}`,
                component: Roles,
                route: PrivateRoute,
                roles: ["Director","profile"],
                title: `${i18n.t("route.role")}`,
                exact: true,
            },
            {
                path: "/administrator/roles/new_role",
                name: "administrator_roles_new_role",
                component: NewRole,
                route: PrivateRoute,
                roles: ["Director","profile"],
                title: `${i18n.t("route.role")}`,
                exact: true,
            },
            {
                path: "/administrator/roles/edit_role",
                name: "administrator_roles_edit_role",
                component: EditRole,
                route: PrivateRoute,
                roles: ["Director","profile"],
                title: `${i18n.t("route.role")}`,
                exact: true,
            },
            {
                path: "/administrator/staff/new",
                name: `New Staff`,
                component: NewStaff,
                route: PrivateRoute,
                roles: ["Director","Admin","staff"],
                title: "New Staff",
                exact: true,
            },
            {
                path: "/administrator/staff/view/:id",
                name: `View Staff`,
                component: ViewStaff,
                route: PrivateRoute,
                roles: ["Director","Admin", "Staff"],
                title: `View Staff`,
                exact: true,
            },
        ]
    },
    
]