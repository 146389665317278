/* AUTH */

const ActionTypes = {
    GET_LIST_CUSTOMER_REQUEST: "GET_LIST_CUSTOMER_REQUEST",
    GET_LIST_CUSTOMER_FAILED: "GET_LIST_CUSTOMER_FAILED",
    GET_LIST_CUSTOMER_SUCCESS: "GET_LIST_CUSTOMER_SUCCESS",

    FETCH_LIST_CUSTOMER_REQUEST: "FETCH_LIST_CUSTOMER_REQUEST",
    FETCH_LIST_CUSTOMER_SUCCESS: "FETCH_LIST_CUSTOMER_SUCCESS",
    FETCH_LIST_CUSTOMER_FAILED: "FETCH_LIST_CUSTOMER_FAILED",

    DELETE_ITEM_CUSTOMER_REQUEST: "DELETE_ITEM_CUSTOMER_REQUEST",
    DELETE_ITEM_CUSTOMER_SUCCESS: "DELETE_ITEM_CUSTOMER_SUCCESS",
    DELETE_ITEM_CUSTOMER_FAILED: "DELETE_ITEM_CUSTOMER_FAILED",

    GET_LIST_GROUPS_REQUEST: "GET_LIST_GROUPS_REQUEST",
    GET_LIST_GROUPS_SUCCESS: "GET_LIST_GROUPS_SUCCESS",
    GET_LIST_GROUPS_FAILED: "GET_LIST_GROUPS_FAILED",

    ADD_GROUP_REQUEST: "ADD_GROUP_REQUEST",
    ADD_GROUP_SUCCESS: "ADD_GROUP_SUCCESS",
    ADD_GROUP_FAILED: "ADD_GROUP_FAILED",

    CHANGE_DATA_PROFILE: "CHANGE_DATA_PROFILE",

    ADD_CUSTOMER_REQUEST: "ADD_CUSTOMER_REQUEST",
    ADD_CUSTOMER_SUCCESS: "ADD_CUSTOMER_SUCCESS",
    ADD_CUSTOMER_FAILED: "ADD_CUSTOMER_FAILED",

    EDIT_CUSTOMER_REQUEST: "EDIT_CUSTOMER_REQUEST",
    EDIT_CUSTOMER_SUCCESS: "EDIT_CUSTOMER_SUCCESS",
    EDIT_CUSTOMER_FAILED: "EDIT_CUSTOMER_FAILED",

    GET_SUMMARY_CUSTOMER_REQUEST: "GET_SUMMARY_CUSTOMER_REQUEST",
    GET_SUMMARY_CUSTOMER_FAILED: "GET_SUMMARY_CUSTOMER_FAILED",
    GET_SUMMARY_CUSTOMER_SUCCESS: "GET_SUMMARY_CUSTOMER_SUCCESS",

    EDIT_ACTIVE_REQUEST: "EDIT_ACTIVE_REQUEST",
    EDIT_ACTIVE_FAILED: "EDIT_ACTIVE_FAILED",
    EDIT_ACTIVE_SUCCESS: "EDIT_ACTIVE_SUCCESS",

    GET_LIST_REMINDER: "GET_LIST_REMINDER",
    GET_LIST_REMINDER_FAILED: "GET_LIST_REMINDER_FAILED",
    GET_LIST_REMINDER_SUCCESS: "GET_LIST_REMINDER_SUCCESS",

    CREATE_REMINDER: "CREATE_REMINDER",
    CREATE_REMINDER_FAILED: "CREATE_REMINDER_FAILED",
    CREATE_REMINDER_SUCCESS: "CREATE_REMINDER_SUCCESS",

    EDIT_REMINDER: "EDIT_REMINDER",
    EDIT_REMINDER_FAILED: "EDIT_REMINDER_FAILED",
    EDIT_REMINDER_SUCCESS: "EDIT_REMINDER_SUCCESS",

    DELETE_REMINDER: "DELETE_REMINDER",
    DELETE_REMINDER_FAILED: "DELETE_REMINDER_FAILED",
    DELETE_REMINDER_SUCCESS: "DELETE_REMINDER_SUCCESS",

    FETCH_LIST_CUSTOMER_ESTIMATE_REQUEST:
        "FETCH_LIST_CUSTOMER_ESTIMATE_REQUEST",
    FETCH_LIST_CUSTOMER_ESTIMATE_SUCCESS:
        "FETCH_LIST_CUSTOMER_ESTIMATE_SUCCESS",
    FETCH_LIST_CUSTOMER_ESTIMATE_FAILED: "FETCH_LIST_CUSTOMER_ESTIMATE_FAILED",

    GET_PROJECT_CUSTOMER_REQUEST: "GET_PROJECT_CUSTOMER_REQUEST",
    GET_PROJECT_CUSTOMER_SUCCESS: "GET_PROJECT_CUSTOMER_SUCCESS",
    GET_PROJECT_CUSTOMER_FAILED: "GET_PROJECT_CUSTOMER_FAILED",

    ADD_CUSTOMER_FILE_PENDING: "ADD_CUSTOMER_FILE_PENDING",
    ADD_CUSTOMER_FILE_SUCCESS: "ADD_CUSTOMER_FILE_SUCCESS",
    ADD_CUSTOMER_FILE_FAILED: "ADD_CUSTOMER_FILE_AILED",

    ADD_CUSTOMER_VAULT_PENDING: "ADD_CUSTOMER_VAULT_PENDING",
    ADD_CUSTOMER_VAULT_SUCCESS: "ADD_CUSTOMER_VAULT_SUCCESS",
    ADD_CUSTOMER_VAULT_FAILED: "ADD_CUSTOMER_VAULT_AILED",

    FETCH_CUSTOMER_BY_ID: "FETCH_CUSTOMER_BY_ID",
    FETCH_CUSTOMER_BY_ID_FAILED: "FETCH_CUSTOMER_BY_ID_FAILED",
    FETCH_CUSTOMER_BY_ID_SUCCESS: "FETCH_CUSTOMER_BY_ID_SUCCESS",

    FETCH_LIST_CUSTOMER_EXPENES_REQUEST: "FETCH_LIST_CUSTOMER_EXPENES_REQUEST",
    FETCH_LIST_CUSTOMER_EXPENES_FAILED: "FETCH_LIST_CUSTOMER_EXPENES_FAILED",
    FETCH_LIST_CUSTOMER_EXPENES_SUCCESS: "FETCH_LIST_CUSTOMER_EXPENES_SUCCESS",

    FETCH_LIST_CUSTOMER_PROPOSAL_REQUEST: "FETCH_LIST_CUSTOMER_PROPOSAL_REQUEST",
    FETCH_LIST_CUSTOMER_PROPOSAL_FAILED: "FETCH_LIST_CUSTOMER_PROPOSAL_FAILED",
    FETCH_LIST_CUSTOMER_PROPOSAL_SUCCESS: "FETCH_LIST_CUSTOMER_PROPOSAL_SUCCESS",

    FETCH_LIST_CUSTOMER_INVOICE_REQUEST: "FETCH_LIST_CUSTOMER_INVOICE_REQUEST",
    FETCH_LIST_CUSTOMER_INVOICE_FAILED: "FETCH_LIST_CUSTOMER_INVOICE_FAILED",
    FETCH_LIST_CUSTOMER_INVOICE_SUCCESS: "FETCH_LIST_CUSTOMER_INVOICE_SUCCESS",
};

export default ActionTypes;
