const ActionTypes = {
    FETCH_DISCUSSION: "FETCH_DISCUSSION",
    FETCH_DISCUSSION_SUCCESS: "FETCH_DISCUSSION_SUCCESS",
    FETCH_DISCUSSION_FAILED: "FETCH_DISCUSSION_FAILED",
    
    CREATE_DISCUSSION: "CREATE_DISCUSSION",
    CREATE_DISCUSSION_SUCCESS: "CREATE_DISCUSSION_SUCCESS",
    CREATE_DISCUSSION_FAILED: "CREATE_DISCUSSION_FAILED",
    
    DELETE_DISCUSSION: "DELETE_DISCUSSION",
    DELETE_DISCUSSION_SUCCESS: "DELETE_DISCUSSION_SUCCESS",
    DELETE_DISCUSSION_FAILED: "DELETE_DISCUSSION_FAILED",
    
    UPDATE_DISCUSSION: "UPDATE_DISCUSSION",
    UPDATE_DISCUSSION_SUCCESS: "UPDATE_DISCUSSION_SUCCESS",
    UPDATE_DISCUSSION_FAILED: "UPDATE_DISCUSSION_FAILED",
    
    SET_EDIT_DISCUSSION: "SET_EDIT_DISCUSSION",    
};
export default ActionTypes;
