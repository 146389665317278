import React from "react";
import { PrivateRoute } from "./PrivateRoute";
import i18n from "../i18n.js";
import { store } from "../constants/Route.js";

// handle auth and authorization
const NewContracts = React.lazy(() => import("../pages/Contract/Add/index"));
const EditContracts = React.lazy(() => import("../pages/Contract/EditContract"));
const ProposalNew = React.lazy(() => import("../pages/Proposal/New/index.js"));
const NewInvoice = React.lazy(() => import("../pages/Sales/Invoice/NewInvoice"));
const EditInvoice = React.lazy(() => import("../pages/Sales/Invoice/EditInvoice"));
const ViewInvoice = React.lazy(() => import("../pages/Sales/Invoice/View"));
const Payments = React.lazy(() => import("../pages/Sales/Payments/index.js"));
const Invoice = React.lazy(() => import("../pages/Sales/Invoice/index.js"));
// const Support = React.lazy(() => import("../pages/Support/index.js"));
const Expense = React.lazy(() => import("../pages/Expense/index.js"));
const Contract = React.lazy(() => import("../pages/Contract/index.js"));
const NewEstimate = React.lazy(() => import("../pages/Estimates/Add/index.js"));
const Proposal = React.lazy(() => import("../pages/Proposal/index.js"));
const Estimates = React.lazy(() => import("../pages/Estimates/index.js"));
const CreditNotes = React.lazy(() => import("../pages/CreditNote/index.js"));
const CustomerCreditNotes = React.lazy(() => import("../pages/Customer/CreditNotes/index.js"));

export const salesRoute = [
    {
        name: `${i18n.t("route.sales")}`,
        route: PrivateRoute,
        roles: ["SaleManager", "SaleStaff", "Director"],
        title: `${i18n.t("route.sales")}`,
        childs: [
            {
                name: `${i18n.t("route.plan")}`,
                route: PrivateRoute,
                roles: ["SaleManager", "SaleStaff", "Director"],
                title: `${i18n.t("route.plan")}`,
                childs: [
                    {
                        path: "/proposal",
                        name: `${i18n.t("route.proposal")}`,
                        component: Proposal,
                        route: PrivateRoute,
                        roles: ["SaleManager", "SaleStaff", "Director",],
                        title: `${i18n.t("route.proposal")}`,
                        exact: true,
                    },
                    {
                        path: "/estimates",
                        name: `${i18n.t("route.estimates")}`,
                        component: Estimates,
                        route: PrivateRoute,
                        roles: ["SaleManager", "SaleStaff", "Director",],
                        title: `${i18n.t("route.estimates")}`,
                        exact: true,
                    },
                    
                ],
            },
            {
                path: "/contract",
                name: `${i18n.t("route.contract")}`,
                component: Contract,
                route: PrivateRoute,
                roles: ["SaleManager", "SaleStaff", "Director"],
                title: "Contract",
                exact: true,
            },
            {
                path: "/invoices",
                name: `${i18n.t("route.invoices")}`,
                component: Invoice,
                route: PrivateRoute,
                roles: ["SaleManager", "SaleStaff", "Director"],
                title: `${i18n.t("route.invoices")}`,
                exact: true,
            },
            {
                path: "/payments",
                name: `${i18n.t("route.payments")}`,
                component: Payments,
                route: PrivateRoute,
                roles: ["SaleManager", "SaleStaff", "Director"],
                title: `${i18n.t("route.payments")}`,
                exact: true,
            },
            {
                path: "/expense",
                name: `${i18n.t("route.expense")}`,
                component: Expense,
                route: PrivateRoute,
                roles: ["SaleManager", "SaleStaff", "Director"],
                exact: true,
                title: "Expense",
            },
            
            {
                path: "/credit_notes",
                name: `${i18n.t("route.creditNotes")}`,
                component: CreditNotes,
                route: PrivateRoute,
                roles: ["SaleManager", "SaleStaff", "Director"],
                title: `${i18n.t("route.creditNotes")}`,
                exact: true,
            },
        ],
    },
    
    {
        path: "/customer/credit_notes/:id",
        name: `Customer_Credit_Notes`,
        component: CustomerCreditNotes,
        route: PrivateRoute,
        roles: ["SaleManager", "SaleStaff", "Director","credit_notes"],
        title: "Credit_Notes",
        exact: true,
    },
    {
        path: "/contract/newContract",
        name: `New Contract`,
        component: NewContracts,
        route: PrivateRoute,
        roles: ["SaleManager", "SaleStaff", "Director",],
        title: `New Contract`,
        exact: true,
    },
    {
        path: "/contract/edit/:id",
        name: `Edit Contract`,
        component: EditContracts,
        route: PrivateRoute,
        roles: ["SaleManager", "SaleStaff", "Director",],
        title: `Edit Contract`,
        exact: true,
    },
    {
        path: "/invoices/list_invoices",
        name: "invoices_list_invoices",
        component: Invoice,
        route: PrivateRoute,
        roles: ["SaleManager", "SaleStaff", "Director","view"],
        title: `${i18n.t("route.invoices")}`,
        exact: true,
    },
    {
        path: "/invoice/new",
        name: "invoice_new_sales",
        component: NewInvoice,
        route: PrivateRoute,
        roles: ["SaleManager", "SaleStaff", "Director","invoice"],
        title: "New Invoice",
        exact: true,
    },
    {
        path: "/customer/invoice/new/:id",
        name: `Customer Invoice New`,
        component: NewInvoice,
        route: PrivateRoute,
        roles: ["SaleManager", "SaleStaff", "Director",],
        title: "Customer Invoices",
        exact: true,
    },
    {
        path: "/invoice/edit/:id",
        name: "invoice_edit_sales",
        component: EditInvoice,
        route: PrivateRoute,
        roles: ["SaleManager", "SaleStaff", "Director","invoice"],
        title: "Edit Invoice",
        exact: true,
    },
    {
        path: "/invoice/view",
        name: `New Invoice`,
        component: ViewInvoice,
        route: PrivateRoute,
        roles: ["SaleManager", "SaleStaff", "Director","invoice"],
        title: "New Invoice",
        exact: true,
    },
    {
        path: "/customer/proposal/new/:id",
        name: `Customer Proposal New`,
        component: ProposalNew,
        route: PrivateRoute,
        roles: ["SaleManager", "SaleStaff", "Director",],
        title: "Customer Proposal",
        exact: true,
    },
    {
        path: "/customer/estimates/new/:id",
        name: `Customer Estimate New`,
        component: NewEstimate,
        route: PrivateRoute,
        roles: ["SaleManager", "SaleStaff", "Director",],
        title: "Customer Proposal",
        exact: true,
    },
]