import ActionTypes from "./types";

const INIT_STATE = {
    isLoading: false,
    listLeads: {},
    listStatus: [],
    listCreatedBy: [],
    listSource: [],
    notifications: [],
    isDeleteLeadSuccess: false,

    listCountries: [],
    isCreateLeadSuccess: false,
    listProposal: {},
    listProposalByLead: {},
    listTask: {},
    listCurrency: [],
    isCreateProposalSuccess: false,
    listItems: [],
    listGroupItems: [],
    listProposalLead: [],
    listStaff: [],
    listReminder: [],
    listNote: [],
    isFetchNote: false,
    listAttachment: [],
    isFetchAttachment: false,
    isConvertToCustomer: false,
};

const LeadReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_LIST_PROPOSAL:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.GET_LIST_PROPOSAL_SUCCESS:
            return {
                ...state,
                listProposal: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_PROPOSAL_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_ITEMS:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.GET_LIST_ITEMS_SUCCESS:
            return {
                ...state,
                listItems: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_ITEMS_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_GROUP_ITEMS:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.GET_LIST_GROUP_ITEMS_SUCCESS:
            return {
                ...state,
                listGroupItems: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_GROUP_ITEMS_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_CURRENCY:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.GET_LIST_CURRENCY_SUCCESS:
            return {
                ...state,
                listCurrency: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_CURRENCY_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        // case ActionTypes.CREATE_TASK:
        //     return {
        //         ...state,
        //         isLoading: true
        //     };

        // case ActionTypes.CREATE_TASK_SUCCESS:
        //     return {
        //         ...state,
        //         isLoading: false,
        //     };

        // case ActionTypes.CREATE_TASK_FAILED:
        //     return {
        //         ...state,
        //         isLoading: false
        //     };

        case ActionTypes.SET_IS_NO_CONTACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.SET_IS_NO_CONTACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.SET_IS_NO_CONTACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.SWITCH_TO_CUSTOMER:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.SWITCH_TO_CUSTOMER_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.SWITCH_TO_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.SET_IS_TRASH:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.SET_IS_TRASH_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.SET_IS_TRASH_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_LEADS:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_LEADS_SUCCESS:
            return {
                ...state,
                listLeads: action.payload,
                isLoading: false,
                isDeleteLeadSuccess: false,
            };

        case ActionTypes.FETCH_LIST_LEADS_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CREATE_PROPOSAL:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CREATE_PROPOSAL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isCreateProposalSuccess: false,
            };

        case ActionTypes.CREATE_PROPOSAL_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        // case ActionTypes.GET_TASK_BY_LEAD:
        //     return {
        //         ...state,
        //         isLoading: true
        //     };

        case ActionTypes.GET_TASK_BY_LEAD_SUCCESS:
            return {
                ...state,
                listTask: action.payload,
                isLoading: false,
            };

        // case ActionTypes.GET_TASK_BY_LEAD_FAILED:
        //     return {
        //         ...state,
        //         isLoading: false
        //     };
        case ActionTypes.GET_LIST_PROPOSAL_BY_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.GET_LIST_PROPOSAL_BY_LEAD_SUCCESS:
            return {
                ...state,
                listProposalByLead: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_PROPOSAL_BY_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.UPDATE_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.UPDATE_LEAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isCreateLeadSuccess: true,
            };

        case ActionTypes.UPDATE_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
                isCreateLeadSuccess: false,
            };

        case ActionTypes.UPDATE_IS_CREATE_SUCCESS:
            return {
                ...state,
                isCreateLeadSuccess: false,
            };

        case ActionTypes.GET_LIST_COUNTRY:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.GET_LIST_COUNTRY_SUCCESS:
            return {
                ...state,
                listCountries: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_COUNTRY_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CREATE_NEW_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CREATE_NEW_LEAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isCreateLeadSuccess: true,
            };

        case ActionTypes.CREATE_NEW_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.DELETE_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.DELETE_LEAD_SUCCESS:
            return {
                ...state,
                isDeleteLeadSuccess: true,
                isLoading: false,
            };

        case ActionTypes.DELETE_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_STATUS_LEADS:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_STATUS_LEADS_SUCCESS:
            return {
                ...state,
                listStatus: action.payload,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_STATUS_LEADS_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.UPDATE_STATUS_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.UPDATE_STATUS_LEAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.UPDATE_STATUS_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_CREATED_BY:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.GET_LIST_CREATED_BY_SUCCESS:
            return {
                ...state,
                listCreatedBy: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_CREATED_BY_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_SOURCE:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.GET_LIST_SOURCE_SUCCESS:
            return {
                ...state,
                listSource: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_SOURCE_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        // Fetch list Proposal Lead

        case ActionTypes.FETCH_LIST_PROPOSAL_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_PROPOSAL_LEAD_SUCCESS:
            return {
                ...state,
                listProposalLead: action.payload,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_PROPOSAL_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_STAFF:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_STAFF_SUCCESS:
            return {
                ...state,
                listStaff: action.payload,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_STAFF_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.FETCH_LIST_REMINDER_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_REMINDER_LEAD_SUCCESS:
            return {
                ...state,
                listReminder: action.payload,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_REMINDER_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.CREATE_REMINDER_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CREATE_REMINDER_LEAD_SUCCESS:
            state.listReminder.data.push(action.payload);
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CREATE_REMINDER_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.DELETE_REMINDER_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.DELETE_REMINDER_LEAD_SUCCESS:
            const newListReminder = state.listReminder.data.filter(
                (item) => item.id !== action.payload
            );
            return {
                ...state,
                listReminder: {
                    ...state.listReminder,
                    data: newListReminder,
                },
                isLoading: false,
            };

        case ActionTypes.DELETE_REMINDER_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.UPDATE_REMINDER_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.UPDATE_REMINDER_LEAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.UPDATE_REMINDER_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_NOTE_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_NOTE_LEAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listNote: action.payload,
            };

        case ActionTypes.FETCH_NOTE_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CREATE_NOTE_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CREATE_NOTE_LEAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchNote: !state.isFetchNote,
            };

        case ActionTypes.CREATE_NOTE_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.DELETE_NOTE_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.DELETE_NOTE_LEAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchNote: !state.isFetchNote,
            };

        case ActionTypes.DELETE_NOTE_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.UPDATE_NOTE_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.UPDATE_NOTE_LEAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchNote: !state.isFetchNote,
            };

        case ActionTypes.UPDATE_NOTE_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_ATTACHMENT_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_ATTACHMENT_LEAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listAttachment: action.payload,
            };

        case ActionTypes.FETCH_LIST_ATTACHMENT_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CREATE_ATTACHMENT_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CREATE_ATTACHMENT_LEAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchAttachment: !state.isFetchAttachment,
            };

        case ActionTypes.CREATE_ATTACHMENT_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.DELETE_ATTACHMENT_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.DELETE_ATTACHMENT_LEAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchAttachment: !state.isFetchAttachment,
            };

        case ActionTypes.DELETE_ATTACHMENT_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        // REDUCER DETAIL TASK MODULE LEAD
        case ActionTypes.FETCH_LIST_TASK_LEAD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_TASK_LEAD_SUCCESS:
            return {
                ...state,
                listTask: action.payload,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_TASK_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.CONVERT_TO_CUSTOMER_LEAD_PENDING:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CONVERT_TO_CUSTOMER_LEAD_SUCCESS:
            return {
                ...state,

                isLoading: false,
            };

        case ActionTypes.CONVERT_TO_CUSTOMER_LEAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.GET_CONVERT_TO_CUSTOMER:
            return {
                ...state,
                isConvertToCustomer: !state.isConvertToCustomer,
            };

        default:
            return { ...state };
    }
};

export default LeadReducer;
