import ActionTypes from "./type";

export const getListCustomer = () => {
    return {
        type: ActionTypes.GET_LIST_CUSTOMER_REQUEST,
    };
};
export const getListCustomerSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_CUSTOMER_SUCCESS,
        payload: data,
    };
};

export const getListCustomerFailed = () => {
    return {
        type: ActionTypes.GET_LIST_CUSTOMER_FAILED,
    };
};

export const fetchListCustomerRequest = (search, params) => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_REQUEST,
        search,
        params,
    };
};

export const fetchListCustomerSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_SUCCESS,
        payload: data,
    };
};

export const fetchListCustomerFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_FAILED,
    };
};

export const deleteItemCustomerRequest = (id) => {
    return {
        type: ActionTypes.DELETE_ITEM_CUSTOMER_REQUEST,
        payload: id,
    };
};

export const deleteItemCustomerSuccess = () => {
    return {
        type: ActionTypes.DELETE_ITEM_CUSTOMER_SUCCESS,
    };
};

export const deleteItemCustomerFailed = () => {
    return {
        type: ActionTypes.DELETE_ITEM_CUSTOMER_FAILED,
    };
};

export const getListGroupRequest = () => {
    return {
        type: ActionTypes.GET_LIST_GROUPS_REQUEST,
    };
};

export const getListGroupSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_GROUPS_SUCCESS,
        payload: data,
    };
};

export const getListGroupFailed = () => {
    return {
        type: ActionTypes.GET_LIST_GROUPS_FAILED,
    };
};

export const addGroupRequest = (data) => {
    return {
        type: ActionTypes.ADD_GROUP_REQUEST,
        payload: data,
    };
};

export const addGroupSuccess = (data) => {
    return {
        type: ActionTypes.ADD_GROUP_SUCCESS,
        payload: data,
    };
};

export const addGroupFailed = () => {
    return {
        type: ActionTypes.ADD_GROUP_FAILED,
    };
};

export const changeDataProfile = (name, value) => {
    return {
        type: ActionTypes.CHANGE_DATA_PROFILE,
        name,
        value,
    };
};

export const addCustomerRequest = (data, history) => {
    return {
        type: ActionTypes.ADD_CUSTOMER_REQUEST,
        payload: data,
        history,
    };
};

export const addCustomerSuccess = () => {
    return {
        type: ActionTypes.ADD_CUSTOMER_SUCCESS,
    };
};

export const editCustomerFailed = () => {
    return {
        type: ActionTypes.EDIT_CUSTOMER_FAILED,
    };
};
export const editCustomerRequest = (data) => {
    return {
        type: ActionTypes.EDIT_CUSTOMER_REQUEST,
        payload: data,
    };
};

export const editCustomerSuccess = () => {
    return {
        type: ActionTypes.EDIT_CUSTOMER_SUCCESS,
    };
};

export const addCustomerFailed = () => {
    return {
        type: ActionTypes.ADD_CUSTOMER_FAILED,
    };
};

export const getSummaryCustomerRequest = () => {
    return {
        type: ActionTypes.GET_SUMMARY_CUSTOMER_REQUEST,
    };
};

export const getSummaryCustomerSuccess = (data) => {
    return {
        type: ActionTypes.GET_SUMMARY_CUSTOMER_SUCCESS,
        payload: data,
    };
};

export const getSummaryCustomerFailed = () => {
    return {
        type: ActionTypes.GET_SUMMARY_CUSTOMER_FAILED,
    };
};

export const editActiveRequest = (id, value) => {
    return {
        type: ActionTypes.EDIT_ACTIVE_REQUEST,
        id,
        value,
    };
};

export const editActiveSuccess = (data) => {
    return {
        type: ActionTypes.EDIT_ACTIVE_SUCCESS,
        payload: data,
    };
};

export const editActiveFailed = () => {
    return {
        type: ActionTypes.EDIT_ACTIVE_FAILED,
    };
};


export const fetchEstimateCustomerRequest = (id, params) => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_ESTIMATE_REQUEST,
        id,
        params,
    };
};
export const fetchEstimateCustomerSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_ESTIMATE_SUCCESS,
        data,
    };
};
export const fetchEstimateCustomerFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_ESTIMATE_FAILED,
    };
};

export const getCustomerProjectRequest = (clientId, body) => {
    return {
        type: ActionTypes.GET_PROJECT_CUSTOMER_REQUEST,
        payload: { clientId: clientId, body: body },
    };
};

export const getCustomerProjectSuccess = (data) => {
    return {
        type: ActionTypes.GET_PROJECT_CUSTOMER_SUCCESS,
        payload: data,
    };
};

export const getCustomerProjectFailed = () => {
    return {
        type: ActionTypes.GET_PROJECT_CUSTOMER_FAILED,
    };
};

export const fetchCustomerById = (data) => {
    return {
        type: ActionTypes.FETCH_CUSTOMER_BY_ID,
        payload: data,
    };
};

export const fetchCustomerByIdSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_CUSTOMER_BY_ID_SUCCESS,
        payload: data,
    };
};


export const fetchCustomerByIdFailed = () => {
    return {
        type: ActionTypes.FETCH_CUSTOMER_BY_ID_FAILED,
    };
};

export const addCustomerFileRequest = (clientId, body) => {
    return {
        type: ActionTypes.ADD_CUSTOMER_FILE_PENDING,
        payload: { clientId: clientId, body: body },
    };
};

export const addCustomerFileSuccess = () => {
    return {
        type: ActionTypes.ADD_CUSTOMER_FILE_SUCCESS,
    };
};

export const addCustomerFileFailed = () => {
    return {
        type: ActionTypes.ADD_CUSTOMER_FILE_FAILED,
    };
};
export const addCustomerVaultRequest = (clientId, body) => {
    return {
        type: ActionTypes.ADD_CUSTOMER_VAULT_PENDING,
        payload: { clientId: clientId, body: body },
    };
};

export const addCustomerVaultSuccess = () => {
    return {
        type: ActionTypes.ADD_CUSTOMER_VAULT_SUCCESS,
    };
};

export const addCustomerVaultFailed = () => {
    return {
        type: ActionTypes.ADD_CUSTOMER_VAULT_FAILED,
    };
};
// export const getListReminder = () => {
//   return {
//     type: ActionTypes.GET_LIST_REMINDER,
//   };
// };

// export const getListReminderSuccess = (data) => {
//   return {
//     type: ActionTypes.GET_LIST_REMINDER_SUCCESS,
//     payload: data,
//   };
// };

// export const getListReminderFailed = () => {
//   return {
//     type: ActionTypes.GET_LIST_REMINDER_FAILED,
//   };
// };

// export const createReminder = () => {
//   return {
//     type: ActionTypes.CREATE_REMINDER,
//   };
// };

// export const createReminderSuccess = (data) => {
//   return {
//     type: ActionTypes.CREATE_REMINDER_SUCCESS,
//     payload: data,
//   };
// };

// export const createReminderFailed = () => {
//   return {
//     type: ActionTypes.CREATE_REMINDER_FAILED,
//   };
// };

// export const editReminder = () => {
//   return {
//     type: ActionTypes.EDIT_REMINDER,
//   };
// };

// export const editReminderSuccess = (data) => {
//   return {
//     type: ActionTypes.EDIT_REMINDER_SUCCESS,
//     payload: data,
//   };
// };

// export const editReminderFailed = () => {
//   return {
//     type: ActionTypes.EDIT_REMINDER_FAILED,
//   };
// };

// export const deleteReminder = () => {
//   return {
//     type: ActionTypes.DELETE_REMINDER,
//   };
// };

// export const deleteReminderSuccess = (data) => {
//   return {
//     type: ActionTypes.DELETE_REMINDER_SUCCESS,
//     payload: data,
//   };
// };

// export const deleteReminderFailed = () => {
//   return {
//     type: ActionTypes.DELETE_REMINDER_FAILED,
//   };
// };

export const fetchExpenesCustomerRequest = (id, params) => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_EXPENES_REQUEST,
        id,
        params,
    };
};
export const fetchExpenesCustomerSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_EXPENES_SUCCESS,
        data,
    };
};
export const fetchExpenesCustomerFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_EXPENES_FAILED,
    };
};

export const fetchProposalCustomerRequest = (id, params) => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_PROPOSAL_REQUEST,
        id,
        params,
    };
};
export const fetchProposalCustomerSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_PROPOSAL_SUCCESS,
        data,
    };
};
export const fetchProposalCustomerFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_PROPOSAL_FAILED,
    };
};

export const fetchInvoiceCustomerRequest = (id, params) => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_INVOICE_REQUEST,
        id,
        params,
    };
};
export const fetchInvoiceCustomerSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_INVOICE_SUCCESS,
        data,
    };
};
export const fetchInvoiceCustomerFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_INVOICE_FAILED,
    };
};