import ActionTypes from "./types";


//fetch invoice
export const fetchInvoicesProject = (data) => {
    return {
        type: ActionTypes.FETCH_INVOICES_PROJECT,
        payload: data,
    };
};

export const fetchInvoicesProjectSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_INVOICES_PROJECT_SUCCESS,
        payload: data,
    };
};

export const fetchInvoicesProjectFailed = () => {
    return {
        type: ActionTypes.FETCH_INVOICES_PROJECT_FAILED,
    };
};


//filter year
export const fetchFilterYearInvoicePrj = (data) => {
    return {
        type: ActionTypes.FETCH_FILTER_YEAR_INVOICE_PRJ,
        payload: data,
    };
};

export const fetchFilterYearInvoicePrjSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_FILTER_YEAR_INVOICE_PRJ_SUCCESS,
        payload: data,
    };
};

export const fetchFilterYearInvoicePrjFailed = () => {
    return {
        type: ActionTypes.FETCH_FILTER_YEAR_INVOICE_PRJ_FAILED,
    };
};

//filter total
export const fetchTotalInvoicePrj = (data) => {
    return {
        type: ActionTypes.FETCH_TOTAL_INVOICE_PRJ,
        payload: data,
    };
};

export const fetchTotalInvoicePrjSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_TOTAL_INVOICE_PRJ_SUCCESS,
        payload: data,
    };
};

export const fetchTotalInvoicePrjFailed = () => {
    return {
        type: ActionTypes.FETCH_TOTAL_INVOICE_PRJ_FAILED,
    };
};

//FETCH USER INVOICE
export const fetchStaffInvoicePrj = (projectId, data) => {
    return {
        type: ActionTypes.FETCH_STAFF_INVOICE_PRJ,
    }
}

export const fetchStaffInvoicePrjSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_STAFF_INVOICE_PRJ_SUCCESS,
        payload: data
    }
}

export const fetchStaffInvoicePrjFailed = () => {
    return {
        type: ActionTypes.FETCH_STAFF_INVOICE_PRJ_FAILED,
    }
}

//FILTER INVOICE
export const filterInvoice = (data) => {
    return {
        type: ActionTypes.FILTER_INVOICE_PRJ,
        payload: data,
    }
}

export const filterInvoiceSuccess = (data) => {
    return {
        type: ActionTypes.FILTER_INVOICE_PRJ_SUCCESS,
        payload: data
    }
}

export const filterInvoiceFailed = () => {
    return {
        type: ActionTypes.FILTER_INVOICE_PRJ_FAILED,
    }
}

//fetch currency
export const fetchCurrencies = (data) => {
    return {
        type: ActionTypes.FETCH_CURRENCIES,
        payload: data,
    };
};

export const fetchCurrenciesSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_CURRENCIES_SUCCESS,
        payload: data,
    };
};

export const fetchCurrenciesFailed = () => {
    return {
        type: ActionTypes.FETCH_CURRENCIES_FAILED,
    };
};