import ActionTypes from "./types";

const INIT_STATE = {
  isLoading: false,
  dataEstimate: {},
  filterYearEstimatePrj: {},
  totalEstimatePrj: {},
};

const EstimateProjectReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ESTIMATES_PROJECT:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_ESTIMATES_PROJECT_SUCCESS:
      return {
        ...state,
        dataEstimate: action.payload,
        isLoading: false,
      };
    case ActionTypes.FETCH_ESTIMATES_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_FILTER_YEAR_ESTIMATE_PRJ:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_FILTER_YEAR_ESTIMATE_PRJ_SUCCESS:
      return {
        ...state,
        filterYearEstimatePrj: action.payload,
        isLoading: false,
      };

    case ActionTypes.FETCH_FILTER_YEAR_ESTIMATE_PRJ_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_TOTAL_ESTIMATE_PRJ:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_TOTAL_ESTIMATE_PRJ_SUCCESS:
      return {
        ...state,
        totalEstimatePrj: action.payload,
        isLoading: false,
      };

    case ActionTypes.FETCH_TOTAL_ESTIMATE_PRJ_FAILED:
      return {
        ...state,
        isLoading: false,
      };
      case ActionTypes.FILTER_ESTIMATE:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FILTER_ESTIMATE_SUCCESS:
      return {
        ...state,
        dataEstimate: action.payload,
        isLoading: false,
      };

    case ActionTypes.FILTER_ESTIMATE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};

export default EstimateProjectReducer;
