export default {
  project: {
    addNew: "NEW PROJECT",
    watchGant: "Gantt View",
    filterBy: "Filter By",
    summary: "Projects Summary",
    notStarted: "Not Started",
    inProgress: "In Progress",
    onHold: "On Hold",
    cancelled: "Cancelled",
    finished: "Finished",
    export: "Export",
    reload: "Reload",
    searching: "Search...",
    search: "Search",
    projectName: "Project Name",
    customer: "Customer",
    tags: "Tags",
    startDate: "Start Date",
    deadline: "Deadline",
    members: "Members",
    status: "Status",
    view: "View",
    copyProject: "Copy Project",
    edit: "Edit",
    delete: "Delete",
    save: "SAVE",
    all: "All",
    myProjects: "My Projects",
    selectAll: "Select All",
    deselectAll: "Deselect All",
    notSelect: "Do not select",
    fixedRate: "Fixed Rate",
    projectHours: "Project Hours",
    taskHours: "Task Hours",
    taskHoursNote: "Based on task hourly rate",
    nothingSelected: "Nothing selected",
    projectOverview: "Project Overview",
    tasks: "Tasks",
    timesheets: "Timesheets",
    milestones: "Milestones",
    files: "Files",
    discussions: "Discussions",
    tickets: "Tickets",
    sales: "Sales",
    notes: "Notes",
    activity: "Activity",
    invoices: "Invoices",
    estimates: "Estimate",
    expenses: "Expenses",
    creditNotes: "Credit Notes",
    subscriptions: "Subscriptions",
    overview: "OVERVIEW",
    title: "Project",
    billingType: "Billing Type",
    dateCreated: "Date Created",
    estimatedHours: "Estimated Hours",
    totalLoggedHours: "Total Logged Hours",
    description: "Description",
    totalLoggedTime: "Total Logged Time",
    timesheetsTags: "Timesheet Tags",
    startTime: "Start Time",
    endTime: "End Time",
    timeH: "Time (h)",
    timeDecimal: "Time (decimal)",
    options: "Options",
    timesheet: "Timesheet",
    close: "CLOSE",
    timeSpent: "Time spent",
    contentTimeSpent: `:15 - 15 Minutes
		2 - 2 Hours
		5:5 - 5 Hours & 5 Minutes
		2:50 - 2 Hours & 50 Minutes`,
    actionDelete: "Are you sure you want to perform this action?",
    deleteMilestone: "Delete milestone",
    deleteTimesheet: "Delete time-sheet",
    createDiscussion: "Create Discussion",
    subject: "Subject",
    lastActivity: "Last Activity",
    totalComments: "Total Comments",
    visibleToCustomer: "Visible to Customer",
    amount: "Amount",
    totalTax: "Total Tax",
    dateExport: "Date Export",
    dueDate: "Due Date",
    viewQuickStats: "View Quick Stats",
    outstandingInvoices: "Outstanding Invoices",
    pastDueInvoices: "Past Due Invoices",
    paidInvoices: "Paid Invoices",
    unpaid: "Unpaid",
    paid: "Paid",
    partiallyPaid: "Partially Paid",
    overdue: "Overdue",
    draft: "Draft",
    creditNotesDate: "Credit Notes Date",
    reference: "Reference",
    remainingAmount: "Remaining Amount",
    subscriptionName: "Subscription Name",
    nextBillingCycle: "Next Billing Cycle",
    dateSubscribed: "Date Subscribed",
    estimate: "Estimate",
    date: "Date",
    expiryDate: "Expiry Date",
    sent: "Sent",
    expired: "Expired",
    accepted: "Accepted",
    declined: "Declined",
    openTask: "OPEN TASKS",
    daysLeft: "DAYS LEFT",
    loggedHours: "Logged Hours",
    billableHours: "Billable Hours",
    billedHours: "Billed Hours",
    unbilledHours: "Unbilled Hours",
    totalExpenses: "Total Expenses",
    billableExpenses: "Billable Expenses",
    billedExpenses: "Billed Expenses",
    unbilledExpenses: "Unbilled Expenses",
    deleteSuccess: "Delete Success",
    updateSuccess: "Update Success",
    createSuccess: "Create Success",
    copyChecklist: "Copy checklist items",
    copyFollowers: "Copy the same followers",
    copyAssignees: "Copy the same assignees",
    taskStatus: "Task Status",
    test: "Testing",
    feedback: "Awaiting Feedback",
    complete: "Complete",
    tooltip: {
      member: "Add/Edit Members",
    },
    ticket: {
      ticketsSummary: "Tickets Summary",
      open: "Open",
      inProgress: "In Progress",
      answered: "Answered",
      close: "Closed",
      newTicket: "NEW TICKET",
      btnNewTicket: {
        subject: "Subject",
        contact: "Contact",
        name: "Name",
        emailAddress: "Email address",
        department: "Department",
        tag: "Tag",
        assignTicket: "Assign ticket(default is current user)",
        priority: "Priority",
        service: "Service",
        btnService: {
          newService: "New Service",
          serviceName: "Service Name",
          close: "CLOSE",
        },
        project: "Project",
        ticketBody: "Ticket Body",
        attachments: "Attachments",
        openTicket: "OPEN TICKET",
      },
      tableTicket: {
        status: "Status",
        lastReply: "Last Reply",
        created: "Created",
      },
      settingTicket: {
        addReply: "Add Reply",
        addNote: "Add Note",
        reminders: "Reminders",
        otherTickets: "Other Tickets",
        tasks: "Tasks",
        settings: "Settings",
        textTitle: "This ticket is linked to project:",
        open: "Open",
        priority: "Priority: ",
        medium: "Medium",
        service: "Service: ",
        department: "Department: ",
        assigned: "Assigned: ",
        changeStatus: "Change Status",
        checkboxReturn: "Return to ticket list after response is submitted",
        staff: "Staff",
        createMailCustomer: "Send email to the customer",
        btnConvertTask: "Convert to task",
        addResponse: "Add response",
        posted: "Posted:",
      },
      summaryProfile: {
        totalLoggedTime: "Total Logged Time",
        lastMonth: "Last Month Logged Time",
        thisMonth: "This month Logged Time",
        lastWeek: "Last Week Logged Time",
        thisWeek: "This Week Logged Time",
        profile: "Profile",
        noteAdminProfile: "This is admin profile",
        notifications: "Notifications",
        noteNotifications: "Make all as read",
        loadMore: "Load more",
      },
    },
    note: {
      personalNotes: "Personal notes",
      btnSaveNote: "Save note",
    },
    total: "Total",
    billable: "Billable",
    nonBillable: "Non Billable",
    notInvoiced: "Not Invoiced",
    billed: "Billed",
    category: "Category",
    name: "Name",
    expensesReceipt: "Expenses Receipt",
    paymentMode: "Payment Mode",
    recordExpense: "Record expense",
    andNewExpense: "And New Expense",
    attachReceipt: "Attach Receipt",
    expenseCategory: "Expense Category",
    expenseDate: "Expense Date",
    tax1: "Tax 1",
    tax2: "Tax 2",

    validateInput: "Required Input...",
    updateDiscussion: "Update Discussion",
    display: "Display",
    noDisplay: "No Display",
    newTask: "newTask",
    notSent: "Not sent",
    invoiceWithNoPaymentRecords: "Invoice with no payment records",
    recurringInvoices: "Recurring invoices",
    saleAgent: "Sale agent",
    madePaymentByBank: "Made payment by bank",
    invoiced: "Invoiced",
    recurring: "Recurring",
    byCategories: "By categories",
    months: "Months",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    fileName: "File Name",
    fileType: "File Type",
    dateUploaded: "Date Uploaded",
    uploadBy: "Uploaded By",
    content: "Content",
  },
  action: {
    createProjectSuccess: "Project created successfully",
    createProjectFailed: "Project creation failed",
    updateProjectSuccess: "Project updated successfully",
    updateProjectFailed: "Project update failed",
    deleteProjectSuccess: "Project deleted successfully",
    deleteProjectFailed: "Project deletion failed",
    copyProjectSuccess: "Project copied successfully",
    copyProjectFailed: "Project copy failed",
    createProjectActivitySuccess: "Project activity created successfully",
    createProjectActivityFailed: "Project activity creation failed",
    updateProjectActivitySuccess: "Project activity updated successfully",
    updateProjectActivityFailed: "Project activity update failed",
    deleteProjectActivitySuccess: "Project activity deleted successfully",
    deleteProjectActivityFailed: "Project activity deletion failed",
    createProjectNotesSuccess: "Project note created successfully",
    createProjectNotesFailed: "Project note creation failed",
    updateProjectNotesSuccess: "Project note updated successfully",
    updateProjectNotesFailed: "Project note update failed",
    deleteProjectNotesSuccess: "Project note deleted successfully",
    deleteProjectNotesFailed: "Project note deletion failed",
    createTimesheetProjectSuccess: "Project timesheet created successfully",
    createTimesheetProjectFailed: "Project timesheet creation failed",
    updateTimesheetProjectSuccess: "Project timesheet updated successfully",
    updateTimesheetProjectFailed: "Project timesheet update failed",
    deleteTimesheetProjectSuccess: "Project timesheet deleted successfully",
    deleteTimesheetProjectFailed: "Project timesheet deletion failed",
    createProjectMilestoneSuccess: "Project milestone created successfully",
    createProjectMilestoneFailed: "Project milestone creation failed",
    updateProjectMilestoneSuccess: "Project milestone updated successfully",
    updateProjectMilestoneFailed: "Project milestone update failed",
    deleteProjectMilestoneSuccess: "Project milestone deleted successfully",
    deleteProjectMilestoneFailed: "Project milestone deletion failed",
    createProjectFilesSuccess: "Project file added successfully",
    createProjectFilesFailed: "Project file addition failed",
    updateProjectFilesSuccess: "Project file updated successfully",
    updateProjectFilesFailed: "Project file update failed",
    deleteProjectFilesSuccess: "Project file deleted successfully",
    deleteProjectFilestoneFailed: "Project file deletion failed",
    changeProjectFilesVisibleSuccess: "Project file display status changed successfully",
    changeProjectFilesVisibleFailed: "Project file display status change failed",
    createProjectTicketSuccess: "Project ticket created successfully",
    createProjectTicketFailed: "Project ticket creation failed",
    updateProjectTicketSuccess: "Project ticket updated successfully",
    updateProjectTicketFailed: "Project ticket update failed",
    deleteProjectTicketSuccess: "Project ticket deleted successfully",
    deleteProjectTicketFailed: "Project ticket deletion failed",
}

};
