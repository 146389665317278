import { deleteEstimate } from "../../pages/Estimates/redux/actions";

export default {
  customer: {
    index: {
      add: "Khách hàng mới",
      import: "Nhập khách hàng ",
      toKanban: "Đổi sang dạng kanban",
      titleDialogCreate: "Thông tin cá nhân",
      removeCustomer: "Xóa Customer",
      confirmRemoveCustomer: "Bạn có chắ muốn xóa",
      cancelRemoveCustomer: "Hủy bỏ",
      sureRemoveCustomer: "Đồng ý",
      titleFilter: "Được lọc theo",
      keyWordPlaceholder: "Tìm kiếm",
      assignedPlaceholder: "Người tạo",
      statusPlaceholder: "Trạng thái ",
      sourcePlaceholder: "Nguồn",
      view: "Xem",
      edit: "Sửa",
      delete: "Xóa",
      yes: "Có",
      no: "Không",
      contacts: "Liên hệ",
    },
    common: {
        id: "Id",
        to: "Đến",
        basicContact: "Liên lạc",
        email: "Email",
        numberPhone: "Số điện thoại",
        company: "Công ty",
        status: "Hoạt động",
        group: "Nhóm",
        created: "Ngày tạo",
        address: "Địa chỉ",
        taxCode: "MST",
        city: "Thành phố",
        phone: "Số điện thoại",
        state: "State",
        website: "Website",
        zipCode: "Mã zipcode",
        country: "Quốc gia",
        currency: "Tiền",
        defaultLanguage: "Ngôn ngữ",
        streetBilling: "Đường",
        streetShipping: "Đường",
        cityBilling: "Thành phố",
        cityShipping: "Thành phố",
        stateBilling: "State",
        stateShipping: "State",
        zipCodeBilling: "Mã zipcode",
        zipCodeShipping: "Mã zipcode",
        countryBilling: "Quốc gia",
        countryShipping: "Quốc gia",
        profile: "Thông tin cá nhân",
        assign: "Chỉ định quản trị viên",
        newContact: "New Contact",
        note: "Notes",
        newNote: "Ghi chú mới",
        search: "Tìm kiếm...",
        Btnclose: "Đóng",
        btnSave: "Lưu",
        apply: "Áp dụng",
        view: "Xem",
        edit: "Chỉnh sửa",
        delete: "Xóa",
        titleSign: "Chữ ký và Xác nhận Danh tính",
        firstName: "Tên",
        lastName: "Họ",
        emailSign: "Email",
        signature: "Chữ ký",
        clear: "Xóa",
        undo: "Hoàn tác",
        noteSign: "Bằng cách nhấp vào 'Ký', tôi đồng ý bị ràng buộc pháp lý bởi chữ ký điện tử này.",
        validateCompany: "Trường này là bắt buộc.",
        validateNameNewCustomerGroup: "Trường này là bắt buộc.",
    },
    overview: {
        id: "Id",
        to: "To",
        overview: "Tổng quan khách hàng",
        total: "Tổng số khách hàng",
        active: "Khách hàng đang hoạt động",
        inactive: "Khách hàng không hoạt động",
        activeContact: "Liên hệ đang hoạt động",
        activeContactLess: "Liên hệ ít hoạt động",
        signIn: "Các liên hệ đã đăng nhập hôm nay",
        export: "Xuất ra",
    },
    menuTabs: {
        story: "Thông tin cá nhân",
        information: "Thông tin khách hàng",
        payment: "Thanh toán & Nhận hàng",
        admin: "Quản trị khách hàng",
    },
    profile: {
        titleNewCustomer: "Hồ sơ",
        billingAddress: "Địa chỉ thanh toán",
        information: "Như thông tin khách hàng",
        shippingAddress: " Địa chỉ nhận hàng",
        coppyBillingAddress: "Sao chép địa chỉ thanh toán",
        name: "Nhân viên",
        created: "Ngày chỉ định",
        option: "Cài đặt",
        assignAdmin: "Chỉ định Quản trị viên",
        titleNewGroup: "Thêm nhóm khách hàng mới",
        nameGroup: "Tên",
        Btnsave: "Lưu",
        Btnclose: "Đóng",
        tootipCurrency:
            "Nếu khách hàng sử dụng đơn vị tiền tệ khác thì đơn vị tiền tệ cơ sở đảm bảo bạn chọn đơn vị tiền tệ thích hợp cho khách hàng này. Không thể thay đổi đơn vị tiền tệ sau khi các giao dịch được ghi lại.",
        company: "Tên doanh nghiệp",
        address: "Địa chỉ",
        vatNumber: "Mã số thuế",
        city: "Thành phố",
        phone: "Điện thoại",
        state: "Tiểu bang",
        website: "Website",
        zipCode: "Mã bưu chính",
        groups: "Nhóm",
        country: "Quốc gia",
        currency: "Đơn vị tiền",
        defaultLanguage: "Ngôn ngữ mặc định",
    },
    tooltip: {
        paymentTooltip:
        "Không điền mục địa chỉ nhận hàng nếu bạn không dùng địa chỉ nhận hàng trên hóa đơn cho khách hàng",
    },
    menuView: {
        profile: "Thông tin",
        contacts: "Liên hệ",
        notes: "Ghi chú",
        statement: "Statement",
        invoices: "Hóa đơn",
        payments: "Thanh toán",
        proposals: "Đề xuất kế hoạch",
        creditNotes: "Ghi chú tín dụng",
        estimates: "Báo giá",
        subscriptions: "Subscriptions",
        expenses: "Chi phí",
        contracts: "Hợp đồng",
        projects: "Các dự án",
        tasks: "Phân công",
        tickets: "Yêu cầu",
        files: "Các tệp file",
        vault: "Vault",
        reminders: "Nhắc nhở",
        map: "Map",
        noteProfile:
            "Hiển thị đầy đủ tên liên hệ cơ bản trên Hóa đơn, Báo giá, Thanh toán, Credit Notes",
        noteEstimatesPayment:
            "Cập nhật thông tin nhận hàng/thanh toán trên tất cả những bản báo giá/hóa đơn trước",
        noteTextPayment:
            "Nếu bạn tick chọn mục này, thông tin nhận hàng và thanh toán sẽ áp dụng cho tất cả hóa đơn và báo giá. Lưu ý: hóa đơn đã thanh toán sẽ không bị ảnh hưởng.",
        noteCreditPayment:
            "Update the shipping/billing info on all previous credit notes (Closed credit notes not affected)",
    },

    projects: {
        title: "Các dự án",
        addNew: "Thêm dự án",
        export: "Xuất in",
        search: "Tìm kiếm...",
        tooltip: {
            reload: "Tải lại",
        },
        status: {
            notStarted: "Chưa bắt đầu",
            inProgress: "Đang thực hiện",
            onHold: "Tạm ngưng",
            cancelled: "Đã hủy",
            finished: "Hoàn thành",
        },
        table: {
            name: "Tên dự án",
            tags: "Các thẻ",
            startDate: "Ngày bắt đầu",
            deadline: "Hạn chót",
            members: "Thành viên",
            status: "Trạng thái",
        },
        nameAction: {
            view: "Xem",
            edit: "Chỉnh sửa",
            delete: "Xóa",
        },
    },
    addNewProject: {
        addNew: "Thêm dự án mới",
        edit: "Chỉnh sửa dự án",
        settings: "Tùy chỉnh dự án",
        form: {
            name: "Tên dự án",
            customer: "Khách hàng",
            calculateProgress: "Tính toán tiến độ qua phân công",
            progress: "Tiến độ",
            billingType: "Loại biên nhận",
            status: "Trạng thái",
            rate: "Tiền công mỗi giờ",
            estimatedHours: "Giờ ước tính",
            members: "Thành viên thực hiện dự án",
            group: "Nhóm",
            startDate: "Ngày bắt đầu",
            deadline: "Hạn chót",
            tags: "Các thẻ",
            description: "Mô tả dự án",
            sendProjectMail: "Gửi email dự án đã thực hiện",
        },
        visibleTab: "Các tab hiển thị",
        listRoles: {
            viewTask: "Cho phép khách hàng xem các phân công",
            createTask: "Cho phép khách hàng tạo phân công mới",
            editTask:
            "Cho phép khách hàng chỉnh sửa phân công (chỉ những phân công được tạo từ liên hệ)",
            commentTask: "Cho phép khách hàng bình luận trên phân công dự án",
            viewTaskComments: "Cho phép khách hàng xem bình luận của phân công",
            viewTaskAttachments:
            "Cho phép khách hàng xem tập tin đính kèm trong phân công",
            viewTaskChecklistItems:
            "Cho phép khách hàng xem danh sách đánh dấu phân công",
            uploadAttachmentsOnTasks:
            "Cho phép khách hàng tải tập tin lên phân công",
            viewLoggedTime:
            "Cho phép khách hàng xem tổng thời gian đăng nhập của phân công",
            viewFinance: "Cho phép khách hàng Xem tổng quan ngân sách",
            uploadFiles: "Cho phép khách hàng tải lên tập tin",
            openDiscussions: "Cho phép khách hàng mở cuộc trao đổi",
            viewMilestones: "Cho phép khách hàng xem các cột mốc",
            viewGantt: "Cho phép khách hàng xem biểu đồ Gantt",
            viewTimesheets: "Cho phép khách hàng xem biểu đồ thời gian",
            viewActivityLog: "Cho phép khách hàng xem nhật ký hoạt động",
            viewTeamMembers: "Cho phép khách hàng xem các thành viên trong nhóm",
            hideProjectTasks:
            "Ẩn phân công dự án trên bảng phân công chính (khu vực admin) ",
        },
    },
    contact: {
        contacts: "Liên Hệ",
        name: "Họ tên đầy đủ",
        email: "Email",
        position: "Vị trí",
        phone: "Điện thoại",
        active: "Đang hoạt động",
        lastLogin: "Đăng nhập lần cuối",
        avatar: "Ảnh đại diện",
        firstName: "Tên",
        lastName: "Họ",
        direction: "Định hướng",
        password: "Mật khẩu",
        primaryContact: "Liên hệ cơ bản",
        notSendEmail: "Không gửi email chào mừng",
        sendPasswordEmail: "Gửi email đặt mật khẩu",
        permissions: "Quyền hạn",
        permissionsContact: "Xin hãy đảm bảo cấp quyền phù hợp cho liên hệ này",
        invoices: "Hóa đơn",
        estimates: "Báo giá",
        contracts: "Hợp đồng",
        proposals: "Đề xuất kế hoạch",
        support: "Hỗ trợ",
        projects: "Dự án",
        emailNotifications: "Email Notifications",
        invoice: "Hóa đơn",
        estimate: "Báo giá",
        creditNote: "Credit Note",
        project: "Dự án",
        tickets: "Yêu cầu",
        contract: "Hợp đồng",
        task: "Phân công",
        addContact: "Thêm liên hệ mới",
        tooltip: "Chỉ các nhiệm vụ liên quan đến dự án",
        notePassword:
        "Lưu ý: nếu bạn nhập mới những mục này, mật khẩu của liên hệ sẽ bị thay đổi.",
    },
    note: {
        description: "Mô tả",
        addFrom: "Được thêm từ",
        dateAdd: "Ngày thêm",
        options: "Cài đặt",
        cancel: "Hủy",
        update: "Cập nhật ghi chú",
        noteDescription: "Mô tả ghi chú",
        removeNote: "Xóa ghi chú",
    },
    statement: {
        statement: "Statement",
        print: "In",
        pdf: "Xem tệp PDF",
        sendEmail: "Gửi đến Email",
        today: "Hôm nay",
        thisWeek: "Tuần này",
        thisMonth: "Tháng này",
        lastMonth: "Tháng trước",
        thisYear: "Năm này",
        lastYear: "Năm trước",
        period: "Giai đoạn",
    },
    invoice: {
        invoice: "Hóa đơn #",
        amount: "Tổng cộng",
        totalTax: "Tổng thuế",
        date: "Ngày xuất",
        project: "Dự án",
        tags: "Các thẻ",
        dueDate: "Hạn trả",
        status: "Trạng thái",
        outstandingInvoices: "Hóa đơn nổi bật",
        pastDueInvoices: "Hóa đơn quá hạn",
        paidInvoices: "Hóa đơn đã thanh toán",
        title: "Hóa đơn",
        newInvoice: "Tạo hóa đơn mới",
        zipInvoice: "Zip hóa đơn",
        invoiceAvailable: "Các hóa đơn có thể gộp",
        expenseAvailable: "Các khoản thu chi hiện diện có thể xuất biên nhận",
        cheap: "Rẻ",
        expensive: "Đắt",
        newMark: "Đánh dấu hủy các hóa đơn đã hộp thay vì xóa",
        customer: "Khách hàng",
        tag: "Tags",
        paymentInvoice:
            "Cho phép sử dụng nhiều phương thức thanh toán hóa đơn này",
        billTo: "Người nhận",
        shipTo: "Chuyển đến",
        currency: "Đơn vị tiền",
        saleAgent: "Người bán",
        recurringInvoice: "Gửi hóa đơn định kỳ?",
        discountType: "Loại chiết khấu",
        invoiceNumber: "Số hóa đơn",
        adminNote: "Ghi chú của admin",
        invoiceDate: " Ngày xuất",
        preventSending: "Ngăn chặn gửi nhắc nhở hết hạn cho hóa đơn này",
        toolTip:
            "Gộp hóa đơn sẽ tạo khoảng trống thứ tự đánh số. Vui lòng không tiến hành gộp nếu bán không muốn có khoảng trống trong lịch sử hóa đơn. Bạn cũng có thể chỉnh thủ công số thứ tự hóa đơn nếu muốn lấp đầy khoảng trống.",
        titlePrice:
            "Tỉ giá ở trường nhập không được định dạng trong khi sửa/thêm mục mới và không nên để  nguyên như vậy. Cũng đừng định dạng thủ công ở đây.",
        titleTask: "Phân công dự án không kèm trong danh sách này.",
        titleTable:
            "Mô tả sản phẩm không hỗ trợ chức năng thêm dòng. Thay vào đó hãy sử dụng chức năng thêm dài.",
        addItem: "Thêm mục mới",
        billTask: "Phân công biên nhận",
        showQuantity: "Hiển thị số lượng dưới dạng :",
        qty: "SLượng",
        hours: "Giờ ",
        qtyHours: "Số lượng/Giờ ",
        item: "Sản phẩm",
        rateTable: "Giá",
        taxTable: "Thuế",
        subTotal: "Chưa qua thuế - Chi phí",
        discount: "Chiếu khấu",
        adjustment: "Điều chỉnh",
        total: "Thành tiền",
        street: "Đường",
        city: "Thành phố",
        state: "Bang",
        zipCode: "Mã bưu chính",
        country: "Quốc gia",
        titleShipping: "Lấy thông tin giao hàng từ tiểu sử khách hàng",
        shippingAddress: "Địa chỉ nhận hàng",
        showShipping: "Hiển thị phí vận chuyển trong hóa đơn",
        titleAddItem: "Thêm mục mới",
        description: "Mô tả",
        longDescription: "Mô tả dài",
        rate: "Rate - VND (Base Currency)",
        tax1: "Thuế 1",
        tax2: "Thuế 2",
        unit: "Đơn vị",
        itemGroup: "Nhóm sản phẩm",
        btnSave: "Lưu lại",
        btnClose: "Đóng",
        btnSaveDraft: "Lưu thành nháp",
        dropSaveSend: "Lưu và gửi",
        dropSaveSendLater: "Lưu và gửi sau",
        dropSaveRecord: "Lưu & Ghi lại hóa đơn",
        action:{
            createInvoiceSuccess: "Tạo hóa đơn thành công.",
            createInvoiceFailed: "Tạo hóa đơn thất bại.",
            editInvoiceSuccess: "Chỉnh sửa hóa đơn xuất thành công.",
            editInvoiceFailed: "Chỉnh sửa hóa đơn xuất thất bại.",
            deleteInvoiceSuccess: "Xóa hóa đơn thành công.",
            deleteInvoiceFailed: "Xóa hóa đơn thất bại.",
            deleteInvoice: "Xóa hóa đơn",
        },
        listInvoice: "Danh sách hóa đơn",
    },
    payment: {
        title: "Thanh toán",
        zipPayment: "Zip thanh toán",
        payment: "Thanh toán #",
        invoice: "Hóa đơn #",
        paymentMode: "Hình thức thanh toán",
        transaction: "Giao dịch ID",
        amount: "Tổng cộng ",
        date: "Ngày xuất",
        titleZip: "Thanh toán Zip",
        paymentMade: "Thanh toán bởi",
        fromDate: "Từ ngày:",
        toDate: "Đến ngày:",
    },
    proposal: {
        assignTo:"Phân công cho",
        approvalStatus:"Trạng thái phê duyệt",
        title: "Đề xuất kế hoạch",
        newProposal: "Đề xuất kế hoạch mới",
        proposal: "Đề xuất kế hoạch #",
        subject: "Chủ đề",
        total: "Tổng cộng",
        date: "Ngày",
        openTill: "Ngày chốt",
        tags: "Các thẻ",
        dateCreated: "Ngày tạo",
        status: "Trạng thái",
        assigned: "Đã chỉ định",
        related: "Liên quan",
        to: "Đến",
        customer: "Khách hàng",
        lead: "Hướng dẫn",
        address: "Địa chỉ",
        currency: "Đơn vị tiền",
        discountType: "Loại chiết khấu",
        city: "Thành phố",
        state: "Bang",
        country: "Quốc gia",
        zipCode: "Mã bưu chính",
        email: "Email",
        phone: "Điện thoại",
        allowComments: "Cho phép bình luận",
        titleComments:
            "Nếu bạn tích chọn chức năng này , khách hàng xem đề xuất sẽ được quyền đăng bình luận .",
        textSave:
            "Kèm theo các mục cũng như các trường được gộp trong nội dung đề xuất như là {proposal_items}",
        btnSaveSend: "Lưu & Gửi",
        btnSave: "Lưu lại",
        listProposal: "Danh sách đề xuất",
        action:{
            createProposalSuccess: "Tạo đề xuất thành công.",
            createProposalFailure: "Tạo đề xuất thất bại.",
            editProposalSuccess: "Chỉnh sửa đề xuất thành công.",
            editProposalFailure: "Chỉnh sửa đề xuất thất bại.",
            deleteProposalSuccess: "Xóa đề xuất thành công.",
            deleteProposalFailure: "Xóa đề xuất thất bại.",
        }
    },
    creditNote: {
        title: "Ghi chú tín dụng",
        creditAvailable: "Các khoản tín dụng có sẵn .",
        newCreditNote: "Tạo mới ghi chú tín dụng",
        zipCreditNote: "Zip ghi chú tín dụng",
        creditNote: "Ghi chú tín dụng #",
        creditNoteDate: "Ngày ghi chú tín dụng",
        status: "Status",
        project: "Dự án",
        reference: "Tham khảo #",
        amount: "Tổng cộng ",
        remainningAmount: "Remainning Amount",
        customer: "Khách hàng",
        currency: "Tiền tệ",
        discountType: "Loại chiết khấu",
        billTo: "Người nhận",
        shipTo: "Chuyển đến",
        adminNote: "Admin Note",
        clientNote: "Ghi chú khách hàng",
        condition: "Điều khoản & luật lệ",
    },
    estimate: {
        title: "Báo giá",
        newEstimate: "Tạo báo giá mới",
        zipEstimate: "Zip báo giá",
        draft: "Nháp",
        sent: "Đã gửi",
        expired: "Hết hạn",
        declined: "Từ chối",
        accepted: "Chấp nhận",
        estimate: "Bản báo giá #",
        amount: "Tổng cộng",
        totalTax: "Tổng thuế",
        project: "Dự án",
        tag: "Các thẻ",
        date: "Ngày",
        expiryDate: "Ngày hết hạn",
        reference: "Tham khảo #",
        status: "Trạng thái",
        customer: "Khách hàng",
        currency: "Đơn vị tiền",
        billTo: "Người nhận",
        shipTo: "Chuyển đến",
        saleAgent: "Người bán",
        estimateNumber: "Số báo giá",
        estimateDate: "Ngày báo",
        discountType: "Loại chiết khấu",
        adminNote: "Ghi chú admin",
        clientNote: "Ghi chú khách hàng",
        condition: "Điều khoản & luật lệ",
        showShipping: "Hiển thị chi tiết giao hàng trong ước tính",
        deleteTitle:"Xóa báo giá",
        action:{
            createEstimateSuccess:  "Tạo báo giá thành công",
            updateEstimateSuccess:  "Cập nhật báo giá thành công",
            createEstimateFailed:  "Tạo báo giá thất bại",
            updateEstimateFailed:  "Cập nhật báo giá thất bại",
            deleteEstimateSuccess:  "Xóa báo giá thành công",
            deleteEstimateFailed:  "Xóa báo giá thất bại",
            deleteEstimates: "Xóa báo giá",
        }
    },
    subscription: {
        title: "Đăng ký",
        newSubscription: "Tạo đăng ký mới",
        subscriptionName: "Tên đăng ký",
        project: "Dự án",
        status: "Trạng thái",
        nextBillingCycle: "Chu kỳ thanh toán tiếp theo",
        dateSubscribed: "ngày đã đăng ký",
        textHeader:
            "Khóa API chưa được định cấu hình, hãy nhấp vào liên kết sau để định cấu hình khóa API :",
        billingPlan: "Gói thanh toán",
        quantity: "Số lượng",
        toolTipDate:
            "Để trống ngày sử dụng khi khách hàng đăng ký thuê bao. Trường này phải là ngày trong tương lai, nếu bạn chọn ngày và ngày đã qua nhưng khách hàng chưa đăng ký thì ngày mà khách hàng đăng ký sẽ được sử dụng.",
        firstBillingDate: "Ngày thanh toán đầu tiên",
        description: "Mô tả",
        toolTipCheckBox:
            "Hữu ích nếu bạn muốn bao gồm thông tin bổ sung trên hóa đơn đăng ký, e.q. đăng ký này bao gồm những gì.",
        includeDescription: "Bao gồm mô tả trong mục hóa đơn",
        customer: "Khách hàng ",
        currency: "Đơn vị tiền",
        tax: "Thuế  (Stripe)",
        termsConditions: "Điều khoản và điều kiện",
        placeHoderTerms:
        " Nhập các điều khoản và điều kiện của khách hàng sẽ được hiển thị cho khách hàng trước khi đăng ký đăng ký.",
    },
    expense: {
        title: "Chi phí",
        recordExpense: "Báo cáo chi phí",
        total: "Tổng cộng",
        billable: "Có thể tạo biên nhận",
        nonBillable: "Không thể tạo biên nhận",
        notInvoiced: "Chưa xuất hóa đơn",
        billed: "Đã xuất biên nhận",
        category: "Phân loại",
        amount: "Số tiền ",
        name: "Tên",
        expenseReceipt: "biên lai chi phí",
        date: "Ngày",
        project: "Dự án",
        invoice: "Hóa đơn",
        reference: "Tài liệu tham khảo #",
        newExpense: "Thêm Chi phí mới",
        attachReceipt: "Đính kèm biên lai",
        tooltipName:
            "Đối với mục đích sử dụng cá nhân - Nếu có thể thanh toán, Tên có thể được thêm vào mô tả dài của hóa đơn.",
        tooltipNote:
            "Nếu có thể lập hóa đơn, có thể thêm Ghi chú vào phần mô tả dài của hóa đơn .",
        note: "Ghi chú",
        expenseCategory: " Phân loại Chi phí",
        expenseDate: "Ngày chi",
        customer: "Khách hàng",
        advancedOption: "Chức năng nâng cao",
        currency: "Đơn vị tiền",
        tax1: "Thuế 1",
        tax2: "Thuế 2",
        paymentMode: "Phương thức thanh toán",
        repeatEvery: "Lặp lại mỗi",
        titleAddExpense: "Danh mục mới",
        categoryName: "Tên phân loại",
        categoryDescription: "Mô tả phân loại",
    },

    reminder: {
        headerReminder: "Nhắc nhở",
        tooltipTitle:
            "Chức năng này cho phép bạn không bao giờ quên mọi thứ về khách hàng.",
        title: "Đặt nhắc nhở",
        export: "Xuất ra",
        dateNotified: "Ngày cần thông báo",
        setReminder: "Chuyển nhắc nhở sang",
        description: "Mô tả",
        sendMail: "Gửi email cho cả nhắc nhở này",
        buttonClose: "Đóng",
        buttonSave: "Lưu lại",
        buttonReload: "Tải lại",
        dropdownNumber: "Tất cả",
        dropdownExport: "In",
        table: {
            description: "Mô tả",
            date: "Ngày",
            remind: "Nhắc nhở",
            notified: "Được thông báo?",
            entriesFound: "Không tìm thấy các mục",
        },
        removeReminder: "Xóa nhắc nhở",
    },
    vault: {
        headerVault: "Vault",
        buttonVault: "Mục Vault Mới",
        vaultEntry: "Mục vault",
        serverAddress: "Địa chỉ máy chủ",
        port: "Cổng",
        username: "Tên người dùng",
        password: "Mật khẩu",
        shortDescription: "Mô tả ngắn",
        visibleAll: "Hiển thị cho những nhân viên đã truy cập khách hàng này",
        visibleAdmin: "Chỉ hiển thị với ban quản trị",
        visibleMe: "Chỉ hiển thị với tôi (và người quản trị)",
        shareVault:
            "Chia sẻ mục nhập vault này trong các dự án với các thành viên dự án.",
        buttonClose: "Đóng",
        buttonSave: "Lưu Lại",
        vaultEntryCustomer: "Vault entries not found for this customer.",
        createdBy: "Mục vault được tạo bởi",
        viewPassword: "Xem Mật Khẩu",
        enterPassword:
            "Vì lý do an ninh xin hãy nhập mật khẩu của bạn trong mục sau",
        buttonConfirm: "Xác nhận",
    },
    tasks: {
        headerTasks: "Phân công",
        buttonTasks: "Phân công mới",
        headerbtnTasks: "Thêm phân công mới",
        checkboxPublic: "Công khai",
        checkboxBillable: "Có thể xuất biên nhận",
        attachFiles: "Attach Files",
        attachment: "Tập tin đính kèm",
        subject: "Chủ đề",
        hourlyRate: "Tiền công theo giờ",
        startDate: "Ngày bắt đầu",
        dueDate: "Hạn chót",
        priority: "Mức độ ưu tiên",
        repeatEvery: "Lặp lại mỗi",
        relatedTo: "Liên quan đến",
        customer: "Khách hàng",
        insertListTemplates: "Chèn mẫu danh sách kiểm tra",
        nothingSelected: "Không có gì được chọn",
        selectAll: "Chọn tất cả",
        deselectAll: "Bỏ chọn tất cả",
        tags: "Các thẻ",
        taskDescription: "Mô tả công việc",
        btnClose: "Đóng",
        btnSave: "Lưu lại",
        tooltipFilter: "Được lọc theo",
        filterAll: "Tất cả",
        filterNotStarted: "Chưa bắt đầu",
        filterInprogress: "Đang tiến hành",
        filterTesting: "Đang kiểm tra",
        filterAwaitingFeedback: "Đang chờ phản hồi",
        filterComplete: "Đã hoàn thành",
        filterTodayTasks: "Nhiệm vụ hôm nay",
        filterDueDatePassed: "Quá hạn",
        checkboxCustomer: "khách hàng",
        checkboxProjects: "Các dự án",
        checkboxInvoices: "Hoá đơn",
        checkboxEstimates: "Báo giá",
        checkboxContracts: "Hợp đồng",
        checkboxTickets: "Yêu cầu",
        checkboxExpenses: "Chi phí",
        checkboxProposals: "Đề xuất kế hoạch",
        export: "Xuất ra",
        search: "Tìm kiếm",
        related: {
            customer: "Khách hàng",
            projects: "Các dự án",
            invoices: "Hóa đơn",
            estimates: "Báo giá",
            contracts: "Hợp đồng",
            tickets: "Yêu cầu",
            expenses: "Chi phí",
            proposals: "Mức độ ưu tiên",
        },
        table: {
            tableName: "Tên",
            tableStatus: "Trạng thái",
            tableStartDate: "Ngày bắt đầu",
            tableDueDate: "Ngày chốt",
            tableAssignedTo: "Đã chỉ định cho",
            tableTags: "Các thẻ",
            tablePriority: "Mức độ ưu tiên",
        },
    },
    contracts: {
        deleteEstimates: "Xóa hợp đồng",
        confirmDelete: "Bạn chắc chắn muốn thực hiện thao tác này ?",
        titleContracts: "Hợp đồng",
        btnContracts: "Hợp đồng mới",
        btnNewContracts: {
            checkboxTrash: "Thùng rác",
            tooltipTrash:
            "Nếu bạn chuyển hợp đồng vào thùng rác, hợp đồng sẽ không hiển thị bên phía khách hàng, không đính kèm trong thống kế biểu đồ và, như mặc định, không xuất hiện nếu bạn liệt kê danh sách hợp đồng.",
            checkboxHideCustomer: "Ẩn với khách hàng",
            inputCustomer: "Khách hàng",
            inputSubject: "Chủ đề",            
            tooltipSubject: "Khách hàng cũng sẽ nhìn thấy chủ đề này.",
            inputContractValue: "Giá trị hợp đồng",
            inputContractType: "Loại hợp đồng",
            inputStartDate: "Ngày bắt đầu",
            inputEndDate: "Ngày kết thúc",
            inputDescription: "Mô tả",
            btnSave: "Lưu Lại",
            dialogContractType: {
            titleContractType: "Loại hợp đồng mới",
            inputName: "Tên",
            btnClose: "Đóng",
            btnSave: "Lưu Lại",
            btnDelete:"Xoá"
            },
        },
        btnExport: "Xuất ra",
        btnReload: "Tải lại",
        btnSearch: "Tìm kiếm",
        table: {
            id: "#",
            subject: "Chủ đề",
            contractType: "Loại hợp đồng",
            contractValue: "Giá trị hợp đồng",
            startDate: "Ngày bắt đầu",
            endDate: "Ngày kết thúc",
            signature: "Chữ ký",
        },
        btnSign: {
            titleSign: "Chữ ký & Xác nhận Danh tính",
            firtName: "Tên riêng",
            lastName: "Họ",
            email: "Email",
            signature: "Chữ ký",
            btnClear: "Xóa",
            btnUndo: "Hoàn tác",
            confirmSign:
            "Bằng cách nhấp vào 'KÝ TÊN', tôi đồng ý bị ràng buộc về mặt pháp lý bởi bản trình bày chữ ký điện tử này của tôi.",
            btnCancel: "Hủy",
            btnConfirmSign: "Ký tên",
        },
        nameAction: {
            view: "Xem",
            edit: "Sửa",
            delete: "Xóa",
        },
    },
    files: {
        titleFiles: "Các tập tin",
        textTitleFiles:
            "Các tệp từ các dự án và nhiệm vụ được liên kết với khách hàng không được hiển thị trên bảng này.",
        formUpload: "Thả tập tin vào đây để tải lên",
        btnExport: "Xuất ra",
        btnSearch: "Tìm kiếm",
        tableFile: {
            columnsFile: "Tập tin",
            columnsCustomersArea: "Hiển thị khu vực khách hàng",
            columnsDateUpload: "Ngày đăng",
            columnsOptions: "Cài đặt",
        },
    },
    tickets: {
        titleTickets: "Yêu cầu hỗ trợ",
        btnNewTicket: "Yêu cầu mới",
        btnExport: "Xuất ra",
        btnSearch: "Tìm kiếm",
        tableTickets: {
            columnsId: "#",
            columnsSubject: "Chủ đề",
            columnsTags: "Các thẻ",
            columnsDepartment: "Phòng ban",
            columnsService: "Dịch vụ",
            columnsContact: "Khách hàng",
            columnsStatus: "Trạng thái",
            columnsPrioty: "Mức độ ưu tiên",
            columnsLastReply: "Phản hồi lần cuối",
            columnsCreated: "Ngày tạo",
        },
    },
  },
};
