import ActionTypes from "./types";

export const fetchListEstimateSaleRequest = (value, params) => {
  return {
    type: ActionTypes.FETCH_ESTIMATES_SALE_REQUEST,
    value,
    params,
  };
};

export const fetchListEstimateSaleSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_ESTIMATES_SALE_SUCCESS,
    payload: data,
  };
};

export const fetchListEstimateSaleFailed = () => {
  return {
    type: ActionTypes.FETCH_ESTIMATES_SALE_FAILED,
  };
};

export const listSelectCustomerRequest = (value) => {
  return {
    type: ActionTypes.LIST_SELECT_CUSTOMER_REQUEST,
    value,
  };
};
export const listSelectCustomerSuccess = (value) => {
  return {
    type: ActionTypes.LIST_SELECT_CUSTOMER_SUCCESS,
    value,
  };
};
export const listSelectCustomerFailed = (value) => {
  return {
    type: ActionTypes.LIST_SELECT_CUSTOMER_FAILED,
    value,
  };
};

export const getCustomFieldEstimateRequest = () => {
  return {
    type: ActionTypes.GET_CUSTOM_FIELD_REQUEST,
  };
};
export const getCustomFieldEstimateSuccess = (data) => {
  return {
    type: ActionTypes.GET_CUSTOM_FIELD_SUCCESS,
    data,
  };
};
export const getCustomFieldEstimateFailed = () => {
  return {
    type: ActionTypes.GET_CUSTOM_FIELD_REQUEST_FAILED,
  };
};

// export const addEstimateCustomerRequest = (customer_id, data) => {
//   return {
//     type: ActionTypes.ADD_ESTIMATE_CUSTOMER_REQUEST,
//     payload: {
//       customer_id,
//       data,
//     },
//     isLoading: true,
//   };
// };


// export const addEstimateCustomerSuccess = (data) => {
//   return {
//     type: ActionTypes.ADD_ESTIMATE_CUSTOMER_SUCCESS,
//     data,
//     isLoading: false,
//   };
// };

// export const addEstimateCustomerFailed = () => {
//   return {
//     type: ActionTypes.ADD_ESTIMATE_CUSTOMER_FAILED,
//     isLoading: false,
//   };
// };

export const addEstimateRequest = (data) => {
  return {
    type: ActionTypes.ADD_ESTIMATE_REQUEST,
    payload: data,
  };
};

export const addEstimateSuccess = (data) => {
  return {
    type: ActionTypes.ADD_ESTIMATE_SUCCESS,
    data,
  };
};

export const addEstimateFailed = () => {
  return {
    type: ActionTypes.ADD_ESTIMATE_FAILED,
  };
};

export const getEstimateById = (data) => {
    return {
        type: ActionTypes.GET_ESTIMATE_BY_ID,
        payload: data,
    };
};

export const getEstimateByIdSuccess = (data) => {
    return {
        type: ActionTypes.GET_ESTIMATE_BY_ID_SUCCESS,
        payload: data,
    };
};

export const getEstimateByIdFailed = () => {
    return {
        type: ActionTypes.GET_ESTIMATE_BY_ID_FAILED,
    };
};

export const deleteEstimate = (data) => {
    return {
        type: ActionTypes.DELETE_ESTIMATE,
        payload: data,
    };
};

export const deleteEstimateSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_ESTIMATE_SUCCESS,
        payload: data,
    };
};

export const deleteEstimateFailed = () => {
    return {
        type: ActionTypes.DELETE_ESTIMATE_FAILED,
    };
};

export const editEstimateFailed = () => {
  return {
      type: ActionTypes.EDIT_ESTIMATE_FAILED,
  };
};
export const editEstimateRequest = (data) => {
  return {
      type: ActionTypes.EDIT_ESTIMATE_REQUEST,
      payload: data,
  };
};

export const editEstimateSuccess = () => {
  return {
      type: ActionTypes.EDIT_ESTIMATE_SUCCESS,
  };
};

