import React from "react";
import { PrivateRoute } from "./PrivateRoute";
import i18n from "../i18n.js";
import { project, task } from "../constants/Route.js";

const Project = React.lazy(() => import("../pages/Project"));
const ProjectEdit = React.lazy(() => import("../pages/Project/Edit"));
 
const ProjectDetail = React.lazy(() => import("../pages/Project/Detail"));
const AddNewProject = React.lazy(() => import("../pages/Project/Add/NewProject.js"));
const ProjectNewTicket = React.lazy(() => import("../pages/Project/Ticket/NewTicket.js"));
const ProjectSettingTicket = React.lazy(() => import("../pages/Support/Tab.js"));
const ProjectSummaryProfile = React.lazy(() => import("../pages/Support/SummaryProfile.js"));
const Task = React.lazy(() => import("../pages/Task/index.js"));

export const projectsRoute = [
    {
        name: `${i18n.t("route.project")}`,
        route: PrivateRoute,
        roles: ["Director","Admin", "Staff","SaleStaff"],
        title: `${i18n.t("route.project")}`,
        childs: [
            {
                path: project.index,
                name: `${i18n.t("route.project")}`,
                component: Project,
                route: PrivateRoute,
                roles: ["Director" ],
                title: `${i18n.t("route.project")}`,
                exact: true
            },
            {
                path: task.index,
                name: `${i18n.t("route.task")}`,
                component: Task,
                route: PrivateRoute,
                roles: ["Director","Admin", "Staff","SaleStaff"],
                title: `${i18n.t("route.task")}`,
                exact: true,
            }
        ]
        
    },
    {
        path: "/new-project",
        name: `New Project`,
        component: AddNewProject,
        route: PrivateRoute,
        roles: ["Director","projects"],
        title: "New Project",
        exact: true,
    },
    {
        path: "/project/ticket/new/:projectId",
        name: "project_ticket_new",
        component: ProjectNewTicket,
        route: PrivateRoute,
        roles: ["Director","ticket"],
        title: "New ticket",
        exact: true,
    },
    {
        path: "/project/ticket/view/profile",
        name: `Profile View Ticket`,
        component: ProjectSummaryProfile,
        route: PrivateRoute,
        roles: ["Director","ticket"],
        title: "Profile View ticket",
        exact: true,
    },
    {
        path: "/project/:id",
        name: `Project Overview`,
        component: ProjectDetail,
        route: PrivateRoute,
        roles: ["Director","projects"],
        title: "Project Overview",
        exact: true,
    },
    {
        path: "/project/edit/:id",
        name: `Project Edit`,
        component: ProjectEdit,
        route: PrivateRoute,
        roles: ["Director","projects"],
        title: "Project Edit",
        exact: true,
    },
    {
        path: "/ticket/:id",
        name: `View Ticket`,
        component: ProjectSettingTicket,
        route: PrivateRoute,
        roles: ["Director","ticket"],
        title: "View ticket",
        exact: true,
    },
]