import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import i18n from '../../../../i18n';

import ActionTypes from "./types";

import { 
    fetchEstimatesProjectSuccess,
    fetchEstimatesProjectFailed,
    fetchFilterYearEstimatePrjSuccess,
    fetchFilterYearEstimatePrjFailed,
    fetchTotalEstimatePrjSuccess,
    fetchTotalEstimatePrjFailed,
    fetchFilterEstimateSuccess,
    fetchFilterEstimateFailed,
} from "./actions";
import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import { 
    getEstimatesProject,
    filterYearEstimatePrj,
    getTotalEstimatePrj,
    filterEstimatePrj,
} from "./api";
import { responeCode, typeMessage } from "../../../../constants/Const";
import {
    throwErrorMessage,
    parseDataNotifications,
} from "../../../../helpers/HandleError";

function* fetchEstimatesProjectSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(getEstimatesProject, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success &&
            response.data.result &&
            response.data.result.data
        ) {
            yield put(fetchEstimatesProjectSuccess(response.data.result));
        } else {
            yield put(fetchEstimatesProjectFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchEstimatesProjectFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* filterYearEstimatePrjSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(filterYearEstimatePrj, payload);
        if (response && response.data) {
            yield put(fetchFilterYearEstimatePrjSuccess(response.data));
        } else {
            yield put(fetchFilterYearEstimatePrjFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchFilterYearEstimatePrjFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchTotalEstimatePrjSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(getTotalEstimatePrj, payload);
        if (response && response.data) {
            yield put(fetchTotalEstimatePrjSuccess(response.data));
        } else {
            yield put(fetchTotalEstimatePrjFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchTotalEstimatePrjFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* filterEstimatePrjSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(filterEstimatePrj, payload);
        if (response && response.data && response.data.result) {
            yield put(fetchFilterEstimateSuccess(response.data.result));
        } else {
            yield put(fetchFilterEstimateFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchFilterEstimateFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

export function* estimateProjectSaga() {
    yield takeEvery(ActionTypes.FETCH_ESTIMATES_PROJECT, fetchEstimatesProjectSaga);
    yield takeEvery(ActionTypes.FETCH_FILTER_YEAR_ESTIMATE_PRJ, filterYearEstimatePrjSaga);
    yield takeEvery(ActionTypes.FETCH_TOTAL_ESTIMATE_PRJ, fetchTotalEstimatePrjSaga);
    yield takeEvery(ActionTypes.FILTER_ESTIMATE, filterEstimatePrjSaga);
}


function* EstimateProjectSaga() {
    yield all([fork(estimateProjectSaga)]);
}

export default EstimateProjectSaga;