import ActionTypes from "./type";

const INIT_STATE = {
    listContractType: [],
    isLoading: false,
    closeDialog: false,
    deleteGroup: false,
};
const AdmintratorContractTypeReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_CONTRACT_TYPE_PENDING:
            return { ...state, isLoading: true };
        case ActionTypes.GET_CONTRACT_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listContractType: action.payload.data,
            };
        case ActionTypes.GET_CONTRACT_TYPE_FAILED:
            return { ...state, isLoading: false };
        case ActionTypes.ADD_CONTRACT_TYPE_PENDING:
            return { ...state, isLoading: true };
        case ActionTypes.ADD_CONTRACT_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.ADD_CONTRACT_TYPE_FAILED:
            return { ...state, isLoading: false };
        case ActionTypes.PUT_CONTRACT_TYPE_PENDING:
            return { ...state, isLoading: true };
        case ActionTypes.PUT_CONTRACT_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.PUT_CONTRACT_TYPE_FAILED:
            return { ...state, isLoading: false };
        case ActionTypes.DELETE_CONTRACT_TYPE_PENDING:
            return { ...state, isLoading: true };
        case ActionTypes.DELETE_CONTRACT_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.DELETE_CONTRACT_TYPE_FAILED:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
export default AdmintratorContractTypeReducer;
