const ActionTypes = {
    GET_LIST_CREDIT_NOTE_PENDING: "GET_LIST_CREDIT_NOTE_PENDING",
    GET_LIST_CREDIT_NOTE_SUCCESS: "GET_LIST_CREDIT_NOTE_SUCCESS",
    GET_LIST_CREDIT_NOTE_FAILED: "GET_LIST_CREDIT_NOTE_FAILED",
    GET_LIST_CREDIT_NOTE_CUSTOMER_PENDING: "GET_LIST_CREDIT_NOTE_CUSTOMER_PENDING",
    GET_LIST_CREDIT_NOTE_CUSTOMER_SUCCESS: "GET_LIST_CREDIT_NOTE_CUSTOMER_SUCCESS",
    GET_LIST_CREDIT_NOTE_CUSTOMER_FAILED: "GET_LIST_CREDIT_NOTE_CUSTOMER_FAILED",
    ADD_CREDIT_NOTE_REQUEST: "ADD_CREDIT_NOTE_REQUEST",
    ADD_CREDIT_NOTE_SUCCESS: "ADD_CREDIT_NOTE_SUCCESS",
    ADD_CREDIT_NOTE_FAILED: "ADD_CREDIT_NOTE_FAILED",
    GET_CREDIT_NOTE_BY_ID: "GET_CREDIT_NOTE_BY_ID",
    GET_CREDIT_NOTE_BY_ID_SUCCESS: "GET_CREDIT_NOTE_BY_ID_SUCCESS",
    GET_CREDIT_NOTE_BY_ID_FAILED: "GET_CREDIT_NOTE_BY_ID_FAILED",
    DELETE_CREDIT_NOTE: "DELETE_CREDIT_NOTE",
    DELETE_CREDIT_NOTE_SUCCESS: "DELETE_CREDIT_NOTE_SUCCESS",
    DELETE_CREDIT_NOTE_FAILED: "DELETE_CREDIT_NOTE_FAILED",
    EDIT_CREDIT_NOTE_REQUEST: "EDIT_CREDIT_NOTE_REQUEST",
    EDIT_CREDIT_NOTE_SUCCESS: "EDIT_CREDIT_NOTE_SUCCESS",
    EDIT_CREDIT_NOTE_FAILED: "EDIT_CREDIT_NOTE_FAILED",
    GET_SALE_CREDIT_NOTES_REQUEST: "GET_SALE_CREDIT_NOTES_REQUEST",
    GET_SALE_CREDIT_NOTES_SUCCESS: "GET_SALE_CREDIT_NOTES_SUCCESS",
    GET_SALE_CREDIT_NOTES_FAILED: "GET_SALE_CREDIT_NOTES_FAILED",
    RESET_CREDIT_NOTE_SUCCESS: "RESET_CREDIT_NOTE_SUCCESS",    
};
export default ActionTypes;