import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    getCustomFieldInvoiceSuccess,
    getCustomFieldInvoiceFailed,
    addInvoiceSuccess,
    addInvoiceFailed,
    getInvoiceByIdSuccess,
    getInvoiceByIdFailed,
    editInvoiceSuccess,
    deleteInvoiceSuccess,
    deleteInvoiceFailed,
    editInvoiceFailed,
} from "./action";
import i18n from 'i18next';
import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import { addInvoiceRequest, deleteInvoiceRequest, editInvoice, getInvoiceByIdRequest, getInvoiceSaleRequest } from "./api";
import { responeCode, typeMessage } from "../../../../constants/Const";
import ActionTypes from "./types";
import {
    throwErrorMessage,
    parseDataNotifications,
} from "../../../../helpers/HandleError";

function* listCustomField(data) {
    try {
        const response = yield call(getInvoiceSaleRequest, data);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getCustomFieldInvoiceSuccess(response.data.result));
        } else {
            yield put(getCustomFieldInvoiceFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getCustomFieldInvoiceFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* addInvoiceSaga(action) {
    console.log("action ", action)
    try {
        const response = yield call(addInvoiceRequest, action.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addInvoiceSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('customer.invoice.action.createInvoiceSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(addInvoiceFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('customer.invoice.action.createInvoiceFailed'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(addInvoiceFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchInvoiceById(data) {
    const { payload } = data;
    try {
        const response = yield call(getInvoiceByIdRequest, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getInvoiceByIdSuccess(response.data.result));
        } else {
            yield put(getInvoiceByIdFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getInvoiceByIdFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* editInvoiceSaga(action) {
    try {
        const response = yield call(editInvoice, action.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(editInvoiceSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('customer.invoice.action.editInvoiceSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(editInvoiceFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('customer.invoice.action.editInvoiceFailed'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(editInvoiceFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteInvoiceSaga(data) {
    try {
        const response = yield call(deleteInvoiceRequest, data.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteInvoiceSuccess(response.data.result));

            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('customer.invoice.action.deleteInvoiceSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteInvoiceFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('customer.invoice.action.deleteInvoiceFailed'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteInvoiceFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* invoiceSaga() {
    yield takeEvery( ActionTypes.GET_SALE_INVOICE_REQUEST, listCustomField);
    yield takeEvery( ActionTypes.ADD_INVOICE_REQUEST, addInvoiceSaga);
    yield takeEvery( ActionTypes.GET_INVOICE_BY_ID, fetchInvoiceById);
    yield takeEvery( ActionTypes.EDIT_INVOICE_REQUEST, editInvoiceSaga);
    yield takeEvery( ActionTypes.DELETE_INVOICE, deleteInvoiceSaga);
}

function* SaleInvoiceSaga() {
    yield all([fork(invoiceSaga)]);
}

export default SaleInvoiceSaga;
