import ActionTypes from "./type";

const INIT_STATE = {
  listWareHouse: [],
  isLoading: false,
  statusAddWareHouse: false,
  statusDelete: false,
  statusEditWareHouse: false,
};

const WareHouseReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_WAREHOUSE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_WAREHOUSE_SUCCESS:
      return {
        ...state,
        listWareHouse: action.data,
        isLoading: false,
      };
    case ActionTypes.FETCH_WAREHOUSE_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.NEW_WAREHOUSE_REQUEST:
      return {
        ...state,
        isLoading: true,
        statusAddWareHouse: false,
      };
    case ActionTypes.NEW_WAREHOUSE_SUCCESS:
      return {
        ...state,
        statusAddWareHouse: true,
        isLoading: false,
      };
    case ActionTypes.NEW_WAREHOUSE_FAILED:
      return {
        ...state,
        isLoading: false,
        statusAddWareHouse: false,
      };

    case ActionTypes.EDIT_WAREHOUSE_REQUEST:
      return {
        ...state,
        isLoading: true,
        statusEditWareHouse: false,
      };
    case ActionTypes.EDIT_WAREHOUSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        statusEditWareHouse: true,
      };
    case ActionTypes.EDIT_WAREHOUSE_FAILED:
      return {
        ...state,
        isLoading: false,
        statusEditWareHouse: false,
      };
    case ActionTypes.DELETE_WAREHOUSE_REQUEST:
      return {
        ...state,
        isLoading: true,
        statusDelete: false,
      };
    case ActionTypes.DELETE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        statusDelete: true,
      };
    case ActionTypes.DELETE_WAREHOUSE_FAILED:
      return {
        ...state,
        isLoading: false,
        statusDelete: false,
      };
    default:
      return { ...state };
  }
};

export default WareHouseReducer;
