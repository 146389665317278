import React from "react";
import { PrivateRoute } from "../PrivateRoute.js";
import i18n from "../../i18n.js";

// Administrator/Settings
const SettingCalendar = React.lazy(() => import("../../pages/Administrator/Setting/Calendar/index.js"));
const SettingCompany = React.lazy(() => import("../../pages/Administrator/Setting/Company/index.js"));
const SettingCustomers = React.lazy(() => import("../../pages/Administrator/Setting/Customers/index.js"));
const SettingCronJob = React.lazy(() => import("../../pages/Administrator/Setting/CronJob/index.js"));
const SettingEmail = React.lazy(() => import("../../pages/Administrator/Setting/Email/index.js"));
const SettingEsign = React.lazy(() => import("../../pages/Administrator/Setting/Esign/index.js"));
const SettingFinance = React.lazy(() => import("../../pages/Administrator/Setting/Finance/index.js"));
const SettingGoogle = React.lazy(() => import("../../pages/Administrator/Setting/Google/index.js"));
const SettingLocalization = React.lazy(() => import("../../pages/Administrator/Setting/Localization/index.js"));
const SettingLeads = React.lazy(() => import("../../pages/Administrator/Setting/Leads/index.js"));
const SettingMisc = React.lazy(() => import("../../pages/Administrator/Setting/Misc/index.js"));
const SettingPDF = React.lazy(() => import("../../pages/Administrator/Setting/PDF/index.js"));
const SettingPaymentGateways = React.lazy(() => import("../../pages/Administrator/Setting/Payment/index.js"));
const SettingPusher = React.lazy(() => import("../../pages/Administrator/Setting/Pusher/index.js"));
const SettingSupport = React.lazy(() => import("../../pages/Administrator/Setting/Support/index.js"));
const SettingSystemInfo = React.lazy(() => import("../../pages/Administrator/Setting/SystemInfo/index.js"));
const SettingSystemUpdate = React.lazy(() => import("../../pages/Administrator/Setting/SystemUpdate/index.js"));
const SettingSubscriptions = React.lazy(() => import("../../pages/Administrator/Setting/Subscriptions/index.js"));
const SettingTags = React.lazy(() => import("../../pages/Administrator/Setting/Tags/index.js"));
const SettingTasks = React.lazy(() => import("../../pages/Administrator/Setting/Tasks/index.js"));
const SettingSMS = React.lazy(() => import("../../pages/Administrator/Setting/SMS/index.js"));
const SettingGeneral = React.lazy(() => import("../../pages/Administrator/Setting/General"));

export const settingRoute = [
    {
        path: "/administrator/setting",
        name: `${i18n.t("route.setting")}`,
        component: SettingGeneral,
        route: PrivateRoute,
        roles: ["Director","profile" , "Admin", "MENU"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=general",
        name: "administrator_setting_group=general",
        component: SettingGeneral,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=company",
        name: "administrator_setting_group=company",
        component: SettingCompany,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=localization",
        name: "administrator_setting_group=localization",
        component: SettingLocalization,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=email",
        name: "administrator_setting_group=email",
        component: SettingEmail,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=finance",
        name: "administrator_setting_group=finance",
        component: SettingFinance,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=subscriptions",
        name: "administrator_setting_group=subscriptions",
        component: SettingSubscriptions,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=paymentGateways",
        name: "administrator_setting_group=paymentGateways",
        component: SettingPaymentGateways,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=customers",
        name: "administrator_setting_group=customers",
        component: SettingCustomers,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=tasks",
        name: "administrator-setting-group=tasks",
        component: SettingTasks,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=tickets",
        name: "administrator_setting_group=tickets",
        component: SettingSupport,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=leads",
        name: "administrator_setting_group=leads",
        component: SettingLeads,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=sms",
        name: "administrator_setting_group=sms",
        component: SettingSMS,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },{
        path: "/administrator/setting/group=calendar",
        name: "administrator_setting_group=calendar",
        component: SettingCalendar,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=pdf",
        name: "administrator_setting_group=pdf",
        component: SettingPDF,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=eSign",
        name: "administrator_setting_group=eSign",
        component: SettingEsign,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=cronJob",
        name: "administrator_setting_group=cronJob",
        component: SettingCronJob,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=tags",
        name: "administrator_setting_group=tags",
        component: SettingTags,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=pusher",
        name: "administrator_setting_group=pusher",
        component: SettingPusher,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=google",
        name: `administrator_setting_group=google`,
        component: SettingGoogle,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=misc",
        name: "administrator_setting_group=misc",
        component: SettingMisc,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=update",
        name: "administrator_setting_group=update",
        component: SettingSystemUpdate,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=info",
        name: "administrator_setting_group=info",
        component: SettingSystemInfo,
        route: PrivateRoute,
        roles: ["Director","profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
]