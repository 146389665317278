const ActionTypes = {
    FETCH_SALES_ITEM_PENDING: "FETCH_SALES_ITEM_PENDING",
    FETCH_SALES_ITEM_SUCCESS: "FETCH_SALES_ITEM_SUCCESS",
    FETCH_SALES_ITEM_FAILURE: "FETCH_SALES_ITEM_FAILURE",
    
    FETCH_SALES_TAX_PENDING: "FETCH_SALES_TAX_PENDING",
    FETCH_SALES_TAX_SUCCESS: "FETCH_SALES_TAX_SUCCESS",
    FETCH_SALES_TAX_FAILURE: "FETCH_SALES_TAX_FAILURE",
    
    FETCH_LIST_ITEM_GROUPS_PENDING: "FETCH_LIST_ITEM_GROUPS_PENDING",
    FETCH_LIST_ITEM_GROUPS_SUCCESS: "FETCH_LIST_ITEM_GROUPS_SUCCESS",
    FETCH_LIST_ITEM_GROUPS_FAILURE: "FETCH_LIST_ITEM_GROUPS_FAILURE",
    
    ADD_NEW_ITEM_PENDING: "ADD_NEW_ITEM_PENDING",
    ADD_NEW_ITEM_SUCCESS: "ADD_NEW_ITEM_SUCCESS",
    ADD_NEW_ITEM_FAILURE: "ADD_NEW_ITEM_FAILURE",    
};
export default ActionTypes;