const ActionTypes = {
    GET_TASK: "GET_TASK",
    GET_TASK_SUCCESS: "GET_TASK_SUCCESS",
    GET_TASK_FAILED: "GET_TASK_FAILED",

    GET_TASK_BY_LEAD: "GET_TASK_BY_LEAD",
    GET_TASK_BY_LEAD_SUCCESS: "GET_TASK_BY_LEAD_SUCCESS",
    GET_TASK_BY_LEAD_FAILED: "GET_TASK_BY_LEAD_FAILED",

    CREATE_NEW_TASK: "CREATE_NEW_TASK",
    CREATE_NEW_TASK_SUCCESS: "CREATE_NEW_TASK_SUCCESS",
    CREATE_NEW_TASK_FAILED: "CREATE_NEW_TASK_FAILED",

    FETCH_STATUS_LEADS: "FETCH_STATUS_LEADS",
    FETCH_STATUS_LEADS_SUCCESS: "FETCH_STATUS_LEADS_SUCCESS",
    FETCH_STATUS_LEADS_FAILED: "FETCH_STATUS_LEADS_FAILED",

    CHANGE_TASK_PRIORITY: "CHANGE_TASK_PRIORITY",
    CHANGE_TASK_PRIORITY_SUCCESS: "CHANGE_TASK_PRIORITY_SUCCESS",
    CHANGE_TASK_PRIORITY_FAILED: "CHANGE_TASK_PRIORITY_FAILED",

    CHANGE_TASK_STATUS: "CHANGE_TASK_STATUS",
    CHANGE_TASK_STATUS_SUCCESS: "CHANGE_TASK_STATUS_SUCCESS",
    CHANGE_TASK_STATUS_FAILED: "CHANGE_TASK_STATUS_FAILED",

    GET_COUNT_TASK: "GET_COUNT_TASK",
    GET_COUNT_TASK_SUCCESS: "GET_COUNT_TASK_SUCCESS",
    GET_COUNT_TASK_FAILED: "GET_COUNT_TASK_FAILED",

    EDIT_TASK: "EDIT_TASK",
    EDIT_TASK_SUCCESS: "EDIT_TASK_SUCCESS",
    EDIT_TASK_FAILED: "EDIT_TASK_FAILED",

    DELETE_TASK: "DELETE_TASK",
    DELETE_TASK_SUCCESS: "DELETE_TASK_SUCCESS",
    DELETE_TASK_FAILED: "DELETE_TASK_FAILED",

    SEARCH_RELATED: "SEARCH_RELATED",
    SEARCH_RELATED_SUCCESS: "SEARCH_RELATED_SUCCESS",
    SEARCH_RELATED_FAILED: "SEARCH_RELATED_FAILED",

    SEARCH_RELATED_BY_FILTER: "SEARCH_RELATED_BY_FILTER",
    SEARCH_RELATED_BY_FILTER_SUCCESS: "SEARCH_RELATED_BY_FILTER_SUCCESS",
    SEARCH_RELATED_BY_FILTER_FAILED: "SEARCH_RELATED_BY_FILTER_FAILED",

    GET_LIST_STAFF: "GET_LIST_STAFF",
    GET_LIST_STAFF_SUCCESS: "GET_LIST_STAFF_SUCCESS",
    GET_LIST_STAFF_FAILED: "GET_LIST_STAFF_FAILED",

    GET_TASK_BY_ID: "GET_TASK_BY_ID",
    GET_TASK_BY_ID_SUCCESS: "GET_TASK_BY_ID_SUCCESS",
    GET_TASK_BY_ID_FAILED: "GET_TASK_BY_ID_FAILED",

    REDIRECT_TO_TASK_EDIT: "REDIRECT_TO_TASK_EDIT",

    ADD_NEW_TIMER: "ADD_NEW_TIMER",
    ADD_NEW_TIMER_SUCCESS: "ADD_NEW_TIMER_SUCCESS",
    ADD_NEW_TIMER_FAILED: "ADD_NEW_TIMER_FAILED",

    DELETE_TIMER: "DELETE_TIMER",
    DELETE_TIMER_SUCCESS: "DELETE_TIMER_SUCCESS",
    DELETE_TIMER_FAILED: "DELETE_TIMER_FAILED",

    ADD_NEW_CHECKLIST: "ADD_NEW_CHECKLIST",
    ADD_NEW_CHECKLIST_SUCCESS: "ADD_NEW_CHECKLIST_SUCCESS",
    ADD_NEW_CHECKLIST_FAILED: "ADD_NEW_CHECKLIST_FAILED",

    DELETE_CHECKLIST: "DELETE_CHECKLIST",
    DELETE_CHECKLIST_SUCCESS: "DELETE_CHECKLIST_SUCCESS",
    DELETE_CHECKLIST_FAILED: "DELETE_CHECKLIST_FAILED",

    ADD_COMMENT: "ADD_COMMENT",
    ADD_COMMENT_SUCCESS: "ADD_COMMENT_SUCCESS",
    ADD_COMMENT_FAILED: "ADD_COMMENT_FAILED",

    EDIT_COMMENT: "EDIT_COMMENT",
    EDIT_COMMENT_SUCCESS: "EDIT_COMMENT_SUCCESS",
    EDIT_COMMENT_FAILED: "EDIT_COMMENT_FAILED",

    DELETE_COMMENT: "DELETE_COMMENT",
    DELETE_COMMENT_SUCCESS: "DELETE_COMMENT_SUCCESS",
    DELETE_COMMENT_FAILED: "DELETE_COMMENT_FAILED",

    ADD_REMINDER: "ADD_REMINDER",
    ADD_REMINDER_SUCCESS: "ADD_REMINDER_SUCCESS",
    ADD_REMINDER_FAILED: "ADD_REMINDER_FAILED",

    EDIT_REMINDER: "EDIT_REMINDER",
    EDIT_REMINDER_SUCCESS: "EDIT_REMINDER_SUCCESS",
    EDIT_REMINDER_FAILED: "EDIT_REMINDER_FAILED",

    DELETE_REMINDER: "DELETE_REMINDER",
    DELETE_REMINDER_SUCCESS: "DELETE_REMINDER_SUCCESS",
    DELETE_REMINDER_FAILED: "DELETE_REMINDER_FAILED",

    ADD_ASSIGN: "ADD_ASSIGN",
    ADD_ASSIGN_SUCCESS: "ADD_ASSIGN_SUCCESS",
    ADD_ASSIGN_FAILED: "ADD_ASSIGN_FAILED",

    DELETE_ASSIGN: "DELETE_ASSIGN",
    DELETE_ASSIGN_SUCCESS: "DELETE_ASSIGN_SUCCESS",
    DELETE_ASSIGN_FAILED: "DELETE_ASSIGN_FAILED",

    ADD_FOLLOWER: "ADD_FOLLOWER",
    ADD_FOLLOWER_SUCCESS: "ADD_FOLLOWER_SUCCESS",
    ADD_FOLLOWER_FAILED: "ADD_FOLLOWER_FAILED",

    DELETE_FOLLOWER: "DELETE_FOLLOWER",
    DELETE_FOLLOWER_SUCCESS: "DELETE_FOLLOWER_SUCCESS",
    DELETE_FOLLOWER_FAILED: "DELETE_FOLLOWER_FAILED",

    OPEN_TASK_EDIT: "OPEN_TASK_EDIT",
    CLOSE_TASK_EDIT: "CLOSE_TASK_EDIT",

    OPEN_TASK_DETAIL: "OPEN_TASK_DETAIL",
    CLOSE_TASK_DETAIL: "CLOSE_TASK_DETAIL",

    OPEN_POPUP_DELETE: "OPEN_POPUP_DELETE",
    CLOSE_POPUP_DELETE: "CLOSE_POPUP_DELETE",

    OPEN_TASK_CREATE: "OPEN_TASK_CREATE",
    CLOSE_TASK_CREATE: "CLOSE_TASK_CREATE",

    GET_TASK_BY_PROJECT: "GET_TASK_BY_PROJECT",
    GET_TASK_BY_PROJECT_SUCCESS: "GET_TASK_BY_PROJECT_SUCCESS",
    GET_TASK_BY_PROJECT_FAILED: "GET_TASK_BY_PROJECT_FAILED",

    GET_TASK_SUMMARY_BY_PROJECT_REQUEST: "GET_TASK_SUMMARY_BY_PROJECT_REQUEST",
    GET_TASK_SUMMARY_BY_PROJECT_SUCCESS: "GET_TASK_SUMMARY_BY_PROJECT_SUCCESS",
    GET_TASK_SUMMARY_BY_PROJECT_FAILED: "GET_TASK_SUMMARY_BY_PROJECT_FAILED",

    FILTER_TASK_BY_PROJECT_REQUEST: "FILTER_TASK_BY_PROJECT_REQUEST",
    FILTER_TASK_BY_PROJECT_SUCCESS: "FILTER_TASK_BY_PROJECT_SUCCESS",
    FILTER_TASK_BY_PROJECT_FAILED: "FILTER_TASK_BY_PROJECT_FAILED",

    CLOSE_TASK_BY_LEADS: "CLOSE_TASK_BY_LEADS",

    COPY_TASK_PENDING: "COPY_TASK_PENDING",
    COPY_TASK_SUCCESS: "COPY_TASK_SUCCESS",
    COPY_TASK_FAILURE: "COPY_TASK_FAILURE",

    GET_FILE_IN_TASK_PENDING: "GET_FILE_IN_TASK_PENDING",
    GET_FILE_IN_TASK_SUCCESS: "GET_FILE_IN_TASK_SUCCESS",
    GET_FILE_IN_TASK_FAILURE: "GET_FILE_IN_TASK_FAILURE",

    DELETE_TAG_IN_TASK_PENDING: "DELETE_TAG_IN_TASK_PENDING",
    DELETE_TAG_IN_TASK_SUCCESS: "DELETE_TAG_IN_TASK_SUCCESS",
    DELETE_TAG_IN_TASK_FAILURE: "DELETE_TAG_IN_TASK_FAILURE",

    BULK_ACTIONS_TASK_REQUEST: "BULK_ACTIONS_TASK_REQUEST",
    BULK_ACTIONS_TASK_SUCCESS: "BULK_ACTIONS_TASK_SUCCESS",
    BULK_ACTIONS_TASK_FAILED: "BULK_ACTIONS_TASK_FAILED",

    FETCH_LIST_STATUS_TASK_CURRENT_WEEK_PENDING: "FETCH_LIST_STATUS_TASK_CURRENT_WEEK_PENDING",
    FETCH_LIST_STATUS_TASK_CURRENT_WEEK_SUCCESS: "FETCH_LIST_STATUS_TASK_CURRENT_WEEK_SUCCESS",
    FETCH_LIST_STATUS_TASK_CURRENT_WEEK_FAILED: "FETCH_LIST_STATUS_TASK_CURRENT_WEEK_FAILED",
};

export default ActionTypes;