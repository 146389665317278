// @flow
import { all, call, fork, put, takeEvery } from "redux-saga/effects";



import ActionTypes from "./type";

import {
  fetchWareHouseSuccess,
  fetchWareHouseFailed,
  newWareHouseFailed,
  newWareHouseSuccess,
  editWareHouseSuccess,
  editWareHouseFailed,
  deleteWareHouseSuccess,
  deleteWareHouseFailed,
} from "./actions";
import {
  fetchListWareHouseRequest,
  addWareHouseRequest,
  editWareHouseRequest,
  deleteWareHouseRequest,
} from "./api";
import { responeCode, typeMessage } from "../../../constants/Const";
import { parseDataNotifications, throwErrorMessage } from "../../../helpers/HandleError";
import { enqueueSnackbar } from "../../../redux/CommonReducer";

function* fetchListWareHouseSaga(action) {
  try {
    const response = yield call(fetchListWareHouseRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(fetchWareHouseSuccess(response.data.result));
    } else {
      yield put(fetchWareHouseFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchWareHouseFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* newWareHouseCustomer(action) {
  try {
    const response = yield call(addWareHouseRequest, action.data);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(newWareHouseSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(newWareHouseFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(newWareHouseFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* editWareHouseCustomer(action) {
  try {
    const response = yield call(editWareHouseRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(editWareHouseSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(editWareHouseFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(editWareHouseFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* deleteWareHouseCustomer(action) {
  try {
    const response = yield call(deleteWareHouseRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(deleteWareHouseSuccess());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(deleteWareHouseFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(deleteWareHouseFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

export function* wareHouseSaga() {
  yield takeEvery(ActionTypes.FETCH_WAREHOUSE_REQUEST, fetchListWareHouseSaga);
  yield takeEvery(ActionTypes.NEW_WAREHOUSE_REQUEST, newWareHouseCustomer);
  yield takeEvery(ActionTypes.EDIT_WAREHOUSE_REQUEST, editWareHouseCustomer);
  yield takeEvery(ActionTypes.DELETE_WAREHOUSE_REQUEST, deleteWareHouseCustomer);
}

function* WareHouseSaga() {
  yield all([
      fork(wareHouseSaga),
      
  ]);
}

export default WareHouseSaga;
