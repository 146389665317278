export default {
  customer: {
    index: {
      add: "NEW CUSTOMER",
      import: "IMPORT CUSTOMERS",
      toKanban: "Change to kanban",
      titleDialogCreate: "Story ",
      removeCustomer: "Remove customer",
      confirmRemoveCustomer: "Are you sure remove",
      cancelRemoveCustomer: "Cancel",
      sureRemoveCustomer: "Yes",
      titleFilter: "Filter",
      keyWordPlaceholder: "Search",
      assignedPlaceholder: "Assigned",
      statusPlaceholder: "Status",
      sourcePlaceholder: "Source",
      view: "View",
      edit: "Edit",
      delete: "Delete",
      contacts: "Contacts",
    },
    common: {
      id: "Id",
      to: "To",
      basicContact: "Primary Contact",
      email: "Primary Email",
      numberPhone: "Phone",
      company: "Company",
      status: "Active",
      group: "Groups",
      created: "Date Created",
      address: "Address",
      taxCode: "VAT Number",
      city: "City",
      phone: "Phone",
      state: "State",
      website: "Website",
      zipCode: "Zip Code",
      country: "Country",
      currency: "Currency",
      defaultLanguage: "Default Language",
      streetBilling: "Street",
      streetShipping: "Street",
      cityBilling: "City",
      cityShipping: "City",
      stateBilling: "State",
      stateShipping: "State",
      zipCodeBilling: "Zip Code",
      zipCodeShipping: "Zip Code",
      countryBilling: "Country",
      countryShipping: "Country",
      profile: "Profile",
      assign: "Assign Admin",
      newContact: "New Contact",
      note: "Notes",

      newNote: "New Note",
      search: "Search...",
      Btnclose: "Close",
      btnSave: "Save",
      apply: "Apply",
      view: "View",
      edit: "Edit",
      delete: "Delete",
      titleSign: "Signature & Confirmation Of Identity",
      firstName: "First Name",
      lastName: "Last Name",
      emailSign: "Email",
      signature: "Signature",
      clear: "Clear",
      undo: "Undo",
      noteSign:
        "By clicking on 'Sign', I consent to be legally bound by this electronic representation of my signature.",
      validateCompany: "This field is required.",
      validateNameNewCustomerGroup: "This field is required.",
    },
    overview: {
      id: "Id",
      to: "To",
      overview: "Customer Summary",
      total: "Total customers",
      active: "Active customers",
      inactive: "Inactive Customers",
      activeContact: "Active Contacts",
      activeContactLess: "Inactive Contacts",
      signIn: "Contacts Logged In Today",
      export: "Export",
    },
    menuTabs: {
      story: "Story",
      information: "Customer Details",
      payment: "Billing & Shipping",
      admin: "Customer Admins",
    },
    profile: {
      titleNewCustomer: "Profile",
      billingAddress: "Billing Address",
      information: "Same as Customer Info",
      shippingAddress: "Shipping Address",
      coppyBillingAddress: "Coppy Billing Address",
      name: "Staff Member",
      created: "Date Assigned",
      option: "Options",
      assignAdmin: "Assign admin",
      titleNewGroup: "Add New Customer Group",
      nameGroup: "Name",
      Btnsave: "Save",
      Btnclose: "Close",
      tootipCurrency:
        "If the customer use other currency then the base currency make sure you select the appropriate currency for this customer. Changing the currency is not possible after transactions are recorded.",
      company: "Company",
      address: "Address",
      vatNumber: "VAT Number",
      city: "City",
      phone: "Phone",
      state: "State",
      website: "Website",
      zipCode: "Zip Code",
      groups: "Groups",
      country: "Country",
      currency: "Currency",
      defaultLanguage: "Default Language",
    },
    tooltip: {
      paymentTooltip:
        "Do not fill shipping address information if you won't use shipping address on customer invoices",
    },
    menuView: {
      profile: "Profile",
      contacts: "Contacts",
      notes: "Notes",
      statement: "Statement",
      invoices: "Invoices",
      payments: "Payments",
      proposals: "Proposals",
      creditNotes: "Credit Notes",
      estimates: "Estimates",
      subscriptions: "Subscriptions",
      expenses: "Expenses",
      contracts: "Contracts",
      projects: "Projects",
      tasks: "Tasks",
      tickets: "Tickets",
      files: "Files",
      vault: "Vault",
      reminders: "Reminders",
      map: "Map",
      noteProfile:
        "Show primary contact full name on Invoices, Estimates, Payments, Credit Notes",
      noteEstimatesPayment:
        "Update the shipping/billing info on all previous invoices/estimates",
      noteTextPayment:
        "If you check this field shipping and billing info will be updated to all invoices and estimates. Note: Invoices with status paid won't be affected.",
      noteCreditPayment:
        "Update the shipping/billing info on all previous credit notes (Closed credit notes not affected)",
    },
    projects: {
      title: "Projects",
      addNew: "New Project",
      export: "Export",
      search: "Search...",
      tooltip: {
        reload: "Reload",
      },
      status: {
        notStarted: "Not Started",
        inProgress: "In Progress",
        onHold: "On Hold",
        cancelled: "Cancelled",
        finished: "Finish",
      },
      table: {
        name: "Project Name",
        tags: "Tags",
        startDate: "Start Date",
        deadline: "Deadline",
        members: "Members",
        status: "Status",
      },
      nameAction: {
        view: "View",
        edit: "Edit",
        delete: "Delete",
      },
    },
    addNewProject: {
      addNew: "Add new project",
      settings: "Project settings",
      form: {
        name: "Project Name",
        customer: "Customer",
        calculateProgress: "Calculate progress through tasks",
        progress: "Progress",
        billingType: "Billing Type",
        status: "Status",
        rate: "Rate Per Hour",
        estimatedHours: "Estimated Hours",
        members: "Members",
        group: "Group",
        startDate: "Start Date",
        deadline: "Deadline",
        tags: "Tags",
        description: "Description",
        sendProjectMail: "Send project created email",
      },
      visibleTab: "Visible Tabs",
      listRoles: {
        viewTask: "Allow customer to view tasks",
        createTask: "Allow customer to create tasks",
        editTask:
          "Allow customer to edit tasks (only tasks created from contact)",
        commentTask: "Allow customer to comment on project tasks",
        viewTaskComments: "Allow customer to view task comments",
        viewTaskAttachments: "Allow customer to view task attachments",
        viewTaskChecklistItems: "Allow customer to view task checklist items",
        uploadAttachmentsOnTasks:
          "Allow customer to upload attachments on tasks",
        viewLoggedTime: "Allow customer to view task total logged time",
        viewFinance: "Allow customer to view finance overview",
        uploadFiles: "Allow customer to upload files",
        openDiscussions: "Allow customer to open discussions",
        viewMilestones: "Allow customer to view milestones",
        viewGantt: "Allow customer to view Gantt",
        viewTimesheets: "Allow customer to view timesheets",
        viewActivityLog: "Allow customer to view activity log",
        viewTeamMembers: "Allow customer to view team members",
        hideProjectTasks: "Hide project tasks on main tasks table (admin area)",
      },
    },
    contact: {
      contacts: "Contacts",
      name: "Full Name",
      email: "Email",
      position: "Position",
      phone: "Phone",
      active: "Active",
      lastLogin: "Last Login",
      avatar: "Profile image",
      firstName: "First Name",
      lastName: "Last Name",
      direction: "Direction",
      password: "Password",
      primaryContact: "Primary Contact",
      notSendEmail: "Do not send welcome email",
      sendPasswordEmail: "Send SET password email",
      permissions: "Permissions",
      permissionsContact:
        "Make sure to set appropriate permissions for this contact",
      invoices: "Invoices",
      estimates: "Estimates",
      contracts: "Contracts",
      proposals: "Proposals",
      support: "Support",
      projects: "Projects",
      emailNotifications: "Email Notifications",
      invoice: "Invoice",
      estimate: "Estimate",
      creditNote: "Credit Note",
      project: "Project",
      tickets: "Tickets",
      contract: "Contract",
      task: "Task",
      addContact: "Add new contact",
      tooltip: "Only project related tasks",
      notePassword:
        "Note: if you populate this field, password will be changed on this contact.",
    },
    note: {
      description: "Description",
      addFrom: "Added From",
      dateAdd: "Date Added",
      options: "Options",
      cancel: "Cancel",
      update: "Update Note",
      noteDescription: "Note description",
      removeNote: "Remove note",
    },
    statement: {
      statement: "Statement",
      print: "Print",
      pdf: "View PDF",
      sendEmail: "Send to Email",
      today: "Today",
      thisWeek: "This Week",
      thisMonth: "This Month",
      lastMonth: "Last Month",
      thisYear: "This Year",
      lastYear: "Last Year",
      period: "Period",
    },
    invoice: {
      invoice: "Invoice #",
      amount: "Amount",
      totalTax: "Total Tax",
      date: "Date",
      project: "Project",
      tags: "Tags",
      dueDate: "Due Date",
      status: "Status",
      outstandingInvoices: "Outstanding Invoices",
      pastDueInvoices: "Past Due Invoices",
      paidInvoices: "Paid Invoices",
      title: "Invoices",
      newInvoice: "Create New Invoice",
      zipInvoice: "Zip Invoices",
      invoiceAvailable: "Invoices Available for Merging",
      expenseAvailable: "Expenses available to bill",
      cheap: "Cheap",
      expensive: "Expensive",
      newMark: "Mark merged invoices as cancelled instead of deleting",
      customer: "Customer",
      tag: "Tags",
      paymentInvoice: "Allowed payment modes for this invoice",
      billTo: "Bill To",
      shipTo: "Ship to",
      currency: "Currency",
      saleAgent: "Sale Agent",
      recurringInvoice: "Recurring Invoice?",
      discountType: "Discount Type",
      invoiceNumber: "Invoice Number",
      adminNote: "Admin Note",
      invoiceDate: "Invoice Date",
      preventSending: "Prevent sending overdue reminders for this invoice",
      toolTip:
        "Merging invoices will create gaps in invoice numbers. Please do not merge invoices if you want no gaps in your invoice history. You also have the option of manually adjusting invoice numbers if you want to fill the gaps",
      titlePrice:
        "The number in the input field is not formatted while edit/add item and should remain not formatted do not try to format it manually in here",
      titleTask: "Projects tasks are not included in this list.",
      titleTable:
        "New lines are not supported for item description. Use the item long description instead.",
      addItem: "Add Item",
      billTask: "Bill Tasks",
      showQuantity: "Show quantity as :",
      qty: "Qty",
      hours: "Hours ",
      qtyHours: "Qty/Hours ",
      item: "Item",
      rateTable: "Rate",
      taxTable: "Tax",
      subTotal: "Sub Total",
      discount: "Discount",
      adjustment: "Adjustment",
      total: "Total ",
      street: "Street",
      city: "City",
      state: "State",
      zipCode: "Zip Code",
      country: "Country",
      titleShipping: "Get shipping details from customer profile",
      shippingAddress: "Shipping Address",
      showShipping: "Show shipping details in invoice",
      titleAddItem: "Add New Item",
      description: "Description",
      longDescription: "Long Description",
      rate: "Rate - VND (Base Currency)",
      tax1: "Tax 1",
      tax2: "Tax 2",
      unit: "Unit",
      itemGroup: "Item Group",
      btnSave: "Save",
      btnClose: "Close",
      btnSaveDraft: "Save as Draft",
      dropSaveSend: "Save & Send",
      dropSaveSendLater: "Save & Send Later",
      dropSaveRecord: "Save & Record Payment",
      action: {
        createInvoiceSuccess: "Create invoice success",
        createInvoiceFailed: "Create invoice Failed",
        editInvoiceSuccess: "Edit invoice success",
        editInvoiceFailed: "Edit invoice Failed",
        deleteInvoiceSuccess: "Delete invoice success",
        deleteInvoiceFailed: "Delete invoice Failed",
      }
    },
    payment: {
      title: "Payments",
      zipPayment: "Zip Payments",
      payment: "Payment #",
      invoice: "Invoice #",
      paymentMode: "Payment Mode",
      transaction: "Transaction ID",
      amount: "Amount",
      date: "Date",
      titleZip: "Zip Payments",
      paymentMade: "Payment made by",
      fromDate: "From Date:",
      toDate: "To Date:",
    },
    proposal: {
      assignTo: "AssignTo",
      approvalStatus: "approvalStatus",
      title: "Proposals",
      newProposal: "New Proposals",
      proposal: "Proposal #",
      subject: "Subject",
      total: "Total",
      date: "Date",
      openTill: "Open Till",
      tags: "Tags",
      dateCreated: "Date Created",
      status: "Status",
      assigned: "Assigned",
      related: "Related",
      to: "To",
      customer: "Customer",
      lead: "Lead",
      address: "Address",
      currency: "Currency",
      discountType: "Discount Type",
      city: "City",
      state: "State",
      country: "Country",
      zipCode: "Zip Code",
      email: "Email",
      phone: "Phone",
      allowComments: "Allow Comments",
      titleComments:
        "If you check this options comments you be allowed when your client view the proposal .",
      textSave:
        "Include proposal items with merge field anywhere in proposal content as {proposal_items}",
      btnSaveSend: "SAVE & SEND",
      btnSave: "SAVE",
      listProposal: "Danh sách đề xuất",
      action: {
        createProposalSuccess: "Create proposal success.",
        createProposalFailure: "Create proposal failure.",
        editProposalSuccess: "Edit proposal success.",
        editProposalFailure: "Edit proposal failure.",
        deleteProposalSuccess: "Delete proposal success.",
        deleteProposalFailure: "Delete proposal failure."
      }

    },
    creditNote: {
      title: "Credit Notes",
      creditAvailable: "Credits available .",
      newCreditNote: "New Credit Note",
      zipCreditNote: "Zip Credit Note",
      creditNote: "Credite Note #",
      creditNoteDate: "Credite Note Date",
      status: "Status",
      project: "Project",
      reference: "Reference #",
      amount: "Amount",
      remainningAmount: "Remainning Amount",
      customer: "Customer",
      currency: "Currency",
      discountType: "Discount Type",
      billTo: "Bill To",
      shipTo: "Ship to",
      adminNote: "Admin Note",
      clientNote: "Client Note",
      condition: "Terms & Conditions",
    },
    estimate: {
      title: "Estimates",
      newEstimate: "Create New Estimate",
      zipEstimate: "Zip Estimates",
      draft: "Draft",
      sent: "Sent",
      expired: "Expired",
      declined: "declined",
      accepted: "Accepted",
      estimate: "Estimate #",
      amount: "Amount",
      totalTax: "Total Tax",
      project: "Project",
      tag: "Tags",
      date: "Date",
      expiryDate: "Expiry Date",
      reference: "Reference #",
      status: "Status",
      customer: "Customer",
      currency: "Currency",
      billTo: "Bill To",
      shipTo: "Ship to",
      saleAgent: "Sale Agent",
      estimateNumber: "Estimate Number",
      estimateDate: "Estimate Date",
      discountType: "Discount Type",
      adminNote: "Admin Note",
      clientNote: "Client Note",
      condition: "Terms & Conditions",
      showShipping: "Show shipping details in estimate",
      deleteTitle: "Delete estimate",
      action: {
        createEstimateSuccess: "Create estimate success",
        updateEstimateSuccess: "Update estimate success",
        createEstimateFailed: "Create estimate failed",
        updateEstimateFailed: "Update estimate failed",
        deleteEstimateSuccess: "Delete estimate success",
        deleteEstimateFailed: "Delete estimate failed",
      }
    },
    subscription: {
      title: "Subscriptions",
      newSubscription: "New Subscription",
      subscriptionName: "Subscription Name",
      project: "Project",
      status: "Status",
      nextBillingCycle: "Next Billing Cycle",
      dateSubscribed: "Date Subscribed",
      textHeader:
        "API key not configured, click on the following link to configure API key :",
      billingPlan: "Billing Plan",
      quantity: "Quantity",
      toolTipDate:
        "Leave blank to use date when the customer is subscribed to the subscription. This field must be future date, if you select date and the date is passed but customer is not yet subscribed, the date when the customer will subscribe will be used.",
      firstBillingDate: "First Billing Date",
      description: "Description",
      toolTipCheckBox:
        "Usefull if you want to include additional information on the subscription invoice, e.q. what this subscription includes.",
      includeDescription: "Include description in invoice item",
      customer: "Customer",
      currency: "Currency",
      tax: "Tax (Stripe)",
      termsConditions: "Terms & Conditions",
      placeHoderTerms:
        "Enter customer terms & condittions to be  displayed to the customer before subscribe to the subscription.",
    },
    expense: {
      title: "Expenses",
      recordExpense: "Record Expense",
      total: "Total",
      billable: "Billable",
      nonBillable: "Non Billable",
      notInvoiced: "Not Invoiced",
      billed: "Billed",
      category: "Category",
      amount: "Amount",
      name: "Name",
      expenseReceipt: "Expense Receipt",
      date: "Date",
      project: "Project",
      invoice: "Invoice",
      reference: "Reference #",
      newExpense: "Add new expense",
      attachReceipt: "Attach Receipt",
      tooltipName:
        "For personal usage - If billable, Name can be added to invoice long description .",
      tooltipNote:
        "If billable, Note can be added to invoice long description .",
      note: "Note",
      expenseCategory: " Expense Category",
      expenseDate: "Expense Date",
      customer: "Customer",
      advancedOption: "Advanced Options",
      currency: "Currency",
      tax1: "Tax 1",
      tax2: "Tax 2",
      paymentMode: "Payment Mode",
      repeatEvery: "Repeat every",
      titleAddExpense: "New Category",
      categoryName: "Category Name",
      categoryDescription: "Category Description",
    },

    reminder: {
      headerReminder: "Reminders",
      tooltipTitle:
        "This option allows you to never forget anything about your customers.",
      title: "Set Reminder",
      export: "Export",
      dateNotified: "Date to be notified",
      setReminder: "Set reminder to",
      description: "Description",
      sendMail: "Send also an email for this reminder",
      buttonClose: "Close ",
      buttonSave: "Save",
      buttonReload: "Reload",
      dropdownNumber: "All",
      dropdownExport: "Print",
      table: {
        id: "Id",
        description: "Description",
        date: "Date",
        remind: "Remind",
        notified: "Is notified?",
        entriesFound: "No entries found",
        yes: "Yes",
        no: "No",
      },
      removeReminder: "Delete reminder",
    },
    reminderExpense: {
      title: "Set Expense Reminder",
    },
    vault: {
      headerVault: "Vault",
      buttonVault: "New Vault Entry",
      vaultEntry: "Vault Entry",
      serverAddress: "Server Address",
      port: "Port",
      username: "Username",
      password: "Password",
      shortDescription: "Short Description",
      visibleAll:
        "Visible to all staff member who have access to this customer",
      visibleAdmin: "Visible only to administrators",
      visibleMe: "Visible only to me (administrators are not excluded)",
      shareVault: "Share this vault entry in projects with project members",
      buttonClose: "Close",
      buttonSave: "Save",
      vaultEntryCustomer: "Vault entries not found for this customer.",
      createdBy: "This vault entry is created by",
      viewPassword: "View Password",
      enterPassword: "For security reasons please enter your password below",
      buttonConfirm: "Confirm",
    },
    tasks: {
      headerTasks: "Tasks",
      buttonTasks: "New task",
      headerbtnTasks: "Add new task",
      checkboxPublic: "Public",
      checkboxBillable: "Billable",
      attachFiles: "Attach Files",
      attachment: "attachment",
      subject: "Subject",
      hourlyRate: "Hourly Rate",
      startDate: "Start Date",
      dueDate: "Due Date",
      priority: "Priority",
      repeatEvery: "Repeat every",
      relatedTo: "Related To",
      customer: "Customer",
      insertListTemplates: "Insert Checklist Templates",
      nothingSelected: "Nothing selected",
      selectAll: "Select All",
      deselectAll: "Deselect All",
      tags: "Tags",
      taskDescription: "Task Description",
      btnClose: "Close",
      btnSave: "Save",
      tooltipFilter: "Filter by",
      filterAll: "All",
      filterNotStarted: "Not Started",
      filterInprogress: "In Progress",
      filterTesting: "Testing",
      filterAwaitingFeedback: "Awaiting Feedback",
      filterComplete: "Complete",
      filterTodayTasks: "Today's tasks",
      filterDueDatePassed: "Due Date Passed",
      checkboxCustomer: "Customer",
      checkboxProjects: "Projects",
      checkboxInvoices: "Invoices",
      checkboxEstimates: "Estimates",
      checkboxContracts: "Contracts",
      checkboxTickets: "Tickets",
      checkboxExpenses: "Expenses",
      checkboxProposals: "Proposals",
      export: "Export",
      search: "Search",
      related: {
        customer: "Customer",
        projects: "Projects",
        invoices: "Invoices",
        estimates: "Estimates",
        contracts: "Contracts",
        tickets: "Tickets",
        expenses: "Expenses",
        proposals: "Proposals",
      },
      table: {
        tableName: "Name",
        tableStatus: "Status",
        tableStartDate: "Start Date",
        tableDueDate: "Due Date",
        tableAssignedTo: "Assigned To",
        tableTags: "Tags",
        tablePriority: "Priority",
      },
    },
    contracts: {
      titleContracts: "Contracts",
      confirmDelete: "Are you want to delete?",
      btnContracts: "New contract",
      btnNewContracts: {
        checkboxTrash: "Trash",
        tooltipTrash:
          "If you add contract to trash, won't be shown on client side, won't be included in chart and other stats and also by default won't be shown when you will list all contracts.",
        checkboxHideCustomer: "Hide from Customer",
        inputCustomer: "Customer",
        inputSubject: "Subject",
        tooltipSubject: "Subject is also visible to customer",
        inputContractValue: "Contract Value",
        tooltipContractValue: "Base currency will be used.",
        inputContractType: "Contract Type",
        inputStartDate: "Start Date",
        inputEndDate: "End Date",
        inputDescription: "Description",
        btnSave: "Save",
        dialogContractType: {
          titleContractType: "New Contract Type",
          inputName: "Name",
          btnClose: "Close",
          btnSave: "Save",
          btnDelete: "Delete"

        },
      },
      nameAction: {
        view: "View",
        edit: "Edit",
        delete: "Delete",
      },
      btnExport: "Export",
      btnReload: "Reload",
      btnSearch: "Search",
      table: {
        id: "#",
        subject: "Subject",
        contractType: "Contract Type",
        contractValue: "Contract Value",
        startDate: "Start Date",
        endDate: "End Date",
        signature: "Signature",
      },
      btnSign: {
        titleSign: "Signature & Confirmation Of Identity",
        firtName: "First Name",
        lastName: "Last Name",
        email: "Email",
        signature: "Signature",
        btnClear: "Clear",
        btnUndo: "Undo",
        confirmSign:
          "By clicking on 'Sign', I consent to be legally bound by this electronic representation of my signature.",
        btnCancel: "Cancel",
        btnConfirmSign: "Sign",
      },
    },
    files: {
      titleFiles: "Files",
      textTitleFiles:
        "Files from projects and tasks linked to the customer are not shown on this table.",
      formUpload: "Drop files here to upload",
      btnExport: "Export",
      btnSearch: "Search",
      tableFile: {
        columnsFile: "File",
        columnsCustomersArea: "Show to customers area",
        columnsDateUpload: "Date Uploaded",
        columnsOptions: "Options",
      },
    },
    tickets: {
      titleTickets: "Tickets",
      btnNewTicket: "New Ticket",
      btnExport: "Export",
      btnSearch: "Search",
      tableTickets: {
        columnsId: "#",
        columnsSubject: "Subject",
        columnsTags: "Tags",
        columnsDepartment: "Department",
        columnsService: "Service",
        columnsContact: "Contact",
        columnsStatus: "Status",
        columnsPrioty: "Prioty",
        columnsLastReply: "Last Reply",
        columnsCreated: "Created",
      },
    },
  },
};
