import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Checkbox,
    Box,
    MenuItem,
    Select,
    Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import TableHeader from "./TableHead";
import "./index.scss";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        paddingBottom: "16px",
    },
    table: {
        width: "100%",
        height: "100%",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    container: {
        maxHeight: 400,
    },
    selected: {},
}));

const CustomTable = (props) => {
    const {
        columns, //type of array - required
        data, //type of array - required
        parseFunction, //type of function
        checkboxSelection, //type og bool
        keySelect, //type of string
        isShowPaging, //type og bool
        onChangePage, //type of function - required if isShowPaging = true
        onChangeRowPerPage, //type of function - required if isShowPaging = true
        totalCountData, //type of number - required if isShowPaging = true
        height, //type of number - required
        dataPaging,
        onListSelectTask ,
        listSelectTask
    } = props;
    const { limit, page } = dataPaging;
    const { t } = useTranslation();
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("");
    const [selected, setSelected] = React.useState([]);
    const [openChangeRowPerPage, setOpenChangeRowPerPage] =
        React.useState(false);

    const resetData = () => {
        setOrder("asc");
        setOrderBy("id");
        setSelected([]);
    };
    useEffect(() => {
        if (listSelectTask) {
            setSelected(listSelectTask);
          }
    }, [listSelectTask]);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order, orderBy) => {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n[keySelect]);
            setSelected(newSelecteds);
            if (onListSelectTask) {
                onListSelectTask(newSelecteds);
            }
            return;
        }
        if (onListSelectTask) {
            onListSelectTask([]);
        }
        setSelected([]);
    };
    const handleClick = useCallback(
        (event, key) => {
          const selectedIndex = selected.indexOf(key);
          let newSelected = [];
      
          if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, key);
          } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
          } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
          } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
              selected.slice(0, selectedIndex),
              selected.slice(selectedIndex + 1)
            );
          }
          if (onListSelectTask) {
            onListSelectTask(newSelected);
          }
          setSelected(newSelected);
        },
        [selected, onListSelectTask]
      );
    const handleChangePage = (event, pageNum) => {
        if (page === pageNum) return;
        if (onChangePage) {
            onChangePage(pageNum);
        }
    };

    const handleChangeRowPerPage = (value) => {
        if (value === limit) {
            return;
        }
        if (onChangeRowPerPage) {
            onChangeRowPerPage(value);
        }
        resetData();
    };

    const handleCloseRowPerPage = () => {
        setOpenChangeRowPerPage(false);
    };

    const handleOpenRowPerPage = () => {
        setOpenChangeRowPerPage(true);
    };

    const isSelected = (key) => selected.indexOf(key) !== -1;

    const totalPage = totalCountData ? Math.ceil(totalCountData / limit) : 1;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer style={{ maxHeight: height ? height : "auto" }}>
                    <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
                        <TableHeader
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            columns={columns}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data.length}
                            checkboxSelection={checkboxSelection}
                        />
                        {data.length > 0 && (
                            <TableBody>
                                {stableSort(data, getComparator(order, orderBy))
                                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row[keySelect]);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={index}
                                                selected={isItemSelected}
                                                className={isItemSelected ? "row-selected" : ""}
                                            >
                                                {checkboxSelection && (
                                                    <TableCell padding="checkbox" onClick={(event) => handleClick(event, row[keySelect])}>
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            inputProps={{ "aria-labelledby": labelId, }}
                                                            color="primary"
                                                        />
                                                    </TableCell>
                                                )}
                                                {columns.map((item) => {
                                                    if (parseFunction) {
                                                        return (
                                                            <TableCell
                                                                style={{
                                                                    maxWidth:
                                                                        item.width,
                                                                    position:
                                                                        "relative",
                                                                }}
                                                                key={item.field}
                                                            >
                                                                {parseFunction(
                                                                    item.field,
                                                                    row
                                                                )}
                                                            </TableCell>
                                                        );
                                                    }
                                                    return (
                                                        <TableCell
                                                            style={{
                                                                maxWidth:
                                                                    item.width,
                                                                position:
                                                                    "relative",
                                                            }}
                                                            key={item.field}
                                                        >
                                                            {row[item.field]}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        )}
                    </Table>
                    {data.length === 0 && (
                        <Box
                            height={200}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            Không có dữ liệu
                        </Box>
                    )}
                </TableContainer>
                {isShowPaging && (
                    <Box
                        marginTop={2}
                        marginRight={2}
                        display="flex"
                        alignItems="center"
                    >
                        {/* Thêm phần hiển thị Total ở tận cùng bên trái */}
                        <Box marginRight={2} flexShrink={0} marginLeft={2}>
                            <Typography variant="body2">
                            {t('customer.proposal.total')} : {totalCountData}
                            </Typography>
                        </Box>
                        <Box marginRight={1} flexGrow={1} display="flex" justifyContent="flex-end">
                            <Select
                                labelId="demo-controlled-open-select-label"
                                id="demo-controlled-open-select"
                                open={openChangeRowPerPage}
                                onClose={handleCloseRowPerPage}
                                onOpen={handleOpenRowPerPage}
                                value={limit}
                                size="small"
                                onChange={(e) =>
                                    handleChangeRowPerPage(e.target.value)
                                }
                            >
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </Box>

                        <Pagination
                            onChange={handleChangePage}
                            page={page}
                            defaultPage={1}
                            count={totalPage}
                            variant="outlined"
                            color="primary"
                            showFirstButton
                            showLastButton
                        />
                    </Box>
                )}
            </Paper>
        </div>
    );
};

CustomTable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    parseFunction: PropTypes.func,
    checkboxSelection: PropTypes.bool,
    keySelect: PropTypes.string, //type of string
    isShowPaging: PropTypes.bool, //type og bool
    onChangePage: PropTypes.func, //type of function - required if isShowPaging = true
    onChangeRowPerPage: PropTypes.func, //type of function - required if isShowPaging = true
    totalCountData: PropTypes.number, //type of number - required if isShowPaging = true
    // height: PropTypes.number.isRequired,             //type of number - required
    dataPaging: PropTypes.object,
};
console.log(PropTypes)

CustomTable.defaultProps = {
    columns: [], //type of array - required
    data: [], //type of array - required
    parseFunction: () => { }, //type of function
    checkboxSelection: false, //type og bool
    keySelect: "id", //type of string
    isShowPaging: false, //type og bool
    onChangePage: () => { }, //type of function - required if isShowPaging = true
    onChangeRowPerPage: () => { }, //type of function - required if isShowPaging = true
    totalCountData: 0, //type of number - required if isShowPaging = true
    height: "450", //type of number - required
    dataPaging: { limit: 15, page: 1 },
};

export default CustomTable;
