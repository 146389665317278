import { all } from "redux-saga/effects";
import authSaga from "./auth/saga";
import LeadSaga from "../pages/Lead/redux/saga";
import CustomerSaga from "../pages/Customer/redux/saga";
import ProjectSaga from "../pages/Project/redux/saga";
import TaskSaga from "../pages/Task/redux/saga";
import CommonSaga from "./CommonSaga";
import ExpenseSaga from "../pages/Expense/redux/saga";
import AdminSaga from "../pages/Administrator/redux/saga";
import ContractSaga from "../pages/Contract/redux/saga";
import EstimateSaga from "../pages/Estimates/redux/saga";
import InvoiceProjectSaga from "../pages/Project/Invoice/redux/saga";
import EstimateProjectSaga from "../pages/Project/Estimate/redux/saga";
import ExpenseProjectSaga from "../pages/Project/Expenses/redux/saga";
import SalePaymentSaga from "../pages/Sales/Payments/redux/saga";
import ProposalSaga from "../pages/Proposal/redux/saga";
import SupportSaga from "../pages/Support/redux/sagas";
import CreditNoteSaga from "../pages/CreditNote/redux/saga";
import SaleInvoiceSaga from "../pages/Sales/Invoice/redux/saga";
import ItemsSaga from "../pages/Sales/Items/redux/sagas";
import DiscussionProjectSaga from "../pages/Project/Discussion/redux/saga";
import ProjectNotesSaga from "../pages/Project/Note/redux/saga";
import ProjectActivitySaga from "../pages/Activity/redux/saga";
import WareHouseSaga from '../pages/Inventory/redux/saga';


export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        CommonSaga(),
        CustomerSaga(),
        LeadSaga(),
        ProjectSaga(),
        TaskSaga(),
        ExpenseSaga(),
        AdminSaga(),
        // ProposalSaga(),
        ContractSaga(),
        EstimateSaga(),
        InvoiceProjectSaga(),
        EstimateProjectSaga(),
        ExpenseProjectSaga(),
        SalePaymentSaga(),
        ProposalSaga(),
        SupportSaga(),
        CreditNoteSaga(),
        SaleInvoiceSaga(),
        ItemsSaga(),
        DiscussionProjectSaga(),
        ProjectNotesSaga(),
        ProjectActivitySaga(),
        WareHouseSaga(),
    ]);
}
