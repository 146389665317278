import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import { isUserAuthenticated, getLoggedInUser } from "../helpers/authUtils";

export const PrivateRoute = ({ component: Component, roles, name, title, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            const isAuthTokenValid = isUserAuthenticated();
            if (!isAuthTokenValid) {
                // not logged in so redirect to login page with the return url
                return (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                );
            }

            const loggedInUser = getLoggedInUser();
            // Chuyển tất cả permission về chữ thường
            const userPermissions = loggedInUser.permissions
                ? loggedInUser.permissions.map(perm => perm.toLowerCase())
                : [];

            // Kiểm tra vai trò của người dùng
            const hasRole = Array.isArray(roles) && Array.isArray(loggedInUser.roles) &&
                loggedInUser.roles.map(r => r.toLowerCase()).some(userRole => roles.map(r => r.toLowerCase()).includes(userRole));

            // Kiểm tra quyền dựa vào roles
            // Kiểm tra quyền dựa vào permissions (so sánh với name/title của route)
            const wordsInName = name ? name.toLowerCase().split(" ") : [];
            const wordsInTitle = title ? title.toLowerCase().split(" ") : [];
            const userPermissionsLower = userPermissions.map(p => p.toLowerCase());

            const hasPermission = [...wordsInName, ...wordsInTitle].some(word =>
                userPermissionsLower.includes(word)
            );

            // Nếu không có roles hợp lệ và permissions hợp lệ => chặn truy cập
            if (!Array.isArray(roles) || roles.length === 0) {
                // Nếu roles không phải là một mảng hoặc là mảng rỗng, bỏ qua kiểm tra role
            } else {
                if (!hasRole && !hasPermission) {
                    return <Redirect to={{ pathname: "/" }} />;
                }
            }

            return <Component {...props} />;
        }}
    />
);