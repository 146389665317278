import { isArray } from "lodash";
import ActionTypes from "./types";

const INIT_STATE = {
  listTask: {},
  listStatus: [],
  listCountTask: [],
  relatedItem: [],
  relatedItemByFilter: [],
  taskEdited: "",
  isUpdateTaskSuccess: false,
  taskDelete: "",
  isLoading: false,
  taskDetail: "",
  isCreateSuccess: false,

  onOpenTaskDetail: false,
  onOpenCreateTask: false,
  onOpenPopupDel: false,
  onOpenEditTask: false,

  onRedirect: true,
  dataTimer: {},
  dataChecklist: {},
  deleteTimer: false,
  deleteChecklistSuccess: false,
  dataComment: {},
  dataEditComment: {},
  deleteCommentSuccess: false,
  dataReminder: {},
  dataEditReminder: {},
  deleteReminderSuccess: false,
  dataAssign: {},
  deleteAssignSuccess: false,
  dataFollower: {},
  deleteFollowerSuccess: false,
  dataChangeStatus: {},
  dataChangePriority: {},
  taskSummaryProject: {},
  isFetchTask: false,
  listStaff: {},
  isCloseTaskByLead: false,
  isEditTaskByLead: false,
  listFiles: [],
  isChangePriority: false,
  isChangeStatus: false,
  isSuccess: false,
  isDeleteTag: false,
  bulkActions: {},
  taskCopy: "",
  listTaskStatus: [],
};

const TaskReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_TASK:
      return {
        ...state,
        isLoading: true,
        taskDelete: "",
        isCreateSuccess: false,
        isChangePriority: false,
        isChangeStatus: false,
        isSuccess: false,
      };

    case ActionTypes.GET_TASK_SUCCESS:
      return {
        ...state,
        listTask: action.payload,
        isLoading: false,
      };

    case ActionTypes.GET_TASK_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_STATUS_LEADS:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_STATUS_LEADS_SUCCESS:
      return {
        ...state,
        listStatus: action.payload,
        isLoading: false,
      };

    case ActionTypes.FETCH_STATUS_LEADS_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.GET_TASK_BY_LEAD:
      return {
        ...state,
        isLoading: true,
        taskDelete: "",
        isEditTaskByLead: false,
      };

    case ActionTypes.GET_TASK_BY_LEAD_SUCCESS:
      return {
        ...state,
        listTask: action.payload,
        isLoading: false,
      };

    case ActionTypes.GET_TASK_BY_LEAD_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.CREATE_NEW_TASK:
      return {
        ...state,
        isLoading: true,
        isCreateSuccess: false,
      };

    case ActionTypes.CREATE_NEW_TASK_SUCCESS:
      if (isArray(action.payload) === true) {
        return {
          ...state,
          taskDetail: action.payload[0],
          isLoading: false,
          onOpenTaskDetail: true,
          onOpenCreateTask: false,
          isCreateSuccess: true,
        };
      } else {
        return {
          ...state,
          taskDetail: action.payload,
          isLoading: false,
          onOpenTaskDetail: true,
          onOpenCreateTask: false,
          isCreateSuccess: true,
        };
      }
    case ActionTypes.CREATE_NEW_TASK_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreateSuccess: false,
      };
    case ActionTypes.CHANGE_TASK_PRIORITY:
      return {
        ...state,
      };
    case ActionTypes.CHANGE_TASK_PRIORITY_SUCCESS:
      return {
        ...state,
        dataChangePriority: action.payload,
        isChangePriority: true,
      };
    case ActionTypes.CHANGE_TASK_PRIORITY_FAILED:
      return {
        ...state,
      };
    case ActionTypes.CHANGE_TASK_STATUS:
      return {
        ...state,
      };
    case ActionTypes.CHANGE_TASK_STATUS_SUCCESS:
      return {
        ...state,
        dataChangeStatus: action.payload,
        isChangeStatus: true,
      };
    case ActionTypes.CHANGE_TASK_STATUS_FAILED:
      return {
        ...state,
      };
    case ActionTypes.GET_COUNT_TASK:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_COUNT_TASK_SUCCESS:
      return {
        ...state,
        listCountTask: action.payload,
        isLoading: false,
      };

    case ActionTypes.GET_COUNT_TASK_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.SEARCH_RELATED:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.SEARCH_RELATED_SUCCESS:
      return {
        ...state,
        relatedItem: [action.payload],
        isLoading: false,
      };
    case ActionTypes.SEARCH_RELATED_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.SEARCH_RELATED_BY_FILTER:
      return {
        ...state,
      };
    case ActionTypes.SEARCH_RELATED_BY_FILTER_SUCCESS:
      return {
        ...state,
        relatedItemByFilter: action.payload,
      };
    case ActionTypes.SEARCH_RELATED_BY_FILTER_FAILED:
      return {
        ...state,
      };
    case ActionTypes.EDIT_TASK:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.EDIT_TASK_SUCCESS:
      if (isArray(action.payload) === true) {
        return {
          ...state,
          taskDetail: action.payload[0],
          isLoading: false,
          onOpenTaskDetail: true,
          onOpenEditTask: false,
          isEditTaskByLead: true,
          isSuccess: true,
        };
      } else {
        return {
          ...state,
          taskDetail: action.payload,
          isLoading: false,
          onOpenTaskDetail: true,
          onOpenEditTask: false,
          isEditTaskByLead: true,
          isSuccess: true,
        };
      }

    case ActionTypes.EDIT_TASK_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.DELETE_TASK:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.DELETE_TASK_SUCCESS:
      return {
        ...state,
        taskDelete: action.payload,
        isLoading: false,
        onOpenTaskDetail: false,
        isSuccess: true,
      };
    case ActionTypes.DELETE_TASK_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.GET_TASK_BY_ID:
      return {
        ...state,
        isLoading: true,
        deleteTimer: false,
        deleteChecklistSuccess: false,
        deleteCommentSuccess: false,
        deleteReminderSuccess: false,
        deleteAssignSuccess: false,
        deleteFollowerSuccess: false,
        isDeleteTag: false,
        dataTimer: {},
        dataReminder: {},
        dataEditReminder: {},
        dataChecklist: {},
        dataComment: {},
        dataEditComment: {},
        dataAssign: {},
        dataFollower: {},
        dataChangeStatus: {},
        dataChangePriority: {},
      };

    case ActionTypes.GET_TASK_BY_ID_SUCCESS:
      return {
        ...state,
        taskDetail: action.payload,
        isLoading: false,
      };

    case ActionTypes.GET_TASK_BY_ID_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.OPEN_TASK_DETAIL:
      return {
        ...state,
        onOpenTaskDetail: true,
        isLoading: true,
        taskDetail: action.payload,
      };

    case ActionTypes.CLOSE_TASK_DETAIL:
      return {
        ...state,
        taskDetail: "",
        onOpenTaskDetail: false,
        isLoading: false,
        isCloseTaskByLead: true,
      };

    case ActionTypes.REDIRECT_TO_TASK_EDIT:
      return {
        ...state,
        isLoading: false,
        onOpenEditTask: true,
        onOpenTaskDetail: false,
      };

    case ActionTypes.OPEN_POPUP_DELETE:
      return {
        ...state,
        onOpenPopupDel: true,
        taskDetail: action.payload,
      };

    case ActionTypes.CLOSE_POPUP_DELETE:
      return {
        ...state,
        onOpenPopupDel: false,
      };

    case ActionTypes.ADD_NEW_TIMER:
      return {
        ...state,
      };

    case ActionTypes.ADD_NEW_TIMER_SUCCESS:
      return {
        ...state,
        dataTimer: action.payload,
        isSuccess: true,
      };

    case ActionTypes.ADD_NEW_TIMER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_TIMER:
      return {
        ...state,
      };

    case ActionTypes.DELETE_TIMER_SUCCESS:
      return {
        ...state,
        deleteTimer: true,
        isSuccess: true,
      };

    case ActionTypes.DELETE_TIMER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.ADD_NEW_CHECKLIST:
      return {
        ...state,
      };

    case ActionTypes.ADD_NEW_CHECKLIST_SUCCESS:
      return {
        ...state,
        dataChecklist: action.payload,
        isSuccess: true,
      };

    case ActionTypes.ADD_NEW_CHECKLIST_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_CHECKLIST:
      return {
        ...state,
      };

    case ActionTypes.DELETE_CHECKLIST_SUCCESS:
      return {
        ...state,
        deleteChecklistSuccess: true,
      };

    case ActionTypes.DELETE_CHECKLIST_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.ADD_COMMENT:
      return {
        ...state,
      };

    case ActionTypes.ADD_COMMENT_SUCCESS:
      return {
        ...state,
        dataComment: action.payload,
      };

    case ActionTypes.ADD_COMMENT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.EDIT_COMMENT:
      return {
        ...state,
      };

    case ActionTypes.EDIT_COMMENT_SUCCESS:
      return {
        ...state,
        dataEditComment: action.payload,
        isSuccess: true,
      };

    case ActionTypes.EDIT_COMMENT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_COMMENT:
      return {
        ...state,
      };

    case ActionTypes.DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        deleteCommentSuccess: true,
      };

    case ActionTypes.DELETE_COMMENT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.ADD_REMINDER:
      return {
        ...state,
      };

    case ActionTypes.ADD_REMINDER_SUCCESS:
      return {
        ...state,
        dataReminder: action.payload,
        isSuccess: true,
      };

    case ActionTypes.ADD_REMINDER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.EDIT_REMINDER:
      return {
        ...state,
      };

    case ActionTypes.EDIT_REMINDER_SUCCESS:
      return {
        ...state,
        dataEditReminder: action.payload,
        isSuccess: true,
      };

    case ActionTypes.EDIT_REMINDER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_REMINDER:
      return {
        ...state,
      };

    case ActionTypes.DELETE_REMINDER_SUCCESS:
      return {
        ...state,
        deleteReminderSuccess: true,
      };

    case ActionTypes.DELETE_REMINDER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_ASSIGN:
      return {
        ...state,
      };

    case ActionTypes.DELETE_ASSIGN_SUCCESS:
      return {
        ...state,
        deleteAssignSuccess: true,
      };

    case ActionTypes.DELETE_ASSIGN_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.ADD_ASSIGN:
      return {
        ...state,
      };

    case ActionTypes.ADD_ASSIGN_SUCCESS:
      return {
        ...state,
        dataAssign: action.payload,
        isSuccess: true,
      };

    case ActionTypes.ADD_ASSIGN_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.ADD_FOLLOWER:
      return {
        ...state,
      };

    case ActionTypes.ADD_FOLLOWER_SUCCESS:
      return {
        ...state,
        dataFollower: action.payload,
        isSuccess: true,
      };

    case ActionTypes.ADD_FOLLOWER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_FOLLOWER:
      return {
        ...state,
      };

    case ActionTypes.DELETE_FOLLOWER_SUCCESS:
      return {
        ...state,
        deleteFollowerSuccess: true,
      };

    case ActionTypes.DELETE_FOLLOWER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.OPEN_TASK_EDIT:
      return {
        ...state,
        isLoading: true,
        taskDetail: action.payload,
        onOpenEditTask: true,
      };

    case ActionTypes.CLOSE_TASK_EDIT:
      return {
        ...state,
        isLoading: false,
        onOpenEditTask: false,
      };

    case ActionTypes.OPEN_TASK_CREATE:
      return {
        ...state,
        onOpenCreateTask: true,
      };

    case ActionTypes.CLOSE_TASK_CREATE:
      return {
        ...state,
        onOpenCreateTask: false,
      };

    case ActionTypes.GET_TASK_BY_PROJECT:
      return {
        ...state,
        isLoading: true,
        taskDelete: "",
        isCreateSuccess: false,
        isChangePriority: false,
        isChangeStatus: false,
        isSuccess: false,
      };

    case ActionTypes.GET_TASK_BY_PROJECT_SUCCESS:
      return {
        ...state,
        listTask: action.payload,
        isLoading: false,
      };

    case ActionTypes.GET_TASK_BY_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.GET_TASK_SUMMARY_BY_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.GET_TASK_SUMMARY_BY_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        taskSummaryProject: action.payload,
      };
    case ActionTypes.GET_TASK_SUMMARY_BY_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.FILTER_TASK_BY_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FILTER_TASK_BY_PROJECT_SUCCESS:
      // let filterTaskProject = { data: action.payload };
      return {
        ...state,
        isLoading: false,
        listTask: action.payload,
      };
    case ActionTypes.FILTER_TASK_BY_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    // case ActionTypes.FILTER_TASK_REQUEST:
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // case ActionTypes.FILTER_TASK_SUCCESS:
    //   // let filterTask = { data: action.payload };
    //   return {
    //     ...state,
    //     isLoading: false,
    //     listTask: action.payload,
    //   };
    // case ActionTypes.FILTER_TASK_FAILED:
    //   return {
    //     ...state,
    //     isLoading: false,
    //   };
    case ActionTypes.GET_LIST_STAFF:
      return {
        ...state,
      };

    case ActionTypes.GET_LIST_STAFF_SUCCESS:
      return {
        ...state,
        listStaff: action.payload,
      };

    case ActionTypes.GET_LIST_STAFF_FAILED:
      return {
        ...state,
      };
    case ActionTypes.CLOSE_TASK_BY_LEADS:
      return {
        ...state,
        isCloseTaskByLead: action.payload,
      };
    case ActionTypes.COPY_TASK_PENDING:
      return { ...state, isLoading: true };
    case ActionTypes.COPY_TASK_SUCCESS:
      return {
        ...state,
        onOpenTaskDetail: false,
        isLoading: false,
        taskCopy: Array.isArray(action.payload) ? action.payload[0] : action.payload,
      };
    case ActionTypes.COPY_TASK_FAILURE:
      return { ...state, isLoading: false };

    case ActionTypes.GET_FILE_IN_TASK_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.GET_FILE_IN_TASK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listFiles: action.payload,
      };
    case ActionTypes.GET_FILE_IN_TASK_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.DELETE_TAG_IN_TASK_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.DELETE_TAG_IN_TASK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleteTag: true,
        isSuccess: true,
      };
    case ActionTypes.DELETE_TAG_IN_TASK_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.BULK_ACTIONS_TASK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.BULK_ACTIONS_TASK_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        bulkActions: action.payload,
        isLoading: false,
      };

    case ActionTypes.BULK_ACTIONS_TASK_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_LIST_STATUS_TASK_CURRENT_WEEK_PENDING:
      return {
        ...state,
        isLoading: true
      }
    case ActionTypes.FETCH_LIST_STATUS_TASK_CURRENT_WEEK_SUCCESS:
      return {
        ...state,
        listTaskCurrentWeekStatus: action.payload,
        isLoading: false
      }
    case ActionTypes.FETCH_LIST_STATUS_TASK_CURRENT_WEEK_FAILED:
      return {
        ...state,
        isLoading: false
      }
    default:
      return { ...state };
  }
};

export default TaskReducer;
