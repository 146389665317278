import ActionTypes from "./types";

export const getListPermissionsRequest = () => {
    return {
        type: ActionTypes.GET_LIST_PERMISSIONS,
    };
};

export const getListPermissionsSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_PERMISSIONS_SUCCESS,
        payload: data,
    };
};

export const getListPermissionsFailed = () => {
    return {
        type: ActionTypes.GET_LIST_PERMISSIONS_FAILED,
    };
};

export const getListRolesRequest = () => {
    return {
        type: ActionTypes.GET_LIST_ROLES,
    };
};

export const getListRolesSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_ROLES_SUCCESS,
        payload: data,
    };
};

export const getListRolesFailed = () => {
    return {
        type: ActionTypes.GET_LIST_ROLES_FAILED,
    };
};

export const getListStaffRequest = () => {
    return {
        type: ActionTypes.GET_LIST_STAFF_REQUEST,
    };
};

export const getListStaffSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_STAFF_SUCCESS,
        payload: data,
    };
};

export const getListStaffFailed = () => {
    return {
        type: ActionTypes.GET_LIST_STAFF_FAILED,
    };
};

export const createNewStaffRequest = (data) => {
    return {
        type: ActionTypes.CREATE_STAFF_REQUEST,
        payload: data,
    };
};

export const createNewStaffSuccess = () => {
    return {
        type: ActionTypes.CREATE_STAFF_SUCCESS,
        // payload: data,
    };
};

export const createNewStaffFailed = () => {
    return {
        type: ActionTypes.CREATE_STAFF_FAILED,
    };
};

export const editStaffRequest = (data) => {
    return {
        type: ActionTypes.EDIT_STAFF_REQUEST,
        payload: data,
    };
};

export const editStaffSuccess = (data) => {
    return {
        type: ActionTypes.EDIT_STAFF_SUCCESS,
        payload: data,
    };
};

export const editStaffFailed = () => {
    return {
        type: ActionTypes.EDIT_STAFF_FAILED,
    };
};

export const deleteStaffRequest = (data) => {
    return {
        type: ActionTypes.DELETE_STAFF_REQUEST,
        payload: data,
    };
};

export const deleteStaffSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_STAFF_SUCCESS,
        payload: data,
    };
};

export const deleteStaffFailed = () => {
    return {
        type: ActionTypes.DELETE_STAFF_FAILED,
    };
};

export const staffStatus = () => {
    return {
        type: ActionTypes.STAFF_STATUS,
    };
};

export const editStaffItem = (data) => {
    return {
        type: ActionTypes.EDIT_STAFF_ITEM,
        payload: data,
    };
};

export const filterStaffRequest = (search, params) => {
    return {
        type: ActionTypes.FILTER_STAFF_REQUEST,
        search,
        params,
    };
};

export const filterStaffSuccess = (data) => {
    return {
        type: ActionTypes.FILTER_STAFF_SUCCESS,
        payload: data,
    };
};

export const filterStaffFailed = () => {
    return {
        type: ActionTypes.FILTER_STAFF_FAILED,
    };
};

export const getDepartmentsRequest = () => {
    return {
        type: ActionTypes.GET_DEPARTMENTS_REQUEST,
    };
};

export const getDepartmentsSuccess = (data) => {
    return {
        type: ActionTypes.GET_DEPARTMENTS_SUCCESS,
        payload: data,
    };
};

export const getDepartmentsFailed = () => {
    return {
        type: ActionTypes.GET_DEPARTMENTS_FAILED,
    };
};

export const getStaffEditRequest = (id) => {
    return {
        type: ActionTypes.GET_STAFF_EDIT_REQUEST,
        payload: id,
    };
};

export const getStaffEditSuccess = (data) => {
    return {
        type: ActionTypes.GET_STAFF_EDIT_SUCCESS,
        payload: data,
    };
};

export const getStaffEditFailed = () => {
    return {
        type: ActionTypes.GET_STAFF_EDIT_FAILED,
    };
};

// staff view
export const getStaffNotePending = (id) => {
    return {
        type: ActionTypes.GET_STAFF_NOTE_PENDING,
        payload: { id: id },
    };
};

export const getStaffNoteSuccess = (data) => {
    return {
        type: ActionTypes.GET_STAFF_NOTE_SUCCESS,
        payload: data,
    };
};
export const getStaffNoteFailure = () => {
    return {
        type: ActionTypes.GET_STAFF_NOTE_FAILURE,
    };
};
export const addNoteStaffPending = (id, description) => {
    return {
        type: ActionTypes.ADD_STAFF_NOTE_PENDING,
        payload: {
            id: id,
            params: { description: description },
        },
    };
};

export const addNoteStaffSuccess = (data) => {
    return {
        type: ActionTypes.ADD_STAFF_NOTE_SUCCESS,
        payload: data,
    };
};
export const addNoteStaffFailure = () => {
    return {
        type: ActionTypes.ADD_STAFF_NOTE_FAILURE,
    };
};

export const getStaffTimesheetPending = (id, time_sheet) => {
    return {
        type: ActionTypes.GET_STAFF_TIMESHEET_PENDING,
        payload: {
            id: id,
            params: { time_sheet: time_sheet },
        },
    };
};

export const getStaffTimesheetSuccess = (data) => {
    return {
        type: ActionTypes.GET_STAFF_TIMESHEET_SUCCESS,
        payload: data,
    };
};
export const getStaffTimesheetFailure = () => {
    return {
        type: ActionTypes.GET_STAFF_TIMESHEET_FAILURE,
    };
};
export const setStaffStatusPending = (id) => {
    return {
        type: ActionTypes.SET_STAFF_STATUS_PENDING,
        payload: {
            id: id,
        },
    };
};

export const setStaffStatusSuccess = () => {
    return {
        type: ActionTypes.SET_STAFF_STATUS_SUCCESS,
    };
};
export const setStaffStatusFailure = () => {
    return {
        type: ActionTypes.SET_STAFF_STATUS_FAILURE,
    };
};

export const resetPasswordRequest = (id) => {
    return {
        type: ActionTypes.RESET_PASSWORD_PENDING,
        payload: {
            id: id,
        },
    };
};

export const resetPasswordSuccess = () => {
    return {
        type: ActionTypes.RESET_PASSWORD_SUCCESS,
    };
};
export const resetPasswordFailure = () => {
    return {
        type: ActionTypes.RESET_PASSWORD_FAILURE,
    };
};

export const changePasswordRequest = (data) => {
    return {
        type: ActionTypes.CHANGE_PASSWORD_PENDING,
        payload: data,
    };
};

export const changePasswordSuccess = () => {
    return {
        type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
    };
};
export const changePasswordFailure = () => {
    return {
        type: ActionTypes.CHANGE_PASSWORD_FAILURE,
    };
};

export const staffChangeAvatarRequest = (data) => {
    return {
        type: ActionTypes.STAFF_CHANGE_AVATAR_REQUEST,
        payload: data,
    };
};

export const staffChangeAvatarSuccess = (data) => {
    return {
        type: ActionTypes.STAFF_CHANGE_AVATAR_SUCCESS,
        payload: data,
    };
};

export const staffChangeAvatarFailed = () => {
    return {
        type: ActionTypes.STAFF_CHANGE_AVATAR_FAILED,
    };
};