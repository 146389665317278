// @flow
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import  i18n  from 'i18next';
import ActionTypes from "./types";
import {
    getTaskByLeadSuccess,
    getTaskByLeadFailed,
    createTaskFailed,
    createTaskSuccess,
    fetchListStatusLeadSuccess,
    fetchListStatusLeadFailed,
    getTaskSuccess,
    getTaskFailed,
    changeTaskPrioritySuccess,
    changeTaskPriorityFailed,
    changeTaskStatusSuccess,
    changeTaskStatusFailed,
    getCountTaskSuccess,
    getCountTaskFailed,
    searchRelatedSuccess,
    searchRelatedFailed,
    searchRelatedByFilterSuccess,
    searchRelatedByFilterFailed,
    editTaskSuccess,
    editTaskFailed,
    deleteTaskSuccess,
    deleteTaskFailed,
    getTaskByIdSuccess,
    getTaskByIdFailed,
    addNewTimerSuccess,
    addNewTimerFailed,
    deleteNewTimerSuccess,
    deleteNewTimerFailed,
    addChecklistSuccess,
    addChecklistFailed,
    deleteChecklistSuccess,
    deleteChecklistFailed,
    addCommentActionSuccess,
    addCommentActionFailed,
    editCommentActionSuccess,
    editCommentActionFailed,
    deleteCommentActionSuccess,
    deleteCommentActionFailed,
    addReminderActionSuccess,
    addReminderActionFailed,
    addAssignActionSuccess,
    addAssignActionFailed,
    addFollowerActionSuccess,
    addFollowerActionFailed,
    editReminderActionSuccess,
    editReminderActionFailed,
    deleteReminderActionSuccess,
    deleteReminderActionFailed,
    deleteAssignActionSuccess,
    deleteAssignActionFailed,
    deleteFollowerActionSuccess,
    deleteFollowerActionFailed,
    getTaskByProjectSuccess,
    getTaskByProjectFailed,
    getTaskSummaryByProjectSuccess,
    getTaskSummaryByProjectFailed,
    filterTaskByProjectSuccess,
    filterTaskByProjectFailed,
    filterTaskSuccess,
    filterTaskFailed,
    getListStaffSuccess,
    getListStaffFailed,
    getTask,
    closePopDel,
    closeTaskEdit,
    copyTaskFailure,
    copyTaskSuccess,
    getListFileSuccess,
    getListFileFailed,
    deleteTagInTaskSuccess,
    deleteTagInTaskFailed,
    bulkActionsTaskSuccess,
    bulkActionsTaskFailed,
    fetchListTaskCurrentWeekStatusFailed,
    fetchListTaskCurrentWeekStatusSuccess,
} from "./actions";
import {
    getListTaskBylead,
    createTask,
    getListStatus,
    getListTask,
    changePriorityTask,
    getCountTask,
    searchRelated,
    editTask,
    deleteTask,
    changeStatusTask,
    searchRelatedByFilter,
    getTaskById,
    addTimer,
    deleteTimer,
    addChecklist,
    deleteChecklist,
    addComment,
    editComment,
    deleteComment,
    addReminder,
    editReminder,
    deleteReminder,
    addAssign,
    addFollower,
    deleteAssign,
    deleteFollower,
    getListTaskByProject,
    getTaskSummaryByProject,
    filterTaskByProject,
    filterTask,
    getListStaff,
    copyTask,
    getFilesInTask,
    deleteTagsInTask,
    bulkActionsTask,
    fetchListTaskCurrentWeekStatus,
} from "./api";
import { enqueueSnackbar } from "../../../redux/CommonReducer";
import {
    throwErrorMessage,
    parseDataNotifications,
} from "../../../helpers/HandleError";
import { responeCode, typeMessage } from "../../../constants/Const";

function* getListTaskByLeadSaga(action) {

    // const { payload } = data;
    try {
        const response = yield call(getListTaskBylead, action);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getTaskByLeadSuccess(response.data.result));
        } else {
            yield put(getTaskByLeadFailed(response.data.error_mess));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getTaskByLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* createTaskSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(createTask, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(createTaskSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.createTaskSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(createTaskFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.createTaskFailed'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(createTaskFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchListStatusSaga() {
    try {
        const response = yield call(getListStatus);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListStatusLeadSuccess(response.data.result));
        } else {
            yield put(fetchListStatusLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListStatusLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getTaskSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(getListTask, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getTaskSuccess(response.data.result));
        } else {
            yield put(getTaskFailed(response.data.error_mess));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getTaskFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* changeTaskPrioritySaga(data) {
    const { payload } = data;
    try {
        const response = yield call(changePriorityTask, payload);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(changeTaskPrioritySuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.updateTaskPrioritySuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(changeTaskPriorityFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.updateTaskPriorityFailed'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(changeTaskPriorityFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* changeTaskStatusSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(changeStatusTask, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(changeTaskStatusSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.updateTaskStatusSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(changeTaskStatusFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.updateTaskStatusFailed'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(changeTaskStatusFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchCountTask() {
    try {
        const response = yield call(getCountTask);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getCountTaskSuccess(response.data.result));
        } else {
            yield put(getCountTaskFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getCountTaskFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchSearchRelated(data) {
    const { payload } = data;
    try {
        const response = yield call(searchRelated, payload);
        if (response && response.data && response.status === 200) {
            yield put(searchRelatedSuccess(response.data.Project));
        } else {
            yield put(searchRelatedFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(searchRelatedFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchSearchRelatedByFilter(data) {
    const { payload } = data;
    try {
        const response = yield call(searchRelatedByFilter, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(searchRelatedByFilterSuccess(response.data.result));
        } else {
            yield put(searchRelatedByFilterFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(searchRelatedByFilterFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchEditTask(data) {
    const { payload, id } = data;
    try {
        const response = yield call(editTask, payload, id);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(editTaskSuccess(response.data.result));
            // console.warn("_______________", response.data.result);

            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.updateTaskSuccess'),
                        typeMessage.success
                    )
                )
            );
            yield put(closeTaskEdit());
        } else {
            yield put(editTaskFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.updateTaskFailed'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(editTaskFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchDeleteTask({ payload }) {
    const { data } = payload;
    try {
        const response = yield call(deleteTask, data);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteTaskSuccess(response.data.error_code));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.deleteTaskSuccess'),
                        typeMessage.success
                    )
                )
            );
            yield put(closePopDel());
        } else {
            yield put(deleteTaskFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.deleteTaskFailed'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteTaskFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchTaskById(data) {
    const { payload } = data;
    try {
        const response = yield call(getTaskById, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getTaskByIdSuccess(response.data.result));
        } else {
            yield put(getTaskByIdFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getTaskByIdFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* createTimerSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(addTimer, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addNewTimerSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.createTimerSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(addNewTimerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.createTimerFailed'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(addNewTimerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteTimerSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(deleteTimer, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteNewTimerSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.deleteTimerSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteNewTimerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.deleteTimerFailed'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteNewTimerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* createChecklistSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(addChecklist, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addChecklistSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.createChecklistSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(addChecklistFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.createChecklistFailed'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(addChecklistFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteChecklistSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(deleteChecklist, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteChecklistSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.deleteChecklistSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteChecklistFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.deleteChecklistFailed'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteChecklistFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* addCommentSaga({ payload }) {
    const { data } = payload;
    try {
        const response = yield call(addComment, data);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addCommentActionSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.addCommentSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(addCommentActionFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.addCommentFailed'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(addCommentActionFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* editCommentSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(editComment, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(editCommentActionSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.editCommentSuccess'), // Dùng khóa i18n
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(editCommentActionFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.editCommentFailed'), // Dùng khóa i18n
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(editCommentActionFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteCommentSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(deleteComment, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteCommentActionSuccess(response.data.result));

            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.deleteCommentSuccess'), // Dùng khóa i18n
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteCommentActionFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.deleteCommentFailed'), // Dùng khóa i18n
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteCommentActionFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* addReminderSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(addReminder, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addReminderActionSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.addReminderSuccess'), // Dùng khóa i18n
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(addReminderActionFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.addReminderFailed'), // Dùng khóa i18n
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(addReminderActionFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* editReminderSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(editReminder, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(editReminderActionSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.editReminderSuccess'), // Dùng khóa i18n
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(editReminderActionFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.editReminderFailed'), // Dùng khóa i18n
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(editReminderActionFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteReminderSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(deleteReminder, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteReminderActionSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.deleteReminderSuccess'), // Dùng khóa i18n
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteReminderActionFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.deleteReminderFailed'), // Dùng khóa i18n
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteReminderActionFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* addAssignSaga(action) {
    const { data } = action.payload;
    try {
        const response = yield call(addAssign, data);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addAssignActionSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.addAssignSuccess'), // Dùng khóa i18n
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(addAssignActionFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.addAssignFailed'), // Dùng khóa i18n
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(addAssignActionFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteAssignSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(deleteAssign, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteAssignActionSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.deleteAssignSuccess'), // Dùng khóa i18n
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteAssignActionFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.deleteAssignFailed'), // Dùng khóa i18n
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteAssignActionFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteFollowerSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(deleteFollower, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteFollowerActionSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.deleteFollowerSuccess'), // Dùng khóa i18n
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteFollowerActionFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.deleteFollowerFailed'), // Dùng khóa i18n
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteFollowerActionFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* addFollowerSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(addFollower, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addFollowerActionSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.addFollowerSuccess'), // Dùng khóa i18n
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(addFollowerActionFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.addFollowerFailed'), // Dùng khóa i18n
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(addFollowerActionFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListTaskByProjectSaga(data) {
    const { id, params } = data;
    try {
        const response = yield call(getListTaskByProject, id, params);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getTaskByProjectSuccess(response.data.result));
        } else {
            yield put(getTaskByProjectFailed(response.data.error_mess));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getTaskByProjectFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getTaskSummaryByProjectSaga(data) {
    const { id } = data;
    try {
        const response = yield call(getTaskSummaryByProject, id);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getTaskSummaryByProjectSuccess(response.data.result));
        } else {
            yield put(getTaskSummaryByProjectFailed(response.data.error_mess));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getTaskSummaryByProjectFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* filterTaskByProjectSaga(action) {
    try {
        const response = yield call(
            filterTaskByProject,
            action.projectId,
            action.data
        );

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(filterTaskByProjectSuccess(response.data.result));
        } else {
            yield put(filterTaskByProjectFailed(response.data.error_mess));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(filterTaskByProjectFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

// function* filterTaskSaga(action) {
//     try {
//         const response = yield call(
//             filterTask,

//             action.payload
//         );
//         if (
//             response &&
//             response.data &&
//             response.data.error_code === responeCode.success
//         ) {
//             yield put(filterTaskSuccess(response.data.result));
//         } else {
//             yield put(filterTaskFailed(response.data.error_mess));
//             yield put(
//                 enqueueSnackbar(
//                     parseDataNotifications(
//                         response.data.error_mess,
//                         typeMessage.error
//                     )
//                 )
//             );
//         }
//     } catch (error) {
//         yield put(filterTaskFailed());
//         yield put(
//             enqueueSnackbar(
//                 parseDataNotifications(
//                     throwErrorMessage(error),
//                     typeMessage.warning
//                 )
//             )
//         );
//     }
// }

function* getListStaffSaga(action) {
    try {
        const response = yield call(
            getListStaff,
            action.projectId,
            action.data
        );
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListStaffSuccess(response.data.result));
        } else {
            yield put(getListStaffFailed(response.data.error_mess));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListStaffFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* copyTaskSaga(action) {
    const { id, body } = action.payload;
    try {
        const response = yield call(copyTask, id, body);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(copyTaskSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.copyTaskSuccess', { taskId: response.data.result[0].id }),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(copyTaskFailure(response.data.error_mess));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.copyTaskFailure', { error: response.data.error_mess }),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(copyTaskFailure());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* fetchListFilesSaga(action) {
    const { id } = action.payload;
    try {
        const response = yield call(getFilesInTask, id);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListFileSuccess(response.data.result));
        } else {
            yield put(getListFileFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListFileFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* deleteTagInTaskSaga(action) {
    try {
        const response = yield call(deleteTagsInTask, action.payload);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteTagInTaskSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.deleteTagSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteTagInTaskFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.deleteTagFailure'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteTagInTaskFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* bulkActionsTaskSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(bulkActionsTask, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(bulkActionsTaskSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.bulkActionsSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(bulkActionsTaskFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('task.taskAction.bulkActionsFailure'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(bulkActionsTaskFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchTaskCurrentWeekStatusSaga() {
    try {
        const response = yield call(fetchListTaskCurrentWeekStatus);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListTaskCurrentWeekStatusSuccess(response.data.result));
        } else {
            yield put(fetchListTaskCurrentWeekStatusFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListTaskCurrentWeekStatusFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* defaultSaga() {
    yield takeEvery(ActionTypes.DELETE_TAG_IN_TASK_PENDING, deleteTagInTaskSaga);
    yield takeEvery(ActionTypes.GET_FILE_IN_TASK_PENDING, fetchListFilesSaga);
    yield takeEvery(ActionTypes.GET_TASK_BY_LEAD, getListTaskByLeadSaga);
    yield takeEvery(ActionTypes.CREATE_NEW_TASK, createTaskSaga);
    yield takeEvery(ActionTypes.FETCH_STATUS_LEADS, fetchListStatusSaga);
    yield takeEvery(ActionTypes.GET_TASK, getTaskSaga);
    yield takeEvery(ActionTypes.CHANGE_TASK_PRIORITY, changeTaskPrioritySaga);
    yield takeEvery(ActionTypes.CHANGE_TASK_STATUS, changeTaskStatusSaga);
    yield takeEvery(ActionTypes.GET_COUNT_TASK, fetchCountTask);
    yield takeEvery(ActionTypes.SEARCH_RELATED, fetchSearchRelated);
    yield takeEvery(ActionTypes.SEARCH_RELATED_BY_FILTER, fetchSearchRelatedByFilter);
    yield takeEvery(ActionTypes.EDIT_TASK, fetchEditTask);
    yield takeEvery(ActionTypes.DELETE_TASK, fetchDeleteTask);
    yield takeEvery(ActionTypes.GET_TASK_BY_ID, fetchTaskById);
    yield takeEvery(ActionTypes.ADD_NEW_TIMER, createTimerSaga);
    yield takeEvery(ActionTypes.DELETE_TIMER, deleteTimerSaga);
    yield takeEvery(ActionTypes.ADD_NEW_CHECKLIST, createChecklistSaga);
    yield takeEvery(ActionTypes.DELETE_CHECKLIST, deleteChecklistSaga);
    yield takeEvery(ActionTypes.ADD_COMMENT, addCommentSaga);
    yield takeEvery(ActionTypes.EDIT_COMMENT, editCommentSaga);
    yield takeEvery(ActionTypes.DELETE_COMMENT, deleteCommentSaga);
    yield takeEvery(ActionTypes.ADD_REMINDER, addReminderSaga);
    yield takeEvery(ActionTypes.EDIT_REMINDER, editReminderSaga);
    yield takeEvery(ActionTypes.DELETE_REMINDER, deleteReminderSaga);
    yield takeEvery(ActionTypes.ADD_ASSIGN, addAssignSaga);
    yield takeEvery(ActionTypes.DELETE_ASSIGN, deleteAssignSaga);
    yield takeEvery(ActionTypes.ADD_FOLLOWER, addFollowerSaga);
    yield takeEvery(ActionTypes.DELETE_FOLLOWER, deleteFollowerSaga);
    yield takeEvery(ActionTypes.GET_TASK_BY_PROJECT, getListTaskByProjectSaga);
    yield takeEvery(ActionTypes.GET_LIST_STAFF, getListStaffSaga);
    yield takeEvery(ActionTypes.
        GET_TASK_SUMMARY_BY_PROJECT_REQUEST,
        getTaskSummaryByProjectSaga
    );
    yield takeEvery(ActionTypes.FILTER_TASK_BY_PROJECT_REQUEST, filterTaskByProjectSaga);
    // yield takeEvery(ActionTypes.FILTER_TASK_REQUEST, filterTaskSaga);
    yield takeEvery(ActionTypes.COPY_TASK_PENDING, copyTaskSaga);
    yield takeEvery(ActionTypes.BULK_ACTIONS_TASK_REQUEST, bulkActionsTaskSaga);
    yield takeEvery(ActionTypes.FETCH_LIST_STATUS_TASK_CURRENT_WEEK_PENDING, fetchTaskCurrentWeekStatusSaga)
}

function* TaskSaga() {
    yield all([fork(defaultSaga)]);
}

export default TaskSaga;
