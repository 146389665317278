import ActionTypes from "./types";

const INIT_STATE = {
    listRoles: [],
    listPermissions: [],
    listStaff: [],
    staffItem: [],
    staffNote: [],
    statusStaff: false,
    listDepartment: [],
    isLoading: false,
    goBackHome: false,
    deleteStaff: false,
    staffTimesheet: [
        {
            task: "Hiep Hoang",

            startTime: "22-01-2021 10:04",

            endTime: "29-01-2021 10:04",

            related: "#8 - phát triển CRM sme - BHOME",

            hourlyRate: "0.00đ",

            timeHour: "00:00",

            timeDecimal: "0",
        },
    ],
    resetPasswordSuccess: false,
    changePasswordSuccess: false,
    staffChangeAvatarSuccess: false,
};

const AdministratorStaffReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_LIST_PERMISSIONS:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.GET_LIST_PERMISSIONS_SUCCESS:
            return {
                ...state,
                listPermissions: action.payload,
                isLoading: false,
            };
        case ActionTypes.GET_LIST_PERMISSIONS_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.GET_LIST_ROLES:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.GET_LIST_ROLES_SUCCESS:
            return {
                ...state,
                listRoles: action.payload,
                isLoading: false,
            };
        case ActionTypes.GET_LIST_ROLES_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.GET_LIST_STAFF_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.GET_LIST_STAFF_SUCCESS:
            return {
                ...state,
                listStaff: action.payload,
                isLoading: false,
                goBackHome: false,
            };

        case ActionTypes.GET_LIST_STAFF_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.CREATE_STAFF_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CREATE_STAFF_SUCCESS:
            // state.listStaff.data.splice(0, 1, action.payload);
            return {
                ...state,
                isLoading: false,
                goBackHome: true,
                // listStaff: state.listStaff,
                statusStaff: false,
            };

        case ActionTypes.CREATE_STAFF_FAILED:
            return {
                ...state,
                isLoading: false,
                statusStaff: false,
            };
        case ActionTypes.EDIT_STAFF_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.EDIT_STAFF_SUCCESS:
            // let itemIndex = state.listStaff.data.findIndex(
            //     (item) => item.staff_id === action.payload.staff_id
            // );
            // if (itemIndex !== -1) {
            //     state.listStaff.data.splice(itemIndex, 1, action.payload);
            // }
            return {
                ...state,
                isLoading: false,
                // listStaff: state.listStaff,
                // goBackHome: true,
                staffItem: {},
                statusStaff: false,
            };

        case ActionTypes.EDIT_STAFF_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.DELETE_STAFF_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.DELETE_STAFF_SUCCESS:
            return {
                ...state,
                isLoading: false,
                deleteStaff: true,
            };

        case ActionTypes.DELETE_STAFF_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.FILTER_STAFF_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.FILTER_STAFF_SUCCESS:
            return {
                ...state,
                listStaff: action.payload,
                isLoading: false,
                goBackHome: false,
                deleteStaff: false,
            };

        case ActionTypes.FILTER_STAFF_FAILED:
            return {
                ...state,
                isLoading: false,
                deleteStaff: false,
            };

        case ActionTypes.STAFF_STATUS:
            state.statusStaff = !state.statusStaff;
            return state;

        case ActionTypes.GET_DEPARTMENTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.GET_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                listDepartment: action.payload,
                isLoading: false,
            };
        case ActionTypes.GET_DEPARTMENTS_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.GET_STAFF_EDIT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.GET_STAFF_EDIT_SUCCESS:
            return {
                ...state,
                staffItem: action.payload,
                isLoading: false,
            };
        case ActionTypes.GET_STAFF_EDIT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.GET_STAFF_NOTE_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.GET_STAFF_NOTE_SUCCESS:
            return {
                ...state,
                staffNote: action.payload,
                isLoading: false,
            };
        case ActionTypes.GET_STAFF_NOTE_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.GET_STAFF_TIMESHEET_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.GET_STAFF_TIMESHEET_SUCCESS:
            return {
                ...state,
                staffTimesheet: action.payload,
                isLoading: false,
            };
        case ActionTypes.GET_STAFF_TIMESHEET_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.SET_STAFF_STATUS_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.SET_STAFF_STATUS_SUCCESS:
            return {
                ...state,
                // staffTimesheet: action.payload,
                isLoading: false,
            };
        case ActionTypes.SET_STAFF_STATUS_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.RESET_PASSWORD_PENDING:
            return {
                ...state,
                resetPasswordSuccess: false,
                isLoading: true,
            };
        case ActionTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordSuccess: true,
                isLoading: false,
            };
        case ActionTypes.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resetPasswordSuccess: false,
                isLoading: false,
            };
        case ActionTypes.CHANGE_PASSWORD_PENDING:
            return {
                ...state,
                changePasswordSuccess: false,
                isLoading: true,
            };
        case ActionTypes.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordSuccess: true,
                isLoading: false,
            };
        case ActionTypes.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePasswordSuccess: false,
                isLoading: false,
            };
        case ActionTypes.STAFF_CHANGE_AVATAR_REQUEST:
            return {
                ...state,
                staffChangeAvatarSuccess: false,
                isLoading: true,
            };
        case ActionTypes.STAFF_CHANGE_AVATAR_SUCCESS:
            return {
                ...state,
                staffChangeAvatarSuccess: true,
                isLoading: false,
            };
        case ActionTypes.STAFF_CHANGE_AVATAR_FAILED:
            return {
                ...state,
                staffChangeAvatarSuccess: false,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

export default AdministratorStaffReducer;
