import ActionTypes from "./type";

export const getCustomFieldCreditNotesRequest = (params) => {
    return {
        type: ActionTypes.GET_SALE_CREDIT_NOTES_REQUEST,
        params,
    };
};

export const getCustomFieldCreditNotesSuccess = (data) => {
    return {
        type: ActionTypes.GET_SALE_CREDIT_NOTES_SUCCESS,
        payload: data,
    };
};

export const getCustomFieldCreditNotesFailed = () => {
    return {
        type: ActionTypes.GET_SALE_CREDIT_NOTES_FAILED,
    };
};

export const getListCreditNotePending = (params) => {
    return {
        type: ActionTypes.GET_LIST_CREDIT_NOTE_PENDING,
        payload: {
            params: params,
        },
    };
};
export const getListCreditNoteSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_CREDIT_NOTE_SUCCESS,
        payload: {
            data: data,
        },
    };
};
export const getListCreditNoteFailed = () => {
    return {
        type: ActionTypes.GET_LIST_CREDIT_NOTE_FAILED,
    };
};

export const getListCreditNoteCustomerPending = (params) => {
    return {
        type: ActionTypes.GET_LIST_CREDIT_NOTE_CUSTOMER_PENDING,
        payload: {
            params: params,
        },
    };
};
export const getListCreditNoteCustomerSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_CREDIT_NOTE_CUSTOMER_SUCCESS,
        payload: {
            data: data,
        },
    };
};
export const getListCreditNoteCustomerFailed = () => {
    return {
        type: ActionTypes.GET_LIST_CREDIT_NOTE_CUSTOMER_FAILED,
    };
};

export const addCreditNotesRequest = (data) => {
    return {
        type: ActionTypes.ADD_CREDIT_NOTE_REQUEST,
        payload: data,
    };
};

export const addCreditNotesSuccess = (data) => {
    return {
        type: ActionTypes.ADD_CREDIT_NOTE_SUCCESS,
        data,
    };
};

export const addCreditNotesFailed = () => {
    return {
        type: ActionTypes.ADD_CREDIT_NOTE_FAILED,
    };
};

export const getCreditNotesById = (data) => {
    return {
        type: ActionTypes.GET_CREDIT_NOTE_BY_ID,
        payload: data,
    };
};

export const getCreditNotesByIdSuccess = (data) => {
    return {
        type: ActionTypes.GET_CREDIT_NOTE_BY_ID_SUCCESS,
        payload: data,
    };
};

export const getCreditNotesByIdFailed = () => {
    return {
        type: ActionTypes.GET_CREDIT_NOTE_BY_ID_FAILED,
    };
};

export const deleteCreditNotes = (data) => {
    return {
        type: ActionTypes.DELETE_CREDIT_NOTE,
        payload: data,
    };
};

export const deleteCreditNotesSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_CREDIT_NOTE_SUCCESS,
        payload: data,
    };
};

export const deleteCreditNotesFailed = () => {
    return {
        type: ActionTypes.DELETE_CREDIT_NOTE_FAILED,
    };
};

export const editCreditNotesFailed = () => {
    return {
        type: ActionTypes.EDIT_CREDIT_NOTE_FAILED,
    };
};
export const editCreditNotesRequest = (data) => {
    return {
        type: ActionTypes.EDIT_CREDIT_NOTE_REQUEST,
        payload: data,
    };
};

export const editCreditNotesSuccess = () => {
    return {
        type: ActionTypes.EDIT_CREDIT_NOTE_SUCCESS,
    };
};

export const resetCreditNoteSuccess = () => ({
    type: ActionTypes.RESET_CREDIT_NOTE_SUCCESS,
});

