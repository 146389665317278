export default {
    warehouse: {
        warehouseName: "Warehouse name",
        requiredField: "This field is required",
        warehouseType: "Warehouse type",
        warehouseAddress: "Warehouse address",
        cancel: "Cancel",
        save: "Save",
        confirm: "Confirm",
        confirmDeleteWarehouse: "Are you sure you want to delete the warehouse?",
        warehouses: "Warehouses",
        addNewWarehouse: "Add new warehouse",
    },
}