import apiBase from "../../../common/baseAPI";
const CREDIT_NOTES_URL = "creditNotes";
export const getListCreditNote = ({ params }) => {
    return new Promise((resolve, reject) => {
        //         Trong sale => chọn creditNote
        // Get dữ liệu bị sai
        // Truyền phương thức: GET
        // Đường dẫn api: api/creditNotes?limit=15&&page=1
        return apiBase
            .get(CREDIT_NOTES_URL, {
                params: params,
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const fetchListCreditNotesCustomerRequest = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(
                `${CREDIT_NOTES_URL}/${action.params.id}?search=${action.params.search}&&limit=${action.params.limit}&&page=${action.params.page}`
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const fetchListCreditNotesRequest = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(
                `${CREDIT_NOTES_URL}/${action.id}?search=${action.params.search}&&limit=${action.params.limit}&&page=${action.params.page}`
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const addCreditNotesRequest = (data) => {
    return new Promise((resolve, reject) => {
      return apiBase
        .post(`${CREDIT_NOTES_URL}`, data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  
  export const getCreditNotesByIdRequest = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${CREDIT_NOTES_URL}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
  };
  
  export const deleteCreditNotesRequest = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${CREDIT_NOTES_URL}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
  };
  
  export const editCreditNotes = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${CREDIT_NOTES_URL}/${data.id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
  };

