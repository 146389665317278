export default {
    expense: {
        listExpense: "List Expense",
        deleteTitle: "Delete Expense",
        index: {
            record: "Record expense",
            add: "New expense",
            edit: "Edit expense",
            summary: 'Summary',
            confirmDelete: "Are you want to delete?",
            titleFilter: "Filter",
            keywordFind: "Search",
            assigned: "Assigned",
            delete: "Delete",
            cancel: "Cancel",
            close: "Close",
            status: "Status",
            source: "Source",
            view: "View",
            yes: "Yes",
            no: "No",
            save: "Save",
        },
        common: {
            id: "Id",
            name: "Name",
            category: "Category",
            amount: "Amount",
            expenseReceipt: "Expense Receipt",
            date: "Date",
            project: "Project",
            customer: "Customer",
            bill: "Bill",
        },
        view: {
            cyclesRemaining: "Cycles Remaining",
            nextExpenseDate: "Next Expense Date",
            nextExpenseDateTip: "Expense will be recreated on specific hour of the day based from the setting located at Setup->Settings-Cron Job",
            amount: "Amount",
            totalWithTax: "Total with tax",
            note: "Note"
        },
        new: {
            title1: "Add new expense",
            title2: "Advanced Options",
            name: "Expense name",
            note: "Note",
            category: "Expense Category",
            date: "Expense Date",
            amount: "Amount",
            customer: "Customer",
            project: "Project",
            billable: "Billable",
            createInvoice: "Auto Create Invoice",
            sendInvoice: "Send the invoice to customer email when expense re-created",
            currency: "Currency",
            tax1: "Tax 1",
            tax2: "Tax 2",
            paymentMode: "Payment mode",
            reference: "Reference #",
            repeatEvery: "Repeat every",
            totalCycles: "Total Cycles",
        },
        tasks: {
            create: "New Task",
            expense: "Expense",
            insertChecklistTemplates: "Insert Checklist Templates",
        },
        action: {
            createExpensesSuccess: "Create Expense Succes",
            createExpensesFailed: "Create Expense Failed",
            updateExpensesSuccess: "Update Expense Succes",
            updateExpensesFailed: "Update Expense Failed",
            deleteExpensesSuccess: "Delete Expense Succes",
            deleteExpensesFailed: "Delete Expense Failed",
        }
    }

}