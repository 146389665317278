export default {
    expense: {
        listExpense: "Danh sách chi phí",
        deleteTitle: "Xóa chi phí",
        index: {
            record: "Báo cáo chi phí",
            add: "Dự án mới",
            summary: "Tóm tắt",
            confirmDelete: "Bạn có chắc chắn muốn xóa không?",
            titleFilter: "Bộ lọc",
            keywordFind: "Tìm kiếm",
            assigned: "Được giao",
            delete: "Xóa",
            cancel: "Hủy",
            status: "Trạng thái",
            source: "Nguồn",
            view: "Xem",
            edit: "Chỉnh sửa",
            yes: "Có",
            no: "Không",
            close: "Đóng", 
            save: "Lưu lại",          
        },
        common: {
            id: "Id",
            name: "Tên",
            category: "Phân loại",
            amount: "Số tiền",
            expenseReceipt: "Hóa đơn chi",
            date: "Ngày",
            project: "Dự án",
            customer: "Khác hàng",
            bill: "Hóa đơn",
        },
        new: {
            title1: "Thêm chi phí mới",
            title2: "Tùy chọn nâng cao",
            name: "Tên chi phí",
            note: "Ghi chú",
            category: "Danh mục chi phí",
            date: "Ngày",
            amount: "Số tiền",
            customer: "Khách hàng",
            project: "Dự án",
            billable: "Có thể lập hóa đơn",
            createInvoice: "Tự động tạo hóa đơn",
            sendInvoice: "Gửi hóa đơn đến email khách hàng khi chi phí được tạo lại",
            currency: "Loại tiền",
            tax1: "Thuế 1",
            tax2: "Thuế 2",
            paymentMode: "Hình thức thanh toán",
            reference: "Tham chiếu #",
            repeatEvery: "Lặp lại mỗi",
            totalCycles: "Tổng số chu kỳ"
            
        },
        action:{
            createExpensesSuccess:  "Báo cáo chi phí thành công",
            updateExpensesSuccess:  "Cập nhật chi phí thành công",
            createExpensesFailed:  "Báo cáo chi phí thất bại",
            updateExpensesFailed:  "Cập nhật chi phí thất bại",
            deleteExpensesSuccess:  "Xóa chi phí thành công",
            deleteExpensesFailed:  "Xóa chi phí thất bại",
        }
    }

}