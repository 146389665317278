import { all, call, fork, put, takeEvery } from "@redux-saga/core/effects";
import { responeCode, typeMessage } from "../../../constants/Const";
import {
    parseDataNotifications,
    throwErrorMessage,
} from "../../../helpers/HandleError";
import { enqueueSnackbar } from "../../../redux/CommonReducer";
import { addCreditNotesRequest, deleteCreditNotesRequest, editCreditNotes, fetchListCreditNotesCustomerRequest, fetchListCreditNotesRequest, getCreditNotesByIdRequest, getListCreditNote } from "./api";
import ActionTypes from "./type";
import { addCreditNotesFailed, addCreditNotesSuccess, deleteCreditNotesFailed, deleteCreditNotesSuccess, editCreditNotesFailed, editCreditNotesSuccess, getCreditNotesByIdFailed, getCreditNotesByIdSuccess, getListCreditNoteCustomerFailed, getListCreditNoteCustomerSuccess, getListCreditNoteFailed, getListCreditNoteSuccess } from "./action";
import i18n from 'i18next';

function* getListCreditNotesSaga(action) {
    try {
        const response = yield call(getListCreditNote, action.payload);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListCreditNoteSuccess(response.data.result));
        } else {
            yield put(getListCreditNoteFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListCreditNoteFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* addCreditNotesSaga(action) {
    try {
        const response = yield call(addCreditNotesRequest, action.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addCreditNotesSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('sales.creditNotes.action.createCreditNotesSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(addCreditNotesFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('sales.creditNotes.action.createCreditNotesFailed'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(addCreditNotesFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchCreditNotesById(data) {
    const { payload } = data;
    try {
        const response = yield call(getCreditNotesByIdRequest, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getCreditNotesByIdSuccess(response.data.result));
        } else {
            yield put(getCreditNotesByIdFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getCreditNotesByIdFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fecthListCreditNotesCustomerSaga(action) {
    console.log("action",action);
    try {
        const response = yield call(fetchListCreditNotesCustomerRequest, action.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListCreditNoteCustomerSuccess(response.data.result));
        } else {
            yield put(getListCreditNoteCustomerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.warning
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListCreditNoteCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fecthListCreditNotesSaga(action) {
    try {
        const response = yield call(fetchListCreditNotesRequest, action);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListCreditNoteSuccess(response.data.result));
        } else {
            yield put(getListCreditNoteFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.warning
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListCreditNoteFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* editCreditNotesSaga(action) {
    try {
        const response = yield call(editCreditNotes, action.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(editCreditNotesSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('sales.creditNotes.action.updateCreditNotesSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(editCreditNotesFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('sales.creditNotes.action.updateCreditNotesFailed'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(editCreditNotesFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteCreditNotesSaga(data) {
    try {
        const response = yield call(deleteCreditNotesRequest, data.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteCreditNotesSuccess(response.data.result));

            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('sales.creditNotes.action.deleteCreditNotesSuccess'),
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteCreditNotesFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        i18n.t('sales.creditNotes.action.deleteCreditNotesFailed'),
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteCreditNotesFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* defaultSaga() {
    yield takeEvery(ActionTypes.GET_LIST_CREDIT_NOTE_PENDING, getListCreditNotesSaga);
    yield takeEvery(ActionTypes.GET_LIST_CREDIT_NOTE_CUSTOMER_PENDING, fecthListCreditNotesCustomerSaga);
    yield takeEvery(ActionTypes.ADD_CREDIT_NOTE_REQUEST, addCreditNotesSaga);
    yield takeEvery(ActionTypes.GET_CREDIT_NOTE_BY_ID, fetchCreditNotesById);
    yield takeEvery(ActionTypes.EDIT_CREDIT_NOTE_REQUEST, editCreditNotesSaga);
    yield takeEvery(ActionTypes.DELETE_CREDIT_NOTE, deleteCreditNotesSaga);
}
function* CreditNotesSaga() {
    yield all([fork(defaultSaga)]);
}
export default CreditNotesSaga;
