const ActionTypes = {
    GET_PAYMENT_REQUEST: "GET_PAYMENT_REQUEST",
    GET_PAYMENT_SUCCESS: "GET_PAYMENT_SUCCESS",
    GET_PAYMENT_FAILED: "GET_PAYMENT_FAILED",
    GET_PAYMENT_MODE_REQUEST: "GET_PAYMENT_MODE_REQUEST",
    GET_PAYMENT_MODE_SUCCESS: "GET_PAYMENT_MODE_SUCCESS",
    GET_PAYMENT_MODE_FAILED: "GET_PAYMENT_MODE_FAILED",
    
};
export default ActionTypes;
