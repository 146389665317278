import React from "react";
import { PrivateRoute } from "./PrivateRoute";
import i18n from "../i18n";

import Support from "../pages/Support";
const SupportNewTicket = React.lazy(() => import("../pages/Support/NewTicket.js"));


export const supportRoute = [
    {
        path: "/support",
        name: `${i18n.t("route.support")}`,
        component: Support,
        route: PrivateRoute,
        roles: ["Director","Admin", ],
        title: "Support",
        exact: true,
    },
    {
        path: "/support/ticket/new",
        name: `New Ticket`,
        component: SupportNewTicket,
        route: PrivateRoute,
        roles: ["Director","ticket"],
        title: "New ticket",
        exact: true,
    },
]