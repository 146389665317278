export default {
    warehouse: {
        warehouseName : "Tên kho",
        requiredField : "Trường này là bắt buộc",
        warehouseType : "Loại kho",
        warehouseAddress : "Địa chỉ kho",
        cancel : "Hủy",
        save : "lưu lại",
        confirm : "xác nhận",
        confirmDeleteWarehouse : "Bạn chắc chắn muốn xóa kho",
        warehouses: "Kho",
        addNewWarehouse : "Thêm mới kho",
    },
}