import ActionTypes from "./types";

const INIT_STATE = {
    isLoading: false,
    listItems: {},
    listTax: [],
    listItemGroups: [],
    isSuccess: false,
};

const ItemsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_SALES_ITEM_PENDING:
            return {
                ...state,
                isLoading: true,
                // isCloseDialog: false,
                // isSuccess: false,
            };

        case ActionTypes.FETCH_SALES_ITEM_SUCCESS:
            // if (!action.payload.data) {
            //     state.listItems = { data: action.payload };
            //     return {
            //         ...state,
            //         listItems: state.listItems,
            //         isLoading: false,
            //     };
            // } else {
            return {
                ...state,
                listItems: action.payload || {},
                isLoading: false,
            };
        // }

        case ActionTypes.FETCH_SALES_ITEM_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.FETCH_SALES_TAX_PENDING:
            return {
                ...state,
                isLoading: true,
                // isCloseDialog: false,
                // isSuccess: false,
            };

        case ActionTypes.FETCH_SALES_TAX_SUCCESS:
            if (!action.payload) {
                state.listTax = action.payload;
                return {
                    ...state,
                    listTax: state.listTax,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    listTax: action.payload,
                    isLoading: false,
                };
            }

        case ActionTypes.FETCH_SALES_TAX_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.FETCH_LIST_ITEM_GROUPS_PENDING:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_ITEM_GROUPS_SUCCESS:
            if (!action.payload) {
                state.listItemGroups = action.payload;
                return {
                    ...state,
                    listItemGroups: state.listItemGroups,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    listItemGroups: action.payload,
                    isLoading: false,
                };
            }

        case ActionTypes.FETCH_LIST_ITEM_GROUPS_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.ADD_NEW_ITEM_PENDING:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.ADD_NEW_ITEM_SUCCESS:
            return {
                ...state,
                // listItemGroups: action.payload,
                isLoading: false,
                isSuccess: true,
            };

        case ActionTypes.ADD_NEW_ITEM_FAILURE:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
            };
        default:
            return state;
    }
};
export default ItemsReducer;
