import ActionTypes from "./types";

const INIT_STATE = {
  isLoading: false,
  dataInvoice: {},
  filterYearInvoicePrj: {},
  totalInvoicePrj: {},
  listUserInvoicePrj: [],
  listCurrencies: [],
};

const InvoiceProjectReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_INVOICES_PROJECT:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_INVOICES_PROJECT_SUCCESS:
      return {
        ...state,
        dataInvoice: action.payload,
        isLoading: false,
      };
    case ActionTypes.FETCH_INVOICES_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.FETCH_FILTER_YEAR_INVOICE_PRJ:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_FILTER_YEAR_INVOICE_PRJ_SUCCESS:
      return {
        ...state,
        filterYearInvoicePrj: action.payload,
        isLoading: false,
      };

    case ActionTypes.FETCH_FILTER_YEAR_INVOICE_PRJ_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_TOTAL_INVOICE_PRJ:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_TOTAL_INVOICE_PRJ_SUCCESS:
      return {
        ...state,
        totalInvoicePrj: action.payload,
        isLoading: false,
      };

    case ActionTypes.FETCH_TOTAL_INVOICE_PRJ_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_STAFF_INVOICE_PRJ:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_STAFF_INVOICE_PRJ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listUserInvoicePrj: action.payload,
      };

    case ActionTypes.FETCH_STAFF_INVOICE_PRJ_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FILTER_INVOICE_PRJ:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FILTER_INVOICE_PRJ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataInvoice: action.payload,
      };

    case ActionTypes.FILTER_INVOICE_PRJ_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_CURRENCIES:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        listCurrencies: action.payload,
        isLoading: false,
      };

    case ActionTypes.FETCH_CURRENCIES_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};

export default InvoiceProjectReducer;
