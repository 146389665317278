// @flow
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import  i18n  from 'i18next';
import ActionTypes from "./types";

import { responeCode, typeMessage } from "../../../constants/Const";
import {
  parseDataNotifications,
  throwErrorMessage,
} from "../../../helpers/HandleError";
import { enqueueSnackbar } from "../../../redux/CommonReducer";
import {
  addProjectMemberFailure,
  addProjectMemberSuccess,
  changeProjectFilesVisibleFailed,
  changeProjectFilesVisibleSuccess,
  copyProjectFailed,
  copyProjectSuccess,
  createCustomerProjectFailed,
  createCustomerProjectSuccess,
  createProjectFailed,
  createProjectFilesFailed,
  createProjectFilesSuccess,
  createProjectMilestoneFailed,
  createProjectMilestoneSuccess,
  createProjectNewTicketFailed,
  createProjectNewTicketSuccess,
  createProjectSuccess,
  deleteProjectFailed,
  deleteProjectFilesFailed,
  deleteProjectFilesSuccess,
  deleteProjectMemberFailure,
  deleteProjectMemberSuccess,
  deleteProjectMilestoneFailed,
  deleteProjectMilestoneSuccess,
  deleteProjectSuccess,
  deleteProjectTicketFailed,
  deleteProjectTicketSuccess,
  downloadFileOfProjectFailure,
  downloadFileOfProjectSuccess,
  editProjectFailed,
  editProjectMilestoneFailed,
  editProjectMilestoneSuccess,
  editProjectSuccess,
  fetchCreateTimesheetProjectFailed,
  fetchCreateTimesheetProjectSuccess,
  fetchDeleteTimesheetProjectFailed,
  fetchDeleteTimesheetProjectSuccess,
  fetchEditTimesheetProjectFailed,
  fetchEditTimesheetProjectSuccess,
  fetchFilterByMemberTimesheetProjectFailed,
  fetchFilterByMemberTimesheetProjectSuccess,
  fetchFilterByStatusTicketProjectFailed,
  fetchFilterByStatusTicketProjectSuccess,
  fetchListProjectsFailed,
  fetchListProjectsSuccess,
  fetchProjectById,
  fetchProjectByIdFailed,
  fetchProjectByIdSuccess,
  fetchProjectMilestoneFailed,
  fetchProjectMilestoneSuccess,
  fetchStaffByTaskTimesheetFailed,
  fetchStaffByTaskTimesheetSuccess,
  fetchStaffsOfProjectFailure,
  fetchStaffsOfProjectSuccess,
  fetchSubscriptionsProjectFailed,
  fetchSubscriptionsProjectSuccess,
  fetchTaskByProjectTimesheetFailed,
  fetchTaskByProjectTimesheetSuccess,
  fetchTicketsProjectFailed,
  fetchTicketsProjectSuccess,
  fetchTimesheetProjectFailed,
  fetchTimesheetProjectSuccess,
  filterDiscussionFailed,
  filterDiscussionSuccess,
  getAllNewProjectFailed,
  getAllNewProjectSuccess,
  getAllProjectViewFailed,
  getAllProjectViewNewTicketFailed,
  getAllProjectViewNewTicketSuccess,
  getAllProjectViewSuccess,
  getProjectFilesFailed,
  getProjectFilesSuccess,
  getProjectMilestoneFailed,
  getProjectMilestoneSuccess,
  getProjectOverViewCountFailed,
  getProjectOverViewCountSuccess,
  getProjectOverViewTaskFailed,
  getProjectOverViewTaskSuccess,
  getProjectSummaryFailed,
  getProjectSummarySuccess,
  getProjectTagFailed,
  getProjectTagSuccess,
  getProjectTicketSummaryFailed,
  getProjectTicketSummarySuccess,
  searchProjectFilesFailed,
  searchProjectFilesSuccess,
  getChartLogTimePrjSuccess,
  getChartLogTimePrjFailed,
} from "./actions";
import {
  addProjectMember,
  changeProjectFilesVisible,
  copyProject,
  createCustomerProject,
  createProject,
  createProjectFiles,
  createProjectMilestone,
  createProjectNewTicket,
  createTimesheetProject,
  deleteProject,
  deleteProjectFiles,
  deleteProjectMemberApi,
  deleteProjectMilestone,
  deleteProjectTicket,
  deleteTimesheetProject,
  downloadFileOfProject,
  editProject,
  editProjectMilestone,
  editTimesheetProject,
  fetchProjectMilestone,
  filterByMemberTimesheetProject,
  filterByStatusTicketProject,
  filterDiscussionProject,
  getListProjects,
  getProjectById,
  getProjectFiles,
  getProjectMilestone,
  getProjectNewTicketContacts,
  getProjectNewTicketCustomField,
  getProjectNewTicketDepartment,
  getProjectNewTicketKnowledgebase,
  getProjectNewTicketPredefined,
  getProjectNewTicketPriority,
  getProjectNewTicketService,
  getProjectOverViewCount,
  getProjectOverViewTask,
  getProjectSummary,
  getProjectTag,
  getProjectTicketSummary,
  getStaffByTaskTimesheet,
  getStaffProject,
  getStaffsOfProject,
  getSubscriptionsProject,
  getTaskByProjectTimesheet,
  getTicketsProject,
  getTimesheetProject,
  searchProjectFiles,
  getChartLogTimePrj,
} from "./api";

function* fetchListProjectSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(getListProjects, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(fetchListProjectsSuccess(response.data.result));
    } else {
      yield put(fetchListProjectsFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchListProjectsFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* fetchProjectByIdSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(getProjectById, payload);
    if (response && response.data) {
      yield put(fetchProjectByIdSuccess(response.data));
    } else {
      yield put(fetchProjectByIdFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchProjectByIdFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* fetchTimesheetProjectSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(getTimesheetProject, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(fetchTimesheetProjectSuccess(response.data.result));
    } else {
      yield put(fetchTimesheetProjectFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchTimesheetProjectFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* deleteTimesheetProjectSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(deleteTimesheetProject, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(fetchDeleteTimesheetProjectSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.deleteTimesheetProjectSuccess'), typeMessage.success)
        )
      );
    } else {
      yield put(fetchDeleteTimesheetProjectFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.deleteTimesheetProjectFailed'), typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchDeleteTimesheetProjectFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* fetchTaskByProjectTimesheetSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(getTaskByProjectTimesheet, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(fetchTaskByProjectTimesheetSuccess(response.data.result));
    } else {
      yield put(fetchTaskByProjectTimesheetFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchTaskByProjectTimesheetFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* fetchStaffByTaskTimesheetSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(getStaffByTaskTimesheet, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(fetchStaffByTaskTimesheetSuccess(response.data.result));
    } else {
      yield put(fetchStaffByTaskTimesheetFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchStaffByTaskTimesheetFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* createTimesheetProjectSaga(action) {
  const { data } = action.payload;
  try {
    const response = yield call(createTimesheetProject, data);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(fetchCreateTimesheetProjectSuccess(response.data.result));

      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.createTimesheetProjectSuccess'), typeMessage.success)
        )
      );
    } else {
      yield put(fetchCreateTimesheetProjectFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.createTimesheetProjectFailed'), typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchCreateTimesheetProjectFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* editTimesheetProjectSaga(action) {
  const { data, projectId } = action.payload;
  try {
    const response = yield call(editTimesheetProject, data);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(fetchEditTimesheetProjectSuccess());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.updateTimesheetProjectSuccess'), typeMessage.success)
        )
      );
    } else {
      yield put(fetchEditTimesheetProjectFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.updateTimesheetProjectFailed'), typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchEditTimesheetProjectFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* filterByMemberTimesheetProjectSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(filterByMemberTimesheetProject, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(
        fetchFilterByMemberTimesheetProjectSuccess(response.data.result)
      );
    } else {
      yield put(fetchFilterByMemberTimesheetProjectFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchFilterByMemberTimesheetProjectFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* filterDiscussionProjectSaga(data) {
  const { payload, id } = data;
  try {
    const response = yield call(filterDiscussionProject, payload, id);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(filterDiscussionSuccess(response.data.result));
    } else {
      yield put(filterDiscussionFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(filterDiscussionFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* fetchSubscriptionsProjectSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(getSubscriptionsProject, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result &&
      response.data.result.data
    ) {
      yield put(fetchSubscriptionsProjectSuccess(response.data.result.data));
    } else {
      yield put(fetchSubscriptionsProjectFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchSubscriptionsProjectFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* fetchTicketsProjectSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(getTicketsProject, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result &&
      response.data.result.data
    ) {
      yield put(fetchTicketsProjectSuccess(response.data.result.data));
    } else {
      yield put(fetchTicketsProjectFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchTicketsProjectFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* filterByStatusTicketProjectSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(filterByStatusTicketProject, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result &&
      response.data.result.data
    ) {
      yield put(
        fetchFilterByStatusTicketProjectSuccess(response.data.result.data)
      );
    } else {
      yield put(fetchFilterByStatusTicketProjectFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchFilterByStatusTicketProjectFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

//Milestone

function* getProjectMilestoneSaga(action) {
  try {
    const response = yield call(getProjectMilestone, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result
    ) {
      yield put(getProjectMilestoneSuccess(response.data.result));
    } else {
      yield put(getProjectMilestoneFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(getProjectMilestoneFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* createProjectMilestoneSaga(action) {
  try {
    const response = yield call(createProjectMilestone, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result
    ) {
      yield put(createProjectMilestoneSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.createProjectMilestoneSuccess'), typeMessage.success)
        )
      );
    } else {
      yield put(createProjectMilestoneFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.createProjectMilestoneFailed'), typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(createProjectMilestoneFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* editProjectMilestoneSaga(action) {
  try {
    const response = yield call(editProjectMilestone, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result
    ) {
      yield put(editProjectMilestoneSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.updateProjectMilestoneSuccess'), typeMessage.success)
        )
      );
    } else {
      yield put(editProjectMilestoneFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.updateProjectMilestoneFailed'), typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(editProjectMilestoneFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
function* deleteProjectMilestoneSaga(action) {
  try {
    const response = yield call(deleteProjectMilestone, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result
    ) {
      yield put(deleteProjectMilestoneSuccess(action.payload));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.deleteProjectMilestoneSuccess'), typeMessage.success)
        )
      );
    } else {
      yield put(deleteProjectMilestoneFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.deleteProjectMilestoneFailed'), typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(deleteProjectMilestoneFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* fetchProjectMilestoneSaga(action) {
  try {
    const response = yield call(fetchProjectMilestone, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result
    ) {
      yield put(fetchProjectMilestoneSuccess(response.data.result));
    } else {
      yield put(fetchProjectMilestoneFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchProjectMilestoneFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

//GET TAG
function* getProjectTagSaga(action) {
  try {
    const response = yield call(getProjectTag, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result
    ) {
      yield put(getProjectTagSuccess(response.data.result));
    } else {
      yield put(getProjectTagFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(getProjectTagFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

// get opentask màn overview

function* getProjectOverViewTaskSaga(action) {
  try {
    const response = yield call(getProjectOverViewTask, action.payload);
    if (response && response.data) {
      yield put(getProjectOverViewTaskSuccess(response.data));
    } else {
      yield put(getProjectOverViewTaskFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(getProjectOverViewTaskFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

// get count (dayleft) màn overview

function* getProjectOverViewCountSaga(action) {
  try {
    const response = yield call(getProjectOverViewCount, action.payload);
    if (response && response.data) {
      yield put(getProjectOverViewCountSuccess(response.data));
    } else {
      yield put(getProjectOverViewCountFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(getProjectOverViewCountFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

// get summary project

function* getProjectSummarySaga() {
  try {
    const response = yield call(getProjectSummary);
    if (response && response.data) {
      yield put(getProjectSummarySuccess(response.data));
    } else {
      yield put(getProjectSummaryFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(getProjectSummaryFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

//project
function* createProjectSaga(action) {
  try {
    const response = yield call(createProject, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(createProjectSuccess(response.data));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.createProjectSuccess'), typeMessage.success)
        )
      );
    } else {
      yield put(createProjectFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.createProjectFailed'), typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(createProjectFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* editProjectSaga(action) {
  try {
    const response = yield call(editProject, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(editProjectSuccess(response.data));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.updateProjectSuccess'), typeMessage.success)
        )
      );
    } else {
      yield put(editProjectFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.updateProjectFailed'), typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(editProjectFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* deleteProjectSaga(action) {
  try {
    const response = yield call(deleteProject, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(deleteProjectSuccess(response.data));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.deleteProjectSuccess'), typeMessage.success)
        )
      );
    } else {
      yield put(deleteProjectFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.deleteProjectFailed'), typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(deleteProjectFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* copyProjectSaga(action) {
  try {
    const response = yield call(copyProject, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(copyProjectSuccess(response.data));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.copyProjectSuccess'), typeMessage.success)
        )
      );
    } else {
      yield put(copyProjectFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.copyProjectFailed'), typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(copyProjectFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* getAllNewProjectSaga() {
  try {
    const [responseTag, responseStaff] = yield all([
      call(getProjectTag),
      call(getStaffProject),
    ]);
    if (
      responseTag &&
      responseTag.data &&
      responseTag.data.error_code === responeCode.success &&
      responseStaff &&
      responseStaff.data &&
      responseStaff.data.error_code === responeCode.success
    ) {
      let listTag = responseTag.data.result;
      let listStaff = responseStaff.data.result;
      yield put(getAllNewProjectSuccess(listTag, listStaff));
    } else {
      yield put(getAllNewProjectFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(responseTag.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(getAllNewProjectFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

//project files
function* createProjectFilesSaga(action) {
  try {
    const response = yield call(
      createProjectFiles,
      action.projectId,
      action.data
    );
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(createProjectFilesSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.createProjectFilesSuccess'), typeMessage.success)
        )
      );
    } else {
      yield put(createProjectFilesFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.createProjectFilesFailed'), typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(createProjectFilesFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
function* getProjectFilesSaga(action) {
  const { payload } = action;
  try {
    const response = yield call(getProjectFiles, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(getProjectFilesSuccess(response.data.result));
    } else {
      yield put(getProjectFilesFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(getProjectFilesFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* changeProjectFilesVisibleSaga(action) {
  try {
    const response = yield call(changeProjectFilesVisible, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(changeProjectFilesVisibleSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.changeProjectFilesVisibleSuccess'), typeMessage.success)
        )
      );
    } else {
      yield put(changeProjectFilesVisibleFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.changeProjectFilesVisibleFailed'), typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(changeProjectFilesVisibleFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* deleteProjectFilesSaga(action) {
  try {
    const response = yield call(deleteProjectFiles, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(deleteProjectFilesSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.deleteProjectFilesSuccess'), typeMessage.success)
        )
      );
    } else {
      yield put(deleteProjectFilesFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.deleteProjectFilestoneFailed'), typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(deleteProjectFilesFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* searchProjectFilesSaga(action) {
  try {
    const response = yield call(
      searchProjectFiles,
      action.projectId,
      action.params
    );
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(searchProjectFilesSuccess(response.data.result));
    } else {
      yield put(searchProjectFilesFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(searchProjectFilesFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* getAllProjectViewSaga(action) {
  const { payload } = action;
  try {
    const [listProjects, projectById, overViewTask, overViewCount] = yield all([
      call(getListProjects),
      call(getProjectById, payload),
      call(getProjectOverViewTask, payload),
      call(getProjectOverViewCount, payload),
    ]);
    if (
      listProjects &&
      listProjects.data &&
      listProjects.data.error_code === responeCode.success &&
      projectById &&
      projectById.data &&
      overViewTask &&
      overViewTask.data &&
      overViewCount &&
      overViewCount.data
    ) {
      let listProject = listProjects.data.result;
      let projectSelect = projectById.data;
      let openTask = overViewTask.data;
      let dayLeft = overViewCount.data;
      yield put(
        getAllProjectViewSuccess(
          listProject,
          projectSelect,
          openTask,
          dayLeft,
          payload
        )
      );
    } else {
      yield put(getAllProjectViewFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(throwErrorMessage("error"), typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(getAllProjectViewFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* getAllProjectViewNewTicketSaga(action) {
  const { payload } = action;
  try {
    const [
      staffs,
      departments,
      tags,
      prioritys,
      predefinedReplies,
      services,
      projectId,
      contacts,
      customField,
      knowledge,
    ] = yield all([
      call(getStaffProject),
      call(getProjectNewTicketDepartment),
      call(getProjectTag),
      call(getProjectNewTicketPriority),
      call(getProjectNewTicketPredefined),
      call(getProjectNewTicketService),
      call(getProjectById, payload),
      call(getProjectNewTicketContacts, payload),
      call(getProjectNewTicketCustomField),
      call(getProjectNewTicketKnowledgebase),
    ]);

    if (
      staffs &&
      staffs.data &&
      staffs.data.error_code === responeCode.success &&
      departments &&
      departments.data &&
      departments.data.error_code === responeCode.success &&
      tags &&
      tags.data &&
      tags.data.error_code === responeCode.success &&
      prioritys &&
      prioritys.data &&
      prioritys.data.error_code === responeCode.success &&
      predefinedReplies &&
      predefinedReplies.data &&
      predefinedReplies.data.error_code === responeCode.success &&
      services &&
      services.data &&
      services.data.error_code === responeCode.success &&
      projectId &&
      projectId.data &&
      contacts &&
      contacts.data &&
      customField &&
      customField.data &&
      customField.data.error_code === responeCode.success &&
      knowledge &&
      knowledge.data &&
      knowledge &&
      knowledge.data &&
      knowledge.data.error_code === responeCode.success
    ) {
      let data = {
        staffs: staffs.data.result,
        departments: departments.data.result,
        tags: tags.data.result,
        prioritys: prioritys.data.result,
        predefinedReplies: predefinedReplies.data.result,
        services: services.data.result,
        projectId: projectId.data,
        contacts: contacts.data.result,
        customField: customField.data.result,
        knowledge: knowledge.data.result,
      };
      yield put(getAllProjectViewNewTicketSuccess(data));
    } else {
      yield put(getAllProjectViewNewTicketFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(throwErrorMessage("error"), typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(getAllProjectViewNewTicketFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* createProjectNewTicketSaga(action) {
  try {
    if (action.file) {
      const [ticket, file] = yield all([
        call(createProjectNewTicket, action),
        call(createProjectFiles, action.projectId, action.file),
      ]);
      if (
        ticket &&
        ticket.data &&
        ticket.data.error_code === responeCode.success &&
        ticket.data.result &&
        file &&
        file.data &&
        file.data.error_code === responeCode.success &&
        file.data.result
      ) {
        let data = {
          file: file.data.result,
          ticket: ticket.data.result,
        };
        yield put(createProjectNewTicketSuccess(data));
        yield put(
          enqueueSnackbar(
            parseDataNotifications(i18n.t('project.action.createProjectTicketSuccess'), typeMessage.success)
          )
        );
      } else {
        yield put(createProjectNewTicketFailed());
        yield put(
          enqueueSnackbar(
            parseDataNotifications(i18n.t('project.action.createProjectTicketFailed'), typeMessage.error)
          )
        );
      }
    } else {
      const response = yield call(createProjectNewTicket, action);
      if (
        response &&
        response.data &&
        response.data.error_code === responeCode.success &&
        response.data.result
      ) {
        yield put(createProjectNewTicketSuccess(response.data.result));
        yield put(
          enqueueSnackbar(
            parseDataNotifications(
              i18n.t('project.action.createProjectTicketSuccess'),
              typeMessage.success
            )
          )
        );
      } else {
        yield put(createProjectNewTicketFailed());
        yield put(
          enqueueSnackbar(
            parseDataNotifications(i18n.t('project.action.createProjectTicketFailed'), typeMessage.error)
          )
        );
      }
    }
  } catch (error) {
    yield put(createProjectNewTicketFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
function* deleteProjectTicketSaga(action) {
  try {
    const response = yield call(deleteProjectTicket, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result
    ) {
      yield put(deleteProjectTicketSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(i18n.t('project.action.deleteProjectFilesSuccess'), typeMessage.success)
        )
      );
    } else {
      yield put(deleteProjectTicketFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(deleteProjectTicketFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
function* getProjectTicketSummarySaga(action) {
  try {
    const response = yield call(getProjectTicketSummary, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result
    ) {
      yield put(getProjectTicketSummarySuccess(response.data.result));
    } else {
      yield put(getProjectTicketSummaryFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(getProjectTicketSummaryFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
function* addProjectMemberSaga(action) {
  const { projectId } = action.payload;

  try {
    const response = yield call(addProjectMember, action.payload);

    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(addProjectMemberSuccess());
      yield put(fetchProjectById(projectId));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(addProjectMemberFailure());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(addProjectMemberFailure());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
function* deleteProjectMemberSaga(action) {
  const { projectId } = action.payload;

  try {
    const response = yield call(deleteProjectMemberApi, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(deleteProjectMemberSuccess());
      yield put(fetchProjectById(projectId));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(deleteProjectMemberFailure());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(deleteProjectMemberFailure());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
function* getStaffsOfProjectSaga(action) {
  try {
    const response = yield call(getStaffsOfProject, action.payload);

    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(fetchStaffsOfProjectSuccess(response.data.result));
      // yield put(fetchProjectById(projectId));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(fetchStaffsOfProjectFailure());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchStaffsOfProjectFailure());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
function* dowloadFileOfProjectSaga(action) {
  try {
    const response = yield call(downloadFileOfProject, action.payload);

    if (response && response.status === 200) {
      yield put(downloadFileOfProjectSuccess());
    } else {
      yield put(downloadFileOfProjectFailure());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.statusText, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(downloadFileOfProjectFailure());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* createCustomerProjectSaga(action) {
  try {
    const response = yield call(createCustomerProject, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(createCustomerProjectSuccess(response.data));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(createCustomerProjectFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(createCustomerProjectFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* getChartLogTimePrjSaga(action) {
  try {
    const response = yield call(getChartLogTimePrj, action.payload);
    if (response && response.data) {
      yield put(getChartLogTimePrjSuccess(response.data));
    } else {
      yield put(getChartLogTimePrjFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(getChartLogTimePrjFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

export function* projectSaga() {
  yield takeEvery(
    ActionTypes.DOWNLOAD_FILE_OF_PROJECT_PENDING,
    dowloadFileOfProjectSaga
  );
  yield takeEvery(
    ActionTypes.DELETE_MEMBER_PROJECT_PENDING,
    deleteProjectMemberSaga
  );
  yield takeEvery(
    ActionTypes.FETCH_STAFFS_OF_PROJECT_PENDING,
    getStaffsOfProjectSaga
  );
  yield takeEvery(ActionTypes.ADD_MEMBER_PROJECT_PENDING, addProjectMemberSaga);
  yield takeEvery(ActionTypes.FETCH_LIST_PROJECTS, fetchListProjectSaga);
  yield takeEvery(ActionTypes.FETCH_PROJECT_BY_ID, fetchProjectByIdSaga);
  yield takeEvery(
    ActionTypes.FETCH_TIMESHEET_PROJECT,
    fetchTimesheetProjectSaga
  );
  yield takeEvery(
    ActionTypes.FETCH_DELETE_TIMESHEET_PROJECT,
    deleteTimesheetProjectSaga
  );
  yield takeEvery(
    ActionTypes.FETCH_STAFF_BY_TASK_TIMESHEET,
    fetchStaffByTaskTimesheetSaga
  );
  yield takeEvery(
    ActionTypes.FETCH_TASK_BY_PROJECT_TIMESHEET,
    fetchTaskByProjectTimesheetSaga
  );
  yield takeEvery(
    ActionTypes.FETCH_CREATE_TIMESHEET_PROJECT,
    createTimesheetProjectSaga
  );
  yield takeEvery(
    ActionTypes.FETCH_EDIT_TIMESHEET_PROJECT,
    editTimesheetProjectSaga
  );
  yield takeEvery(
    ActionTypes.FETCH_FILTER_BY_MEMBER_TIMESHEET_PROJECT,
    filterByMemberTimesheetProjectSaga
  );
  yield takeEvery(ActionTypes.FILTER_DISCUSSION, filterDiscussionProjectSaga);
  yield takeEvery(
    ActionTypes.FETCH_SUBSCRIPTIONS_PROJECT,
    fetchSubscriptionsProjectSaga
  );
  yield takeEvery(ActionTypes.FETCH_TICKETS_PROJECT, fetchTicketsProjectSaga);
  yield takeEvery(
    ActionTypes.FETCH_FILTER_BY_STATUS_TICKET_PROJECT,
    filterByStatusTicketProjectSaga
  );

  yield takeEvery(
    ActionTypes.GET_PROJECT_MILESTONE_REQUEST,
    getProjectMilestoneSaga
  );
  yield takeEvery(
    ActionTypes.CREATE_PROJECT_MILESTONE_REQUEST,
    createProjectMilestoneSaga
  );
  yield takeEvery(
    ActionTypes.EDIT_PROJECT_MILESTONE_REQUEST,
    editProjectMilestoneSaga
  );
  yield takeEvery(
    ActionTypes.DELETE_PROJECT_MILESTONE_REQUEST,
    deleteProjectMilestoneSaga
  );
  yield takeEvery(
    ActionTypes.FETCH_PROJECT_MILESTONE_REQUEST,
    fetchProjectMilestoneSaga
  );
  yield takeEvery(ActionTypes.GET_PROJECT_TAG_REQUEST, getProjectTagSaga);
  yield takeEvery(
    ActionTypes.GET_PROJECT_OVERVIEW_TASK_REQUEST,
    getProjectOverViewTaskSaga
  );
  yield takeEvery(
    ActionTypes.GET_PROJECT_OVERVIEW_COUNT_REQUEST,
    getProjectOverViewCountSaga
  );
  yield takeEvery(
    ActionTypes.GET_PROJECT_SUMMARY_REQUEST,
    getProjectSummarySaga
  );
  yield takeEvery(ActionTypes.CREATE_PROJECT_REQUEST, createProjectSaga);
  yield takeEvery(ActionTypes.EDIT_PROJECT_REQUEST, editProjectSaga);
  yield takeEvery(ActionTypes.DELETE_PROJECT_REQUEST, deleteProjectSaga);
  yield takeEvery(ActionTypes.COPY_PROJECT_REQUEST, copyProjectSaga);
  yield takeEvery(
    ActionTypes.GET_ALL_NEW_PROJECT_REQUEST,
    getAllNewProjectSaga
  );
  yield takeEvery(
    ActionTypes.CREATE_PROJECT_FILES_REQUEST,
    createProjectFilesSaga
  );
  yield takeEvery(ActionTypes.GET_PROJECT_FILES_REQUEST, getProjectFilesSaga);
  yield takeEvery(
    ActionTypes.CHANGE_FILES_VISIBLE_REQUEST,
    changeProjectFilesVisibleSaga
  );
  yield takeEvery(
    ActionTypes.DELETE_PROJECT_FILES_REQUEST,
    deleteProjectFilesSaga
  );
  yield takeEvery(
    ActionTypes.SEARCH_PROJECT_FILES_REQUEST,
    searchProjectFilesSaga
  );
  yield takeEvery(
    ActionTypes.GET_ALL_PROJECT_VIEW_REQUEST,
    getAllProjectViewSaga
  );
  yield takeEvery(
    ActionTypes.GET_ALL_PROJECT_VIEW_NEW_TICKET_REQUEST,
    getAllProjectViewNewTicketSaga
  );
  yield takeEvery(
    ActionTypes.CREATE_PROJECT_NEW_TICKET_REQUEST,
    createProjectNewTicketSaga
  );
  yield takeEvery(
    ActionTypes.DELETE_PROJECT_TICKET_REQUEST,
    deleteProjectTicketSaga
  );
  yield takeEvery(
    ActionTypes.GET_PROJECT_TICKET_SUMMARY_REQUEST,
    getProjectTicketSummarySaga
  );
  yield takeEvery(
    ActionTypes.CREATE_PROJECT_CUSTOMER_REQUEST,
    createCustomerProjectSaga
  );
  yield takeEvery(
    ActionTypes.GET_CHART_LOG_TIME_PROJECT,
    getChartLogTimePrjSaga
  );
}

function* ProjectSaga() {
  yield all([fork(projectSaga)]);
}

export default ProjectSaga;
