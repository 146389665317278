// import { createListContract } from "./actions";
import ActionTypes from "./types";

const INIT_STATE = {
    isLoading: false,
    listContracts: [],
    listCustomers: [],
    currentContract: {},
    summaryContracts: {},
    listAttachment: [],
    listNote: [],
    listComment: [],
    listRenewal: [],
    isFetchAttachment: false,
    isFetchNote: false,
    isFetchComment: false,
    isFetchRenewal: false,
    listTask: [],
    isFetchTask: false,
    dataComment: "",
    dataNotes: "",
    listContractType: [],
    isFetchContractType: false,
    listCustomField: [],
    contractByType: {},
    contractValueByType: {},
    listYear: [],
    listContractByCustomer: {},
    createSuccess: false,
};
const ContractReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_LIST_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.FETCH_LIST_CONTRACT_SUCCESS:
            return {
                ...state,
                listContracts: action.payload,
                isLoading: false,
            };
        case ActionTypes.FETCH_LIST_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.FETCH_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.FETCH_CONTRACT_SUCCESS:
            return {
                ...state,
                currentContract: action.payload,
                isLoading: false,
            };
        case ActionTypes.FETCH_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.CREATE_CONTRACT:
            return {
                ...state,
                isLoading: true,
                createSuccess: false,
            };
        case ActionTypes.CREATE_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                createSuccess: true,
            };
        case ActionTypes.CREATE_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
                createSuccess: false,
            };
        case ActionTypes.DELETE_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.DELETE_CONTRACT_SUCCESS:
            const newArr = [...state.listContracts.data];
            const newListContract = newArr.filter(
                (item) => item.id !== action.payload
            );
            return {
                ...state,
                isLoading: false,
                listContracts: {
                    ...state.listContracts,
                    data: newListContract,
                },
            };
        case ActionTypes.DELETE_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_CUSTOMER_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.FETCH_LIST_CUSTOMER_CONTRACT_SUCCESS:
            return {
                ...state,
                listCustomers: action.payload,
                isLoading: false,
            };
        case ActionTypes.FETCH_LIST_CUSTOMER_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.SET_EDIT_CONTRACT:
            return {
                ...state,
                isLoading: false,
                contractEditting: action.payload,
            };
        case ActionTypes.UPDATE_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.UPDATE_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.UPDATE_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.FILTER_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.FILTER_CONTRACT_SUCCESS:
            return {
                ...state,
                listContracts: action.payload,
                isLoading: false,
            };
        case ActionTypes.FILTER_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.FETCH_SUMMARY_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.FETCH_SUMMARY_CONTRACT_SUCCESS:
            return {
                ...state,
                summaryContracts: action.payload,
                isLoading: false,
            };
        case ActionTypes.FETCH_SUMMARY_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_ATTACHMENT_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_ATTACHMENT_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listAttachment: action.payload,
            };

        case ActionTypes.FETCH_LIST_ATTACHMENT_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CREATE_ATTACHMENT_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CREATE_ATTACHMENT_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchAttachment: !state.isFetchAttachment,
            };

        case ActionTypes.CREATE_ATTACHMENT_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.DELETE_ATTACHMENT_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.DELETE_ATTACHMENT_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchAttachment: !state.isFetchAttachment,
            };

        case ActionTypes.DELETE_ATTACHMENT_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_NOTE_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_NOTE_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listNote: action.payload,
            };

        case ActionTypes.FETCH_NOTE_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CREATE_NOTE_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CREATE_NOTE_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchNote: !state.isFetchNote,
                dataNotes: "",
            };

        case ActionTypes.CREATE_NOTE_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.DELETE_NOTE_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.DELETE_NOTE_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchNote: !state.isFetchNote,
            };

        case ActionTypes.DELETE_NOTE_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.UPDATE_NOTE_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.UPDATE_NOTE_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchNote: !state.isFetchNote,
            };

        case ActionTypes.UPDATE_NOTE_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_COMMENT_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_COMMENT_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listComment: action.payload,
            };

        case ActionTypes.FETCH_LIST_COMMENT_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CREATE_COMMENT_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CREATE_COMMENT_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchComment: !state.isFetchComment,
                dataComment: "",
            };

        case ActionTypes.CREATE_COMMENT_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.DELETE_COMMENT_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.DELETE_COMMENT_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchComment: !state.isFetchComment,
            };

        case ActionTypes.DELETE_COMMENT_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.UPDATE_COMMENT_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.UPDATE_COMMENT_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchComment: !state.isFetchComment,
            };

        case ActionTypes.UPDATE_COMMENT_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.FETCH_LIST_RENEWAL_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_RENEWAL_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listRenewal: action.payload,
            };

        case ActionTypes.FETCH_LIST_RENEWAL_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CREATE_RENEWAL_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CREATE_RENEWAL_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchRenewal: !state.isFetchRenewal,
            };

        case ActionTypes.CREATE_RENEWAL_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.DELETE_RENEWAL_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.DELETE_RENEWAL_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchRenewal: !state.isFetchRenewal,
            };

        case ActionTypes.DELETE_RENEWAL_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_TASK_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_TASK_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listTask: action.payload,
            };

        case ActionTypes.FETCH_LIST_TASK_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.DELETE_TASK_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.DELETE_TASK_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchTask: !state.isFetchTask,
            };

        case ActionTypes.DELETE_TASK_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.DATA_COMMENT:
            return {
                ...state,
                dataComment: action.payload,
            };
        case ActionTypes.DATA_NOTES:
            return {
                ...state,
                dataNotes: action.payload,
            };

        case ActionTypes.FETCH_CONTRACT_TYPE:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_CONTRACT_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listContractType: action.payload,
                isFetchContractType: false,
            };

        case ActionTypes.FETCH_CONTRACT_TYPE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.CREATE_CONTRACT_TYPE:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CREATE_CONTRACT_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchContractType: true,
            };

        case ActionTypes.CREATE_CONTRACT_TYPE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.FETCH_CUSTOM_FIELD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_CUSTOM_FIELD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listCustomField: action.payload,
            };

        case ActionTypes.FETCH_CUSTOM_FIELD_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_CONTRACT_BY_TYPE:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_CONTRACT_BY_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                contractByType: action.payload,
            };

        case ActionTypes.FETCH_CONTRACT_BY_TYPE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.FETCH_CONTRACT_VALUE_BY_TYPE:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_CONTRACT_VALUE_BY_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                contractValueByType: action.payload,
            };

        case ActionTypes.FETCH_CONTRACT_VALUE_BY_TYPE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.FETCH_YEAR:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_YEAR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listYear: action.payload,
            };

        case ActionTypes.FETCH_YEAR_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.ADD_NEW_CONTRACT_PENDING:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.ADD_NEW_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.ADD_NEW_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.GET_CONTRACT_BY_CUSTOMER_PENDING:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_CONTRACT_BY_CUSTOMER_SUCCESS:
            return {
                ...state,
                listContractByCustomer: action.payload,
                isLoading: false,
            }
        case ActionTypes.GET_CONTRACT_BY_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return { ...state };
    }
};

export default ContractReducer;
