export default {
    lead:{
        index: {
            buttonAddLead: "Khách hàng tiềm năng mới",
            importLead: "Nhập khách hàng tiềm năng",
            switchToKanban: "Đổi sang dạng kanban",
            chartTooltip:"Leads Summary",
            titleDialogCreateLead: 'Thêm khách hàng tiềm năng mới',
            removeLead: "Xóa lead",
            confirmRemoveLead: "Bạn có chắ muốn xóa",
            cancelRemoveLead: "Hủy bỏ",
            sureRemoveLead: "Đồng ý",
            titleFilter: "Được lọc theo",
            keyWordPlaceholder: "Tìm kiếm",
            assignedPlaceholder: "Người tạo",
            statusPlaceholder: "Trạng thái ",
            sourcePlaceholder: "Nguồn",
            view: "Xem",
            edit: "Sửa",
            delete: "Xóa",
            yes: "Có",
            no: "Không"
        },
        common:{
            id: "Id",
            to: "Đến",
            qty:"Số lượng",
            hours: "Giờ",
            lead: "Mục tiêu",
            rate: "Giá",
            tax: "Thuế",
            amount: "Tổng",
            noTax: "Miễn thuế",
            subTotal: "Chưa qua thuế - Chi phí",
            discount: "Chiết khấu",
            adjustment: "Điều chỉnh",
            allowComment: "Cho phép bình luận",
            currency: "Tiền tệ",
            discountType: "Lọai chiết khấu",
            related: "Liên quan",
            asigned:"Người tạo",
            name: "Tên",
            company: "Công ty",
            email: "Email",
            numberPhone: "Điện thoại",
            tag: "Thẻ",
            createBy: "Người tạo",
            status: "Trạng thái",
            source: "Nguồn",
            createAt: "Đã tạo",
            address: "Địa chỉ",
            position: "Vị trí",
            city: "Thành phố",
            state: "Tiểu bang",
            website: "Website",
            country: "Quốc gia",
            zip: "Mã bưu chính",
            defaultLanguage: "Ngôn ngữ mặc định",
            description: "Mô tả",
            public: "Công khai",
            lastContact:"Liên hệ cuối",
            activityLog: "Lịch sử hoạt động",
            yes: "Có",
            no: "Không",
            proposal: "Đề xuất kế hoạch",
            subject: "Chủ đề",
            total: "Tổng cộng",
            date: "Ngày",
            acceptDate: "Ngày chốt",
            priorities:"Mức độ ưu tiên 1",
            dateContact: 'Ngày liên lạc cuối',
            customerPersonal: "Tính cách khách hàng",
            createLeadSuccess: 'Tạo Lead thành công',
            deleteLeadSuccess: 'Xóa Lead thành công',
            updateLeadSuccess: 'Cập nhật Lead thành công',
            
        },
        profile: {
            createNewLead: "Tạo khách hàng tiềm năng mới",
            isPublic: "Công khai",
            contactToDay: "Đã liên hệ hôm nay",
            buttonClose: "Đóng",
            buttonSave: "Lưu",
            view: {
                buttonEdit: "Chỉnh sửa",
                viewMore: "Xem thêm",
                disconnected: "Đánh dấu mất liên lạc",
                spam: "Đánh dấu là rác",
                delete: "Xóa",
                switchToCustomer: "Chuyển thành khách hàng",
                convertToCustomer: "Chuyển thành khách hàng",
                print: "In",
                titleInforLead: "Thông tin mục tiêu",
                titleGeneral: "Thông tin chung",
                titleCustomField:"Tùy chỉnh trường",
                tooltipSwitch: "Email mục tiêu tồn tại trong dữ liệu khách hàng"
            }
        },
        menuTabs:{
            profile: "Thông tin cá nhân",
            proposal: "Đề xuất kế hoạch",
            task: "Phân công",
            file: "Tệp đính kèm",
            remind: "Nhắc nhở",
            note: "Ghi chú",
            activityLog: "Nhật ký hoạt động"
        },
        proposal: {
            title: "Đề xuất kế hoạch mới",
            customer: "Khách hàng",
            noDiscount: 'Không miễn thuế',
            beforeTax: 'Trước thuế',
            afterTax: 'Sau thuế',
            draft: 'Nháp',
            send: 'Đã gửi',
            open: 'Đang mở',
            rivised: 'Sửa lại',
            declined: 'Đã từ chối',
            accept: 'Đã chấp nhận'
        },
        remind : {
            setLeadReminder : 'Thêm nhắc nhở khách hàng tiềm năng',
            editLeadReminder : 'Chỉnh sửa nhắc nhở khách hàng tiềm năng',
            description: 'Mô tả',
            date: 'Ngày',
            remind: 'Nhắc nhở',
            isNotify: 'Được thông báo?',
            remindTooltip: 'Chức năng này cho phép bạn không bao giờ quên mọi thứ về khách hàng..',
            dateNotifi: 'Ngày cần thông báo',
            setReminder: 'Chuyển nhắc nhở sang',
            sendEmail: 'Gửi email cho cả nhắc nhở này'
        },
        note : {
            touch: 'Tôi đã liên hệ mục tiêu này',
            notContact: 'Tôi chưa liên hệ mục tiêu này',
            dateContact: 'Ngày liên hệ',
            addnote: 'Thêm ghi chú'
        },
        convertCustomer: {
            firstname: 'Tên',
            lastname: 'Họ',
            position: 'Vị trí',
            email: 'Email',
            company: 'Công ty',
            phone: 'Điện thoại',
            website: 'Website',
            address: 'Địa chỉ',
            city: 'Thành phố',
            state: 'Tiểu bang',
            country: 'Quốc gia',
            zipcode: 'Mã bưu chính',
            copyCustomField: 'Sao chép các mục tự tạo vào tiểu sử khách hàng',
            customerFieldMerge: 'Gộp các mục khách hàng',
            customField: 'Gộp thành mục tùy chỉnh',
            customFieldTitle: 'Nếu có bất kì mục nào không tồn tại cho khách hàng thì hệ thống sẽ tự tạo ra mục tương ứng mang cùng tên, nếu không thì sẽ chỉ sao chép giá trị từ tiểu sử mục tiêu.',
            contactField: 'Gộp thành mục liên hệ',
            dataField: 'Gộp với mục cơ sở dữ liệu',
            doNotMerge: 'Không gộp',
            password: 'Mật khẩu',
            sendPassword: 'Gửi email đặt mật khẩu',
            notSendPassword: 'Không gửi email chào mừng',
            transferLead: 'Chuyển ghi chú khách hàng tiềm năng vào hồ sơ khách hàng',
            backToLead: 'Back to lead',
            save: 'Lưu lại'
        }
    }

}