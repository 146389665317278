import ActionTypes from "./types";

export const getCustomFieldInvoiceRequest = (params) => {
    return {
        type: ActionTypes.GET_SALE_INVOICE_REQUEST,
        params,
    };
};

export const getCustomFieldInvoiceSuccess = (data) => {
    return {
        type: ActionTypes.GET_SALE_INVOICE_SUCCESS,
        payload: data,
    };
};

export const getCustomFieldInvoiceFailed = () => {
    return {
        type: ActionTypes.GET_SALE_INVOICE_FAILED,
    };
};

export const addInvoiceRequest = (data) => {
    console.log("data ", data)
    return {
        type: ActionTypes.ADD_INVOICE_REQUEST,
        payload: data,
    };
};

export const addInvoiceSuccess = (data) => {
    return {
        type: ActionTypes.ADD_INVOICE_SUCCESS,
        data,
    };
};

export const addInvoiceFailed = () => {
    return {
        type: ActionTypes.ADD_INVOICE_FAILED,
    };
};

export const getInvoiceById = (data) => {
    return {
        type: ActionTypes.GET_INVOICE_BY_ID,
        payload: data,
    };
};

export const getInvoiceByIdSuccess = (data) => {
    return {
        type: ActionTypes.GET_INVOICE_BY_ID_SUCCESS,
        payload: data,
    };
};

export const getInvoiceByIdFailed = () => {
    return {
        type: ActionTypes.GET_INVOICE_BY_ID_FAILED,
    };
};

export const deleteInvoice = (data) => {
    return {
        type: ActionTypes.DELETE_INVOICE,
        payload: data,
    };
};

export const deleteInvoiceSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_INVOICE_SUCCESS,
        payload: data,
    };
};

export const deleteInvoiceFailed = () => {
    return {
        type: ActionTypes.DELETE_INVOICE_FAILED,
    };
};

export const editInvoiceFailed = () => {
    return {
        type: ActionTypes.EDIT_INVOICE_FAILED,
    };
};
export const editInvoiceRequest = (data) => {
    return {
        type: ActionTypes.EDIT_INVOICE_REQUEST,
        payload: data,
    };
};

export const editInvoiceSuccess = () => {
    return {
        type: ActionTypes.EDIT_INVOICE_SUCCESS,
    };
};

