const ActionTypes = {
  FETCH_EXPENSES_PROJECT: "FETCH_EXPENSES_PROJECT",
  FETCH_EXPENSES_PROJECT_SUCCESS: "FETCH_EXPENSES_PROJECT_SUCCESS",
  FETCH_EXPENSES_PROJECT_FAILED: "FETCH_EXPENSES_PROJECT_FAILED",
  
  FETCH_FILTER_YEAR_EXPENSE_PRJ: "FETCH_FILTER_YEAR_EXPENSE_PRJ",
  FETCH_FILTER_YEAR_EXPENSE_PRJ_SUCCESS: "FETCH_FILTER_YEAR_EXPENSE_PRJ_SUCCESS",
  FETCH_FILTER_YEAR_EXPENSE_PRJ_FAILED: "FETCH_FILTER_YEAR_EXPENSE_PRJ_FAILED",
  
  FILTER_PROJECT_EXPENSES_REQUEST: "FILTER_PROJECT_EXPENSES_REQUEST",
  FILTER_PROJECT_EXPENSES_SUCCESS: "FILTER_PROJECT_EXPENSES_SUCCESS",
  FILTER_PROJECT_EXPENSES_FAILED: "FILTER_PROJECT_EXPENSES_FAILED",
  
  FETCH_CREATE_EXPENSE_PRJ: "FETCH_CREATE_EXPENSE_PRJ",
  FETCH_CREATE_EXPENSE_PRJ_SUCCESS: "FETCH_CREATE_EXPENSE_PRJ_SUCCESS",
  FETCH_CREATE_EXPENSE_PRJ_FAILED: "FETCH_CREATE_EXPENSE_PRJ_FAILED",
  
  FETCH_TAX: "FETCH_TAX",
  FETCH_TAX_SUCCESS: "FETCH_TAX_SUCCESS",
  FETCH_TAX_FAILED: "FETCH_TAX_FAILED",
  
  FETCH_EXPENSE_CATEGORY: "FETCH_EXPENSE_CATEGORY",
  FETCH_EXPENSE_CATEGORY_SUCCESS: "FETCH_EXPENSE_CATEGORY_SUCCESS",
  FETCH_EXPENSE_CATEGORY_FAILED: "FETCH_EXPENSE_CATEGORY_FAILED",
  
  FETCH_CUSTOMER: "FETCH_CUSTOMER",
  FETCH_CUSTOMER_SUCCESS: "FETCH_CUSTOMER_SUCCESS",
  FETCH_CUSTOMER_FAILED: "FETCH_CUSTOMER_FAILED",
  
  FETCH_PAYMENT_MODE: "FETCH_PAYMENT_MODE",
  FETCH_PAYMENT_MODE_SUCCESS: "FETCH_PAYMENT_MODE_SUCCESS",
  FETCH_PAYMENT_MODE_FAILED: "FETCH_PAYMENT_MODE_FAILED",
  
};
export default ActionTypes;