import ActionTypes from "./types";

export const fetchListExpenses = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_EXPENSES,
        payload: data,
    };
};

export const fetchListExpensesSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_EXPENSES_SUCCESS,
        payload: data,
    };
};

export const fetchListExpensesFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_EXPENSES_FAILED,
    };
};

export const getExpense = (item) => {
    return {
        type: ActionTypes.GET_EXPENSE_ITEM,
        payload: item,
    };
};

export const setIsSuccess = (item) => {
    return {
        type: ActionTypes.SET_ISSUCCESS,
        payload: item,
    };
};

export const fetchExpenseById = (data) => {
    return {
        type: ActionTypes.FETCH_EXPENSE_BY_ID,
        payload: data,
    };
};

export const fetchExpenseByIdSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_EXPENSE_BY_ID_SUCCESS,
        payload: data,
    };
};

export const fetchExpenseByIdFailed = () => {
    return {
        type: ActionTypes.FETCH_EXPENSE_BY_ID_FAILED,
    };
};

export const fetchDeleteExpense = (data) => {
    return {
        type: ActionTypes.FETCH_DELETE_EXPENSE,
        payload: data,
    };
};

export const fetchDeleteExpenseSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_DELETE_EXPENSE_SUCCESS,
        payload: data,
    };
};

export const fetchDeleteExpenseFailed = () => {
    return {
        type: ActionTypes.FETCH_DELETE_EXPENSE_FAILED,
    };
};

export const fetchPaymentMode = (data) => {
    return {
        type: ActionTypes.FETCH_PAYMENT_MODE,
        payload: data,
    };
};

export const fetchPaymentModeSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_PAYMENT_MODE_SUCCESS,
        payload: data,
    };
};

export const fetchPaymentModeFailed = () => {
    return {
        type: ActionTypes.FETCH_PAYMENT_MODE_FAILED,
    };
};

export const fetchListCustomer = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER,
        payload: data,
    };
};

export const fetchListCustomerSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_SUCCESS,
        payload: data,
    };
};

export const fetchListCustomerFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_FAILED,
    };
};

export const fetchListExpensesCategory = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_EXPENSES_CATEGORY,
        payload: data,
    };
};

export const fetchListExpensesCategorySuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_EXPENSES_CATEGORY_SUCCESS,
        payload: data,
    };
};

export const fetchListExpensesCategoryFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_EXPENSES_CATEGORY_FAILED,
    };
};

export const fetchCreateExpenses = (data) => {
    return {
        type: ActionTypes.FETCH_CREATE_EXPENSES,
        payload: data,
    };
};

export const fetchCreateExpensesSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_CREATE_EXPENSES_SUCCESS,
        payload: data,
    };
};

export const fetchCreateExpensesFailed = () => {
    return {
        type: ActionTypes.FETCH_CREATE_EXPENSES_FAILED,
    };
};

export const fetchEditExpenses = (id, data) => {
    return {
        type: ActionTypes.FETCH_EDIT_EXPENSES,
        id: id,
        data: data,
    };
};

export const fetchEditExpensesSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_EDIT_EXPENSES_SUCCESS,
        payload: data,
    };
};

export const fetchEditExpensesFailed = () => {
    return {
        type: ActionTypes.FETCH_EDIT_EXPENSES_FAILED,
    };
};

export const fetchListProject = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_PROJECT,
        payload: data,
    };
};

export const fetchListProjectSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_PROJECT_SUCCESS,
        payload: data,
    };
};

export const fetchListProjectFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_PROJECT_FAILED,
    };
};

export const fetchListSummary = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_SUMMARY,
        payload: data,
    };
};

export const fetchListSummarySuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_SUMMARY_SUCCESS,
        payload: data,
    };
};

export const fetchListSummaryFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_SUMMARY_FAILED,
    };
};
