import apiBase from "../../../common/baseAPI";
const ESTIMATES_SALE = "estimate";
const CUSTOMER = "customer";
const CUSTOMFIELD_ESTIMATE = "customField/fieldto/items";

export const getEstimateSaleRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${ESTIMATES_SALE}?limit=${action.params.limit}&&page=${action.params.page}&&search=${action.value}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const listSelectCustomerRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${CUSTOMER}?search=${action.value}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const listCustomFieldRequest = () => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${CUSTOMFIELD_ESTIMATE}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

// export const addEstimateCustomerRequest = (customer_id ,data) => {
//   return new Promise((resolve, reject) => {
//     return apiBase
//       .post(`${ESTIMATES_SALE}/${CUSTOMER}/${customer_id}`, data)
//       .then((res) => resolve(res))
//       .catch((err) => reject(err));
//   });
// };

export const addEstimateRequest = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${ESTIMATES_SALE}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getEstimateByIdRequest = (data) => {
  return new Promise((resolve, reject) => {
      return apiBase
          .get(`${ESTIMATES_SALE}/${data}`)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
  });
};

export const deleteEstimateRequest = (data) => {
  return new Promise((resolve, reject) => {
      return apiBase
          .delete(`${ESTIMATES_SALE}/${data}`)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
  });
};

export const editEstimate = (data) => {
  return new Promise((resolve, reject) => {
      return apiBase
          .put(`estimate/${data.id}`, data)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
  });
};