const ActionTypes = {
  FETCH_ESTIMATES_SALE_REQUEST: "FETCH_ESTIMATES_SALE_REQUEST",
  FETCH_ESTIMATES_SALE_SUCCESS: "FETCH_ESTIMATES_SALE_SUCCESS",
  FETCH_ESTIMATES_SALE_FAILED: "FETCH_ESTIMATES_SALE_FAILED",

  LIST_SELECT_CUSTOMER_REQUEST: "LIST_SELECT_CUSTOMER_REQUEST",
  LIST_SELECT_CUSTOMER_SUCCESS: "LIST_SELECT_CUSTOMER_SUCCESS",
  LIST_SELECT_CUSTOMER_FAILED: "LIST_SELECT_CUSTOMER_FAILED",

  GET_CUSTOM_FIELD_REQUEST: "GET_CUSTOM_FIELD_REQUEST",
  GET_CUSTOM_FIELD_SUCCESS: "GET_CUSTOM_FIELD_SUCCESS",
  GET_CUSTOM_FIELD_FAILED: "GET_CUSTOM_FIELD_FAILED",

  // ADD_ESTIMATE_CUSTOMER_REQUEST: "ADD_ESTIMATE_CUSTOMER_REQUEST",
  // ADD_ESTIMATE_CUSTOMER_SUCCESS: "ADD_ESTIMATE_CUSTOMER_SUCCESS",
  // ADD_ESTIMATE_CUSTOMER_FAILED: "ADD_ESTIMATE_CUSTOMER_FAILED",

  ADD_ESTIMATE_REQUEST: "ADD_ESTIMATE_REQUEST",
  ADD_ESTIMATE_SUCCESS: "ADD_ESTIMATE_SUCCESS",
  ADD_ESTIMATE_FAILED: "ADD_ESTIMATE_FAILED",

  GET_ESTIMATE_BY_ID : "GET_ESTIMATE_BY_ID",
  GET_ESTIMATE_BY_ID_SUCCESS : "GET_ESTIMATE_BY_ID_SUCCESS",
  GET_ESTIMATE_BY_ID_FAILED :"GET_ESTIMATE_BY_ID_FAILED",

  DELETE_ESTIMATE : "DELETE_ESTIMATE",
  DELETE_ESTIMATE_SUCCESS : "DELETE_ESTIMATE_SUCCESS",
  DELETE_ESTIMATE_FAILED :"DELETE_ESTIMATE_FAILED",

  EDIT_ESTIMATE_REQUEST: "EDIT_ESTIMATE_REQUEST",
  EDIT_ESTIMATE_SUCCESS: "EDIT_ESTIMATE_SUCCESS",
  EDIT_ESTIMATE_FAILED: "EDIT_ESTIMATE_FAILED",
  
};
export default ActionTypes;
