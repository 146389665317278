// @flow
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { contactsCustomerSaga } from "../Contacts/redux/saga";
import { notesCustomerSaga } from "../Notes/redux/saga";
import { statementCustomerSaga } from "../Statement/redux/saga";
// import { invoicesCustomerSaga } from "../Invoices/redux/saga";
import { paymentCustomerSaga } from "../Payments/redux/saga";
// import proposalsCustomerSaga from "../../Proposal/Customer/redux/saga";
import { vaultCustomerSaga } from "../Vault/redux/saga";
// import { expensesCustomerSaga } from "../Expenses/redux/saga";


import { reminderCustomerSaga } from "../Reminders/redux/saga";
import { enqueueSnackbar } from "../../../redux/CommonReducer";
import { responeCode, typeMessage } from "../../../constants/Const";
import {
    throwErrorMessage,
    parseDataNotifications,
} from "../../../helpers/HandleError";
import ActionTypes from "./type";

import {
    fetchListCustomerSuccess,
    fetchListCustomerFailed,
    deleteItemCustomerSuccess,
    deleteItemCustomerFailed,
    getListGroupFailed,
    getListGroupSuccess,
    addGroupSuccess,
    addGroupFailed,
    addCustomerSuccess,
    addCustomerFailed,
    getSummaryCustomerFailed,
    getSummaryCustomerSuccess,
    editActiveSuccess,
    editActiveFailed,
    fetchEstimateCustomerSuccess,
    fetchEstimateCustomerFailed,

    getCustomerProjectSuccess,
    getCustomerProjectFailed,
    addCustomerFileSuccess,
    addCustomerFileFailed,
    addCustomerVaultSuccess,
    addCustomerVaultFailed,
    // getListReminderSuccess,
    // getListReminderFailed,
    // createReminderSuccess,
    // createReminderFailed,
    // editReminderSuccess,
    // editReminderFailed,
    // deleteReminderSuccess,
    // deleteReminderFailed,
    fetchCustomertById,
    fetchCustomerByIdFailed,
    fetchCustomerByIdSuccess,
    editCustomerSuccess,
    editCustomerFailed,
    fetchExpenesCustomerSuccess,
    fetchExpenesCustomerFailed,
    fetchProposalCustomerSuccess,
    fetchProposalCustomerFailed,
    fetchInvoiceCustomerSuccess,
    fetchInvoiceCustomerFailed,
} from "./actions";
import {
    getSummaryCustomerRequest,
    fetchListCustomerRequest,
    deleteItemCustomerRequest,
    getListGroupRequest,
    addGroupRequest,
    addCustomerRequest,
    editActiveRequest,
    fetchListEstimateCustomerRequest,
    createCustomerProject,
    getCustomerProject,
    addCustomerFile,
    addCustomerVault,
    // getListReminder,
    // createReminder,
    // editReminder,
    // deleteReminder,
    getCustomerById,
    editCustomer,
    fetchListExpenesCustomerRequest,
    fetchListProposalCustomerRequest,
    fetchListInvoiceCustomerRequest
} from "./api";


function* fetchCustomerByIdSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(getCustomerById, payload);
        if (response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchCustomerByIdSuccess(response.data.result));
        } else {
            yield put(fetchCustomerByIdFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(response.data.error_mess, typeMessage.error)
                )
            );
        }
    } catch (error) {
        yield put(fetchCustomerByIdFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
            )
        );
    }
}
function* getSummaryCustomer() {
    try {
        const response = yield call(getSummaryCustomerRequest);
        if (response && response.data && response.status === 200) {
            yield put(getSummaryCustomerSuccess(response.data));
        } else {
            yield put(getSummaryCustomerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getSummaryCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* fetchListCustomer(action) {
    try {
        const response = yield call(fetchListCustomerRequest, action);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListCustomerSuccess(response.data.result));
        } else {
            yield put(fetchListCustomerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* deleteItemCustomer(value) {
    try {
        const response = yield call(deleteItemCustomerRequest, value);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteItemCustomerSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteItemCustomerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteItemCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* getListGroup() {
    try {
        const response = yield call(getListGroupRequest);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListGroupSuccess(response.data.result));
        } else {
            yield put(getListGroupFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListGroupFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* addGroup(action) {
    try {
        const response = yield call(addGroupRequest, { name: action.payload });
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addGroupSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(addGroupFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListGroupFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* addCustomer(action) {
    try {
        const response = yield call(addCustomerRequest, action.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addCustomerSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
            action.history.push(
                `/customer/profile/${response.data.result[0].id}`
            );
        } else {
            yield put(addCustomerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(addCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* editCustomerSaga(action) {
    try {
        const response = yield call(editCustomer, action.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(editCustomerSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(editCustomerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(editCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* editActive(action) {
    try {
        const response = yield call(editActiveRequest, action);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(editActiveSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(editActiveFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(addCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* fecthListEstimateSaga(action) {
    try {
        const response = yield call(fetchListEstimateCustomerRequest, action);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchEstimateCustomerSuccess(response.data.result));
        } else {
            yield put(fetchEstimateCustomerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.warning
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchEstimateCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fecthListExpenesCustomerSaga(action) {
    try {
        const response = yield call(fetchListExpenesCustomerRequest, action);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchExpenesCustomerSuccess(response.data.result));
        } else {
            yield put(fetchExpenesCustomerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.warning
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchExpenesCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

// function* getListReminderSaga() {
//   try {
//     const response = yield call(getListReminder);
//     if (response && response.data && response.status === 200) {
//       yield put(getListReminderSuccess(response.data));
//     } else {
//       yield put(getListReminderFailed());
//       yield put(
//         enqueueSnackbar(
//           parseDataNotifications(response.data.error_mess, typeMessage.error)
//         )
//       );
//     }
//   } catch (error) {
//     yield put(getListReminderFailed());
//     yield put(
//       enqueueSnackbar(
//         parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
//       )
//     );
//   }
// }

// function* createReminderSaga() {
//   try {
//     const response = yield call(createReminder);
//     if (response && response.data && response.status === 200) {
//       yield put(createReminderSuccess(response.data));
//     } else {
//       yield put(createReminderFailed());
//       yield put(
//         enqueueSnackbar(
//           parseDataNotifications(response.data.error_mess, typeMessage.error)
//         )
//       );
//     }
//   } catch (error) {
//     yield put(createReminderFailed());
//     yield put(
//       enqueueSnackbar(
//         parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
//       )
//     );
//   }
// }

// function* editReminderSaga() {
//   try {
//     const response = yield call(editReminder);
//     if (response && response.data && response.status === 200) {
//       yield put(editReminderSuccess(response.data));
//     } else {
//       yield put(editReminderFailed());
//       yield put(
//         enqueueSnackbar(
//           parseDataNotifications(response.data.error_mess, typeMessage.error)
//         )
//       );
//     }
//   } catch (error) {
//     yield put(editReminderFailed());
//     yield put(
//       enqueueSnackbar(
//         parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
//       )
//     );
//   }
// }

// function* deleteReminderSaga() {
//   try {
//     const response = yield call(deleteReminder);
//     if (response && response.data && response.status === 200) {
//       yield put(deleteReminderSuccess(response.data));
//     } else {
//       yield put(deleteReminderFailed());
//       yield put(
//         enqueueSnackbar(
//           parseDataNotifications(response.data.error_mess, typeMessage.error)
//         )
//       );
//     }
//   } catch (error) {
//     yield put(deleteReminderFailed());
//     yield put(
//       enqueueSnackbar(
//         parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
//       )
//     );
//   }
// }

function* getCustomerProjectSaga(action) {
    try {
        const response = yield call(getCustomerProject, action.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getCustomerProjectSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(getCustomerProjectFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getCustomerProjectFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* addCustomerFileSaga(action) {
    try {
        const response = yield call(addCustomerFile, action.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addCustomerFileSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(addCustomerFileFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(addCustomerFileFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* addCustomerVaultSaga(action) {
    try {
        const response = yield call(addCustomerVault, action.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addCustomerVaultSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(addCustomerVaultFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(addCustomerVaultFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fecthListProposalCustomerSaga(action) {
    try {
        const response = yield call(fetchListProposalCustomerRequest, action);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchProposalCustomerSuccess(response.data.result));
        } else {
            yield put(fetchProposalCustomerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.warning
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchProposalCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fecthListInvoiceCustomerSaga(action) {
    try {
        const response = yield call(fetchListInvoiceCustomerRequest, action);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchInvoiceCustomerSuccess(response.data.result));
        } else {
            yield put(fetchProposalCustomerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.warning
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchInvoiceCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

export function* customerSaga() {

    yield takeEvery(
        ActionTypes.ADD_CUSTOMER_VAULT_PENDING,
        addCustomerVaultSaga
    );
    yield takeEvery(ActionTypes.ADD_CUSTOMER_FILE_PENDING, addCustomerFileSaga);
    yield takeEvery("GET_PROJECT_CUSTOMER_REQUEST", getCustomerProjectSaga);
    yield takeEvery(ActionTypes.FETCH_LIST_CUSTOMER_REQUEST, fetchListCustomer);
    yield takeEvery(ActionTypes.EDIT_CUSTOMER_REQUEST, editCustomerSaga);

    yield takeEvery(
        ActionTypes.DELETE_ITEM_CUSTOMER_REQUEST,
        deleteItemCustomer
    );
    yield takeEvery(ActionTypes.GET_LIST_GROUPS_REQUEST, getListGroup);
    yield takeEvery(ActionTypes.ADD_GROUP_REQUEST, addGroup);
    yield takeEvery(ActionTypes.ADD_CUSTOMER_REQUEST, addCustomer);
    yield takeEvery(
        ActionTypes.GET_SUMMARY_CUSTOMER_REQUEST,
        getSummaryCustomer
    );
    yield takeEvery(ActionTypes.EDIT_ACTIVE_REQUEST, editActive);
    yield takeEvery(
        ActionTypes.FETCH_LIST_CUSTOMER_ESTIMATE_REQUEST,
        fecthListEstimateSaga
    );
    yield takeEvery(ActionTypes.FETCH_CUSTOMER_BY_ID, fetchCustomerByIdSaga);
    yield takeEvery(ActionTypes.FETCH_LIST_CUSTOMER_EXPENES_REQUEST, fecthListExpenesCustomerSaga);
    yield takeEvery(ActionTypes.FETCH_LIST_CUSTOMER_PROPOSAL_REQUEST, fecthListProposalCustomerSaga);
    yield takeEvery(ActionTypes.FETCH_LIST_CUSTOMER_INVOICE_REQUEST, fecthListInvoiceCustomerSaga);
    // yield takeEvery(ActionTypes.GET_LIST_REMINDER, getListReminderSaga);
    // yield takeEvery(ActionTypes.CREATE_REMINDER, createReminderSaga);
    // yield takeEvery(ActionTypes.EDIT_REMINDER, editReminderSaga);
    // yield takeEvery(ActionTypes.DELETE_REMINDER, deleteReminderSaga);
}

function* CustomerSaga() {
    yield all([
        fork(customerSaga),
        fork(contactsCustomerSaga),
        fork(notesCustomerSaga),
        fork(statementCustomerSaga),
        // fork(invoicesCustomerSaga),
        fork(paymentCustomerSaga),
        fork(reminderCustomerSaga),
        fork(vaultCustomerSaga),
        // fork(expensesCustomerSaga),
        // fork(proposalsCustomerSaga),
    ]);
}

export default CustomerSaga;
