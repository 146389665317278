import ActionTypes from "./types";

export const fetchListContract = (search, params) => {
    return {
        type: ActionTypes.FETCH_LIST_CONTRACT,
        search,
        params,
    };
};
export const fetchListContractSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_CONTRACT_SUCCESS,
        payload: data,
    };
};
export const fetchListContractFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_CONTRACT_FAILED,
    };
};

export const fetchContract = (id) => {
    return {
        type: ActionTypes.FETCH_CONTRACT,
        payload: id,
    };
};
export const fetchContractSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_CONTRACT_SUCCESS,
        payload: data,
    };
};
export const fetchContractFailed = () => {
    return {
        type: ActionTypes.FETCH_CONTRACT_FAILED,
    };
};

export const createContract = (data, history) => {
    return {
        type: ActionTypes.CREATE_CONTRACT,
        payload: data,
        history,
    };
};
export const createContractSuccess = (data) => {
    return {
        type: ActionTypes.CREATE_CONTRACT_SUCCESS,
        payload: data,
    };
};
export const createContractFailed = () => {
    return {
        type: ActionTypes.CREATE_CONTRACT_FAILED,
    };
};

export const deleteContract = (data, history) => {
    return {
        type: ActionTypes.DELETE_CONTRACT,
        payload: data,
        history,
    };
};
export const deleteContractSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_CONTRACT_SUCCESS,
        payload: data,
    };
};
export const deleteContractFailed = () => {
    return {
        type: ActionTypes.DELETE_CONTRACT_FAILED,
    };
};

export const fetchListCustomerContract = () => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_CONTRACT,
    };
};
export const fetchListCustomerContractSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_CONTRACT_SUCCESS,
        payload: data,
    };
};
export const fetchListCustomerContractFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_CUSTOMER_CONTRACT_FAILED,
    };
};
export const setEditContract = (data) => {
    return {
        type: ActionTypes.SET_EDIT_CONTRACT,
        payload: data,
    };
};
export const updateContract = (data) => {
    return {
        type: ActionTypes.UPDATE_CONTRACT,
        payload: data,
    };
};
export const updateContractSuccess = (data) => {
    return {
        type: ActionTypes.UPDATE_CONTRACT_SUCCESS,
        payload: data,
    };
};
export const updateContractFailed = () => {
    return {
        type: ActionTypes.UPDATE_CONTRACT_FAILED,
    };
};

export const filterContract = (data) => {
    return {
        type: ActionTypes.FILTER_CONTRACT,
        payload: data,
    };
};
export const filterContractSuccess = (data) => {
    return {
        type: ActionTypes.FILTER_CONTRACT_SUCCESS,
        payload: data,
    };
};
export const filterContractFailed = () => {
    return {
        type: ActionTypes.FILTER_CONTRACT_FAILED,
    };
};

export const fetchSummaryContract = () => {
    return {
        type: ActionTypes.FETCH_SUMMARY_CONTRACT,
    };
};
export const fetchSummaryContractSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_SUMMARY_CONTRACT_SUCCESS,
        payload: data,
    };
};
export const fetchSummaryContractFailed = () => {
    return {
        type: ActionTypes.FETCH_SUMMARY_CONTRACT_FAILED,
    };
};
export const fetchListAttachmentContract = (id) => {
    return {
        type: ActionTypes.FETCH_LIST_ATTACHMENT_CONTRACT,
        payload: id,
    };
};

export const fetchListAttachmentContractSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_ATTACHMENT_CONTRACT_SUCCESS,
        payload: data,
    };
};

export const fetchListAttachmentContractFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_ATTACHMENT_CONTRACT_FAILED,
    };
};

export const createAttachmentContract = (id, data) => {
    return {
        type: ActionTypes.CREATE_ATTACHMENT_CONTRACT,
        payload: data,
        id,
    };
};

export const createAttachmentContractSuccess = () => {
    return {
        type: ActionTypes.CREATE_ATTACHMENT_CONTRACT_SUCCESS,
    };
};

export const createAttachmentContractFailed = () => {
    return {
        type: ActionTypes.CREATE_ATTACHMENT_CONTRACT_FAILED,
    };
};

export const deleteAttachmentContract = (id) => {
    return {
        type: ActionTypes.DELETE_ATTACHMENT_CONTRACT,
        payload: id,
    };
};

export const deleteAttachmentContractSuccess = () => {
    return {
        type: ActionTypes.DELETE_ATTACHMENT_CONTRACT_SUCCESS,
    };
};

export const deleteAttachmentContractFailed = () => {
    return {
        type: ActionTypes.DELETE_ATTACHMENT_CONTRACT_FAILED,
    };
};

export const fetchNoteContract = (id) => {
    return {
        type: ActionTypes.FETCH_NOTE_CONTRACT,
        payload: id,
    };
};

export const fetchNoteContractSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_NOTE_CONTRACT_SUCCESS,
        payload: data,
    };
};

export const fetchNoteContractFailed = () => {
    return {
        type: ActionTypes.FETCH_NOTE_CONTRACT_FAILED,
    };
};

export const createNoteContract = (id, data) => {
    return {
        type: ActionTypes.CREATE_NOTE_CONTRACT,
        payload: data,
        id,
    };
};

export const createNoteContractSuccess = () => {
    return {
        type: ActionTypes.CREATE_NOTE_CONTRACT_SUCCESS,
    };
};

export const createNoteContractFailed = () => {
    return {
        type: ActionTypes.CREATE_NOTE_CONTRACT_FAILED,
    };
};

export const deleteNoteContract = (id) => {
    return {
        type: ActionTypes.DELETE_NOTE_CONTRACT,
        payload: id,
    };
};

export const deleteNoteContractSuccess = () => {
    return {
        type: ActionTypes.DELETE_NOTE_CONTRACT_SUCCESS,
    };
};

export const deleteNoteContractFailed = () => {
    return {
        type: ActionTypes.DELETE_NOTE_CONTRACT_FAILED,
    };
};

export const updateNoteContract = (id, data) => {
    return {
        type: ActionTypes.UPDATE_NOTE_CONTRACT,
        payload: data,
        id,
    };
};

export const updateNoteContractSuccess = () => {
    return {
        type: ActionTypes.UPDATE_NOTE_CONTRACT_SUCCESS,
    };
};

export const updateNoteContractFailed = () => {
    return {
        type: ActionTypes.UPDATE_NOTE_CONTRACT_FAILED,
    };
};

export const fetchListCommentContract = (id) => {
    return {
        type: ActionTypes.FETCH_LIST_COMMENT_CONTRACT,
        payload: id,
    };
};

export const fetchListCommentContractSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_COMMENT_CONTRACT_SUCCESS,
        payload: data,
    };
};

export const fetchListCommentContractFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_COMMENT_CONTRACT_FAILED,
    };
};

export const createCommentContract = (id, data) => {
    return {
        type: ActionTypes.CREATE_COMMENT_CONTRACT,
        payload: data,
        id,
    };
};

export const createCommentContractSuccess = () => {
    return {
        type: ActionTypes.CREATE_COMMENT_CONTRACT_SUCCESS,
    };
};

export const createCommentContractFailed = () => {
    return {
        type: ActionTypes.CREATE_COMMENT_CONTRACT_FAILED,
    };
};

export const deleteCommentContract = (id) => {
    return {
        type: ActionTypes.DELETE_COMMENT_CONTRACT,
        payload: id,
    };
};

export const deleteCommentContractSuccess = () => {
    return {
        type: ActionTypes.DELETE_COMMENT_CONTRACT_SUCCESS,
    };
};

export const deleteCommentContractFailed = () => {
    return {
        type: ActionTypes.DELETE_COMMENT_CONTRACT_FAILED,
    };
};

export const updateCommentContract = (id, data) => {
    return {
        type: ActionTypes.UPDATE_COMMENT_CONTRACT,
        payload: data,
        id,
    };
};

export const updateCommentContractSuccess = () => {
    return {
        type: ActionTypes.UPDATE_COMMENT_CONTRACT_SUCCESS,
    };
};

export const updateCommentContractFailed = () => {
    return {
        type: ActionTypes.UPDATE_COMMENT_CONTRACT_FAILED,
    };
};
// renewl Actions

export const fetchListRenewalContract = (id) => {
    return {
        type: ActionTypes.FETCH_LIST_RENEWAL_CONTRACT,
        payload: id,
    };
};

export const fetchListRenewalContractSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_RENEWAL_CONTRACT_SUCCESS,
        payload: data,
    };
};

export const fetchListRenewalContractFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_RENEWAL_CONTRACT_FAILED,
    };
};

export const createRenewalContract = (id, data) => {
    return {
        type: ActionTypes.CREATE_RENEWAL_CONTRACT,
        payload: data,
        id,
    };
};

export const createRenewalContractSuccess = () => {
    return {
        type: ActionTypes.CREATE_RENEWAL_CONTRACT_SUCCESS,
    };
};

export const createRenewalContractFailed = () => {
    return {
        type: ActionTypes.CREATE_RENEWAL_CONTRACT_FAILED,
    };
};

export const deleteRenewalContract = (id) => {
    return {
        type: ActionTypes.DELETE_RENEWAL_CONTRACT,
        payload: id,
    };
};

export const deleteRenewalContractSuccess = () => {
    return {
        type: ActionTypes.DELETE_RENEWAL_CONTRACT_SUCCESS,
    };
};

export const deleteRenewalContractFailed = () => {
    return {
        type: ActionTypes.DELETE_RENEWAL_CONTRACT_FAILED,
    };
};
// ACTIONS MODULE TASKS

export const fetchListTaskContract = (id) => {
    return {
        type: ActionTypes.FETCH_LIST_TASK_CONTRACT,
        payload: id,
    };
};

export const fetchListTaskContractSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_LIST_TASK_CONTRACT_SUCCESS,
        payload: data,
    };
};

export const fetchListTaskContractFailed = () => {
    return {
        type: ActionTypes.FETCH_LIST_TASK_CONTRACT_FAILED,
    };
};

export const createTaskContract = (id, data) => {
    return {
        type: ActionTypes.CREATE_TASK_CONTRACT,
        payload: data,
        id,
    };
};

export const createTaskContractSuccess = () => {
    return {
        type: ActionTypes.CREATE_TASK_CONTRACT_SUCCESS,
    };
};

export const createTaskContractFailed = () => {
    return {
        type: ActionTypes.CREATE_TASK_CONTRACT_FAILED,
    };
};

export const deleteTaskContract = (id) => {
    return {
        type: ActionTypes.DELETE_TASK_CONTRACT,
        payload: id,
    };
};

export const deleteTaskContractSuccess = () => {
    return {
        type: ActionTypes.DELETE_TASK_CONTRACT_SUCCESS,
    };
};

export const deleteTaskContractFailed = () => {
    return {
        type: ActionTypes.DELETE_TASK_CONTRACT_FAILED,
    };
};

export const updateTaskContract = (id, data) => {
    return {
        type: ActionTypes.UPDATE_TASK_CONTRACT,
        payload: data,
        id,
    };
};

export const updateTaskContractSuccess = () => {
    return {
        type: ActionTypes.UPDATE_TASK_CONTRACT_SUCCESS,
    };
};

export const updateTaskContractFailed = () => {
    return {
        type: ActionTypes.UPDATE_TASK_CONTRACT_FAILED,
    };
};

export const setDataComment = (data) => {
    return {
        type: ActionTypes.DATA_COMMENT,
        payload: data,
    };
};

export const setDataNotes = (data) => {
    return {
        type: ActionTypes.DATA_NOTES,
        payload: data,
    };
};

export const fetchContractType = () => {
    return {
        type: ActionTypes.FETCH_CONTRACT_TYPE,
    };
};

export const fetchContractTypeSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_CONTRACT_TYPE_SUCCESS,
        payload: data,
    };
};

export const fetchContractTypeFailed = () => {
    return {
        type: ActionTypes.FETCH_CONTRACT_TYPE_FAILED,
    };
};

export const createContractType = (data) => {
    return {
        type: ActionTypes.CREATE_CONTRACT_TYPE,
        payload: data,
    };
};

export const createContractTypeSuccess = () => {
    return {
        type: ActionTypes.CREATE_CONTRACT_TYPE_SUCCESS,
    };
};

export const createContractTypeFailed = () => {
    return {
        type: ActionTypes.CREATE_CONTRACT_TYPE_FAILED,
    };
};

export const fetchCustomField = () => {
    return {
        type: ActionTypes.FETCH_CUSTOM_FIELD,
    };
};

export const fetchCustomFieldSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_CUSTOM_FIELD_SUCCESS,
        payload: data,
    };
};

export const fetchCustomFieldFailed = () => {
    return {
        type: ActionTypes.FETCH_CUSTOM_FIELD_FAILED,
    };
};

export const fetchContractByType = () => {
    return {
        type: ActionTypes.FETCH_CONTRACT_BY_TYPE,
    };
};

export const fetchContractByTypeSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_CONTRACT_BY_TYPE_SUCCESS,
        payload: data,
    };
};

export const fetchContractByTypeFailed = () => {
    return {
        type: ActionTypes.FETCH_CONTRACT_BY_TYPE_FAILED,
    };
};

export const fetchContractValueByType = () => {
    return {
        type: ActionTypes.FETCH_CONTRACT_VALUE_BY_TYPE,
    };
};

export const fetchContractValueByTypeSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_CONTRACT_VALUE_BY_TYPE_SUCCESS,
        payload: data,
    };
};

export const fetchContractValueByTypeFailed = () => {
    return {
        type: ActionTypes.FETCH_CONTRACT_VALUE_BY_TYPE_FAILED,
    };
};

export const fetchYear = () => {
    return {
        type: ActionTypes.FETCH_YEAR,
    };
};

export const fetchYearSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_YEAR_SUCCESS,
        payload: data,
    };
};

export const fetchYearFailed = () => {
    return {
        type: ActionTypes.FETCH_YEAR_FAILED,
    };
};
export const addNewContractPending = (params) => {
    return {
        type: ActionTypes.ADD_NEW_CONTRACT_PENDING,
        payload: { params: params },
    };
};

export const addNewContractSuccess = () => {
    return {
        type: ActionTypes.ADD_NEW_CONTRACT_SUCCESS,
    };
};

export const addNewContractFailed = () => {
    return {
        type: ActionTypes.ADD_NEW_CONTRACT_FAILED,
    };
};

export const getContractByCustomerPending = (id, data) => {
    return {
        type: ActionTypes.GET_CONTRACT_BY_CUSTOMER_PENDING,
        payload: { id: id, data: data }
    }
}
export const getContractByCustomerSuccess = (data) => {
    return {
        type: ActionTypes.GET_CONTRACT_BY_CUSTOMER_SUCCESS,
        payload: data
    }
}
export const getContractByCustomerFailed = () => {
    return {
        type: ActionTypes.GET_CONTRACT_BY_CUSTOMER_FAILED,
    }
}