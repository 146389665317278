import ActionTypes from "./type";

const INIT_STATE = {
    isLoading: false,
    listCreditNotes: {},
    listCreditNotesCustomer: {},
    creditNotesById: {},
    dataEditCreditNotes: {},
    isSuccess: false,
};
const CreditNotesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_LIST_CREDIT_NOTE_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.GET_LIST_CREDIT_NOTE_SUCCESS:
            return {
                ...state,
                listCreditNotes: action.payload.data,
                isLoading: false,
            };
        case ActionTypes.GET_LIST_CREDIT_NOTE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.GET_LIST_CREDIT_NOTE_CUSTOMER_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.GET_LIST_CREDIT_NOTE_CUSTOMER_SUCCESS:
            return {
                ...state,
                listCreditNotesCustomer: action.payload.data,
                isLoading: false,
            };
        case ActionTypes.GET_LIST_CREDIT_NOTE_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.GET_CREDIT_NOTE_BY_ID:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.GET_CREDIT_NOTE_BY_ID_SUCCESS:
            return {
                ...state,
                creditNotesById: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_CREDIT_NOTE_BY_ID_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.DELETE_CREDIT_NOTE:
            return {
                ...state,
                isSuccess: false,
                isLoading: true,
            };

        case ActionTypes.DELETE_CREDIT_NOTE_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                isLoading: false,
            };

        case ActionTypes.DELETE_CREDIT_NOTE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
            };
        case ActionTypes.EDIT_CREDIT_NOTE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.EDIT_CREDIT_NOTE_SUCCESS:
            return {
                ...state,
                dataEditCreditNotes: action.payload,
                isSuccess: true,
                isLoading: false,
            };

        case ActionTypes.EDIT_CREDIT_NOTE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.ADD_CREDIT_NOTE_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };

        case ActionTypes.ADD_CREDIT_NOTE_SUCCESS:
            return {
                ...state,
                dataEditCreditNotes: action.payload,
                isSuccess: true,
                isLoading: false,
            };

        case ActionTypes.ADD_CREDIT_NOTE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
            };
        case ActionTypes.RESET_CREDIT_NOTE_SUCCESS:
            return {
                ...state,
                isSuccess: false,
            };
        default:
            return state;
    }
};
export default CreditNotesReducer;
