import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import i18n from "../../../../i18n";

import ActionTypes from "./types";
import {
  fetchExpensesProjectSuccess,
  fetchExpensesProjectFailed,
  fetchFilterYearExpensePrjSuccess,
  fetchFilterYearExpensePrjFailed,
  fetchExpenseCategorySuccess,
  fetchExpenseCategoryFailed,
  fetchCreateExpensePrjSuccess,
  fetchCreateExpensePrjFailed,
  filterProjectExpensesFailed,
  filterProjectExpensesSuccess,
  fetchCustomerSuccess,
  fetchCustomerFailed,
  fetchPaymentModeSuccess,
  fetchPaymentModeFailed,
  fetchTaxSuccess,
  fetchTaxFailed,
} from "./actions";
import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import {
  getExpensesProject,
  filterYearExpensePrj,
  getExpenseCategory,
  createExpensesProject,
  getPaymentMode,
  getCustomer,
  getTax,
  filterProjectExpenses,
} from "./api";
import { responeCode, typeMessage } from "../../../../constants/Const";
import {
  throwErrorMessage,
  parseDataNotifications,
} from "../../../../helpers/HandleError";

function* fetchExpensesProjectSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(getExpensesProject, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result
    ) {
      yield put(fetchExpensesProjectSuccess(response.data.result));
    } else {
      yield put(fetchExpensesProjectFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchExpensesProjectFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* filterYearExpensePrjSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(filterYearExpensePrj, payload);
    if (response && response.data) {
      yield put(fetchFilterYearExpensePrjSuccess(response.data));
    } else {
      yield put(fetchFilterYearExpensePrjFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchFilterYearExpensePrjFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* fetchExpenseCategorySaga(data) {
  const { payload } = data;
  try {
    const response = yield call(getExpenseCategory, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result
    ) {
      yield put(fetchExpenseCategorySuccess(response.data.result));
    } else {
      yield put(fetchExpenseCategoryFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchExpenseCategoryFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* fetchCustomerSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(getCustomer, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result
    ) {
      yield put(fetchCustomerSuccess(response.data.result));
    } else {
      yield put(fetchCustomerFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchCustomerFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* fetchPaymentModeSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(getPaymentMode, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result
    ) {
      yield put(fetchPaymentModeSuccess(response.data.result));
    } else {
      yield put(fetchPaymentModeFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchPaymentModeFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* fetchCreateExpensePrjSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(createExpensesProject, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result
    ) {
      yield put(fetchCreateExpensePrjSuccess(response.data.result));
    } else {
      yield put(fetchCreateExpensePrjFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchCreateExpensePrjFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* fetchTaxSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(getTax, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result
    ) {
      yield put(fetchTaxSuccess(response.data.result));
    } else {
      yield put(fetchTaxFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchTaxFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

//Filter project expenses
function* filterProjectExpensesSaga(action) {
  try {
    const response = yield call(filterProjectExpenses, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result
    ) {
      yield put(filterProjectExpensesSuccess(response.data.result));
    } else {
      yield put(filterProjectExpensesFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(filterProjectExpensesFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

export function* expenseProjectSaga() {
  yield takeEvery(ActionTypes.FETCH_EXPENSES_PROJECT, fetchExpensesProjectSaga);
  yield takeEvery(ActionTypes.FETCH_FILTER_YEAR_EXPENSE_PRJ, filterYearExpensePrjSaga);
  yield takeEvery(ActionTypes.FETCH_EXPENSE_CATEGORY, fetchExpenseCategorySaga);
  yield takeEvery(ActionTypes.FETCH_CUSTOMER, fetchCustomerSaga);
  yield takeEvery(ActionTypes.FETCH_PAYMENT_MODE, fetchPaymentModeSaga);
  yield takeEvery(ActionTypes.FETCH_TAX, fetchTaxSaga);
  yield takeEvery(ActionTypes.FETCH_CREATE_EXPENSE_PRJ, fetchCreateExpensePrjSaga);
  yield takeEvery(ActionTypes.FILTER_PROJECT_EXPENSES_REQUEST, filterProjectExpensesSaga);
}

function* ExpenseProjectSaga() {
  yield all([fork(expenseProjectSaga)]);
}

export default ExpenseProjectSaga;
