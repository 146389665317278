const ActionTypes = {
    GET_PROPOSAL_REQUEST: "GET_PROPOSAL_REQUEST",
    GET_PROPOSAL_SUCCESS: "GET_PROPOSAL_SUCCESS",
    GET_PROPOSAL_FAILED: "GET_PROPOSAL_FAILED",

    CREATE_PROPOSAL_REQUEST: "CREATE_PROPOSAL_REQUEST",
    CREATE_PROPOSAL_SUCCESS: "CREATE_PROPOSAL_SUCCESS",
    CREATE_PROPOSAL_FAILED: "CREATE_PROPOSAL_FAILED",

    GET_LIST_CUSTOMER_REQUEST: "GET_LIST_CUSTOMER_REQUEST",
    GET_LIST_CUSTOMER_FAILED: "GET_LIST_CUSTOMER_FAILED",
    GET_LIST_CUSTOMER_SUCCESS: "GET_LIST_CUSTOMER_SUCCESS",

    FETCH_LIST_STAFF: "FETCH_LIST_STAFF",
    FETCH_LIST_STAFF_SUCCESS: "FETCH_LIST_STAFF_SUCCESS",
    FETCH_LIST_STAFF_FAILED: "FETCH_LIST_STAFF_FAILED",

    GET_PROPOSAL_BY_ID: "GET_PROPOSAL_BY_ID",
    GET_PROPOSAL_BY_ID_SUCCESS: "GET_PROPOSAL_BY_ID_SUCCESS",
    GET_PROPOSAL_BY_ID_FAILED: "GET_PROPOSAL_BY_ID_FAILED",

    DELETE_PROPOSAL: "DELETE_PROPOSAL",
    DELETE_PROPOSAL_SUCCESS: "DELETE_PROPOSAL_SUCCESS",
    DELETE_PROPOSAL_FAILED: "DELETE_PROPOSAL_FAILED",

    EDIT_PROPOSAL_REQUEST: "EDIT_PROPOSAL_REQUEST",
    EDIT_PROPOSAL_SUCCESS: "EDIT_PROPOSAL_SUCCESS",
    EDIT_PROPOSAL_FAILED: "EDIT_PROPOSAL_FAILED",

};
export default ActionTypes;