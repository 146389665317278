const ActionTypes = {
    FETCH_LIST_LEADS: "FETCH_LIST_LEADS",
    FETCH_LIST_LEADS_FAILED: "FETCH_LIST_LEADS_FAILED",
    FETCH_LIST_LEADS_SUCCESS: "FETCH_LIST_LEADS_SUCCESS",
    GET_LIST_CURRENCY: "GET_LIST_CURRENCY",
    GET_LIST_CURRENCY_FAILED: "GET_LIST_CURRENCY_FAILED",
    GET_LIST_CURRENCY_SUCCESS: "GET_LIST_CURRENCY_SUCCESS",
    GET_TASK_BY_LEAD: "GET_TASK_BY_LEAD",
    GET_TASK_BY_LEAD_SUCCESS: "GET_TASK_BY_LEAD_SUCCESS",
    GET_TASK_BY_LEAD_FAILED: "GET_TASK_BY_LEAD_FAILED",
    DELETE_LEAD: "DELETE_LEAD",
    DELETE_LEAD_FAILED: "DELETE_LEAD_FAILED",
    DELETE_LEAD_SUCCESS: "DELETE_LEAD_SUCCESS",
    FETCH_LIST_STATUS_LEADS: "FETCH_LIST_STATUS_LEADS",
    FETCH_LIST_STATUS_LEADS_SUCCESS: "FETCH_LIST_STATUS_LEADS_SUCCESS",
    FETCH_LIST_STATUS_LEADS_FAILED: "FETCH_LIST_STATUS_LEADS_FAILED",
    UPDATE_STATUS_LEAD: "UPDATE_STATUS_LEAD",
    UPDATE_STATUS_LEAD_SUCCESS: "UPDATE_STATUS_LEAD_SUCCESS",
    UPDATE_STATUS_LEAD_FAILED: "UPDATE_STATUS_LEAD_FAILED",
    GET_LIST_CREATED_BY: "GET_LIST_CREATED_BY",
    GET_LIST_CREATED_BY_SUCCESS: "GET_LIST_CREATED_BY_SUCCESS",
    GET_LIST_CREATED_BY_FAILED: "GET_LIST_CREATED_BY_FAILED",
    GET_LIST_SOURCE: "GET_LIST_SOURCE",
    GET_LIST_SOURCE_SUCCESS: "GET_LIST_SOURCE_SUCCESS",
    GET_LIST_SOURCE_FAILED: "GET_LIST_SOURCE_FAILED",
    ENQUEUE_SNACKBAR: "ENQUEUE_SNACKBAR",
    CLOSE_SNACKBAR: "CLOSE_SNACKBAR",
    REMOVE_SNACKBAR: "REMOVE_SNACKBAR",
    CREATE_NEW_LEAD: "CREATE_NEW_LEAD",
    CREATE_NEW_LEAD_SUCCESS: "CREATE_NEW_LEAD_SUCCESS",
    CREATE_NEW_LEAD_FAILED: "CREATE_NEW_LEAD_FAILED",
    GET_LIST_TAGS_IN_LEAD: "GET_LIST_TAGS_IN_LEAD",
    GET_LIST_TAGS_IN_LEAD_SUCCESS: "GET_LIST_TAGS_IN_LEAD_SUCCESS",
    GET_LIST_TAGS_IN_LEAD_FAILED: "GET_LIST_TAGS_IN_LEAD_FAILED",
    GET_LIST_COUNTRY: "GET_LIST_COUNTRY",
    GET_LIST_COUNTRY_SUCCESS: "GET_LIST_COUNTRY_SUCCESS",
    GET_LIST_COUNTRY_FAILED: "GET_LIST_COUNTRY_FAILED",
    UPDATE_IS_CREATE_SUCCESS: "UPDATE_IS_CREATE_SUCCESS",
    UPDATE_LEAD: "UPDATE_LEAD",
    UPDATE_LEAD_SUCCESS: "UPDATE_LEAD_SUCCESS",
    UPDATE_LEAD_FAILED: "UPDATE_LEAD_FAILED",
    GET_LIST_PROPOSAL: "GET_LIST_PROPOSAL",
    GET_LIST_PROPOSAL_SUCCESS: "GET_LIST_PROPOSAL_SUCCESS",
    GET_LIST_PROPOSAL_FAILED: "GET_LIST_PROPOSAL_FAILED",
    GET_LIST_PROPOSAL_BY_LEAD: "GET_LIST_PROPOSAL_BY_LEAD",
    GET_LIST_PROPOSAL_BY_LEAD_SUCCESS: "GET_LIST_PROPOSAL_BY_LEAD_SUCCESS",
    GET_LIST_PROPOSAL_BY_LEAD_FAILED: "GET_LIST_PROPOSAL_BY_LEAD_FAILED",
    CREATE_PROPOSAL: "CREATE_PROPOSAL",
    CREATE_PROPOSAL_SUCCESS: "CREATE_PROPOSAL_SUCCESS",
    CREATE_PROPOSAL_FAILED: "CREATE_PROPOSAL_FAILED",
    SET_IS_NO_CONTACT: "SET_IS_NO_CONTACT",
    SET_IS_NO_CONTACT_SUCCESS: "SET_IS_NO_CONTACT_SUCCESS",
    SET_IS_NO_CONTACT_FAILED: "SET_IS_NO_CONTACT_FAILED",
    SET_IS_TRASH: "SET_IS_TRASH",
    SET_IS_TRASH_SUCCESS: "SET_IS_TRASH_SUCCESS",
    SET_IS_TRASH_FAILED: "SET_IS_TRASH_FAILED",
    SWITCH_TO_CUSTOMER: "SWITCH_TO_CUSTOMER",
    SWITCH_TO_CUSTOMER_SUCCESS: "SWITCH_TO_CUSTOMER_SUCCESS",
    SWITCH_TO_CUSTOMER_FAILED: "SWITCH_TO_CUSTOMER_FAILED",
    CREATE_TASK: "CREATE_TASK",
    CREATE_TASK_SUCCESS: "CREATE_TASK_SUCCESS",
    CREATE_TASK_FAILED: "CREATE_TASK_FAILED",
    SAVE_ACTIVITY: "SAVE_ACTIVITY",
    SAVE_ACTIVITY_SUCCESS: "SAVE_ACTIVITY_SUCCESS",
    SAVE_ACTIVITY_FAILED: "SAVE_ACTIVITY_FAILED",
    GET_LIST_ITEMS: "GET_LIST_ITEMS",
    GET_LIST_ITEMS_SUCCESS: "GET_LIST_ITEMS_SUCCESS",
    GET_LIST_ITEMS_FAILED: "GET_LIST_ITEMS_FAILED",
    GET_LIST_GROUP_ITEMS: "GET_LIST_GROUP_ITEMS",
    GET_LIST_GROUP_ITEMS_SUCCESS: "GET_LIST_GROUP_ITEMS_SUCCESS",
    GET_LIST_GROUP_ITEMS_FAILED: "GET_LIST_GROUP_ITEMS_FAILED",
    FETCH_LIST_PROPOSAL_LEAD: "FETCH_LIST_PROPOSAL_LEAD",
    FETCH_LIST_PROPOSAL_LEAD_SUCCESS: "FETCH_LIST_PROPOSAL_LEAD_SUCCESS",
    FETCH_LIST_PROPOSAL_LEAD_FAILED: "FETCH_LIST_PROPOSAL_LEAD_FAILED",
    FETCH_LIST_STAFF: "FETCH_LIST_STAFF",
    FETCH_LIST_STAFF_SUCCESS: "FETCH_LIST_STAFF_SUCCESS",
    FETCH_LIST_STAFF_FAILED: "FETCH_LIST_STAFF_FAILED",
    FETCH_LIST_REMINDER_LEAD: "FETCH_LIST_REMINDER_LEAD",
    FETCH_LIST_REMINDER_LEAD_SUCCESS: "FETCH_LIST_REMINDER_LEAD_SUCCESS",
    FETCH_LIST_REMINDER_LEAD_FAILED: "CREATE_REMINDER_LEAD_FAILED",
    CREATE_REMINDER_LEAD: "CREATE_REMINDER_LEAD",
    CREATE_REMINDER_LEAD_SUCCESS: "CREATE_REMINDER_LEAD_SUCCESS",
    CREATE_REMINDER_LEAD_FAILED: "CREATE_REMINDER_LEAD_FAILED",
    DELETE_REMINDER_LEAD: "DELETE_REMINDER_LEAD",
    DELETE_REMINDER_LEAD_SUCCESS: "DELETE_REMINDER_LEAD_SUCCESS",
    DELETE_REMINDER_LEAD_FAILED: "DELETE_REMINDER_LEAD_FAILED",
    UPDATE_REMINDER_LEAD: "UPDATE_REMINDER_LEAD",
    UPDATE_REMINDER_LEAD_SUCCESS: "UPDATE_REMINDER_LEAD_SUCCESS",
    UPDATE_REMINDER_LEAD_FAILED: "UPDATE_REMINDER_LEAD_FAILED",
    FETCH_NOTE_LEAD: "FETCH_NOTE_LEAD",
    FETCH_NOTE_LEAD_SUCCESS: "FETCH_NOTE_LEAD_SUCCESS",
    FETCH_NOTE_LEAD_FAILED: "FETCH_NOTE_LEAD_FAILED",
    CREATE_NOTE_LEAD: "CREATE_NOTE_LEAD",
    CREATE_NOTE_LEAD_SUCCESS: "CREATE_NOTE_LEAD_SUCCESS",
    CREATE_NOTE_LEAD_FAILED: "CREATE_NOTE_LEAD_FAILED",
    DELETE_NOTE_LEAD: "DELETE_NOTE_LEAD",
    DELETE_NOTE_LEAD_SUCCESS: "DELETE_NOTE_LEAD_SUCCESS",
    DELETE_NOTE_LEAD_FAILED: "DELETE_NOTE_LEAD_FAILED",
    UPDATE_NOTE_LEAD: "UPDATE_NOTE_LEAD",
    UPDATE_NOTE_LEAD_SUCCESS: "UPDATE_NOTE_LEAD_SUCCESS",
    UPDATE_NOTE_LEAD_FAILED: "UPDATE_NOTE_LEAD_FAILED",
    FETCH_LIST_ATTACHMENT_LEAD: "FETCH_LIST_ATTACHMENT_LEAD",
    FETCH_LIST_ATTACHMENT_LEAD_SUCCESS: "FETCH_LIST_ATTACHMENT_LEAD_SUCCESS",
    FETCH_LIST_ATTACHMENT_LEAD_FAILED: "FETCH_LIST_NOTE_LEAD_FAILED",
    CREATE_ATTACHMENT_LEAD: "CREATE_ATTACHMENT_LEAD",
    CREATE_ATTACHMENT_LEAD_SUCCESS: "CREATE_ATTACHMENT_LEAD_SUCCESS",
    CREATE_ATTACHMENT_LEAD_FAILED: "CREATE_ATTACHMENT_LEAD_FAILED",
    DELETE_ATTACHMENT_LEAD: "DELETE_ATTACHMENT_LEAD",
    DELETE_ATTACHMENT_LEAD_SUCCESS: "DELETE_ATTACHMENT_LEAD_SUCCESS",
    DELETE_ATTACHMENT_LEAD_FAILED: "DELETE_ATTACHMENT_LEAD_FAILED",
    FETCH_LIST_TASK_LEAD: "FETCH_LIST_TASK_LEAD",
    FETCH_LIST_TASK_LEAD_SUCCESS: "FETCH_LIST_TASK_LEAD_SUCCESS",
    FETCH_LIST_TASK_LEAD_FAILED: "FETCH_LIST_TASK_LEAD_FAILED",
    CONVERT_TO_CUSTOMER_LEAD_PENDING: "CONVERT_TO_CUSTOMER_LEAD_PENDING",
    CONVERT_TO_CUSTOMER_LEAD_SUCCESS: "CONVERT_TO_CUSTOMER_LEAD_SUCCESS",
    CONVERT_TO_CUSTOMER_LEAD_FAILED: "CONVERT_TO_CUSTOMER_LEAD_FAILED",
    GET_CONVERT_TO_CUSTOMER: "GET_CONVERT_TO_CUSTOMER",    
};
export default ActionTypes;