import ActionTypes from "./types";

const INIT_STATE = {
  isLoading: false,
  listExpenses: [],
  listCustomer: [],
  filterYearExpensePrj: {},
  listExpenseCategory: [],
  listPaymentMode: [],
  listTax: [],
  isCreateExpense: false,
};

const ExpenseProjectReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_EXPENSES_PROJECT:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_EXPENSES_PROJECT_SUCCESS:
      return {
        ...state,
        listExpenses: action.payload,
        isLoading: false,
        isCreateExpense: false,
      };

    case ActionTypes.FETCH_EXPENSES_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_FILTER_YEAR_EXPENSE_PRJ:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_FILTER_YEAR_EXPENSE_PRJ_SUCCESS:
      return {
        ...state,
        filterYearExpensePrj: action.payload,
        isLoading: false,
      };

    case ActionTypes.FETCH_FILTER_YEAR_EXPENSE_PRJ_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.FETCH_EXPENSE_CATEGORY:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_EXPENSE_CATEGORY_SUCCESS:
      return {
        ...state,
        listExpenseCategory: action.payload,
        isLoading: false,
      };

    case ActionTypes.FETCH_EXPENSE_CATEGORY_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.FETCH_PAYMENT_MODE:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_PAYMENT_MODE_SUCCESS:
      return {
        ...state,
        listPaymentMode: action.payload,
        isLoading: false,
      };

    case ActionTypes.FETCH_PAYMENT_MODE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_TAX:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_TAX_SUCCESS:
      return {
        ...state,
        listTax: action.payload,
        isLoading: false,
      };
    case ActionTypes.FETCH_TAX_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_CREATE_EXPENSE_PRJ:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_CREATE_EXPENSE_PRJ_SUCCESS:
      return {
        ...state,
        isCreateExpense: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_CREATE_EXPENSE_PRJ_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FETCH_CUSTOMER:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        listCustomer: action.payload,
        isLoading: false,
      };

    case ActionTypes.FETCH_CUSTOMER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FILTER_PROJECT_EXPENSES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FILTER_PROJECT_EXPENSES_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        listExpenses: action.payload,
      };
    case ActionTypes.FILTER_PROJECT_EXPENSES_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};

export default ExpenseProjectReducer;
