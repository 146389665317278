// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import ActionTypes from "./types";
import {
    getListStaffFailed,
    getListStaffSuccess,
    createNewStaffSuccess,
    createNewStaffFailed,
    editStaffSuccess,
    editStaffFailed,
    deleteStaffSuccess,
    deleteStaffFailed,
    filterStaffSuccess,
    filterStaffFailed,
    getDepartmentsSuccess,
    getDepartmentsFailed,
    getStaffEditSuccess,
    getStaffEditFailed,
    getStaffNoteSuccess,
    getStaffNoteFailure,
    getStaffTimesheetSuccess,
    getStaffTimesheetFailure,
    setStaffStatusSuccess,
    setStaffStatusFailure,
    getListStaffRequest,
    filterStaffRequest,
    getListRolesFailed,
    getListRolesSuccess,
    getListPermissionsFailed,
    getListPermissionsSuccess,
    changePasswordSuccess,
    changePasswordFailure,
    resetPasswordSuccess,
    resetPasswordFailure,
    staffChangeAvatarSuccess,
    staffChangeAvatarFailed,
} from "./actions";
import { enqueueSnackbar } from "../../../redux/CommonReducer";
import {
    getListStaff,
    createStaff,
    editStaff,
    deleteStaff,
    filterStaff,
    getDepartments,
    getStaffEdit,
    getStaffNote,
    addStaffNote,
    // getStaffTimeSheet,
    getStaffTimesheet,
    setStaffStatus,
    getListRoles,
    getListPermissions,
    resetPassword,
    changePassword,
    staffChangeAvatar,
} from "./api";
import { responeCode, typeMessage } from "../../../constants/Const";
import {
    throwErrorMessage,
    parseDataNotifications,
} from "../../../helpers/HandleError";

// function* getListStaffSaga() {
//     try {
//         const response = yield call(getListStaff);
//         if (
//             response &&
//             response.data &&
//             response.data.error_code === responeCode.success &&
//             response.data.result
//         ) {
//             yield put(getListStaffSuccess(response.data.result));
//         } else {
//             yield put(getListStaffFailed());
//             yield put(
//                 enqueueSnackbar(
//                     parseDataNotifications(
//                         response.data.error_mess,
//                         typeMessage.error
//                     )
//                 )
//             );
//         }
//     } catch (error) {
//         yield put(getListStaffFailed());
//         yield put(
//             enqueueSnackbar(
//                 parseDataNotifications(
//                     throwErrorMessage(error),
//                     typeMessage.warning
//                 )
//             )
//         );
//     }
// }

function* getListPermissionsSaga() {
    try {
        const response = yield call(getListPermissions);
        if (
            response &&
            response.data &&
            // response.data.error_code === responeCode.success &&
            response.data.result
        ) {
            yield put(getListPermissionsSuccess(response.data.result));
        } else {
            yield put(getListPermissionsFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListPermissionsFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListRolesSaga(action) {
    try {
        const response = yield call(
            getListRoles,
            action.data
        );
        if (
            response &&
            response.data &&
            // response.data.error_code === responeCode.success &&
            response.data.result
        ) {
            yield put(getListRolesSuccess(response.data.result));
        } else {
            yield put(getListRolesFailed(response.data.error_mess));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListRolesFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* createStaffSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(createStaff, payload);
        if (response && response.data && response.data.error_code === 0) {
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
            yield put(createNewStaffSuccess());
        } else {
            yield put(createNewStaffFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(createNewStaffFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* editStaffSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(editStaff, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(editStaffSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(editStaffFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(editStaffFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteStaffSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(deleteStaff, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteStaffSuccess(payload));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteStaffFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteStaffFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* filterStaffSaga(action) {
    try {
        const response = yield call(filterStaff, action);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success &&
            response.data.result
        ) {
            yield put(filterStaffSuccess(response.data.result));
        } else {
            yield put(filterStaffFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(filterStaffFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getDepartmentsSaga() {
    try {
        const response = yield call(getDepartments);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success &&
            response.data.result
        ) {
            yield put(getDepartmentsSuccess(response.data.result));
        } else {
            yield put(getDepartmentsFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getDepartmentsFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getStaffEditSaga(action) {
    const id = action.payload;
    try {
        const response = yield call(getStaffEdit, id);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success &&
            response.data.result
        ) {
            yield put(getStaffEditSuccess(response.data.result));
        } else {
            yield put(getStaffEditFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getStaffEditFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* getStaffNoteSaga(action) {
    const id = action.payload.id;
    try {
        const response = yield call(getStaffNote, id);
        if (
            response &&
            response.data &&
            response.data.error_code === 0 &&
            response.data.result
        ) {
            yield put(getStaffNoteSuccess(response.data.result));
        } else {
            yield put(getStaffNoteFailure());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getStaffNoteFailure());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* addStaffNoteSaga(action) {
    const id = action.payload.id;
    const { params } = action.payload;
    try {
        yield call(addStaffNote, id, params);
        const response = yield call(getStaffNote, id);
        if (
            response &&
            response.data &&
            response.data.error_code === 0 &&
            response.data.result
        ) {
            yield put(getStaffNoteSuccess(response.data.result));
        } else {
            yield put(getStaffNoteFailure());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getStaffNoteFailure());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* getStaffTimesheetSaga(action) {
    const { params, id } = action.payload;
    try {
        const response = yield call(getStaffTimesheet, id, params);
        // const response = yield call(getStaffNote, id);
        if (
            response &&
            response.data &&
            response.data.error_code === 0 &&
            response.data.result
        ) {
            yield put(getStaffTimesheetSuccess(response.data.result));
        } else {
            yield put(getStaffTimesheetFailure());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getStaffNoteFailure());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* setStaffStatusSaga(action) {
    const { id } = action.payload;

    try {
        const response = yield call(setStaffStatus, id);

        if (
            response &&
            response.data &&
            response.data.error_code === 0 &&
            response.data.result
        ) {
            yield put(setStaffStatusSuccess());
            yield put(
                filterStaffRequest("", {
                    page: 1,
                    limit: 15,
                })
            );
        } else {
            yield put(setStaffStatusFailure());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getStaffNoteFailure());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* resetPasswordSaga(action) {
    const { id } = action.payload;

    try {
        const response = yield call(resetPassword, id);
        console.log("response", response.data.error_code)
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success &&
            response.data.result
        ) {
            yield put(resetPasswordSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(resetPasswordFailure());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(resetPasswordFailure());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* changePasswordSaga(action) {

    try {
        const response = yield call(changePassword, action.payload);

        if (
            response &&
            response.data &&
            response.data.error_code === 0 &&
            response.data.result
        ) {
            yield put(changePasswordSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(changePasswordFailure());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(changePasswordFailure());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* staffChangeAvatarSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(staffChangeAvatar, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(staffChangeAvatarSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(staffChangeAvatarFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(staffChangeAvatarFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

export function* adminStaffSaga() {
    // yield takeEvery(ActionTypes.GET_LIST_STAFF_REQUEST, getListStaffSaga);
    yield takeEvery(ActionTypes.CREATE_STAFF_REQUEST, createStaffSaga);
    yield takeEvery(ActionTypes.EDIT_STAFF_REQUEST, editStaffSaga);
    yield takeEvery(ActionTypes.DELETE_STAFF_REQUEST, deleteStaffSaga);
    yield takeEvery(ActionTypes.FILTER_STAFF_REQUEST, filterStaffSaga);
    yield takeEvery(ActionTypes.GET_DEPARTMENTS_REQUEST, getDepartmentsSaga);
    yield takeEvery(ActionTypes.GET_STAFF_EDIT_REQUEST, getStaffEditSaga);
    yield takeEvery(ActionTypes.GET_STAFF_NOTE_PENDING, getStaffNoteSaga);
    yield takeEvery(ActionTypes.ADD_STAFF_NOTE_PENDING, addStaffNoteSaga);
    yield takeEvery(ActionTypes.GET_STAFF_TIMESHEET_PENDING, getStaffTimesheetSaga);
    yield takeEvery(ActionTypes.SET_STAFF_STATUS_PENDING, setStaffStatusSaga);
    yield takeEvery(ActionTypes.GET_LIST_ROLES, getListRolesSaga);
    yield takeEvery(ActionTypes.GET_LIST_PERMISSIONS, getListPermissionsSaga);
    yield takeEvery(ActionTypes.RESET_PASSWORD_PENDING, resetPasswordSaga);
    yield takeEvery(ActionTypes.CHANGE_PASSWORD_PENDING, changePasswordSaga);
    yield takeEvery(ActionTypes.STAFF_CHANGE_AVATAR_REQUEST, staffChangeAvatarSaga);
}

export default adminStaffSaga;
